import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import mapStyles from "../jsonData/mapStyles.json";
import { ReactComponent as MarkerIcon } from "../assets/images/map-marker.svg";

// Marker component
const Marker = () => {
  return (
    <div
      style={{
        padding: "5px 5px",
        display: "inline-flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        transform: "translate(-50%, -90%)",
        position: "relative",
      }}
    >
      <MarkerIcon />
    </div>
  );
};

export default function GCheckoutMap(props) {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [locationMarker, setLocationMarker] = useState(props.center || {});
  useEffect(() => {
    props.places.map((m, i) => {
      m.show = false;
      m.id = i;
    });
    setMarkers(props.places || []);
    setLocationMarker(props.center);
  }, [props]);

  useEffect(() => {
    console.log(locationMarker);
    if (mapRef.current && locationMarker?.lat) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: locationMarker.lat,
        lng: locationMarker.lng,
      });
      mapRef.current.fitBounds(bounds);
      mapRef.current.setZoom(16);
    }
  }, [locationMarker]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["places", "geometry"],
      }}
      defaultCenter={props.center}
      defaultZoom={props.zoom}
      options={{
        zoomControl: false,
        fullscreenControl: false,
        styles: mapStyles,
      }}
      style={Object.assign(
        {},
        { height: "150px", borderRadius: 10, overflow: "hidden" },
        props.style
      )}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      onChildClick={() => console.log("child click")}
    >
      <Marker lat={locationMarker.lat} lng={locationMarker.lng} />
    </GoogleMapReact>
  );
}
