import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as Room } from "../assets/images/map-marker.svg";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";
import useWindowSize from "../DataFactory/useWindowSize";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

const Input = styled.input`
  border: 0;
  outline: none;
  width: 100%;
`;

export const LocationSearch = ({
  isNav,
  value,
  valueData,
  handleLocationChange,
  style,
}) => {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const [address, setAddress] = useState(value || "");
  const [selectedLoc, setSelectedLoc] = useState("");
  const { defaultAddress } = useContext(UserAddressContext);

  useEffect(() => {
    setAddress(value || "");
    setSelectedLoc(valueData || {});
  }, [value]);

  const handleSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    console.log(results[0].geometry.location);
    setAddress(value);
    let location = {
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      address: value,
    };
    console.log(location);
    setSelectedLoc(location);
    handleLocationChange(location);
  };

  useEffect(() => {
    console.log(value);
    console.log(defaultAddress);
    if (value === "" || value === null) {
      setAddress(defaultAddress?.title);
      handleLocationChange({
        lat: defaultAddress?.lat,
        lng: defaultAddress?.lng,
        address: defaultAddress?.title,
      });
    }
    if (typeof value === "object") {
      if (value.lat == 0) {
        setAddress(defaultAddress?.title);
        handleLocationChange({
          lat: defaultAddress?.lat,
          lng: defaultAddress?.lng,
          address: defaultAddress?.title,
        });
      } else {
        setAddress(defaultAddress?.title);
      }
    }
  }, [defaultAddress]);

  return (
    <div
      style={Object.assign({}, style, {
        width: "100%",
        maxWidth: "450px",
        display: "inline-block",
        position: "relative",
      })}
    >
      <div
        style={{
          background: "#fff",
          border: 0,
          height: "45px",
          fontSize: "0.97rem",
          // textAlign: "left",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Room
          style={{
            width: "15px",
            height: "15px",
            color: "#ccc",
            verticalAlign: "middle",
          }}
        />
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ width: "100%", textAlign: "left" }}>
              <p
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  padding: "12px 10px",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Input
                  {...getInputProps({
                    placeholder: strings?.w_search_your_location,
                  })}
                />
              </p>

              {suggestions && suggestions.length > 0 ? (
                <div
                  style={{
                    background: "#fff",
                    position: "absolute",
                    left: "0",
                    zIndex: "8",
                    width: "100%",
                    maxWidth: "450px",
                    boxSizing: "border-box",
                    padding: "0 0 5px 5px",
                    order: 0,
                    top: width > 767 ? "65px" : "50px",
                    borderRadius: "5px",
                    boxShadow:
                      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: "15px 10px",
                      margin: "0",
                      maxHeight: "220px",
                      overflow: "auto",
                      textAlign: "left",
                      display: "block",
                      textAlign: I18nManager.getConstants().isRTL
                        ? "right"
                        : "",
                    }}
                  >
                    {suggestions.map((suggestion, ind) => {
                      const style = {
                        display: "flex",
                        backgroundColor: suggestion.active
                          ? "#e2e2e2"
                          : "#ffffff",
                        cursor: "pointer",
                        color: "#333",
                        fontSize: "15px",
                        borderBottom: "1px solid #e2e2e2",
                        alignItems: "center",
                        padding: "12px 10px",
                        marginRight: 10,
                      };
                      return (
                        <li
                          key={ind}
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <Room
                            style={{
                              width: "15px",
                              height: "15px",
                              color: "#ccc",
                              verticalAlign: "middle",
                              marginRight: 10,
                            }}
                          />{" "}
                          <p style={{ margin: 0, width: "calc(100% - 25px)" }}>
                            {suggestion.description}
                          </p>
                          <br />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </div>
  );
};
