import React, { useState, useEffect, useRef, useContext } from "react";
import { Grid } from "@mui/material";
import useWindowSize from "../DataFactory/useWindowSize";
import { I18nManager } from "react-native";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../contexts/userLocation";
import useDataFctory from "../ApiService/useDataFactory";
import { useParams } from "react-router-dom";
import StoreView from "../Elements/gStoreView";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import { GCardShimmer, GStoresShimmer } from "../Elements/gShimmer";
import GView from "../Elements/GView";
import GStoreSubClasses from "../Elements/gStoreSubClasses";
import StoreFilters from "../Components/storeFilters";
import StoreMobileFilters from "../Components/storeFiltersMobileView";
import GProductsSlider from "../Elements/gProductsSliderView";
import styled from "styled-components";

const HR = styled.hr`
  border-color: ${({ theme }) => theme.body};
  width: 40px;
  margin: 0 5px 10px;
`;

export default function Stores() {
  let { classId } = useParams();
  const [classData, setClassData] = useState({});
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubClass, setSelectedSubClass] = useState("");
  const [sortFilters, setSortFilters] = useState({
    orderby: "",
    popular: "",
    new: "",
    favourite: "",
    under_30: "",
    min_rating: "",
    discounts: "",
    price_range: "",
    order_type: "",
  });
  const {
    loading,
    Shimmer,
    Placeholder,
    loadMore,
    data: storesList,
    refreshData,
    pagination,
    fullData,
  } = useDataFctory("store", true, {
    class: classId || "",
    sub_class: selectedSubClass || "",
    include_product_groups: true,
    geofence: "",
    location: userLocation?.lat
      ? userLocation.lat + "," + userLocation.lng
      : "",
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    let classInfo = settings.classes.filter((c) => c.id == classId);
    console.log(classInfo);
    setClassData(classInfo.length > 0 ? classInfo[0] : {});
  }, [classId]);

  useEffect(() => {
    if (isLoading == false) {
      refreshData({
        class: classId || "",
        sub_class: selectedSubClass || "",
        include_product_groups: true,
        geofence: "",
        location: userLocation?.lat
          ? userLocation.lat + "," + userLocation.lng
          : "",
        ...sortFilters,
      });
    }
  }, [sortFilters, selectedSubClass]);

  return (
    <React.Fragment>
      <div className="main-content" style={{ minHeight: "400px" }}>
        <div style={{ padding: "10px 15px" }}>
          {isLoading === true ? (
            <GStoresShimmer />
          ) : (
            <React.Fragment>
              {settings.classes
                .filter((c) => c.id == classId)
                .map((clas) => {
                  return <GText g3 bold text={clas.title} />;
                })}

              {width <= 991 && (
                <GView style={{ padding: "20px 0 0" }}>
                  <StoreMobileFilters
                    filters={sortFilters}
                    onApply={(filter) => setSortFilters({ ...filter })}
                  />
                </GView>
              )}

              {settings.classes
                .filter((c) => c.id == classId)
                .map((clas) => {
                  return (
                    <React.Fragment key={clas.id}>
                      {clas.childs && clas.childs.length > 0 && (
                        <GStoreSubClasses
                          data={clas.childs}
                          setSelectedSubClass={setSelectedSubClass}
                        />
                      )}
                    </React.Fragment>
                  );
                })}

              {fullData?.product_groups &&
                fullData?.product_groups?.length > 0 &&
                fullData?.product_groups.map((p) => {
                  return (
                    <GView style={{ margin: "30px 0 15px" }}>
                      <GText
                        g4
                        semi
                        text={p.title}
                        style={{ margin: "5px 5px 0 5px" }}
                      />
                      <HR />
                      <GProductsSlider
                        data={p.products || []}
                        isOfferView={true}
                        showGroupIndex={true}
                        cardStyle={{
                          boxShadow: "rgba(204, 204, 204, 0.52) 0px 0px 10px",
                          margin: "5px 7px",
                        }}
                      />
                    </GView>
                  );
                })}

              <GView
                style={{
                  padding: "10px 0 30px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                {width > 991 && (
                  <GView
                    style={{
                      width: "30%",
                      borderRight: "1px solid #e2e2e2",
                      maxWidth: "250x",
                      padding: "10px 10px 0 0",
                    }}
                  >
                    <StoreFilters
                      filters={sortFilters}
                      onApply={(filter) => setSortFilters({ ...filter })}
                    />
                  </GView>
                )}
                <GView
                  style={{
                    width: width > 991 ? "70%" : "100%",
                    flex: 1,
                    padding: width > 991 ? "0 0 0 15px" : 0,
                    boxSizing: "border-box",
                  }}
                >
                  <GText
                    semi
                    med
                    dir={I18nManager.getConstants().isRTL ? "rtl" : "ltr"}
                    text={pagination?.total + " " + strings?.w_store}
                    style={{
                      fontStyle: "italic",
                      width: "auto",
                      flex: "1",
                      margin: "20px 0",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      alignItems: "center",
                    }}
                  ></div>

                  {loading === true ? (
                    <GCardShimmer
                      width={
                        width <= 767
                          ? "calc(100% - 16px)"
                          : width > 767 && width <= 1200
                          ? "calc(50% - 16px)"
                          : "calc(25% - 16px)"
                      }
                      height={250}
                      itemLength={
                        width <= 767 ? 2 : width > 767 && width <= 1200 ? 4 : 7
                      }
                    />
                  ) : storesList && storesList.length > 0 ? (
                    <React.Fragment>
                      <Grid container spacing={4}>
                        {storesList.map((store, i) => {
                          return (
                            <Grid
                              item
                              xl={classData.list_theme === "row" ? 6 : 3}
                              lg={classData.list_theme === "row" ? 6 : 4}
                              md={classData.list_theme === "row" ? 6 : 6}
                              sm={classData.list_theme === "row" ? 12 : 6}
                              xs={12}
                              key={i}
                            >
                              <StoreView
                                data={store}
                                theme={classData.list_theme}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      {pagination.next_page_url && (
                        <GButton
                          variant="condensed"
                          children={strings?.w_load_more}
                          type="button"
                          onClick={() => loadMore()}
                          style={{ display: "flex", margin: "30px auto 10px" }}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <Placeholder type="store" />
                  )}
                </GView>
              </GView>
            </React.Fragment>
          )}
        </div>
        <br />
      </div>
    </React.Fragment>
  );
}
