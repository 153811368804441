import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../DataFactory/useSettings";

const UserAddressContext = React.createContext(); // line A - creating the context

function UserDefaultAddress({ children }) {
  const { settings } = useContext(SettingsContext);
  const [defaultAddress, setDefaultAddress] = useState({
    lat: 0,
    lng: 0,
    address: "Dominician Republic",
  });

  useEffect(() => {
    console.log(settings);
    if (settings && settings?.default_location) {
      let defaultLoc = JSON.parse(settings.default_location);
      console.log(defaultLoc);
      setDefaultAddress(defaultLoc);
    }
  }, [settings]);

  return (
    <UserAddressContext.Provider value={{ defaultAddress }}>
      {children}
    </UserAddressContext.Provider>
  );
}

export { UserDefaultAddress, UserAddressContext };
