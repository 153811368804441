import React, { useContext } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";

const Placeholder = ({ type, action }) => {
  const [strings] = useContext(StringsContext);

  const PlaceholderData = {
    coupon: {
      icon: require("../assets/images/placeholders/e-coupon.png"),
      title: strings?.s_placeholder_coupon_title,
      subtitle: strings?.s_placeholder_coupon_subtitle,
    },
    cart: {
      icon: require("../assets/images/placeholders/e-cart.png"),
      title: strings?.s_placeholder_cart_title,
      subtitle: strings?.s_placeholder_cart_subtitle,
    },
    address: {
      icon: require("../assets/images/placeholders/e-address.png"),
      title: strings?.s_placeholder_address_title,
      subtitle: strings?.s_placeholder_address_subtitle,
    },
    faqs: {
      icon: require("../assets/images/placeholders/e-faq.png"),
      title: strings?.s_placeholder_faqs_title,
      subtitle: strings?.s_placeholder_faqs_subtitle,
    },
    order: {
      icon: require("../assets/images/placeholders/e-order.png"),
      title: strings?.s_placeholder_order_title,
      subtitle: strings?.s_placeholder_order_subtitle,
    },
    reviews: {
      icon: require("../assets/images/placeholders/e-review.png"),
      title: strings?.s_placeholder_reviews_title,
      subtitle: strings?.s_placeholder_reviews_subtitle,
    },
    store: {
      icon: require("../assets/images/placeholders/e-store.png"),
      title: strings?.s_placeholder_store_title,
      subtitle: strings?.s_placeholder_store_subtitle,
    },
    products: {
      icon: require("../assets/images/placeholders/e-product.png"),
      title: strings?.s_placeholder_products_title,
      subtitle: strings?.s_placeholder_products_subtitle,
    },
    wishlist: {
      icon: require("../assets/images/placeholders/e-favourite.png"),
      title: strings?.s_placeholder_wishlist_title,
      subtitle: strings?.s_placeholder_wishlist_subtitle,
    },
    wallet: {
      icon: require("../assets/images/placeholders/e-wallet.png"),
      title: strings?.s_placeholder_wallet_title,
      subtitle: strings?.s_placeholder_wallet_subtitle,
    },
    other: {
      icon: require("../assets/images/placeholders/e-other.png"),
      title: strings?.s_placeholder_other_title,
      subtitle: strings?.s_placeholder_other_subtitle,
    },
    substitute: {
      icon: null,
      title: "",
      subtitle: "No Products Available.",
    },
    categories: {
      icon: require("../assets/images/placeholders/e-product.png"),
      title: strings?.s_placeholder_products_title,
      subtitle: strings?.s_placeholder_products_subtitle,
    },
    login: {
      icon: require("../assets/images/placeholders/e-product.png"),
      title: strings?.s_placeholder_login_title,
      subtitle: strings?.s_placeholder_login_subtitle,
      button: {
        title: strings?.w_login,
      },
    },
    internet: {
      icon: require("../assets/images/placeholders/e-internet.png"),
      title: strings?.s_placeholder_internet_title,
      subtitle: strings?.s_placeholder_internet_subtitle,
      button: {
        title: strings?.w_retry,
      },
    },
  };
  const data = PlaceholderData[type] || PlaceholderData["other"];

  return (
    <View
      style={{
        padding: 30,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        // height: '100%',
        marginTop: 10,
        paddingBottom: 100,
        flex: 1,
      }}
    >
      {data.icon && (
        <Image
          source={data.icon}
          style={{
            height: 130,
            width: 225,
            marginBottom: 30,
          }}
        />
      )}
      {data.title != "" && (
        <GText
          g3
          bold
          text={data.title}
          style={{ marginBottom: 5, textAlign: "center", color: "#555" }}
        />
      )}
      <GText
        g4
        text={data.subtitle}
        style={{ textAlign: "center", color: "#555", fontSize: "15.5px" }}
      />

      {data.button && (
        <TouchableOpacity
          onPress={() => (action ? action() : null)}
          style={{
            padding: 40,
            paddingVertical: 7,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 30,
            borderWidth: 1,
            borderColor: "#ccc",
            width: "auto",
          }}
        >
          <GText g3 text={data.button.title} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Placeholder;
