import React, { Component, useRef, useEffect } from "react";
import Pickup from "../assets/images/mark-p.png";
import Dropoff from "../assets/images/mark-d.png";
import driverIcon from "../assets/images/gms-marker.svg";
import StoreIcon from "../assets/images/store_pickup.png";
import HomeIcon from "../assets/images/map-marker.svg";
import mapStyles from "../jsonData/mapStyles.json";

const GMap = (props) => {
  const googleMapRef = useRef(null);
  let googleMap = null;
  // list of icons

  // list of the marker object along with icon
  const markerList = [];

  useEffect(() => {
    if (props.agent?.latitude) {
      markerList.push({
        lat: parseFloat(props.agent?.latitude),
        lng: parseFloat(props?.agent?.longitude),
        icon: driverIcon,
        title: props?.agent?.title,
        address: props.agent?.title,
      });
    }
    if (props.type === "standard") {
      markerList.push(
        {
          lat: parseFloat(props.data?.pickup_lat),
          lng: parseFloat(props.data?.pickup_lng),
          icon: StoreIcon,
          title: "pickup",
          address: props.data.pickup_address,
        },
        {
          lat: parseFloat(props.data?.delivery_lat),
          lng: parseFloat(props.data?.delivery_lng),
          icon: HomeIcon,
          title: "delivery",
          address: props.data.delivery_address,
        }
      );
    } else {
      props.data.map((c) => {
        let coordinates = {
          lat: parseFloat(c.delivery_lat),
          lng: parseFloat(c.delivery_lng),
          icon: c.delivery_type === "pickup" ? Pickup : Dropoff,
          title: c.delivery_type,
          address: c.delivery_address,
        };
        markerList.push(coordinates);
      });
    }

    if (props.type == "standard") {
      if (
        props.routeJsonData?.routes &&
        props.routeJsonData?.routes.length > 0
      ) {
        googleMap = initGoogleMap();
        var bounds = new window.google.maps.LatLngBounds();
        showRoute(googleMap);
        markerList.map((x) => {
          const marker = createMarker(x);
          const infoWindow = createInfoWindow(x);

          bounds.extend(marker.position);
        });
        googleMap.fitBounds(bounds);
      }
    } else {
      googleMap = initGoogleMap();
      var bounds = new window.google.maps.LatLngBounds();

      markerList.map((x) => {
        const marker = createMarker(x);
        const infoWindow = createInfoWindow(x);

        // window.google.maps.event.addListener(marker, "click", function () {
        //   infoWindow.close(); // Close previously opened infowindow
        //   infoWindow.setContent(
        //     '<p class="infowindowstyle">' + x.address + "</p>"
        //   );
        //   infoWindow.open(googleMap, marker);
        // });
        bounds.extend(marker.position);
      });
      googleMap.fitBounds(bounds); // the map to contain all markers
    }
  }, [props]);

  function showRoute(googleMap) {
    if (typeof window.google.maps.geometry !== "undefined") {
      var polyline = new window.google.maps.Polyline({
        path: window.google.maps.geometry?.encoding?.decodePath(
          props.routeJsonData?.routes[0].overview_polyline.points
        ),
        map: googleMap,
        strokeColor: "#111",
        strokeWeight: 4,
      });
    }
  }

  // function calculateAndDisplayRoute(directionsService, directionsRenderer) {
  //   directionsService
  //     .route({
  //       origin: props.data[0]?.lat + "," + props.data[0]?.lng,
  //       destination: props.data[1]?.lat + "," + props.data[1]?.lng,
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     })
  //     .then((response) => {
  //       directionsRenderer.setDirections(response);
  //     });
  //   // .catch((e) => window.alert("Directions request failed due to " + status));
  // }

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: {
        lat: parseFloat(props.data?.agent?.latitude),
        lng: parseFloat(props.data?.agent?.longitude),
      },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: mapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      title: markerObj.address,
      icon: {
        url: markerObj.icon,
        // set marker width and height
        scaledSize:
          props.type === "standard"
            ? new window.google.maps.Size(30, 30)
            : new window.google.maps.Size(40, 40),
      },
    });

  const createInfoWindow = (markerObj) =>
    new window.google.maps.InfoWindow({
      content: markerObj.address,
    });

  return (
    <div
      ref={googleMapRef}
      style={{ width: "100%", height: props.height || 250 }}
    />
  );
};

export default GMap;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyAl8q9s5LY5qUCPybXG-FWIp717RMB8IqE'
// })(MapContainer)
