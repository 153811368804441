import React, { useContext } from "react";
import { FlatList, View } from "react-native";
import BackIcon from "../Elements/gBackIcon";
import GInfo from "../Elements/gInfo";
import useDataFctory from "../ApiService/useDataFactory";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import QuoteImage from "../assets/images/icons/quote.png";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderReviews(props) {
  const [strings] = useContext(StringsContext);
  const { loading, Shimmer, Placeholder, data, loadMore, pagination } =
    useDataFctory("reviews", true, { store_id: props.store.id });

  return (
    <React.Fragment>
      <div className="modal-header">
        <BackIcon onClick={() => props.setSRVisible(false)} />
        <GText g4 semi text={props.store.title} style={{ fontSize: "17px" }} />
      </div>
      <div className="modal-body">
        {loading === true ? (
          <Shimmer></Shimmer>
        ) : (
          <React.Fragment>
            {data.length > 0 ? (
              <p
                style={{
                  margin: "0 0 15px",
                  color: "#777",
                  fontStyle: "italic",
                }}
              >
                {data.length} {strings?.w_review}
              </p>
            ) : (
              ""
            )}
            <View style={{ flex: 1, flexDirection: "row" }}>
              <FlatList
                initialNumToRender={data.length}
                contentContainerStyle={{ flex: 1 }}
                keyExtractor={(data) => data.id}
                data={data}
                numColumns={1}
                renderItem={({ item }) => (
                  <div style={{ flex: 1 }}>
                    <div
                      style={{
                        background: "#fff",
                        padding: "15px 5px",
                        borderBottom: "1px solid #f2f2f2",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={QuoteImage}
                          alt="Quote"
                          style={{
                            width: "20px",
                            verticalAlign: "top",
                            height: "fit-content",
                          }}
                          width="20px"
                          height="auto"
                        />
                        <h4
                          style={{
                            marginBottom: "10px",
                            fontWeight: "400",
                            width: "calc(100% - 40px)",
                            padding: "0 10px",
                            flex: "1",
                            display: "inline-block",
                            lineHeight: "22px",
                            minHeight: "20px",
                          }}
                        >
                          {item.review}
                        </h4>
                      </div>
                      <GInfo
                        title=""
                        subtitle={item?.customer.title}
                        subtitle2={item?.created}
                        imgType="user"
                        photo={item?.customer.thumb_photo}
                        rating={item?.rating}
                        subtitleStyle={{ fontWeight: "400" }}
                      />
                    </div>
                  </div>
                )}
                ListEmptyComponent={Placeholder}
              />
            </View>

            {pagination.next_page_url ? (
              <GButton
                variant="condensed"
                children={strings?.w_load_more}
                type="button"
                onClick={() => loadMore()}
                style={{ display: "flex", margin: "10px auto" }}
              />
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </div>
      <br />
    </React.Fragment>
  );
}
