import React, { useState, useEffect } from "react";

const useLocalStorage = (key, initialValue = [], refresh = false) => {
  const [storedValue, setStoredValue] = useState(initialValue);
  const getStoredItem = async (key, initialValue) => {
    try {
      const item = window.localStorage.getItem(key);
      const value =
        item && typeof item !== "string" ? JSON.parse(item) : initialValue;
      setStoredValue(value);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStoredItem(key, initialValue);
  }, [refresh]);

  const setValue = async (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof valueToStore === "string") {
        window.localStorage.setItem(key, valueToStore);
      } else {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const onReceieveMessage = (e) => {
      const { key, newValue } = e;
      if (key === storedValue) {
        setStoredValue(JSON.parse(newValue));
      }
    };
    window.addEventListener("storage", onReceieveMessage);
    return () => window.removeEventListener("storage", onReceieveMessage);
  }, [storedValue, setStoredValue]);

  return [storedValue, setValue];
};

export default useLocalStorage;
