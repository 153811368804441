import React, { useState, useEffect, useContext } from "react";
import GMap from "./orderLocation";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as ChatIcon } from "../assets/images/chat.svg";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
import styled from "styled-components";
import { ReactComponent as StageStartIcon } from "../assets/images/stage-start.svg";
import { ReactComponent as StageStepIcon } from "../assets/images/stage-step.svg";
import { ReactComponent as StageEndIcon } from "../assets/images/stage-end.svg";

const StepsDiv = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  position: absolute;
  z-index: 9;
  margin: 10px 15px;

  svg {
    width: 20%;
    &.active {
      fill: ${({ theme }) => theme.body};

      polygon {
        fill: ${({ theme }) => theme.body};
      }
    }
  }
`;

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const App = (props) => {
  const [strings] = useContext(StringsContext);
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {props.type === "standard" && (
        <StepsDiv>
          <GText
            semi
            text={props.orderData?.current_stage?.eta_display || "--"}
          />
          <div style={{ display: "flex", margin: "6px 0" }}>
            {Array.apply(null, { length: 5 }).map(function (_, i) {
              return (
                <React.Fragment key={i}>
                  {i == 0 ? (
                    <StageStartIcon
                      className={
                        props.orderData?.current_stage?.step > i ? "active" : ""
                      }
                    />
                  ) : i == 4 ? (
                    <StageEndIcon
                      className={
                        props.orderData?.current_stage?.step > i ? "active" : ""
                      }
                    />
                  ) : (
                    <StageStepIcon
                      className={
                        props.orderData?.current_stage?.step > i ? "active" : ""
                      }
                    />
                  )}
                  {/* <img
                    src={i == 0 ? StageStart : i == 4 ? StageEnd : StageStep}
                    style={{ width: "20%" }}
                    className={
                      props.orderData?.current_stage?.step > i + 1
                        ? "active"
                        : ""
                    }
                  /> */}
                </React.Fragment>
              );
            })}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <GText g6 text={props.orderData?.current_stage?.title || "--"} />
            {props.orderData?.current_stage?.time_display && (
              <GText
                g6
                text={props.orderData?.current_stage?.time_display}
                style={{ marginLeft: "auto" }}
              />
            )}
          </div>
        </StepsDiv>
      )}
      {!loadMap ? (
        <div>Loading...</div>
      ) : (
        <GMap
          map={true}
          routeJsonData={props.routeJsonData || {}}
          showRoute={props.showRoute || false}
          agent={props.agent}
          type={props.type || ""}
          height={props.type === "standard" ? 300 : 250}
          data={props.type === "standard" ? props.deliveryData : props.taskData}
        />
      )}
      {props.type === "standard" && props.orderStatus == "pending" ? (
        ""
      ) : (
        <div style={{ padding: "18px 0 8px", display: "flex" }}>
          <div>
            <h4
              style={{ margin: "5px 0", fontWeight: "500", fontSize: "15px" }}
            >
              {props.status?.title}{" "}
              <span
                style={{
                  background: "#" + props.status?.color1,
                  height: "8px",
                  width: "8px",
                  display: "inline-block",
                  marginLeft: "3px",
                  borderRadius: "20px",
                }}
              ></span>
            </h4>
            <GText
              g6
              text={
                strings.w_delivery_agent +
                " : " +
                (props.agent?.title || "Not Assigned yet")
              }
            />
          </div>
          {props.agent?.id && (
            <div
              style={{
                marginLeft: "auto",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <img
                src={ImgUrl("user") + "/" + props.agent?.thumb_photo}
                alt={props.agent?.title}
                onError={(e) => addDefaultSrc(e, "user")}
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "20px",
                }}
                width="40px"
                height="40px"
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"tel:" + props.agent?.phone}
                style={{
                  cursor: "pointer",
                  margin: "0 5px",
                  display: "inherit",
                }}
              >
                {" "}
                <PhoneIcon
                  style={{
                    width: "32px",
                    height: "32px",
                    // marginTop: "20px",
                    // // position: "absolute",
                    // right: "-10px",
                    // bottom: "-5px",
                  }}
                />
              </a>

              <ChatIcon
                onClick={() => props.setOmvisible(true)}
                style={{ width: "32px", height: "32px", cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
