import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ApiService } from "../ApiService/services";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/icons/untick.svg";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import AddCreditCard from "./addCard";
import GText from "../Elements/GText";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { StringsContext } from "../DataFactory/useStrings";

const CardDiv = styled.div`
  ul {
    list-style-type: none;
    li {
      border: 1px solid #e2e2e2;
      padding: 5px 10px;
      border-radius: 4px;
      margin: 8px 0;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      span {
        svg {
          height: 20px;
          width: 20px;
        }
      }
      span.active svg {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
      label {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        color: #555;
        margin: 0;
        svg {
          font-size: 8px;
          color: #ccc;
          padding: 0 1px;
        }
      }
    }
  }
`;

const LI = styled.li`
  div {
    display: inline-flex;
    align-items: center;
    label {
      border: 1px solid #e2e2e2;
      padding: 5px 10px;
      border-radius: 4px;
      margin: 10px 0;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
    }
  }
`;

export default function CardPayment(props) {
  const [strings] = useContext(StringsContext);
  const [selectedCard, setSelectedCard] = useState({});
  const [cardPayment, setCardPayment] = useState(
    props.paymentGateways?.card || {}
  );
  const [isAddCard, setAddCard] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  const deleteCreditCard = () => {
    ApiService({ method: "DELETE", route: "c/cards/" + selectedCardId })
      .then((response) => {
        if (response.data.status_code === 1) {
          console.log(response.data);
          let newData = cardPayment?.options.filter(
            (c) => c.id !== selectedCardId
          );
          cardPayment.options = newData;
          setCardPayment(cardPayment);
          if (props.onDeleteCard) {
            props.onDeleteCard();
          }
          return;
        } else {
          enqueueSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!props.cardPayment?.card_id) {
      setSelectedCard({});
      setSelectedCardId("");
    }
  }, [props.cardPayment]);
  return (
    <CardDiv>
      {props.isCardSelected !== false && (
        <GText text={strings?.w_choose_card} />
      )}
      <ul
        style={{
          paddingLeft: props.isCardSelected == false ? "20px" : "15px",
          listStyleType: props.isCardSelected == false ? "square" : "none",
        }}
      >
        {cardPayment?.options &&
          cardPayment?.options?.map((c, i) => {
            return (
              <>
                {props.isCardSelected == false ? (
                  <LI style={{ border: 0, display: "list-item" }}>
                    <div>
                      <label htmlFor={"v_option_" + c.id}>
                        • • • •&nbsp;&nbsp; • • • •&nbsp;&nbsp; • • • •
                        &nbsp;&nbsp;
                        {c.last_4_digits}
                      </label>
                      {props.isDeleted === true && (
                        <GButton
                          variant="linkable"
                          onClick={() => {
                            setSelectedCardId(c.id);
                            setDialogOpen(true);
                          }}
                          style={{ padding: "1px" }}
                        >
                          <Delete
                            style={{
                              float: "right",
                              width: "20px",
                              marginLeft: 20,
                            }}
                          />
                        </GButton>
                      )}
                    </div>
                  </LI>
                ) : (
                  <div style={{ display: "flex" }} key={i}>
                    <li
                      onClick={() => {
                        setSelectedCard(c);
                        props.onSelecteCard(c);
                      }}
                    >
                      <input
                        name="option_card_payment"
                        type="radio"
                        id={"v_option_" + c.id}
                        value={c.id}
                        style={{ display: "none" }}
                        defaultChecked={selectedCard?.id === c.id}
                      />
                      {selectedCard?.id === c.id ? (
                        <span className="active">
                          <Check style={{ fontSize: "1rem" }} />
                        </span>
                      ) : (
                        <span>
                          <Uncheck style={{ fontSize: "1rem" }} />
                        </span>
                      )}
                      &nbsp;&nbsp;
                      <label htmlFor={"v_option_" + c.id}>
                        • • • •&nbsp;&nbsp; • • • •&nbsp;&nbsp; • • • •
                        &nbsp;&nbsp;
                        {c.last_4_digits}
                      </label>
                    </li>

                    {props.isDeleted === true && (
                      <GButton
                        variant="linkable"
                        onClick={() => {
                          setSelectedCardId(c.id);
                          setDialogOpen(true);
                        }}
                        style={{ padding: "1px" }}
                      >
                        <Delete style={{ float: "right", width: "20px" }} />
                      </GButton>
                    )}
                  </div>
                )}
              </>
            );
          })}
      </ul>

      {isAddCard === true ? (
        <React.Fragment>
          <hr style={{ margin: "20px 0", border: "1px dashed #e2e2e2" }} />
          <AddCreditCard
            cardType={cardPayment.identifier}
            setAddCard={setAddCard}
            onCardAdd={(card) => {
              setAddCard(false);
              cardPayment.options.push(card);
              setCardPayment(cardPayment);
              setSelectedCard(card);
              if (props.onSelecteCard) {
                props.onSelecteCard(card);
              }
              if (props.onCardAdd) {
                props.onCardAdd(card);
              }
            }}
          />
        </React.Fragment>
      ) : (
        <GButton
          variant="linkable"
          onClick={() => {
            setAddCard(true);
          }}
        >
          {strings?.w_plus_add_card}
        </GButton>
      )}

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings?.w_delete_card}
          text={strings?.s_delete_subtitle}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCreditCard();
          }}
        ></GConfirmDialog>
      )}
    </CardDiv>
  );
}
