import React, { useState, useContext } from "react";
import { ApiService, ImgUrl } from "../ApiService/services";
import GBackIcon from "../Elements/gBackIcon";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { GImagePicker } from "../Elements/gImagePicker";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

export default function EditProfile(props) {
  const { settings, setSettings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    first_name: settings?.user?.first_name || "",
    last_name: settings?.user?.last_name || "",
    email: settings?.user?.email || "",
    photo: settings?.user?.photo || "",
    thumb_photo: settings?.user?.thumb_photo || "",
    phone: settings?.user?.phone | "",
  };
  const enqueueSnackbar = useEnqueueSnackbar();

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (!values.password) {
      delete values.password;
    }
    console.log(values);

    ApiService({ method: "PUT", route: "c/me", body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          settings.user = response.data.data;
          setSettings({ ...settings });
          props.setEpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    );
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings?.w_required),
  });

  if (props.epvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setEpvisible(false)} />
        {strings?.w_edit_profile}
      </div>
      <div className="modal-body" style={{ paddingBottom: "10px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div style={{ margin: "0 15px" }}>
                <GImagePicker
                  type="user"
                  alt={settings?.user?.first_name}
                  height={100}
                  width={100}
                  radius={50}
                  editor
                  editorProps={{
                    height: 200,
                    width: 200,
                    radius: 100,
                  }}
                  serverProps={{
                    size: 200,
                  }}
                  setBtnLoading={setBtnLoading}
                  onSuccess={(v) => {
                    console.log(v);
                    values.photo = v.image;
                    values.thumb_photo = v.thumb_image;
                  }}
                  isPreviewImg={true}
                  default={ImgUrl("user") + "/" + settings?.user?.photo}
                />
              </div>
              <br />

              <FormikControl
                control="input"
                type="text"
                label={strings?.w_first_name}
                name="first_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label={strings?.w_last_name}
                name="last_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label={strings?.w_email_id}
                name="email"
                disabled={true}
              />

              <FormikControl
                control="input"
                type="text"
                label={strings?.w_phone}
                name="phone"
                disabled={true}
                style={{
                  direction: "ltr",
                  textAlign: I18nManager.getConstants().isRTL
                    ? "right"
                    : "left",
                }}
              />

              <br />
              <GButton
                variant="condensed"
                children={strings?.w_update}
                style={{ margin: "0 15px", width: "92%" }}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
