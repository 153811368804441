import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

function formikInput(props) {
  const { label, name, spanLabel, ...rest } = props;
  return (
    <div className="form-control" style={props.divstyle}>
      <div style={props.innerDivStyle}>
        {label ? (
          <label htmlFor={name} style={props.labelStyle}>
            {label}
            {spanLabel && (
              <span style={{ fontSize: "13px", color: "#aaa" }}>
                {spanLabel}
              </span>
            )}
          </label>
        ) : (
          ""
        )}

        <Field id={name} name={name} {...rest} />
      </div>

      {props.errorstyle ? (
        <div style={props.errorstyle}>
          <ErrorMessage name={name} component={TextErrors} />
        </div>
      ) : (
        <ErrorMessage name={name} component={TextErrors} />
      )}
    </div>
  );
}

export default formikInput;
