import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as Cancel } from "../assets/images/icons/cancel.svg";
import styled from "styled-components";
import TipImg from "../assets/images/tip-icon.png";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";

const TipDiv = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 15px;
  align-items: start;

  img {
    width: 25px;
    padding: 2px 0;
    box-sizing: border-box;
  }
  ul {
    margin: 5px 0;
    list-style-type: none;
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    li {
      color: #333;
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      width: 50px;
      text-align: center;
      padding: 6px 7px;
      font-size: 14px;
      margin: 5px 10px 0 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      // align-items: center;

      &.active {
        border: 1px solid ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.body};
        background: ${({ theme }) => theme.opacityColor};
        p {
          color: ${({ theme }) => theme.body}!important;
        }
        svg {
          fill: ${({ theme }) => theme.body};
        }
      }
      & svg {
        padding: 0px 0 0 4px;
        vertical-align: top;
      }
    }
  }
`;

export default function CheckoutOrderTip(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [appliedTip, setAppliedTip] = useState("");
  const tipValues = settings?.tip_values || [50, 80, 100];

  useEffect(() => {
    // console.log(props);
    setAppliedTip(props.selectedTip);
    props.setSelectedTip(props.selectedTip);
  }, [props]);
  return (
    <TipDiv>
      {props.showImg !== false && (
        <img src={TipImg} alt="Tip" width="25px" height="auto" />
      )}
      <React.Fragment>
        <div
          style={{
            flex: 1,
          }}
        >
          <GText semi g5 text={strings?.s_payment_tip_title} />
          <GText
            style={{ padding: "5px 0", color: "#555", fontSize: "14px" }}
            text={strings?.s_payment_tip_subtitle}
          />
          <ul>
            {tipValues.map((key, ival) => {
              return (
                <li
                  key={ival}
                  onClick={() => {
                    if (appliedTip == key) {
                      props.setSelectedTip("");
                    } else {
                      props.setSelectedTip(key);
                    }
                  }}
                  className={appliedTip == key ? "active" : ""}
                >
                  <GText
                    text={props.currencySymbol + "" + key}
                    style={{ lineHeight: "15px" }}
                  />
                  {appliedTip == key ? (
                    <Cancel style={{ width: "14px", height: "14px" }} />
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    </TipDiv>
  );
}
