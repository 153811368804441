import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import GText from "../Elements/GText";
import { SettingsContext } from "../DataFactory/useSettings";
import { Item } from "../Models/Item";
import useCart from "../useCart";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import OrderProductVariants from "../Components/orderProductVariants";
import OrderProductInfo from "../Components/productInfo";
import useDataFctory from "../ApiService/useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import GProductThemeCard from "../Elements/gProductThemeCard";

export default function ProductsView(props) {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const { addProduct, decreaseQuantity } = useCart();
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    loading,
    Shimmer,
    Placeholder,
    loadMore,
    data,
    refreshData,
    pagination,
  } = useDataFctory("products", true, { category: props.selectedCatId });

  useEffect(() => {
    console.log(props);
    // if (initialLoading === false) {
    refreshData({ category: props.selectedCatId });
    // }
    // setInitialLoading(false);
  }, [props.selectedCatId]);

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  return (
    <div
      style={{
        padding: width > 959 ? "10px 30px 20px 10px" : "20px 10px",
        // borderLeft: width > 767 ? "1px solid #f2f2f2" : "0",
      }}
    >
      {loading === true ? (
        <Shimmer />
      ) : data && data.length > 0 ? (
        <>
          <GText
            g3
            semi
            text={data[0].categories_title + " (" + pagination.total + ")"}
            style={{
              margin: "0 0 15px",
            }}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                width <= 350
                  ? "repeat(auto-fit, minmax(350px, 1fr))"
                  : width > 500 && width <= 767
                  ? props.catLength > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr"
                  : width > 767 && width <= 991
                  ? props.catLength > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr 1fr"
                  : width > 991 && width <= 1200
                  ? props.catLength > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr"
                  : width > 1200 && width <= 1720
                  ? props.catLength > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr 1fr"
                  : width > 1720
                  ? props.catLength > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr 1fr 1fr"
                  : "repeat(auto-fit, minmax(350px, 1fr))",

              // gridTemplateColumns: "repeat(auto-fit, minmax(260px, 2fr))",
              gridGap: "20px",
              width: "100%",
            }}
          >
            {data
              .map((o) => new Item(o))
              .map((item, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <GProductThemeCard
                      item={item}
                      ind={ind}
                      setSelectedProduct={setSelectedProduct}
                      setItemInfoVisible={setItemInfoVisible}
                      settings={settings}
                      addProduct={addProduct}
                      decreaseQuantity={decreaseQuantity}
                      setOrderVarVisible={setOrderVarVisible}
                      strings={strings}
                      catLength={props.data?.length}
                    />
                  </React.Fragment>
                );
              })}
          </div>

          {pagination.next_page_url && (
            <GButton
              variant="condensed"
              loading={btnDisabled}
              children={strings?.w_load_more}
              type="button"
              onClick={() => {
                setBtnDisabled(true);
                loadMore();
              }}
              style={{
                display: "flex",
                margin: "20px auto 10px",
                minWidth: "150px",
              }}
            />
          )}
        </>
      ) : (
        <Placeholder />
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={{
          content: {
            width: "420px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minHeight: "50vh",
            maxHeight: "80vh",
            borderRadius: "0PX",
          },
        }}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          settings={settings}
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(item, itemVariants) => {
            console.log(itemVariants);
            addProduct(item, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>

      <Modal
        isOpen={itemInfoVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            minHeight: "350px",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
