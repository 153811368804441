import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import useDataFctory from "../ApiService/useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";
import GView from "../Elements/GView";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";
import Modal from "react-modal";
import AddFundForm from "../forms/addFundForm";
import CardPayment from "../paymentComponents/cardPayments";

export default function Wallet() {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { width } = useWindowSize();
  const [adFundVisible, setAdFundVisible] = useState(false);

  const { loading, TableShimmer, Placeholder, data, refreshData } =
    useDataFctory("gateways", false);

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={strings?.w_wallet} />
        <GButton
          variant="condensed"
          children={strings?.w_add_funds}
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? 0 : "auto",
            marginRight: I18nManager.getConstants().isRTL ? "auto" : 0,
          }}
          onClick={() => {
            setAdFundVisible(true);
          }}
        />
      </GView>
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px 0 ",
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              padding: "10px 25px 20px",
              minWidth: width <= 991 ? "150px" : "200px",
            }}
          >
            <GText
              h5
              med
              text={strings?.w_available_points}
              style={{
                margin: "10px 0",
                letterSpacing: "0",
              }}
            />
            <GText g3 semi text={settings?.user?.wallet.toFixed(2)} />
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              borderLeft:
                width <= 767
                  ? "0"
                  : I18nManager.getConstants().isRTL
                  ? "0"
                  : "1px solid #f2f2f2",
              borderRight:
                width < 600
                  ? "0"
                  : I18nManager.getConstants().isRTL
                  ? "1px solid #f2f2f2"
                  : "0",
              borderTop: width <= 767 ? "1px solid #f2f2f2" : "0",
              width: width <= 767 ? "100%" : "auto",
              padding: "15px 25px",
            }}
          >
            <GText g5="true" med text={strings?.w_cards} />

            {loading === true ? (
              <GView style={{ padding: "0 20px" }}>
                <TableShimmer></TableShimmer>
              </GView>
            ) : data.card?.options && data.card?.options.length > 0 ? (
              <>
                <CardPayment
                  isDeleted={true}
                  // cardPayment={cardPayment}
                  paymentGateways={data}
                  isCardSelected={false}
                  onDeleteCard={() => refreshData()}
                  onCardAdd={() => refreshData()}
                />
              </>
            ) : (
              <Placeholder />
            )}
          </GView>
        </GView>
      </GView>

      <Modal
        isOpen={adFundVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdFundVisible(false)}
        style={{
          content: {
            width: "450px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Add Fund Form Modal"
      >
        <AddFundForm
          adFundVisible={adFundVisible}
          setAdFundVisible={setAdFundVisible}
          onSubmit={refreshData}
        />
      </Modal>
    </React.Fragment>
  );
}
