import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import { ImgUrl, ApiLoginService } from "../ApiService/services";
import GText from "../Elements/GText";
import styled from "styled-components";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";
import useWindowSize from "../DataFactory/useWindowSize";
import { UserLocContext } from "../contexts/userLocation";
import useLocalStorage from "../DataFactory/useLocalStorage";
import { I18nManager } from "react-native-web";
import GView from "../Elements/GView";
import { AreaSearch } from "../Components/areaSearch";
import HomeClasses from "../Components/homeClasses";
import { Slider } from "../Elements/gSlideCarousel";
import StoreView from "../Elements/gStoreView";
import { GHomeShimmer } from "../Elements/gShimmer";
import StoreMobileFilters from "../Components/storeFiltersMobileView";
import Placeholder from "../Placeholders/Placeholder";
import { useLocation } from "react-router-dom";
import StoreFilters from "../Components/storeFilters";
import HomeBanners from "../Components/homeBanners";
import NavLocation from "../Components/navLocation";
import GStoresSlider from "../Elements/gStoresSliderView";

export const SearchDiv = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 150px 0;

  h1 {
    margin: 10px 0;
    font-family: LatoWebBold;
  }
  p {
    margin: 10px 0;
    color: rgb(85, 85, 85);
  }
`;

export default function Home() {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [homePageData, setHomePageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { updateLocation, userLocation } = useContext(UserLocContext);
  const [cureentLocation, setcureentLocation] = useState(false);
  const [sortFilters, setSortFilters] = useState({
    orderby: "",
    popular: "",
    new: "",
    favourite: "",
    under_30: "",
    min_rating: "",
    discounts: "",
    price_range: "",
    order_type: "",
  });

  const getHomePageData = () => {
    // console.log({
    //   type: "web",
    //   geofence:  "",
    //   location: userLocation?.lat
    //     ? userLocation.lat + "," + userLocation.lng
    //     : "",
    //   ...sortFilters,
    // });
    ApiLoginService("GET", `c/homepage`, {
      type: "web",
      geofence: "",
      location: userLocation?.lat
        ? userLocation.lat + "," + userLocation.lng
        : "",
      ...sortFilters,
    })
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          setHomePageData(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userLocation) {
      setIsLoading(true);
      getHomePageData();
    } else {
      setIsLoading(false);
    }
  }, [userLocation, sortFilters, settings]);

  return (
    <div>
      {/* <SearchDiv
        style={{
          backgroundImage: `url(${
            ImgUrl("website") + "/" + settings?.web?.web_home_header
          })`,
          padding:
            width <= 767
              ? "10% 0"
              : width > 767 && width <= 1800
              ? "150 0"
              : width > 1800 && width <= 2200
              ? "200px 0"
              : "225px 0",
          backgroundPosition: "center",
        }}
      >
        <div className="searchTextDiv">
          <GText
            g1
            bold
            text={
              settings?.web?.web_home_title || strings?.s_find_store_near_you
            }
            style={{
              color: "#ffffff",
              margin: "0",
              fontSize: width <= 767 ? "2em" : "2.6rem",
              padding: width <= 767 ? "2rem 0 0" : "1.5rem 0",
              textShadow: "0 0 2px #000",
              fontWeight: "700",
            }}
          />
          {settings?.web?.web_home_desc && (
            <GText
              med
              text={settings?.web?.web_home_desc}
              style={{ padding: "10px 0" }}
            />
          )}

          <br />

          <div
            style={{
              display: "inline-flex",
              maxWidth: "90vw",
              minWidth: width > 767 ? "500px" : "90%",
              margin: width <= 767 ? "0 0 20px" : "10px 0",
              textAlign: I18nManager.getConstants().isRTL ? "right" : "left",
            }}
          >
            <AreaSearch
              value={userArea || userLocation || ""}
              setcureentLocation={setcureentLocation}
              handleAreaChange={(area) => {
                console.log(area);
                localStorage.setItem("area", area || "");
                updateLocation(area || "", "");
              }}
              handleLocationChange={(loc) => {
                console.log(loc);
                localStorage.setItem("location", JSON.stringify(loc) || "");
                updateLocation("", loc || "");
              }}
            />
          </div>
        </div>
      </SearchDiv> */}

      <div className="main-content">
        {width <= 767 && <NavLocation />}
        {isLoading == true ? (
          <GHomeShimmer />
        ) : (
          <>
            {userLocation && (
              <>
                {homePageData?.classes && homePageData?.classes.length > 0 && (
                  <HomeClasses
                    data={homePageData.classes}
                    isAtmAvailable={homePageData?.atm_available}
                  />
                )}

                {width <= 991 && (
                  <GView style={{ padding: "0 0 10px" }}>
                    <StoreMobileFilters
                      filters={sortFilters}
                      onApply={(filter) => setSortFilters({ ...filter })}
                    />
                  </GView>
                )}

                {homePageData?.banners && homePageData?.banners.length > 0 && (
                  <GView style={{ padding: "5px 0 30px" }}>
                    {/* <Slider
                      images={homePageData?.banners || []}
                      imgType={"web"}
                    /> */}
                    <HomeBanners data={homePageData.banners || []} />
                  </GView>
                )}

                <GView
                  style={{
                    padding: "10px 0 30px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  {width > 991 && (
                    <GView
                      style={{
                        width: "240px",
                        borderRight: "1px solid #e2e2e2",
                        maxWidth: "250x",
                      }}
                    >
                      <StoreFilters
                        filters={sortFilters}
                        onApply={(filter) => setSortFilters({ ...filter })}
                      />
                    </GView>
                  )}

                  <GView
                    style={{
                      width: width > 991 ? "calc(100% - 240px)" : "100%",
                      flex: 1,
                      padding: width > 991 ? "0 0 0 15px" : 0,
                      boxSizing: "border-box",
                    }}
                  >
                    {homePageData?.groups && homePageData?.groups.length > 0 ? (
                      <>
                        {homePageData?.groups.map((group, i) => {
                          return (
                            <React.Fragment key={i}>
                              <GView
                                style={{
                                  padding:
                                    i == 0 ? "0 0 10px 0" : "20px 0 10px",
                                }}
                              >
                                <GText
                                  g3
                                  semi
                                  text={group.title}
                                  style={{ margin: "10px 0" }}
                                />
                                <GStoresSlider data={group.stores || []} />
                              </GView>
                              <hr
                                style={{
                                  margin:
                                    homePageData?.groups.length == i + 1
                                      ? "10px 0 40px"
                                      : "10px 0",
                                  border: 0,
                                  borderBottom: "1px solid #e2e2e2",
                                }}
                              />
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                    {homePageData.data?.data &&
                    homePageData.data?.data.length > 0 ? (
                      <Grid container spacing={4}>
                        {homePageData.data?.data.map((store, i) => {
                          return (
                            <Grid
                              item
                              xl={3}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              key={i}
                            >
                              <StoreView data={store} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <Placeholder type="store" />
                    )}
                  </GView>
                </GView>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
