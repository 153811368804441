import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiLoginService } from "../ApiService/services";
import styled from "styled-components";
import { ReactComponent as Room } from "../assets/images/map-marker.svg";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import { ReactComponent as KeyboardArrowDown } from "../assets/images/icons/arrowDown.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import { StringsContext } from "../DataFactory/useStrings";
import GButton from "../Elements/GButton";
import { I18nManager } from "react-native";
import useWindowSize from "../DataFactory/useWindowSize";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

const LI = styled.li`
  padding: 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #f4f5f9;
  }
  svg {
    g {
      fill: #ccc;
    }
  }
`;

const Input = styled.input`
  width: 82%;
  /* min-width: 350px; */
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;

export const AreaSearch = ({
  isNav,
  value,
  handleAreaChange,
  style,
  handleLocationChange,
}) => {
  const [strings] = useContext(StringsContext);
  const { defaultAddress } = useContext(UserAddressContext);
  const wrapperSRef = useRef(null);
  const { width } = useWindowSize();
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [cityList, setCities] = useState([]);

  useEffect(() => {
    var hitUrl = "c/cities?per_page=30";
    ApiLoginService("GET", hitUrl)
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          setCities(response.data);
          response.data.map((values) => {
            console.log(value);
            if (typeof value !== "object" && value !== null) {
              values.geofences.map((g, i) => {
                if (g.id === parseInt(value)) {
                  setSelectedTitle(g.title);
                }
              });
            }
          });
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(value);
    console.log(defaultAddress);
    if (value === "" || value === null) {
      setSelectedTitle(defaultAddress?.title);
      handleLocationChange({
        lat: defaultAddress?.lat,
        lng: defaultAddress?.lng,
        address: defaultAddress?.title,
      });
    }
    if (typeof value === "object") {
      if (value.lat == 0) {
        setSelectedTitle(defaultAddress?.title);
        handleLocationChange({
          lat: defaultAddress?.lat,
          lng: defaultAddress?.lng,
          address: defaultAddress?.title,
        });
      } else {
        setSelectedTitle(defaultAddress?.title);
      }
    }
  }, [defaultAddress]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign({}, style, {
        width: "100%",
        maxWidth: "100%",
        display: "inline-block",
        position: "relative",
      })}
    >
      <div
        style={{
          background: "#fff",
          border: 0,
          height: "45px",
          fontSize: "0.97rem",
          // textAlign: "left",
          color: "#555",
          boxSizing: "border-box",
          padding: "10px",
          fontFamily: "sans-serif",
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <Room
          style={{
            width: "15px",
            height: "15px",
            color: "#ccc",
            verticalAlign: "middle",
          }}
        />
        {/* <div style={{ flex: "1", padding: "0 10px" }}> */}
        {selectedTitle ? (
          <span
            style={{
              // flex: "1",
              padding: "0 10px",
              textAlign: I18nManager.getConstants().isRTL ? "right" : "",
            }}
            onClick={() => {
              setShowFilterList(true);
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <span
            onClick={() => {
              setShowFilterList(true);
            }}
            style={{
              color: "#777",
              verticalAlign: "middle",
              fontSize: "14px",
              flex: "1",
              padding: "0 10px",
            }}
          >
            {strings?.w_choose_area}
          </span>
        )}
        {/* {selectedTitle && (
          <Close
            fontSize="small"
            style={{
              width: "12px",
              height: "12px",
              color: "#777",
              cursor: "pointer",
              margin: "0 10px",
            }}
            onClick={() => {
              setSelectedTitle("");
              handleAreaChange("");
            }}
          />
        )} */}
        <KeyboardArrowDown
          onClick={() => {
            setShowFilterList(true);
          }}
          style={{
            cursor: "pointer",
            width: 12,
            fill: "#333",
            paddingLeft: 10,
          }}
        />
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "8",
            width: "100%",
            boxSizing: "border-box",
            padding: "0 0 5px 5px",
            border: 0,
            top: width > 767 ? "65px" : "50px",
            borderRadius: "5px",
            boxShadow:
              "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "15px 10px",
              margin: "0",
              maxHeight: "220px",
              overflow: "auto",
              textAlign: "left",
              display: "block",
              textAlign: I18nManager.getConstants().isRTL ? "right" : "",
            }}
          >
            {cityList.map((c, i) => {
              return (
                <li key={i} style={{ color: "#ccc", fontSize: "15px" }}>
                  {c.title}

                  <ul
                    style={{
                      padding: "5px 0 10px",
                      listStyleType: "none",
                      display: "block",
                    }}
                  >
                    {c.geofences.map((g, ind) => {
                      return (
                        <LI
                          key={ind}
                          onClick={() => {
                            setSelectedTitle(g.title);
                            setShowFilterList(false);
                            handleAreaChange(g.id);
                          }}
                        >
                          <Room
                            style={{
                              width: "15px",
                              height: "15px",
                              fill: "#ccc",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                          {g.title}
                        </LI>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
