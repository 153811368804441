import React, { useState, useEffect, useContext } from "react";
import CouponImg from "../assets/images/coupon.png";
import ApplyCouponPopup from "./couponPopup";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import Modal from "react-modal";
import GView from "../Elements/GView";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";
import { ReactComponent as ArrowRight } from "../assets/images/icons/arrowBack.svg";

const customStyles = {
  content: {
    width: "450px",
  },
};

export default function CheckoutOrderCoupon(props) {
  const [strings] = useContext(StringsContext);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  useEffect(() => {
    setAppliedCoupon(props.selectedCoupon);
  }, [props]);
  return (
    <>
      <GView
        style={{
          display: "flex",
          marginTop: "10px",
          cursor: "pointer",
          gap: "15px",
          alignItems: "start",
        }}
        onClick={() => props.setCouponVisible(true)}
      >
        <img
          src={CouponImg}
          alt="coupon"
          style={{ width: "25px", paddingTop: "7px", boxSizing: "border-box" }}
          height="auto"
          width="25px"
        />

        <div style={{ flex: "1" }}>
          <GText
            g4
            semi
            text={
              appliedCoupon && props.isCouponValid === true
                ? appliedCoupon
                : strings?.w_coupon
            }
          />
          <GText
            style={{
              color: "#555",
            }}
            text={
              appliedCoupon && props.isCouponValid === true
                ? strings?.s_payment_promo_added
                : strings?.s_payment_offers
            }
          />

          {appliedCoupon && (
            <GButton
              variant="linkable"
              children={strings.w_remove}
              onClick={(e) => {
                e.stopPropagation();
                setAppliedCoupon("");
                props.setSelectedCoupon("");
                props.setIsCouponValid(false);
              }}
              style={{ marginTop: "10px", padding: 0 }}
            />
          )}
        </div>
        <ArrowRight
          style={{
            width: 15,
            height: 15,
            fill: "#aaa",
            transform: "rotate(180deg)",
            padding: "2px",
          }}
        />
      </GView>

      <Modal
        isOpen={props.couponVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => props.setCouponVisible(false)}
        style={customStyles}
        contentLabel="Coupon Modal"
      >
        <ApplyCouponPopup
          couponVisible={props.couponVisible}
          applyCoupon={true}
          setCouponVisible={props.setCouponVisible}
          onApplyCoupon={(coupon) => {
            console.log(coupon);
            if (appliedCoupon && appliedCoupon === coupon) {
              setAppliedCoupon(coupon);
              props.setSelectedCoupon(...coupon);
              props.setIsCouponValid(false);
              return;
            }
            setAppliedCoupon(coupon);
            props.setSelectedCoupon(coupon);
            props.setIsCouponValid(true);
          }}
        />
      </Modal>
    </>
  );
}
