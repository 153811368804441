import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import BannerImg from "../assets/images/vendor-signup-banner.png";
import { ReactComponent as BgImg } from "../assets/images/driver-signup-bg.svg";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import useDataFctory from "../ApiService/useDataFactory";
import GInput from "../Elements/gInput";
import GImageEditor from "../Elements/gFormImageEditor";
import { ImgUrl, ApiLoginService } from "../ApiService/services";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import SignupAddress from "../forms/signupAddressForm";

const Div = styled.div`
  flex: 1;
  padding: 40px 30px 0;
  background: #fff;
  display: inline-flex;
  .imgView {
    margin-top: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    text-align: center;

    svg {
      max-width: 100%;
      height: auto;
      path {
        fill: ${({ theme }) => theme.opacityColor};
      }
    }

    img {
      position: absolute;
      left: 10%;
      bottom: 0;
    }
  }
`;
const FormDiv = styled.div`
  flex: 1;
  background: #f4f4f4;
  padding: 20px 40px;
  align-selft: center;
  box-sizing: border-box;
  overflow: overlay;

  form {
    .form-control {
      padding: 2px 12px;
      margin-bottom: 2px;

      input {
        padding: 0.5em;
        height: 38px;
      }
    }
  }
`;

const P = styled.p`
  padding: 30px 15px 15px;
  font-size: 14px;
  a {
    color: ${({ theme }) => theme.body};
  }
`;

export default function VendorSignup() {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const [signupAddFVisible, setSignupAddFVisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    title: "",
    store_phone: "",
    latitude: "",
    longitude: "",
    terms: "",
  };
  const enqueueSnackbar = useEnqueueSnackbar();
  const { loading, Shimmer, data, refreshData } = useDataFctory(
    "m_document_types",
    false,
    { user_type: 2 }
  );
  const [docsData, setDocsData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0 && loading === false) {
      data.map((d) => {
        let obj = {};
        obj.title = d.title;
        obj.document_type_id = d.id;
        obj.user_type = d.user_type;
        obj.id = d.id;
        obj.type = d.type;
        if (d.type === "image") {
          obj.photo = "";
          obj.thumb_photo = "";
        } else {
          obj.text = "";
        }

        docsData.push(obj);
      });

      setDocsData([...docsData]);
    }
  }, [data, loading]);

  const onSubmit = (values) => {
    if (!selectedAddress?.address) {
      enqueueSnackbar("Address is required");
      return false;
    }
    if (docsData.length > 0) {
      let validate = true;
      let m = "";
      docsData.map((f) => {
        console.log(f);
        if (f.type === "text") {
          if (!f.text) {
            validate = false;
            m = f;
          }
        }
        if (f.type === "image") {
          if (!f.photo) {
            validate = false;
            m = f;
          }
        }
      });
      if (validate === false) {
        enqueueSnackbar(m.title + " is required");
        return false;
      }
    }
    if (!checked) {
      enqueueSnackbar("Address is required");
    }
    setBtnLoading(true);
    let sendData = {
      manager: {
        first_name: values.first_name,
        last_name: values.last_name || "",
        email: values.email,
        phone: values.phone,
        password: values.password,
      },
      store: {
        title: values.title,
        phone: values.store_phone,
        latitude: selectedAddress?.latitude,
        longitude: selectedAddress?.longitude,
        address: selectedAddress?.address,
      },
      documents: [...docsData],
    };
    console.log(sendData);
    // return;
    ApiLoginService("POST", "m/signup", sendData).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        document.getElementById("resetButton").click();
        setSelectedAddress({});
        setDocsData([]);
        setChecked(false);
        refreshData({ user_type: "2" });
        window.scrollTo(0, 0);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings?.w_required),
    email: Yup.string().required(strings?.w_required),
    phone: Yup.string().required(strings?.w_required),
    password: Yup.string().required(strings?.w_required),
    title: Yup.string().required(strings?.w_required),
    store_phone: Yup.string().required(strings?.w_required),
    terms: Yup.string().required(strings?.w_required),
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: width <= 767 ? "column" : "row",
          background: "#f4f5f9",
        }}
      >
        <Div style={{ padding: width <= 767 ? "30px 15px 0" : "40px 30px 0" }}>
          <div
            style={{
              width: width <= 767 ? "90%" : "75%",
              margin: "10px auto 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GText
              g1
              bold
              text={strings.w_join_as_vendor}
              style={{ fontSize: "27px", fontWeight: "700" }}
            />
            <GText
              text={strings.s_expand_your_business}
              style={{ margin: "15px 0" }}
            />
            <GText
              text={strings.s_signup_easily_using_omline_registration}
              style={{ marginBottom: width <= 767 ? 0 : "40px" }}
            />

            <div
              className="imgView"
              style={{
                marginTop: width <= 767 ? "30%" : "auto",
                position: "relative",
              }}
            >
              <BgImg />
              <img src={BannerImg} style={{ maxWidth: "80%" }} />
            </div>
          </div>
        </Div>

        <FormDiv
          style={{
            padding: width <= 767 ? "30px 15px" : "20px 40px",
            maxHeight: width > 767 ? "calc(100vh - 80px)" : "auto",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ values, setFieldValue, resetForm, isValid }) => (
              <Form
                style={{
                  width: width <= 767 ? "100%" : "75%",
                }}
              >
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_first_name}
                  name="first_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_last_name}
                  name="last_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="email"
                  label={strings?.w_email_id}
                  name="email"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_phone}
                  name="phone"
                  onChange={(event) => {
                    const re = /^[0-9 +\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("phone", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_password}
                  name="password"
                />

                <GText
                  g4
                  semi
                  text={strings.w_store_details}
                  style={{ padding: "20px 13px 10px" }}
                />

                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_title}
                  name="title"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_phone}
                  name="store_phone"
                  onChange={(event) => {
                    const re = /^[0-9 +\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("store_phone", event.target.value);
                    }
                  }}
                />
                <div style={{ padding: "8px 15px" }}>
                  <GText
                    text={strings.w_address}
                    style={{
                      fontSize: "14px",
                      color: "#555",
                      marginBottom: 10,
                    }}
                  />

                  {selectedAddress?.latitude ? (
                    <div>
                      <GText text={selectedAddress?.address} />
                      <GButton
                        variant="linkable"
                        onClick={() => {
                          setIsEmpty(false);
                          setSignupAddFVisible(true);
                        }}
                        style={{
                          textDecoration: "underline",
                          padding: "10px 0",
                          fontStyle: "italic",
                        }}
                      >
                        {strings.w_change}
                      </GButton>
                    </div>
                  ) : (
                    <GButton
                      variant="outlined"
                      onClick={() => {
                        setIsEmpty(true);
                        setSignupAddFVisible(true);
                      }}
                      style={{ borderRdaius: "4px" }}
                    >
                      {strings.w_choose}
                    </GButton>
                  )}
                </div>

                {docsData && docsData.length > 0 ? (
                  <>
                    <GText
                      g4
                      semi
                      text={strings.w_other_details}
                      style={{ padding: "20px 15px 5px" }}
                    />
                    {docsData.map((d, ind) => {
                      return (
                        <div
                          key={ind}
                          style={{
                            padding: "2px 10px",
                          }}
                        >
                          {d.type === "text" && (
                            <>
                              <GText
                                text={d.title}
                                med
                                style={{
                                  margin: "3px 8px 0",
                                  fontSize: "14px",
                                }}
                              />
                              <GInput
                                type="text"
                                name={d.id}
                                required={true}
                                id={d.id + "_" + ind}
                                value={d.text}
                                display="block"
                                onChange={(e) => {
                                  d.text = e.target.value;
                                  docsData[ind].text = e.target.value;
                                }}
                                style={{
                                  background: "#fff",
                                  color: "#333",
                                }}
                                divstyle={{ padding: "2px 5px" }}
                              />
                            </>
                          )}

                          {d.type === "image" && (
                            <GImageEditor
                              title={d.title}
                              onSubmit={(newValue, id, thumb_val) => {
                                d.photo = newValue;
                                d.thumb_photo = thumb_val;
                                docsData[ind].photo = newValue;
                                docsData[ind].thumb_photo = thumb_val;
                              }}
                              id={`thumb_photo_${d.id}`}
                              image={
                                d.photo
                                  ? ImgUrl("document") + "/" + d.photo
                                  : ""
                              }
                              type="document"
                              apiType="vendor"
                              style={{ height: "155px", paddingTop: "60px" }}
                              divstyle={{ margin: "0", display: "block" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}

                <P>
                  <span
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="Terms"
                      name="terms"
                      value={checked}
                      checked={checked}
                      onChange={() => {
                        console.log(!checked == true ? "agree" : "");
                        setChecked(!checked);
                        setFieldValue("terms", !checked == true ? "agree" : "");
                      }}
                      style={{ marginRight: "8px", amrginTop: "2px" }}
                    ></input>
                    <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                      {strings.s_by_proceeding_i_acknowledge}{" "}
                      <Link
                        to={{
                          pathname: "/terms",
                          state: { title: "Terms & Conditions" },
                        }}
                        target="_blank"
                      >
                        {strings?.w_read}
                      </Link>
                    </span>
                  </span>
                </P>
                <GButton
                  variant="condensed"
                  disabled={!isValid}
                  children={strings?.w_sign_up}
                  style={{ margin: "8px 15px", width: "200px" }}
                  type="submit"
                  loading={isBtnLoading}
                />
                <button
                  type="button"
                  id="resetButton"
                  style={{ display: "none" }}
                  onClick={() => resetForm()}
                ></button>
              </Form>
            )}
          </Formik>
        </FormDiv>
      </div>

      <Modal
        isOpen={signupAddFVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupAddFVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Address Modal"
      >
        <SignupAddress
          signupAddFVisible={signupAddFVisible}
          setSignupAddFVisible={setSignupAddFVisible}
          data={selectedAddress}
          onSubmit={(d) => {
            setSelectedAddress({ ...d });
          }}
          isEmpty={isEmpty}
        />
      </Modal>
    </div>
  );
}
