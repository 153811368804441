import React, { useContext, useEffect } from "react";
import { ApiLoginService } from "../ApiService/services";
import GView from "../Elements/GView";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Ios } from "../assets/images/ios-logo.svg";
import { ReactComponent as Android } from "../assets/images/android-logo.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/icons/phone.svg";
import { ReactComponent as MailIcon } from "../assets/images/icons/mail.svg";
import { ReactComponent as WhatsAppIcon } from "../assets/images/icons/whatsapp.svg";
import useLocalStorage from "../DataFactory/useLocalStorage";
import { SettingsContext } from "../DataFactory/useSettings";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

const Footer = styled.div`
  // margin-top: 20px;
  border-top: 3px solid ${({ theme }) => theme.body};
  background: #fff;
  a {
    text-decoration: none;
    &.theme {
      color: ${({ theme }) => theme.body};
      font-weight: 600;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    &.apps {
      display: flex;
      margin-bottom: 20px;
      li {
        padding: 0;
        a {
          margin: 5px;
          padding: 4px;
          height: 24px;
          width: 24px;
          text-align: center;
          background: ${({ theme }) => theme.body};
          border-radius: 20px;
          svg {
            fill: #fff;
            width: 17px;
            height: 17px;
            vertical-align: top;
          }
        }
      }
    }
    @media (max-width: 767px) {
      &.apps {
        justify-content: center;
      }
    }
    li {
      a {
        p {
          margin: 2px 0 !important;
        }
      }
    }
  }
`;
export default function FooterView() {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const [socialLinks, setSocialLinks] = useLocalStorage("social", []);
  const [otherPages] = useLocalStorage("other", []);

  const PagesList = [
    { title: strings?.w_terms_conditions, link: "/terms" },
    { title: strings?.w_privacy_policy, link: "/privacy-policy" },
    { title: strings?.w_help_faqs, link: "/faqs" },
    { title: strings?.w_offers, link: "/offers" },
    { title: strings?.w_coupons, link: "/coupons" },
  ];

  useEffect(() => {
    if (socialLinks && socialLinks.length > 0) {
      return;
    }
    ApiLoginService("GET", "c/social")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          setSocialLinks(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Footer style={{ background: settings?.web?.web_footer_color || "#fff" }}>
      <div className="main-content">
        <GView
          style={{
            display: "flex",
            padding: "20px 0",
            flexDirection: width <= 767 ? "column" : "row",
            gap: "10px",
          }}
        >
          <GView
            style={{
              flex: "1 1 70%",
              textAlign:
                width <= 767
                  ? "center"
                  : I18nManager.getConstants().isRTL
                  ? "right"
                  : "left",
            }}
          >
            {settings?.i_c_url || settings?.a_c_url ? (
              <React.Fragment>
                <GText
                  g3="true"
                  bold="true"
                  text={strings?.w_download_apps}
                  style={{ margin: "15px 0" }}
                />
                <ul className="apps">
                  <li>
                    {settings?.i_c_url && (
                      <a
                        href={settings?.i_c_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Ios
                          style={{
                            transform: I18nManager.getConstants().isRTL
                              ? "ScaleX(-1)"
                              : "ScaleX(1)",
                          }}
                        />
                      </a>
                    )}
                    {settings?.a_c_url && (
                      <a
                        href={settings?.a_c_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Android />
                      </a>
                    )}
                  </li>
                </ul>
              </React.Fragment>
            ) : (
              <br />
            )}
            <a
              style={{
                display: "flex",
                margin: I18nManager.getConstants().isRTL
                  ? "0 0 0 10px"
                  : "0 10px 0 0",
                justifyContent:
                  width <= 767
                    ? "center"
                    : I18nManager.getConstants().isRTL
                    ? "right"
                    : "left",
              }}
              href={`mailto:${settings?.admin_email}`}
              target="_blank"
              rel="noreferrer nofollow"
            >
              <MailIcon
                style={{
                  width: "20px",
                  margin: I18nManager.getConstants().isRTL
                    ? "0 0 0 10px"
                    : "0 10px 0 0",
                  fill: "#555",
                }}
              />
              <GText dark="true" text={settings?.admin_email}></GText>
            </a>
            <a
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent:
                  width <= 767
                    ? "center"
                    : I18nManager.getConstants().isRTL
                    ? "right"
                    : "left",
              }}
              target="_blank"
              rel="noreferrer nofollow"
              href={`tel:${settings?.admin_phone}`}
            >
              <PhoneIcon
                style={{
                  width: "20px",
                  margin: I18nManager.getConstants().isRTL
                    ? "0 0 0 10px"
                    : "0 10px 0 0",
                  fill: "#555",
                  transform: I18nManager.getConstants().isRTL
                    ? "ScaleX(-1)"
                    : "ScaleX(1)",
                }}
              />
              <GText dark="true" text={settings?.admin_phone}></GText>
            </a>

            {settings?.admin_whatsapp && (
              <a
                style={{
                  display: "flex",
                  margin: "10px 0",
                  justifyContent:
                    width <= 767
                      ? "center"
                      : I18nManager.getConstants().isRTL
                      ? "right"
                      : "left",
                }}
                target="_blank"
                rel="noreferrer nofollow"
                href={
                  "https://api.whatsapp.com/send?phone=" +
                  settings?.admin_whatsapp
                }
              >
                <WhatsAppIcon
                  style={{
                    width: "20px",
                    margin: I18nManager.getConstants().isRTL
                      ? "0 0 0 10px"
                      : "0 10px 0 0",
                    fill: "#555",
                    transform: I18nManager.getConstants().isRTL
                      ? "ScaleX(-1)"
                      : "ScaleX(1)",
                  }}
                />
                <GText dark="true" text={settings?.admin_whatsapp}></GText>
              </a>
            )}
          </GView>
          <GView
            style={{
              flex: "50%",
              textAlign:
                width <= 767
                  ? "center"
                  : I18nManager.getConstants().isRTL
                  ? "right"
                  : "left",
            }}
          >
            <GText
              g3="true"
              bold="true"
              text={strings?.w_pages}
              style={{
                margin: "15px 0",
                textAlign:
                  width <= 767
                    ? "center"
                    : I18nManager.getConstants().isRTL
                    ? "right"
                    : "",
              }}
            />
            <ul>
              {PagesList.map((p, i) => {
                return (
                  <li key={i} style={{ padding: "5px 0" }}>
                    <Link
                      to={{
                        pathname: p.link,
                        state: { title: p.title },
                      }}
                      state={{ title: p.title }}
                    >
                      <GText dark="true" text={p.title} />
                    </Link>
                  </li>
                );
              })}
              {settings?.signup_agent === true && (
                <li style={{ padding: "5px 0" }}>
                  <Link
                    to={{
                      pathname: "/driver-signup",
                      state: { title: "Driver Signup" },
                    }}
                    state={{ title: "Driver Signup" }}
                  >
                    <GText dark="true" text={strings.w_become_an_agent} />
                  </Link>
                </li>
              )}
              {settings?.signup_vendor === true && (
                <li style={{ padding: "5px 0" }}>
                  <Link
                    to={{
                      pathname: "/vendor-signup",
                      state: { title: "Vendor Signup" },
                    }}
                    state={{ title: "Vendor Signup" }}
                  >
                    <GText dark="true" text={strings.w_join_as_vendor} />
                  </Link>
                </li>
              )}
            </ul>
          </GView>
          {socialLinks && socialLinks.length > 0 ? (
            <GView
              style={{
                flex: "50%",
                textAlign:
                  width <= 767
                    ? "center"
                    : I18nManager.getConstants().isRTL
                    ? "right"
                    : "left",
              }}
            >
              <GText
                g3="true"
                bold="true"
                text={strings?.w_follow_us}
                style={{
                  margin: "15px 0",
                  textAlign:
                    width <= 767
                      ? "center"
                      : I18nManager.getConstants().isRTL
                      ? "right"
                      : "",
                }}
              />
              <ul>
                {socialLinks.map((s, i) => {
                  return (
                    <li key={i} style={{ padding: "5px 0" }}>
                      <a href={s.url} target="_blank" rel="noopener noreferrer">
                        <GText dark="true" text={s.title} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </GView>
          ) : (
            ""
          )}

          {otherPages && otherPages.length > 0 && (
            <div>
              <h4 className="listTitle">{strings?.w_other}</h4>
              <ul>
                {otherPages.map((link, i) => {
                  return (
                    <li key={i}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </GView>
        <GView
          style={{
            display: "flex",
            padding: "20px 0",
            borderTop: "1px solid #e2e2e2",
            flexDirection: width <= 767 ? "column" : "row",
            justifyContent: width <= 767 ? "center" : "",
          }}
        >
          <GText
            text={`(c) ${new Date().getFullYear()} ${settings?.project_name}. ${
              strings?.s_all_rights_reserved
            }`}
            style={{
              color: "#333",
              width: "auto",
              margin: width <= 767 ? "0 auto" : "0",
            }}
          />

          {settings?.wl_active != 1 && (
            <p
              style={{
                margin:
                  width > 767
                    ? I18nManager.getConstants().isRTL
                      ? "0 auto 0 0"
                      : "0 0 0 auto"
                    : "5px auto 0 ",
                fontSize: "14px",
                color: "#333",
              }}
            >
              {strings?.s_powered_by}{" "}
              <a
                // href="https://www.goteso.com"
                // target="_blank"
                // rel="noopener noreferrer"
                className="theme"
              >
                Ambar
              </a>
            </p>
          )}
        </GView>
      </div>
    </Footer>
  );
}
