import React, { useEffect, useState, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import styled from "styled-components";
import GText from "../Elements/GText";
import GTableShimmer from "../Elements/gTableShimmer";
import GEmpty from "../Elements/gEmpty";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager, FlatList, View } from "react-native";

const CatDiv = styled.div`
  display: inline-block;
  padding: 0 0 25px;
  box-sizing: border-box;
  &.popup {
    width: 100%;
    display: block;
    padding: 10px 20px;
    max-height: 53vh;
    overflow: overlay;
  }
  .sticky {
    position: fixed;
    top: 100px;
    width: 19%;
    z-index: 2;
  }

  #catSidebar {
    // padding-right: 20px;

    .sticky {
      z-index: 1;
      position: fixed;
      top: 80px;
      background: #fff;
      box-shadow: rgb(204 204 204 / 52%) 0px 0px 10px;
      width: 100%;
      left: 0;
      right: 0;
    }
  }

  @media (min-width: 1201px) {
    #catSidebar {
      .sticky {
        padding: 0 7.5%;
        box-sizing: border-box;
      }
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    &.active {
      path {
        fill: ${({ theme }) => theme.body};
      }
    }
  }
`;
const Div = styled.div`
  border-left: 3px solid transparent;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
  &.active {
    background: ${({ theme }) => theme.opacityColor};
    border-left: 3px solid ${({ theme }) => theme.body};
  }
`;

const SubCategoryView = styled.div`
  display: inline-flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding: 4px 8px 10px;
  margin: 5px 12px 0 0;
  // border-radius: 3px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.body};
  }
`;

export default function CategoriesView(props) {
  const { width, height } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(props.data || []);
  const [selectedCatgoryId, setSelectedCategoryId] = useState(
    Number.isInteger(props.selectedCatId)
      ? props.selectedCatId
      : parseInt(props.selectedCatId) || ""
  );

  useEffect(() => {
    console.log(props);
    setCategory(props.data);
    setSelectedCategoryId(
      Number.isInteger(props.selectedCatId)
        ? props.selectedCatId
        : parseInt(props.selectedCatId)
    );
  }, [props]);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 500);
  }, [category]);

  function myPFunction() {
    var productsSection = document.getElementById("gProductView");
    var menuBar = document.getElementById("subCatMenu");
    var stickyBottom = productsSection?.clientHeight;
    var stickyTop = productsSection?.offsetTop - 50;
    var bottom = height;
    var bottom = stickyBottom + 500;

    // console.log("top - " + stickyTop);
    // console.log(window.pageYOffset);

    // console.log("bottom - " + bottom);

    if (menuBar && (stickyTop || stickyBottom)) {
      if (stickyTop < window.pageYOffset && bottom > window.pageYOffset) {
        menuBar.classList.add("sticky");
      } else {
        menuBar.classList.remove("sticky");
      }
    }
  }
  useEffect(() => {
    if (width > 767 || (category && category.length > 0)) {
      window.addEventListener("scroll", myPFunction);
    }
  }, [width, category]);

  return (
    <CatDiv
      style={{
        width: "100%",
        padding: "15px 0 0",
      }}
    >
      <div
        id="catSidebar"
        // style={{
        //   borderLeft:
        //     width > 767 && category.length > 1 ? "1px solid #e2e2e2" : "0",
        // }}
      >
        {isLoading === true ? (
          <GTableShimmer />
        ) : category && category.length === 0 ? (
          <GEmpty type="category" />
        ) : (
          <div id="subCatMenu">
            <View
              className="main-scroll-view"
              style={{
                flex: 1,
                flexDirection: "row",
                marginRight: "auto",
                marginLeft: "auto",
                width:
                  width > 1200
                    ? "100%"
                    : width <= 1200 && width > 991
                    ? "970px"
                    : width <= 991 && width > 767
                    ? "750px"
                    : "100%",
              }}
            >
              <FlatList
                initialNumToRender={
                  category.filter((s) => s.item_count > 0).length
                }
                contentContainerStyle={{ flex: 1 }}
                keyExtractor={(data) => data.id}
                data={category}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                initialScrollIndex={category.findIndex(
                  (s) => s.id === selectedCatgoryId
                )}
                renderItem={({ item }) => (
                  // <div style={{ flex: 1 }}>
                  <SubCategoryView
                    onClick={() => {
                      setSelectedCategoryId(item?.id);
                      props.onCatSelect(item?.id);
                    }}
                    className={selectedCatgoryId == item?.id ? "active" : ""}
                  >
                    <img
                      src={ImgUrl("category") + "/" + item?.photo}
                      alt={item?.title}
                      onError={(e) => addDefaultSrc(e, "category")}
                      style={{
                        margin: I18nManager.isRTL ? "0 0 0 8px" : "0 8px 0 0",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                    <GText
                      g4
                      bold
                      text={item?.title}
                      style={{ fontSize: "14.5px" }}
                    />
                  </SubCategoryView>
                )}
              />
            </View>
          </div>
        )}
      </div>
    </CatDiv>
  );
}
