import React, { useContext, useEffect, useState } from "react";
import { ApiService } from "../ApiService/services";
import useWindowSize from "../DataFactory/useWindowSize";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import GInput from "../Elements/gInput";
import { GTableContent } from "../Elements/gContent";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import styled from "styled-components";
import AtmOrderCheckout from "./atmOrderCheckout";
import OrderAtmOptions from "../Components/atmOrderOptions";
import CustomerAddress from "../Components/checkoutCustomerAddress";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/icons/untick-empty.svg";
import { ReactComponent as ArrowRight } from "../assets/images/icons/arrowBack.svg";

const CustomAmountView = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  span.active {
    svg {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

export default function AtmOrderCreate() {
  const { width } = useWindowSize();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [selectedAmount, setSelectedAmount] = useState({
    type: "",
    custom_value: "",
    value: "",
  });
  const [orderData, setOrderData] = useState({
    amount: "",
    delivery_address: "",
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);
  const [ocAddvisible, setOcAddVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkoutData, setCheckoutData] = useState({
    summary: {
      data: [],
    },
    wallet: {},
  });

  useEffect(() => {
    console.log(orderData);
    let COrderData = { ...orderData };
    if (COrderData.amount && COrderData.delivery_address) {
      ApiService({
        method: "POST",
        route: "c/atm/order/checkout",
        body: COrderData,
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setCheckoutData(response.data.data);
            setIsDisabled(false);
          } else {
            setCheckoutData(response.data.data);
            setIsDisabled(true);
            enqueueSnackbar(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [orderData]);

  useEffect(() => {
    if (selectedAmount.type === "custom") {
      setOrderData({ ...orderData, amount: selectedAmount.custom_value || "" });
    } else {
      setOrderData({ ...orderData, amount: selectedAmount.value || "" });
    }
  }, [selectedAmount]);

  return (
    <div className="main-content">
      <GView style={{ margin: "30px 0" }}>
        <h1>{strings.w_ambar_cash}</h1>
        <GText g4 text={strings.s_request_for_cash} />

        <OrderAtmOptions
          defaultValue={selectedAmount.value || ""}
          data={settings?.atm_options || []}
          onSelect={(val) => {
            setSelectedAmount({
              type: "",
              value: val,
              custom_value: "",
            });
          }}
        />

        <GView
          style={{
            display: "flex",
            flexDirection: width > 767 ? "row" : "column",
            gap: "20px",
            boxSizing: "border-box",
          }}
        >
          <GView style={{ width: width > 767 ? "60%" : "100%" }}>
            <GView style={{ padding: "20px 0" }}>
              <GText g4 text={strings.w_custom_amount} />
              <CustomAmountView>
                <GInput
                  type="text"
                  name={"amount"}
                  required={true}
                  id={"amount"}
                  placeholder={"$1500"}
                  autoComplete={"off"}
                  disabled={!selectedAmount.type}
                  value={selectedAmount.custom_value || ""}
                  display="block"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedAmount({
                      ...selectedAmount,
                      custom_value: e.target?.value || "",
                    });
                  }}
                  style={{
                    padding: "5px 20px",
                    height: "40px",
                    fontSize: "18px",
                    color: "#333",
                  }}
                  divStyle={{ padding: 0, width: "calc(100% - 30px)" }}
                />
                {selectedAmount.type === "custom" ? (
                  <span className="active">
                    <Check style={{ width: "22px", height: "22px" }} />
                  </span>
                ) : (
                  <span>
                    <UnCheck
                      style={{ width: "22px", height: "22px", fill: "#ccc" }}
                      onClick={() => {
                        setSelectedAmount({
                          custom_value: "",
                          type: "custom",
                          value: "",
                        });
                      }}
                    />
                  </span>
                )}
              </CustomAmountView>
            </GView>

            <GView style={{ padding: "20px 0" }}>
              <GText g4 text={strings.w_delivery_address} />
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  cursor: "pointer",
                  gap: "15px",
                  alignItems: "start",
                  background: "#f5f5f5",
                  padding: "15px 20px",
                }}
                onClick={() => {
                  setOcAddVisible(true);
                }}
              >
                <div style={{ flex: "1" }}>
                  <GText
                    style={{
                      color: "#777",
                      letterSpacing: "0.2px",
                    }}
                    text={
                      orderData?.delivery_address
                        ? orderData?.delivery_address.formatted
                        : strings?.w_choose_address
                    }
                  />
                </div>
                <ArrowRight
                  style={{
                    width: 15,
                    height: 15,
                    fill: "#aaa",
                    transform: "rotate(180deg)",
                    padding: "2px",
                  }}
                />
              </div>
            </GView>
          </GView>
          {checkoutData.summary?.total && (
            <GView style={{ width: width > 767 ? "40%" : "100%" }}>
              <GView
                style={{
                  padding: width > 767 ? "20px" : "0 15px",
                  margin: width > 767 ? "20px" : "0 0 20px",
                  boxShadow:
                    width > 767
                      ? "rgba(204, 204, 204, 0.52) 0px 0px 10px"
                      : "none",
                }}
              >
                <GText
                  g4
                  semi
                  text={strings.w_bill_details}
                  style={{ marginBottom: "15px" }}
                />
                {checkoutData?.summary?.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={settings.currency_symbol + "" + p.value}
                      align="right"
                      lAlign="right"
                      color="#333"
                      padding="2px 5px"
                    />
                  );
                })}
                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #e2e2e2",
                    margin: "5px 0",
                  }}
                />
                <GTableContent
                  title={strings.w_grand_total}
                  value={
                    settings.currency_symbol +
                    checkoutData?.summary?.total_display
                  }
                  align="right"
                  lAlign="right"
                  bold={true}
                />
              </GView>
              <GButton
                variant="condensed"
                style={{
                  width:
                    width > 767 ? "calc(100% - 40px)" : "calc(100% - 30px)",
                  display: "flex",
                  alignItems: "center",
                  margin: width > 767 ? "0 20px" : "0 15px",
                }}
                onClick={() => setCheckoutVisible(true)}
              >
                <span style={{ flex: "1", textAlign: "initial" }}>
                  {strings.w_pay_and_request_cash}
                </span>
                <span
                  style={{
                    marginLeft: "auto",
                    fontWeight: "500",
                  }}
                >
                  {settings.currency_symbol +
                    checkoutData?.summary?.total_display}
                </span>
              </GButton>
            </GView>
          )}
        </GView>
      </GView>

      <Modal
        isOpen={ocAddvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcAddVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Address Modal"
      >
        <CustomerAddress
          ocAddvisible={ocAddvisible}
          setOcAddVisible={setOcAddVisible}
          defaultAddress={orderData?.delivery_address}
          onSelectAddress={(address) => {
            orderData.delivery_address = address;
            setOrderData({ ...orderData });
          }}
        />
      </Modal>

      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <AtmOrderCheckout
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          checkoutData={checkoutData}
          data={{ ...orderData }}
        />
      </Modal>
    </div>
  );
}
