import React from "react";
import styled from "styled-components";
const H4 = styled.h4`
  color: #000;
  font-size: 15px;
  margin: 2px 0;
  font-weight: 500;
  &.inline {
    font-weight: 400;
    color: #333;
  }
`;
const P = styled.p`
  color: #333;
  font-size: 13.5px;
  margin: 2px 0px;
  line-height: 19px;

  &.inline {
    color: #555;
  }
`;

const Table = styled.table`
  width: 100%;

  tr.line {
    td {
      padding: 0px;
      border-top: 1px solid #e2e2e2;
    }
  }

  td {
    padding: 6px 5px;
  }
`;

export const GContent = (props) => {
  return (
    <div
      style={Object.assign(
        { padding: "5px 0", textTransform: props.textTransform },
        props.style
      )}
    >
      <H4>{props.title}</H4>
      <P> {props.value}</P>
    </div>
  );
};

export const GInlineContent = (props) => {
  return (
    <div
      style={{
        padding: "5px 0",
        display: "flex",
        felxItems: "baseline",
        textTransform: props.textTransform,
      }}
    >
      <H4 className="inline">{props.title}</H4>
      <span style={{ padding: "0 5px" }}> : </span>
      <P className="inline" dir="ltr">
        {props.value}
      </P>
    </div>
  );
};

export const GTableContent = (props) => {
  return (
    <Table>
      <tbody>
        <tr className={props.title}>
          <td
            style={{
              padding: props.padding,
              width: "80%",
            }}
          >
            {props.title === "line" ? (
              ""
            ) : (
              <P
                style={{
                  color: props.color,
                  fontSize: props.fontSize,
                  fontWeight: props.bold === true ? "600" : "400",
                }}
              >
                {props.title}
              </P>
            )}
          </td>
          <td
            style={{
              textAlign: props.align ? props.align : "",
              padding: props.padding,
              // width: "50%",
            }}
          >
            {props.title === "line" ? (
              ""
            ) : (
              <P
                dir={"ltr"}
                style={{
                  color: props.color,
                  fontSize: props.fontSize,
                  fontWeight: props.bold === true ? "600" : "400",
                }}
              >
                {props.value}
                {props.innerValue ? <span>({props.innerValue})</span> : ""}
              </P>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
