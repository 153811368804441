import React, { useEffect, useState } from "react";

const CartContext = React.createContext(); // line A - creating the context

const CartItemStore = ({ children }) => {
  let cartItems = localStorage.getItem("cart");
  const [itemCart, setItemCart] = useState([]); // line B - setting the initial cart
  const [storeCart, setStoreCart] = useState("");
  const updateCart = (item) => setItemCart(item); // line C - changing the cart
  useEffect(() => {
    let cartItems = localStorage.getItem("cart");
    cartItems = cartItems ? JSON.parse(cartItems) : cartItems;
    // console.log(cartItems);
    updateCart(cartItems);
    if (cartItems) {
      setStoreCart(cartItems.length > 0 ? cartItems[0]?.store_id : "");
    }
  }, []);
  return (
    <CartContext.Provider
      value={{ updateCart, itemCart, storeCart, setStoreCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartItemStore, CartContext };
