import React, { useState, useContext } from "react";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function RedeemCode(props) {
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    code: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);
    ApiService({
      method: "POST",
      route: "c/redeem-invitation",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setRedeemCodeVisible(false);
        props.onRedeem();
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    code: Yup.string().required(strings?.w_required),
  });

  if (props.redeemCodeVisible === false) {
    return null;
  }
  return (
    <div className="modal-body">
      <h4 style={{ margin: "5px 10px" }}>{strings?.s_redeem_invite_code}</h4>
      <p
        style={{
          fontSize: "14px",
          margin: "10px",
          lineHeight: "20px",
          color: "#333",
        }}
      >
        {strings?.s_enter_referral_code}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder={strings?.s_enter_code}
              name="code"
              style={{ height: "45px" }}
            />
            <br />
            <GButton
              variant="condensed"
              // disabled={!isValid}
              style={{
                margin: "0 15px",
                width: "92%",
              }}
              children={strings?.w_submit}
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
