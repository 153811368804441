import React, { useState, useEffect, useContext } from "react";
import { ApiLoginService, getAmount } from "../ApiService/services";
import useCart from "../useCart";
import { GTableContent } from "../Elements/gContent";
import GItemTable from "../Elements/gCartItems";
import { CartContext } from "../DataFactory/useCart";
import GEmpty from "../Elements/gEmpty";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import styled from "styled-components";
import GText from "../Elements/GText";
import useWindowSize from "../DataFactory/useWindowSize";
import GTableShimmer from "../Elements/gTableShimmer";
import { StringsContext } from "../DataFactory/useStrings";

const CartDiv = styled.div`
  &.sticky {
    position: fixed;
    top: 80px;
    width: 21%;
  }
`;

const StockDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.body};
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;

  p {
    text-align: left;
  }
`;
const StickyButton = styled.div`
  #bottomCheckout {
    border-radius: 0;
    margin-bottom: 20px;
    padding: 0.3rem 0.6rem;
    &.sticky {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
    }
    p {
      color: #fff;
    }
  }
`;

const CartView = (props) => {
  const [strings] = useContext(StringsContext);
  const { itemCart, updateCart, storeCart } = useContext(CartContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(true);
  const { width, height } = useWindowSize();
  const { addProduct, removeProduct, decreaseQuantity } = useCart();
  const [newOrderData, setNewOrderData] = useState({
    free_products: [],
    products: [],
    summary: {
      data: [],
    },
  });

  useEffect(() => {
    console.log(itemCart);
    if (!itemCart || (itemCart && itemCart.length === 0)) {
      setNewOrderData({
        products: [],
        summary: {
          data: [],
        },
      });
      return;
    }

    setIsBtnLoading(true);
    let orderCartData = {
      store_id: itemCart[0]?.store_id,
      products: itemCart,
    };
    ApiLoginService("POST", "c/order/cart", orderCartData)
      .then((response) => {
        console.log(response);
        setNewOrderData(response.data);
        setIsBtnLoading(false);
        if (response.status_code === 1) {
        } else {
          setIsBtnLoading(false);
          enqueueSnackbar(response.message);
        }

        setIsLoading(false);
        if (props.getTotal) {
          props.getTotal(response.data?.summary?.total_display);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [itemCart]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [storeCart]);

  function myFunction() {
    if (itemCart && newOrderData && itemCart.length > 0) {
      var footer = document.getElementById("stickyBtn");
      var footerBtn = document.getElementById("bottomCheckout");
      var sticky = footer?.offsetTop - 400;
      if (sticky) {
        if (window.pageYOffset < sticky) {
          footerBtn.classList.add("sticky");
        } else {
          footerBtn.classList.remove("sticky");
        }
      }
    }
  }
  useEffect(() => {
    if (width <= 959) {
      window.addEventListener("scroll", myFunction);
      myFunction();
    }
    if (width > 959) {
      window.addEventListener("scroll", fixedCart);
    }
  }, [itemCart]);

  function fixedCart() {
    var productSection = document.getElementById("productView");
    var csidebar = document.getElementById("cartSidebar");
    var stickyBottom = productSection?.clientHeight;
    var stickyTop = productSection?.offsetTop - 100;
    var bottom = height;
    bottom = stickyBottom - bottom + 300;

    if (csidebar && (stickyTop || stickyBottom)) {
      if (stickyTop < window.pageYOffset && bottom > window.pageYOffset) {
        csidebar.classList.add("sticky");
      } else {
        csidebar.classList.remove("sticky");
      }
    }
  }
  useEffect(() => {
    if (width > 959) {
      window.addEventListener("scroll", myFunction);
      window.addEventListener("scroll", fixedCart);
    }
  }, [width]);

  return (
    <React.Fragment>
      {width > 959 ? (
        <CartDiv style={{ padding: "25px 10px" }} id="cartSidebar">
          {isLoading === true ? (
            <GTableShimmer />
          ) : itemCart &&
            newOrderData &&
            newOrderData.products.length >= 0 &&
            newOrderData?.summary?.total_display ? (
            <React.Fragment>
              <GText g3 semi text={strings?.w_cart} />
              <br />
              <div
                style={{
                  maxHeight: "calc(100vh - 420px)",
                  overflowY: "overlay",
                }}
              >
                {newOrderData.products.length !==
                newOrderData.original_count ? (
                  <StockDiv>
                    <p>
                      {newOrderData.original_count -
                        newOrderData.products.length}{" "}
                      out of {newOrderData.original_count}{" "}
                      {strings?.w_products_unavilable_text}
                    </p>
                    <GButton
                      variant="linkable"
                      children={strings?.w_remove}
                      type="button"
                      onClick={() => {
                        updateCart([...newOrderData.products]);
                        localStorage.setItem(
                          "cart",
                          JSON.stringify([...newOrderData.products])
                        );
                      }}
                    />
                  </StockDiv>
                ) : (
                  ""
                )}
                <GItemTable
                  items={newOrderData.products}
                  freeItems={newOrderData?.free_products || []}
                  currencySymbol={props.settings?.currency_symbol}
                  editable={true}
                  onRemove={(item) => removeProduct(item)}
                  onIncQuantity={(item) => addProduct(item, item.variants)}
                  onDecQuantity={(item) => decreaseQuantity(item)}
                />
              </div>

              <React.Fragment>
                <GText
                  semi
                  g4
                  text={strings?.w_bill_details}
                  style={{
                    padding: "15px 0",
                    marginTop: "10px",
                  }}
                />
                {newOrderData?.summary?.data.map((p) => {
                  return (
                    <GTableContent
                      key={p.title}
                      title={p.title}
                      value={getAmount(p.value)}
                      align="right"
                      color="#555"
                      padding="3px 5px"
                    />
                  );
                })}
              </React.Fragment>

              <hr
                style={{
                  border: "0",
                  borderBottom: "1px solid #e2e2e2",
                  margin: "5px 0",
                }}
              />

              <GTableContent
                title={strings?.w_to_pay}
                value={getAmount(newOrderData?.summary?.total_display)}
                align="right"
                bold={true}
              />

              <GButton
                style={{ width: "100%", margin: "15px 0", borderRadius: "0" }}
                type="button"
                variant="condensed"
                disabled={
                  newOrderData.products.length !== newOrderData.original_count
                    ? true
                    : isBtnLoading
                }
                children={strings?.w_proceed_to_checkout}
                onClick={() => {
                  props.goToCheckout();
                }}
              />
            </React.Fragment>
          ) : (
            <GEmpty
              type={strings?.w_cart}
              title={strings?.s_placeholder_cart_title}
              subtitle={strings?.s_placeholder_cart_subtitle}
            />
          )}
        </CartDiv>
      ) : itemCart && newOrderData && itemCart.length > 0 ? (
        <StickyButton id="stickyBtn">
          <GButton
            id="bottomCheckout"
            variant="condensed"
            onClick={() => props.goToCheckout()}
            style={{ width: "100%", display: "flex" }}
          >
            <GTableContent
              title={strings?.w_proceed_to_checkout}
              value={
                newOrderData?.summary?.total_display
                  ? getAmount(newOrderData?.summary?.total_display)
                  : ""
              }
              align="right"
              bold={true}
            />
          </GButton>
        </StickyButton>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default CartView;
