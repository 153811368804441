import React, { useEffect, useState, useRef } from "react";
import { getAmount, ImgUrl, addDefaultSrc } from "../ApiService/services";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "./GText";
import GView from "./GView";
import Modal from "react-modal";
import ProductInfo from "../Components/productInfo";

export const OfferProductDiv = styled.div`
  border: 1px solid #f4f5f9;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .discount {
    color: #fff;
    background: ${({ theme }) => theme.body};
    position: absolute;
    top: 20px;
    right: 0;
    padding: 2px 8px;
    font-size: 14px;
    box-shadow: rgba(204, 204, 204, 0.52) 0px 0px 10px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

export default function GOfferProduct(props) {
  const { width } = useWindowSize();
  const [productData, setProductData] = useState({});
  const [itemInfoVisible, setItemInfoVisible] = useState(false);

  useEffect(() => {
    setProductData(props.data);
  }, []);

  return (
    <>
      <OfferProductDiv
        onClick={() => {
          setItemInfoVisible(true);
        }}
      >
        <img
          src={ImgUrl("item") + "/" + productData.photo}
          alt={productData.title}
          onError={(e) => addDefaultSrc(e, "item")}
          style={{
            maxWidth: "100%",
            height:
              width <= 767
                ? "auto"
                : width > 1200 && width <= 1500
                ? "110px"
                : width > 1500 && width <= 1600
                ? "120px"
                : width > 1600 && width <= 1800
                ? "140px"
                : width > 1800
                ? "150px"
                : "130px",
            objectFit: "cover",
            borderRadius: "8px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: "100%",
            aspectRatio: "2/2",
          }}
          width="auto"
          height="120px"
        />
        <div className="discount">{productData.discount}% off</div>

        <div
          style={{
            padding: "12px",
          }}
        >
          {productData.price !== productData.price_old ? (
            <div>
              <GText
                theme
                semi
                text={getAmount(productData.price_discounted)}
                style={{
                  fontWeight: 700,
                }}
              />
              <GText
                light
                text={getAmount(productData.price)}
                style={{
                  textDecoration: "line-through",
                }}
              />
            </div>
          ) : (
            <GText
              theme
              semi
              text={getAmount(productData.price)}
              style={{ padding: "5px 0", fontWeight: 700 }}
            />
          )}
          <GText
            g4
            semi
            text={productData.title}
            style={{
              letterSpacing: "0",
              fontSize: "15px",
              lineHeight: "20px",
              marginBottom: "5px",
              minHeight: "20px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </OfferProductDiv>
      <Modal
        isOpen={itemInfoVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
          },
        }}
        contentLabel="Item info Modal"
      >
        <ProductInfo
          item={productData}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          position={"right"}
        />
      </Modal>
    </>
  );
}
