import React, { useState, useEffect, useContext } from "react";
import { ImgUrl } from "../ApiService/services";
import { StringsContext } from "../DataFactory/useStrings";
import GRouteAddress from "../Elements/GRouteAddress";
import { GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import OrderMessages from "../Components/orderMesaages";
import OrderTrack from "../Components/orderGoogleMap";
import GText from "../Elements/GText";
import GView from "../Elements/GView";

export default function CourierOrderDetail(props) {
  const [strings] = useContext(StringsContext);
  const [orderData, setOrderData] = useState(props.orderData || {});
  const [omvisible, setOmvisible] = useState(false);
  const [orderMessages, setOrderMessages] = useState(
    props?.orderMessagesData || []
  );
  const { status, tasks, payment_summary, order_fields, cancelled_log } =
    orderData;

  useEffect(() => {
    setOrderData(props.orderData || {});
  }, [props.orderData]);

  return (
    <React.Fragment>
      <GView
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px 0",
        }}
      ></GView>

      <OrderTrack
        taskData={tasks}
        agent={orderData.agent}
        status={status}
        setOmvisible={setOmvisible}
      />

      {status?.title === "Cancelled" && (
        <GText
          style={{
            padding: "10px",
            color: "#F96D77",
            fontWeight: "450",
            letterSpacing: "0.2px",
            borderRadius: "5px",
            boxSizing: "border-box",
            fontSize: "13.5px",
            marginBottom: "10px",
            background: "rgba(249, 109, 119, 0.2)",
          }}
          text={cancelled_log.reason}
        />
      )}
      <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
      <div style={{ paddingBottom: "10px" }}>
        <GText
          g6
          med
          text={"Tasks"}
          style={{
            textTransform: "uppercase",
            fontStyle: "italic",
            margin: "15px 0 10px",
          }}
        />
        <GRouteAddress task={tasks} status={true} />
      </div>

      {(order_fields && order_fields.length > 0) ||
      orderData?.vehicle_type_id ? (
        <>
          <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
          <div style={{ paddingBottom: "10px" }}>
            <GText
              g6
              med
              text={"Order Info"}
              style={{
                textTransform: "uppercase",
                fontStyle: "italic",
                margin: "15px 0 10px",
              }}
            />
            {orderData?.vehicle_type_title && (
              <GInlineContent
                title="vehicle Type"
                value={orderData?.vehicle_type_title}
                textTransform="capitalize"
              />
            )}

            {order_fields.map((field, i) => {
              return (
                <>
                  {field.type === "image" ? (
                    <div style={{ margin: "10px 0" }}>
                      <GText
                        semi
                        text={field.title}
                        style={{ margin: "5px 0" }}
                      />
                      {field.value ? (
                        <img
                          src={`${ImgUrl("form")}/${field.value}`}
                          style={{ maxWidth: "100%", height: "100px" }}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  ) : field.type === "document" ? (
                    <div style={{ margin: "10px 0" }}>
                      <GText
                        semi
                        text={field.title}
                        style={{ margin: "5px 0" }}
                      />
                      <a
                        target="_blank"
                        href={`${ImgUrl("form")}/${field.value?.path}`}
                        style={{ wordBreak: "break-word" }}
                      >
                        {ImgUrl("form")}/{field.value?.path}
                      </a>
                    </div>
                  ) : (
                    <GInlineContent
                      key={i}
                      title={field.title}
                      value={field.value}
                      textTransform="capitalize"
                    />
                  )}
                </>
              );
            })}

            {orderData?.delivery_type !== "pickup_delivery" &&
              orderData.delivery_type && (
                <GInlineContent
                  title="Delivery Type"
                  value={
                    orderData.delivery_type === "asap"
                      ? "Deliver Immediately"
                      : orderData?.delivery_type
                          .replace("_", " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase()) || "-"
                  }
                  textTransform="capitalize"
                />
              )}

            {orderData.pickup_time && (
              <GInlineContent
                title="Pickup Time"
                value={orderData.pickup_time}
              />
            )}

            {orderData.delivery_time && (
              <GInlineContent
                title="Delivery Time"
                value={orderData.delivery_time}
              />
            )}
            {orderData.instructions && (
              <GInlineContent
                title="Instructions"
                value={orderData.instructions}
              />
            )}
          </div>
        </>
      ) : (
        ""
      )}

      <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
      <div>
        <GText
          g6
          med
          text={strings.w_payment_summary}
          style={{
            textTransform: "uppercase",
            fontStyle: "italic",
            margin: "15px 0 10px",
          }}
        />
        {payment_summary?.data.map((p) => {
          return (
            <GTableContent
              key={p.title}
              title={p.title}
              value={props.settings.currency_symbol + "" + p.value}
              align="right"
              lAlign="right"
              padding="2px 5px"
            />
          );
        })}
        <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

        <GTableContent
          title={strings.w_grand_total}
          value={
            props.settings.currency_symbol + "" + payment_summary?.total_display
          }
          align="right"
          lAlign="right"
          fontSize="15px"
          color="#000"
          bold={true}
        />
      </div>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
