import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import moment from "moment-timezone";
import { I18nManager } from "react-native";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/icons/untick.svg";
import { ReactComponent as ArrowRight } from "../assets/images/icons/arrowBack.svg";
import { ReactComponent as AddressHome } from "../assets/images/address-home.svg";
import InstructionIcon from "../assets/images/instructions.png";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { StringsContext } from "../DataFactory/useStrings";
import useWindowSize from "../DataFactory/useWindowSize";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import CustomerAddress from "./checkoutCustomerAddress";
import OrderTimeslots from "./checkoutOrderTimeslots";
import CheckoutOrderInstruction from "./checkoutOrderInstructions";
import GView from "../Elements/GView";
import GCheckoutMap from "./checkoutOrderMap";

const CheckoutDiv = styled.div`
  label {
    display: block;
    margin: 10px 0 15px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.2px;
    /* font-style:italic; */
    color: #555;
  }
  .inputDiv {
    display: inline-flex;
    padding: 8px 10px;
    cursor: pointer;
    align-items: center;
    border: 1px solid #f4f5f9;
    background: #fff;
    margin: 5px;
    border-radius: 4px;

    &.active {
      background: #f4f5f9;
    }

    label {
      font-weight: 500;
      // display: inline-block;
      letter-spacing: 0;
      padding: 0 10px;
      font-style: normal;
      text-transform: none;
      color: #333;
      margin: 0;
      font-size: 16px;
      vertical-align: top;
      cursor: pointer;
      p {
        color: #555;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 0;
      }
      span {
        font-weight: 400;
        padding: 5px 0;
      }
    }
    svg {
      width: 17px;
      height: 17px;
    }

    .icon {
      display: inline-flex;
    }
    span.active svg {
      path {
        fill: ${({ theme }) => theme.body};
      }
    }
  }
`;

const DeliveryTypes = ["home_delivery", "store_pickup"];
export const CheckoutForm = ({
  CheckoutData,
  setCheckoutData,
  onChange,
  newOrderData,
}) => {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const [ocAddvisible, setOcAddVisible] = useState(false);
  const [otslotsvisible, setOTslotsVisible] = useState(false);
  const [oInsVisible, setOInsVisible] = useState(false);
  const [deliveryAddressVisible, setDeliveryAddressVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDateType, setSelectedDateType] = useState("");
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    setDeliveryAddressVisible(
      CheckoutData?.order_type === "store_pickup" ? false : true
    );
  }, [CheckoutData]);

  return (
    <CheckoutDiv>
      {CheckoutData?.order_type_visible === true ? (
        <div
          style={{
            padding: deliveryAddressVisible === true ? "0 0 5px" : "0",
          }}
        >
          <GText
            g4
            semi
            text={strings?.w_order_type_}
            style={{ margin: "0 5px 8px" }}
          />

          {DeliveryTypes.map((d, i) => {
            return (
              <div
                key={i}
                className={
                  CheckoutData.order_type === d ? "inputDiv active" : "inputDiv"
                }
                onClick={() => {
                  CheckoutData.order_type = d;
                  if (d === "home_delivery") {
                    setDeliveryAddressVisible(true);
                  } else {
                    setDeliveryAddressVisible(false);
                    CheckoutData.delivery_address = "";
                  }
                  onChange(CheckoutData);
                }}
              >
                <input
                  name="option_order_type"
                  type="radio"
                  id={`v_option_${d}`}
                  value={d}
                  style={{ display: "none" }}
                  defaultChecked={CheckoutData?.order_type === d}
                />
                {CheckoutData?.order_type === d ? (
                  <span className="icon active">
                    <Check style={{ fontSize: "1rem" }} />
                  </span>
                ) : (
                  <span className="icon">
                    <Uncheck style={{ fontSize: "1rem" }} />
                  </span>
                )}
                <label htmlFor={`v_option_${d}`}>
                  {d == "home_delivery"
                    ? strings?.w_home_delivery
                    : strings?.w_store_pickup}
                </label>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {deliveryAddressVisible === true ? (
        <GView
          style={{
            display: "flex",
            flexDirection: width <= 1200 ? "column" : "row",
            gap: width <= 1200 ? 5 : 15,
          }}
        >
          {CheckoutData?.delivery_address && (
            <div
              style={{
                width: "100%",
                order: width <= 1200 ? "" : 2,
                maxWidth: width <= 1200 ? "100%" : "400px",
              }}
            >
              <GCheckoutMap
                center={{
                  lat:
                    parseFloat(CheckoutData?.delivery_address?.latitude) ||
                    30.70586,
                  lng:
                    parseFloat(CheckoutData?.delivery_address?.longitude) ||
                    76.708282,
                }}
                zoom={14}
                places={[
                  {
                    ...CheckoutData?.delivery_address,
                    delivery_lat: CheckoutData?.delivery_address?.latitude,
                    delivery_lng: CheckoutData?.delivery_address?.longitude,
                  },
                ]}
                isAutoComplete={false}
                style={{ position: "relative" }}
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  process.env.REACT_APP_GOOGLE_KEY +
                  "&libraries=geometry,drawing,places"
                }
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              cursor: "pointer",
              gap: "15px",
              alignItems: "start",
            }}
            onClick={() => {
              setOcAddVisible(true);
            }}
          >
            <AddressHome
              style={{
                width: 25,
                height: 25,
                padding: "2px",
                boxSizing: "border-box",
              }}
            />
            <div style={{ flex: "1" }}>
              <GText
                g5
                semi
                text={
                  CheckoutData?.delivery_address
                    ? CheckoutData?.delivery_address.title
                    : strings?.w_address
                }
                style={{
                  marginBottom: "2px",
                  textTransform: "uppercase",
                }}
              />
              <GText
                style={{
                  color: "#777",
                  letterSpacing: "0.2px",
                }}
                text={
                  CheckoutData?.delivery_address
                    ? CheckoutData?.delivery_address.formatted
                    : strings?.w_choose_address
                }
              />
              {CheckoutData.delivery_address && newOrderData?.eta_display && (
                <GText
                  theme
                  semi
                  text={newOrderData?.eta_display}
                  style={{ marginTop: "10px" }}
                />
              )}
            </div>
            <ArrowRight
              style={{
                width: 15,
                height: 15,
                fill: "#aaa",
                transform: "rotate(180deg)",
                padding: "2px",
              }}
            />
          </div>
        </GView>
      ) : (
        ""
      )}

      <hr
        style={{
          margin: "17px 0",
          border: "0",
          borderBottom: "1px solid #f2f2f2",
        }}
      />

      <div>
        <GText
          g4
          semi
          text={strings?.w_delivery_time}
          style={{ margin: "0 5px 8px" }}
        />

        <div>
          {CheckoutData?.asap_visible === true ? (
            <React.Fragment>
              <div
                className={
                  CheckoutData.delivery_type === "asap"
                    ? "inputDiv active"
                    : "inputDiv"
                }
                onClick={() => {
                  CheckoutData.delivery_type = "asap";
                  CheckoutData.delivery_from = "";
                  CheckoutData.delivery_to = "";
                  CheckoutData.formatted_schedule_time = "";
                  setSelectedDay("");
                  setCheckoutData(CheckoutData);
                  onChange(CheckoutData);
                }}
                style={{
                  minHeight:
                    CheckoutData.delivery_type === "scheduled"
                      ? "38px"
                      : "auto",
                }}
              >
                <input
                  name="option_delivery_type"
                  type="radio"
                  id="v_option_asap"
                  value="asap"
                  style={{ display: "none" }}
                  //checked={CheckoutData?.delivery_type === "asap"}
                />
                {CheckoutData?.delivery_type === "asap" ? (
                  <span className="icon active">
                    <Check style={{ fontSize: "1rem" }} />
                  </span>
                ) : (
                  <span className="icon">
                    <Uncheck style={{ fontSize: "1rem" }} />
                  </span>
                )}
                <label htmlFor="v_option_asap">
                  {strings?.w_asap}
                  <GText
                    g5
                    med
                    text={newOrderData?.eta_range || "ASAP"}
                    style={{ padding: "0" }}
                  />
                </label>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {CheckoutData?.delivery_type_visible === true ? (
            <div
              className={
                CheckoutData.delivery_type === "scheduled"
                  ? "inputDiv active"
                  : "inputDiv"
              }
              onClick={() => {
                setOTslotsVisible(true);
              }}
            >
              <input
                name="option_delivery_type"
                type="radio"
                id="v_option_scheduled"
                style={{ display: "none" }}
                value="scheduled"
                // disabled={true}
                // checked={CheckoutData?.delivery_type === "scheduled"}
              />
              {CheckoutData?.delivery_type === "scheduled" ? (
                <span className="icon active">
                  <Check style={{ fontSize: "1rem" }} />
                </span>
              ) : (
                <span className="icon">
                  <Uncheck style={{ fontSize: "1rem" }} />
                </span>
              )}
              <label htmlFor="v_option_scheduled">
                {strings?.w_later}
                <GText
                  g5
                  med
                  text={
                    selectedDay
                      ? CheckoutData?.formatted_schedule_time
                      : strings?.w_choose_time
                  }
                  style={{ padding: "0" }}
                />
              </label>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <hr
        style={{
          margin: "17px 0",
          border: "0",
          borderBottom: "1px solid #f2f2f2",
        }}
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          cursor: "pointer",
          gap: "15px",
          alignItems: "start",
        }}
        onClick={() => {
          setOInsVisible(true);
        }}
      >
        <img
          src={InstructionIcon}
          style={{
            width: 28,
            height: 28,
            padding: "2px",
            boxSizing: "border-box",
          }}
        />
        <div style={{ flex: "1" }}>
          <GText g4 semi text={strings?.w_instructions} />
          <GText
            style={{
              color: "#555",
              letterSpacing: "0.2px",
            }}
            text={CheckoutData?.instructions || strings?.w_add_more_details}
          />
          {CheckoutData.instructions && (
            <GButton
              variant="linkable"
              children={strings.w_clear}
              onClick={(e) => {
                e.stopPropagation();
                CheckoutData.instructions = "";
                setCheckoutData({ ...CheckoutData });
              }}
              style={{ marginTop: "10px", padding: 0 }}
            />
          )}
        </div>
        <ArrowRight
          style={{
            width: 15,
            height: 15,
            fill: "#aaa",
            transform: "rotate(180deg)",
            padding: "2px",
          }}
        />
      </div>

      <Modal
        isOpen={ocAddvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcAddVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Address Modal"
      >
        <CustomerAddress
          ocAddvisible={ocAddvisible}
          setOcAddVisible={setOcAddVisible}
          defaultAddress={CheckoutData?.delivery_address}
          onSelectAddress={(address) => {
            CheckoutData.delivery_address = address;
            setCheckoutData(CheckoutData);
            onChange(CheckoutData);
          }}
        />
      </Modal>

      <Modal
        isOpen={otslotsvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOTslotsVisible(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Checkout Order Timeslot Modal"
      >
        <OrderTimeslots
          fromDate={""}
          selectedDateType={selectedDateType}
          selectedDate={selectedDay}
          otslotsvisible={otslotsvisible}
          setOTslotsVisible={setOTslotsVisible}
          onChooseTimeslot={(slotDate, slotTime) => {
            console.log(slotDate);

            CheckoutData.delivery_type = "scheduled";
            setSelectedDay(slotDate.display);
            CheckoutData.delivery_from =
              slotDate.date + " " + slotTime.from_time;
            CheckoutData.delivery_to = slotDate.date + " " + slotTime.to_time;
            CheckoutData.formatted_schedule_time = I18nManager.getConstants()
              .isRTL
              ? moment(slotTime.from_time, "hh:mm:ss").format("hh:mm A") +
                " - " +
                moment(slotTime.to_time, "hh:mm:ss").format("hh:mm A") +
                ", " +
                slotDate.display
              : slotDate.display +
                ", " +
                moment(slotTime.from_time, "hh:mm:ss").format("hh:mm A") +
                " - " +
                moment(slotTime.to_time, "hh:mm:ss").format("hh:mm A");
            setCheckoutData(CheckoutData);
            onChange(CheckoutData);
          }}
        />
      </Modal>

      <Modal
        isOpen={oInsVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOInsVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Instructions Modal"
      >
        <CheckoutOrderInstruction
          oInsVisible={oInsVisible}
          setOInsVisible={setOInsVisible}
          defaultInstruction={CheckoutData?.instructions || ""}
          onSelect={(ins) => {
            console.log(ins);
            CheckoutData.instructions = ins;
            setCheckoutData(CheckoutData);
            onChange(CheckoutData);
          }}
        />
      </Modal>
    </CheckoutDiv>
  );
};
