import React, { useEffect, useState, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";

export default function CancelReasons(props) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [orderCancelReasons, setOrderCancelReasons] = useState([]);

  useEffect(() => {
    console.log(props);
    function getCancelReasons() {
      ApiService({ method: "GET", route: "c/cancel-reasons?user_type=1" }).then(
        (response) => {
          console.log(response.data);
          let reasons = [...response.data.data, { title: "Other" }];
          setOrderCancelReasons([...reasons]);
        }
      );
    }
    getCancelReasons();
  }, []);

  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    console.log(values);
    if (sendData.cancel_reason !== "Other") {
      delete sendData.other_reason;
    } else {
      sendData.cancel_reason = sendData.other_reason;
    }

    ApiService({
      method: "PUT",
      route: "c/order/cancel/" + props.linkedId,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setOcrVisible(false);
        props.onSubmit();
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object().shape({
    cancel_reason: Yup.string().required(strings?.w_required),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other", // alternatively: (val) => val == true
      then: (schema) => schema.required(strings?.w_required),
    }),
  });

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOcrVisible(false)} />
        {strings?.w_cancel_order}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <label style={{ margin: "15px 15px 5px" }}>
                {strings?.s_choose_cancellation_reason}
              </label>

              {orderCancelReasons.length > 0 ? (
                <FormikControl
                  control="radio"
                  name="cancel_reason"
                  options={orderCancelReasons}
                  key_title="title"
                  key_value="title"
                  custom={true}
                  divstyle={{ display: "block", padding: "5px 0" }}
                />
              ) : (
                ""
              )}
              {values.cancel_reason === "Other" && (
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder={strings?.s_enter_reason}
                  name="other_reason"
                />
              )}

              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                children={strings?.w_cancel_order}
                style={{ margin: "0 15px", width: "93%" }}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
