import React, { useEffect, useState, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";

export default function CheckoutOrderInstruction(props) {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [orderInstructionsData, setOrderInstructionsData] = useState([
    { title: "Other", value: "Other" },
  ]);

  const initialValues = {
    cancel_reason: props.defaultInstruction
      ? settings.notes_options.includes(props.defaultInstruction)
        ? props.defaultInstruction
        : "Other"
      : "",
    other_reason: !settings.notes_options.includes(props.defaultInstruction)
      ? props.defaultInstruction
      : "",
  };

  useEffect(() => {
    let ins = [];
    settings.notes_options.map((s) => {
      ins.push({ title: s });
    });
    setOrderInstructionsData(
      [...ins, { title: "Other" }] || {
        title: "Other",
      }
    );
  }, []);

  const onSubmit = (values) => {
    let sendData = { ...values };
    console.log(values);
    if (sendData.cancel_reason === "Other") {
      props.onSelect(sendData.other_reason);
    } else {
      props.onSelect(sendData.cancel_reason);
    }

    props.setOInsVisible(false);
  };

  const validationSchema = Yup.object().shape({
    cancel_reason: Yup.string().required(strings?.w_required),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other", // alternatively: (val) => val == true
      then: (schema) => schema.required(strings?.w_required),
    }),
  });

  if (props.oInsVisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOInsVisible(false)} />
        {strings?.w_instructions}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              {orderInstructionsData.length > 0 ? (
                <FormikControl
                  control="radio"
                  name="cancel_reason"
                  options={orderInstructionsData}
                  key_title="title"
                  key_value="title"
                  custom={true}
                  divstyle={{ display: "block", padding: "5px 0" }}
                />
              ) : (
                ""
              )}
              {values.cancel_reason === "Other" && (
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder={strings?.w_type_here}
                  name="other_reason"
                />
              )}

              <br />
              <GButton
                variant="condensed"
                children={strings?.w_submit}
                style={{ margin: "0 15px", width: "93%" }}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
