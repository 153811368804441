// theme.js
import React, { useContext } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ThemeContext } from "../DataFactory/useTheme";
import { SettingsContext } from "../DataFactory/useSettings";

const GlobalStyle = createGlobalStyle`
  * {
// margin: 0;
// padding: 0;
  }
  hr{
    margin:0
  }
`;

const Theme = ({ children }) => {
  const { settings } = useContext(SettingsContext);
  const { theme } = useContext(ThemeContext); // get the current theme ('light' or 'dark')
  const themes = {
    light: {
      body: settings?.web?.web_color || "#B6D024",
      opacityColor: settings?.web?.web_color
        ? `rgba(${hexToRgbA(settings?.web?.web_color)}, 0.2)`
        : `rgba(${hexToRgbA("#B6D024")}, 0.2)`,
      text: "#363537",
      toggleBorder: "#FFF",
      gradient: "linear-gradient(#39598A, #79D7ED)",
    },
    dark: {
      body: settings?.web?.web_color || "#151515",
      opacityColor: `rgba(${hexToRgbA(
        settings?.web?.web_color || "#B6D024"
      )}, 0.2
      )`,
      text: { primary: "#ddd", secondary: "#fff" },
      button: "#f23938",
      buttonRgba: hexToRgbA("#f23938"),
      toggleBorder: "#6B8096",
      gradient: "linear-gradient(#091236, #1E215D)",
      divider: "#777",
      borderColor: "#444",
      background: {
        primary: "#3a393e",
        secondary: "#f23938",
      },
      iconColor: "#fff",
    },
  };

  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
    }
    throw new Error("Bad Hex");
  }

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
