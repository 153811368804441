import React, { useState, useContext, useEffect } from "react";
import GBackIcon from "../Elements/gBackIcon";
import GMap from "../Elements/gMaps";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import styled from "styled-components";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

const Div = styled.div`
  label {
    text-transform: uppercase;
  }
`;

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry,places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

export default function CreateAddress(props) {
  const { defaultAddress } = useContext(UserAddressContext);
  const [loadMap, setLoadMap] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data.latitude),
          lng: parseFloat(props.data.longitude),
        }
      : {
          lat: defaultAddress?.lat,
          lng: defaultAddress?.lng,
        }
  );

  const addressTitle = [
    {
      key: strings.w_house,
      value: "house",
    },
    {
      key: strings.w_apartment,
      value: "apartment",
    },
    {
      key: strings.w_office,
      value: "office",
    },
    {
      key: strings.w_hotel,
      value: "hotel",
    },
    {
      key: strings.w_other,
      value: "other",
    },
  ];

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const initialValues = {
    type: "saved",
    customer_id: "",
    title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? props.data.title
          : "other"
        : "house"
      : "house",
    other_title: !props.isEmpty
      ? props.data.title
        ? addressTitle.filter((at) => at.value == props.data.title).length > 0
          ? ""
          : props.data.title
        : props.data.title
      : "",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    phone: !props.isEmpty ? props.data.phone : settings?.user?.phone,
    latitude: !props.isEmpty ? props.data.latitude : addressLocation?.lat,
    longitude: !props.isEmpty ? props.data.longitude : addressLocation?.lng,
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    apartment: !props.isEmpty ? props.data.apartment : "",
    building_name: !props.isEmpty ? props.data.building_name : "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    let sendValues = { ...values };
    sendValues.customer_id = settings?.user?.id;
    sendValues.title =
      sendValues.title === "other" ? sendValues.other_title : sendValues.title;
    console.log(sendValues);
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "c/addresses/" + props.data.id
      : "c/addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: sendValues }).then(
      (response) => {
        console.log(response.data);

        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response.data.status_code === 1) {
            props.onSubmit();
            props.setAdvisible(false);
          }
          setBtnLoading(false);
          enqueueSnackbar(response.data.message);
          return;
        }
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings?.w_required),
    line1: Yup.string().required(strings?.w_required),
    apartment: Yup.string().required(strings.w_required),
    phone: Yup.string().required(strings?.w_required),
  });

  if (props.advisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setAdvisible(false)} />
        {props.isEmpty === true
          ? strings?.w_add_address
          : strings?.w_edit_address}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 75px)",
          height: "calc(100vh - 75px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <GMap
                  lat={values.latitude}
                  lng={values.longitude}
                  search={true}
                  map={true}
                  placeholder={strings?.w_search + ".."}
                  centerLocation={addressLocation}
                  zoom={16}
                  height="30vh"
                  type="createAddress"
                  onChange={(address, loc) => {
                    values.latitude = loc.lat();
                    values.longitude = loc.lng();
                    values.line1 = address.address;
                    values.city = address.city;
                    values.state = address.state;
                    values.country = address.country;
                    values.zipcode = address.zipcode;
                    setFieldValue("line1", address.address);
                  }}
                  padding={true}
                  onLoad={!props.isEmpty ? false : true}
                />
              )}
              <FormikControl
                control="input"
                type="text"
                placeholder={strings?.s_address_line1}
                name="line1"
                readOnly={true}
              />
              <Div>
                <FormikControl
                  control="select"
                  as="select"
                  name="title"
                  label={strings.w_building_type}
                  options={addressTitle}
                  key_title="key"
                  key_value="value"
                />
                {values.title === "other" && (
                  <FormikControl
                    control="input"
                    type="text"
                    label={strings?.s_address_line_title}
                    name="other_title"
                  />
                )}
              </Div>
              <FormikControl
                control="input"
                type="text"
                label={
                  strings.w_address_apartment + " (" + strings.w_required + ")"
                }
                name="apartment"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_building_name}
                name="building_name"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings?.s_address_line2}
                name="line2"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={strings?.s_save_and_proceed}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px 15px" }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
