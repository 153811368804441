import * as React from "react";
import { ImgUrl } from "../ApiService/services";
import { StyleSheet, View, ScrollView, Dimensions, Image } from "react-native";

const DEVICE_WIDTH = Dimensions.get("window").width;

class BackgroundCarousel extends React.Component {
  scrollRef = React.createRef();
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedIndex: 0,
    };
    this.scrollRef = React.createRef();

    this.interval = null;
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.setState(
        (prev) => ({
          selectedIndex:
            prev.selectedIndex === this.props.images.length - 1
              ? 0
              : prev.selectedIndex + 1,
        }),
        () => {
          this.scrollRef.current.scrollTo({
            animated: true,
            x: DEVICE_WIDTH * this.state.selectedIndex,
            y: 0,
          });
        }
      );
    }, 5000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  setSelectedIndex = (event) => {
    const contentOffset = event.nativeEvent.contentOffset;
    const viewSize = event.nativeEvent.layoutMeasurement;

    // Divide the horizontal offset by the width of the view to see which page is visible
    const selectedIndex = Math.floor(contentOffset.x / viewSize.width);
    this.setState({ selectedIndex });
  };

  render() {
    const { images } = this.props;
    const { selectedIndex } = this.state;
    return (
      <View style={Object.assign({ height: "220px" }, this.props.style)}>
        <ScrollView
          horizontal
          pagingEnabled
          onMomentumScrollEnd={this.setSelectedIndex}
          showsHorizontalScrollIndicator={false}
          ref={this.scrollRef}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          {images &&
            images.map((image, i) => (
              <Image
                key={i}
                resizeMode="contain"
                style={{
                  //width: images.length === 1 ? DEVICE_WIDTH : DEVICE_WIDTH - 30,
                  width: "100%",
                  aspectRatio: 7 / 4,
                  resizeMode: "cover",
                  backgroundColor: "white",
                  marginRight: 10,
                  height: "100%",
                  minWidth: "100%",
                }}
                source={{
                  uri: `${ImgUrl(this.props.imgType)}/${image.photo}`,
                }}
              />
            ))}
        </ScrollView>
        {/* <View style={styles.circleDiv}>
          {images.map((image, i) => (
            <View
              style={[
                styles.whiteCircle,
                { opacity: i === selectedIndex ? 1 : 0.5 },
              ]}
              key={i}
              active={i === selectedIndex}
            />
          ))}
        </View> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backgroundImage: {
    height: "100%",
    width: Dimensions.get("window").width,
  },
  circleDiv: {
    position: "absolute",
    bottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 10,
  },
  whiteCircle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 5,
    backgroundColor: "#fff",
  },
});

export { BackgroundCarousel };
