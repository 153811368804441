import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Search } from "../assets/images/icons/search.svg";
import { I18nManager } from "react-native";
import useWindowSize from "../DataFactory/useWindowSize";
import useDebounce from "../DataFactory/useDebounce";

const Input = styled.input`
  width: 35vw;
  min-width: 230px;

  margin: 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #555;
`;

export const Filter = ({
  value,
  handleChange,
  handleBlur,
  handleFocus,
  placeholder,
  initialLoading,
  mainStyle,
}) => {
  let { width } = useWindowSize();
  const [searchWord, setSearchWord] = useState(value || "");
  const debouncedSearchTerm = useDebounce(searchWord, 800);

  useEffect(() => {
    setSearchWord(value || "");
  }, [value]);
  useEffect(() => {
    if (initialLoading === false) {
      handleChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  return (
    <div
      style={Object.assign(
        {
          display: "flex",
          alignItems: "center",
          background: "#f4f5f9",
          padding: "2px 5px",
          borderRadius: "20px",
          width: "85%",
        },
        mainStyle
      )}
    >
      <Search
        fontSize="small"
        style={{
          verticalAlign: "middle",
          width: "18px",
          height: "18px",
          fill: "#333",
          padding: "0 10px",
          transform: I18nManager.getConstants().isRTL
            ? "scaleX(-1)"
            : "scaleX(1)",
        }}
      />
      <Input
        value={searchWord}
        onChange={(e) => setSearchWord(e.target.value)}
        // onBlur={handleBlur}
        // onFocus={handleFocus}
        placeholder={placeholder}
        style={{
          maxWidth:
            width > 1300
              ? "350px"
              : width > 1150 && width <= 1300
              ? "260px"
              : "200px",
        }}
      />
    </div>
  );
};
