import React, { useEffect, useState } from "react";

const UserLocContext = React.createContext(); // line A - creating the context

const UserDefaultLocation = ({ children }) => {
  const [userArea, setUserArea] = useState(); // line B - setting the initial cart
  const [userLocation, setUserLocation] = useState({});
  const updateLocation = (area, location) => {
    setUserArea(area);
    setUserLocation(location);
  }; // line C - changing the cart

  useEffect(() => {
    let locArea = localStorage.getItem("area");
    let locCoordinates = localStorage.getItem("location");

    console.log(locCoordinates);
    locArea = locArea;
    locCoordinates = locCoordinates
      ? JSON.parse(locCoordinates)
      : locCoordinates;
    // console.log(locArea);
    // console.log(locCoordinates);
    updateLocation(locArea, locCoordinates);
  }, []);
  return (
    <UserLocContext.Provider value={{ updateLocation, userArea, userLocation }}>
      {children}
    </UserLocContext.Provider>
  );
};

export { UserDefaultLocation, UserLocContext };
