import React, { useState, useContext, useEffect } from "react";
import { StringsContext } from "../DataFactory/useStrings";
import FiltersView from "./storeFiltersView";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import { I18nManager } from "react-native-web";
import { ReactComponent as DeliveryIcon } from "../assets/images/filterIcons/delivery.svg";

const FilterDiv = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 7px 12px;
  border: 1px solid #e2e2e2;
  gap: 7px;
  box-sizing: border-box;
  svg {
    fill: #000;
    width: 17px;
    height: 17px;
  }

  &.active {
    border: 1px solid ${({ theme }) => theme.body};
    p {
      color: ${({ theme }) => theme.body};
    }
    svg {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

export default function StoreFilters(props) {
  const [strings] = useContext(StringsContext);
  const [filters, setFilters] = useState(props.filters);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (initialLoading == false) {
      props?.onApply(filters);
    }
    setInitialLoading(false);
  }, [filters]);

  //   const handleClear = () => {
  //     let filter = {
  //       orderby: "",
  //       popular: "",
  //       new: "",
  //       favourite: "",
  //       under_30: "",
  //       min_rating: "",
  //       discounts: "",
  //       price_range: "",
  //     };
  //     setFilters(filter);
  //     props?.onApply(filter);
  //   };

  //   const handleApply = () => {
  //     // refRBSheet?.current?.close();
  //     props?.onApply(filters);
  //   };

  const FilterOption = ({ identifier, title, action, selected }) => {
    return (
      <FilterDiv
        onClick={action}
        className={selected ? "active" : ""}
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 5,
          alignSelf: "flex-start",
          minWidth: 100,
        }}
      >
        {identifier === "home_delivery" ? (
          <DeliveryIcon />
        ) : identifier === "store_pickup" ? (
          <DeliveryIcon />
        ) : (
          ""
        )}
        <GText text={title} semi={selected} />
      </FilterDiv>
    );
  };

  return (
    <GView>
      <GView style={{ display: "flex", marginBottom: 15 }}>
        <GText text={strings.w_filters} bold g3 />

        {/* <GButton
          variant="linkable"
          children={strings?.w_apply}
          type="button"
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? "0" : "auto",
            marginRight: I18nManager.getConstants().isRTL ? "10px" : "0",
            padding: "2px 7px",
            textDecoration: "underline",
          }}
          onClick={() => handleApply()}
        />
        <GButton
          variant="linkable"
          children={strings?.w_reset}
          type="button"
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? "0" : "5px",
            marginRight: I18nManager.getConstants().isRTL ? "auto" : "0",
            color: "#777",
            fontWeight: 500,
            padding: "2px 7px",
            textDecoration: "underline",
          }}
          onClick={() => handleClear()}
        /> */}
      </GView>

      <FiltersView filters={filters} setFilters={setFilters} />

      <hr
        style={{
          margin: "15px 0",
          border: 0,
          borderBottom: "1px solid #e2e2e2",
        }}
      />
      <GView>
        <GText
          text={strings.w_delivery_type}
          semi
          g4
          style={{ marginBottom: 15 }}
        />

        <GView
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            gap: 10,
          }}
        >
          <FilterOption
            title={strings.w_home_delivery}
            identifier={"home_delivery"}
            selected={filters?.order_type == "home_delivery"}
            action={() =>
              setFilters({
                ...filters,
                order_type:
                  filters?.order_type == "home_delivery" ? "" : "home_delivery",
              })
            }
          />
          <FilterOption
            title={strings.w_store_pickup}
            identifier={"store_pickup"}
            selected={filters?.order_type == "store_pickup"}
            action={() =>
              setFilters({
                ...filters,
                order_type:
                  filters?.order_type == "store_pickup" ? "" : "store_pickup",
              })
            }
          />
        </GView>
      </GView>
    </GView>
  );
}
