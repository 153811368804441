import React from "react";
import styled from "styled-components";
import Loader from "../assets/images/loader.svg";

const Button = styled.button`
  padding: 0.6em 1.5em;
  font-size: 15px;
  font-weight: 550;
  border-radius: 0px;
  cursor: pointer;
  width: fit-content;
  box-shadow: none;
  outline: 0;

  &.condensed {
    background: ${({ theme }) => theme.body};
    color: #fff;
    border: 0;
  }

  &.outlined {
    color: ${({ theme }) => theme.body};
    background: transparent;
    border: 1px solid ${({ theme }) => theme.body};
  }

  &.linkable {
    color: ${({ theme }) => theme.body};
    background: transparent;
    border: 0px;
    padding: 5px;
  }

  &.text {
    background: transparent;
    padding: 6px 12px;
    border: 0px;
    color: ${({ theme }) => theme.body};

    &:hover {
      background: ${({ theme }) => theme.opacityColor};
    }
  }

  &.hide {
    display: none;
  }

  &[disabled] {
    cursor: not-allowed;
    background: #d2d2d2;
    border: 0px;
  }
`;

function GButton({ loading, children, ...props }) {
  return (
    <Button
      id={props.id}
      type={props.type || "button"}
      className={props.variant}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled || loading}
    >
      {children}
      {loading && (
        <>
          &nbsp;
          <img
            src={Loader}
            alt="loading"
            style={{ verticalAlign: "middle", float: "right" }}
            width="auto"
            height="auto"
          />
        </>
      )}
    </Button>
  );
}

export default GButton;
