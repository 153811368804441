import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import GAvtar from "./GAvtar";
import GText from "./GText";
import { I18nManager } from "react-native";

const ItemImage = styled.div`
  .MuiAvatar-root {
    height: 60px;
    width: 60px;
  }
  & img {
    height: 60px;
    width: 60px;
  }
  &.headerInfo {
    .MuiAvatar-root {
      height: 50px;
      width: 50px;
      text-transform: uppercase;
      background: ${({ theme }) => theme.body};
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }
`;

const ItemText = styled.div`
  h5 {
    margin: 5px 0;
    color: #aaa;
  }
  .tagSubtitle {
    background: ${({ theme }) => theme.body};
    color: #fff;
    width: auto;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
  }
  & .amount {
    background: ${({ theme }) => theme.body} /*#f4f5f9*/;
    color: #fff; /*#777;*/
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 20px;
  }
  & .rating {
    background: ${({ theme }) => theme.body};
    float: right;
    font-size: 14px;
    color: #fff;
    padding: 2px 7px;
    display: flex;
    align-items: flex-end;
    border-radius: 15px;
    margin-top: -25px;
  }

  &.headerInfo {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

function GInfo(props) {
  const [infoData, setInfoData] = useState({});

  useEffect(() => {
    setInfoData(props);
  }, [props]);

  return (
    <div
      style={Object.assign(
        {},
        { display: "inline-flex", width: "100%" },
        props.style
      )}
    >
      {infoData.imgType ? (
        <ItemImage
          className={infoData.className}
          style={{
            margin: I18nManager.getConstants().isRTL
              ? "0 0 0 10px"
              : "0 10px 0 0",
          }}
        >
          {infoData.imgType !== "user" ? (
            <img
              src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
              alt={infoData.title}
              onError={(e) => addDefaultSrc(e, infoData.imgType)}
              style={props.imgStyle}
            />
          ) : (
            <GAvtar
              src={infoData.photo || ""}
              title={infoData.title}
              width={props.width || ""}
              height={props.height || ""}
              imgType={infoData.imgType}
              imgOrderType={infoData.imgOrderType || ""}
              imgStyle={{ objectFit: "cover" }}
            />
          )}
        </ItemImage>
      ) : (
        ""
      )}
      <ItemText
        className={infoData.className}
        style={{ width: infoData.imgType ? "calc(100% - 65px)" : "100%" }}
      >
        {infoData.rightTitle && (
          <div
            className="amount"
            style={Object.assign(
              {
                float: I18nManager?.getConstants().isRTL ? "left" : "right",
              },
              infoData.rightTitleStyles
            )}
          >
            {infoData.rightTitle}
          </div>
        )}
        {infoData.titleTheme ? (
          <GText
            g4
            semi
            theme
            text={infoData.title}
            style={infoData.titleStyle}
          />
        ) : (
          infoData.title && (
            <GText g4 semi text={infoData.title} style={props.titleStyle} />
          )
        )}
        {infoData.subtitle && (
          <GText
            style={props.subtitleStyle}
            text={infoData.subtitle}
            className={props.subtitleClass}
          />
        )}
        {infoData.subtitle2 ? (
          <GText
            med
            g6
            text={infoData.subtitle2}
            style={Object.assign({ padding: "3px 0" }, props.subtitleStyle2)}
          />
        ) : (
          ""
        )}
        {infoData.status ? (
          <div style={{ fontSize: "14px", color: "#777" }}>
            {infoData?.amount && <span> {infoData.amount} | </span>}
            <span
              style={{
                color: "#" + infoData.status.color1,
              }}
            >
              {infoData.status.title}
            </span>
          </div>
        ) : (
          ""
        )}
        {infoData.rating ? (
          <span className="rating">★ {infoData.rating}</span>
        ) : (
          ""
        )}
      </ItemText>
    </div>
  );
}

export default GInfo;
