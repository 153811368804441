import React, { useEffect, useState, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Css/slickCarousel.css";
import { I18nManager } from "react-native";
import GView from "../Elements/GView";

const customStyles = {
  content: {
    width: "400px",
  },
};

const BannerDiv = styled.div`
  background: #fff;
  margin: 0 10px 0 0;
  position: relative;
`;

const HomeBanners = (props) => {
  const [bannersData, setBannersData] = useState(props.data || []);
  const { width } = useWindowSize();

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      width >= 1800
        ? 4
        : width > 1500 && width <= 1799
        ? 3
        : width > 1279 && width <= 1500
        ? 3
        : width > 991 && width <= 1279
        ? 2
        : width > 767 && width <= 991
        ? 2
        : width > 500 && width <= 767
        ? 1
        : 1,
    slidesToScroll: 1,
  };

  return (
    <GView style={{ padding: "10px 0" }}>
      <Slider {...SliderSettings}>
        {bannersData.map((c, i) => {
          return (
            <div
              key={i}
              // style={{
              //   width:
              //     width >= 1800
              //       ? "10%"
              //       : width > 1500 && width <= 1799
              //       ? "15%"
              //       : width > 1279 && width <= 1500
              //       ? "12.5%"
              //       : width > 991 && width <= 1279
              //       ? "16.66%"
              //       : width > 767 && width <= 991
              //       ? "20%"
              //       : width > 500 && width <= 767
              //       ? "25%"
              //       : "33.3%",
              // }}
            >
              <BannerDiv
                style={{
                  padding: I18nManager.getConstants().isRTL
                    ? width <= 767
                      ? "15px 0 15px 8px"
                      : "15px 5px 15px 10px"
                    : width <= 767
                    ? "15px 8px 15px 0"
                    : "15px 10px 15px 2px",
                  margin: "0px 5px",
                }}
              >
                <div
                  style={{
                    aspectRatio:
                      width > 991
                        ? "12/7"
                        : width > 767 && width <= 991
                        ? "10/4"
                        : width > 601 && width <= 767
                        ? "6.5/2"
                        : "5/2",
                    position: "relative",
                    cursor: "pointer",
                    background: "#f4f5f9",
                    borderRadius: "12px",
                  }}
                >
                  <img
                    src={ImgUrl("web") + "/" + c.photo}
                    alt={c.title}
                    onError={(e) => addDefaultSrc(e, "web")}
                    style={Object.assign(
                      {
                        width: "100%",
                        aspectRatio: 7 / 4,
                        resizeMode: width > 991 ? "auto" : "auto",
                        backgroundColor: "#f4f5f9",
                        marginRight: 10,
                        height: "100%",
                        minWidth: "100%",
                        borderRadius: "12px",
                        objectFit: "contain",
                      },
                      props.imgStyle
                    )}
                  />
                </div>
              </BannerDiv>
            </div>
          );
        })}
      </Slider>
    </GView>
  );
};

export default HomeBanners;
