import React from "react";
import styled from "styled-components";
import placeholder from "../assets/images/placeholders/empty-placeholder.svg";
import emptyOrders from "../assets/images/placeholders/e-order.png";
import emptyAddress from "../assets/images/placeholders/e-address.png";
import emptyOffers from "../assets/images/placeholders/e-coupon.png";
import emptyFaqs from "../assets/images/placeholders/e-faq.png";
import emptyWallet from "../assets/images/placeholders/e-wallet.png";

const PlaceHolder = styled.div`
  padding: 40px 0;
  h4 {
    color: #000;
    font-size: 1.2rem;
    font-weight: 450;
    margin: 10px 0;
    padding: 0px;
  }
  p {
    color: #000;
    font-size: 0.9rem;
    margin: 5px 0;
    font-weight: 400;
  }
`;

function GEmpty(props) {
  return (
    <PlaceHolder>
      <div id="noRecords" style={{ textAlign: "center" }}>
        <img
          src={
            props.type === "order"
              ? emptyOrders
              : props.type === "address"
              ? emptyAddress
              : props.type === "offer"
              ? emptyOffers
              : props.type === "faq"
              ? emptyFaqs
              : props.type === "wallet"
              ? emptyWallet
              : placeholder
          }
          alt=""
          style={{ width: "120px", marginBottom: "10px" }}
        />
        <h4>{props.title ? props.title : "No Records."}</h4>
        <p>
          {props.subtitle ? props.subtitle : "No data found for this search."}
        </p>
      </div>
    </PlaceHolder>
  );
}

export default GEmpty;
