import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../assets/images/address-home.svg";
import { ReactComponent as OfficeIcon } from "../assets/images/address-office.svg";
import { ReactComponent as OtherIcon } from "../assets/images/address-other.svg";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/icons/untick.svg";
import { I18nManager } from "react-native-web";

const Div = styled.div`
  color: #000;
  background: transparent;

  & input[type="radio"] {
    display: none;
  }

  .custom svg {
    height: 1.3rem;
    width: 1.3rem;
    vertical-align: middle;
    margin-right: 15px;
    path {
      fill: #ccc;
    }
  }
  input[type="radio"]:checked + label .custom svg {
    path {
      fill: ${({ theme }) => theme.body};
    }
  }

  input[type="radio"]:checked + label .filters svg {
    circle {
      fill: ${({ theme }) => theme.body};
    }
    path {
      fill: #fff;
    }
  }

  &.selected {
    background: ${({ theme }) => theme.body}!important;
    color: #fff;
    .icon svg {
      #Artboard {
        stroke: #fff;
      }
      path {
        stroke: #fff;
      }
      #Rectangle,
      #Circle,
      circle#Oval {
        fill: #fff;
      }
    }
  }
  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0 5px;
  }
  .icon svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
`;

function radioButton(props) {
  const {
    label,
    name,
    options,
    selected,
    key_title,
    style,
    icons,
    key_value,
    ...rest
  } = props;
  return (
    <div className="form-control" style={Object.assign({}, props.mainStyle)}>
      {label ? (
        <label
          style={Object.assign(
            {},
            {
              fontSize: "0.98rem",
              color: "#333",
              marginBottom: "5px",
            },
            props.mainLabelStyle
          )}
        >
          {label}
          {props.type === "variant" && props.isRequired === true ? "*" : ""}
        </label>
      ) : (
        ""
      )}
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <Div
                key={index}
                className={selected === option[key_value] ? "selected" : ""}
                style={Object.assign(
                  {},
                  {
                    display: "inline-block",
                  },
                  props.divstyle
                )}
              >
                <input
                  type="radio"
                  id={name + "_option_" + option[key_value]}
                  {...field}
                  value={option[key_value]}
                  checked={field.value == option[key_value]}
                  style={style}
                />
                <label
                  style={Object.assign(
                    {},
                    {
                      color: selected == option[key_value] ? "#fff" : "#000",
                    },
                    props.labelStyle
                  )}
                  htmlFor={name + "_option_" + option[key_value]}
                >
                  {icons === true ? (
                    <span className="icon">
                      {option[key_value] === "home" ? (
                        <HomeIcon />
                      ) : option[key_value] === "work" ? (
                        <OfficeIcon />
                      ) : (
                        <OtherIcon />
                      )}{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {(props.type === "variant" || props.custom === true) && (
                    <span className="custom">
                      {field.value == option[key_value] ? (
                        <span>
                          <Check
                            style={{
                              width: "18px",
                              height: "18px",
                              margin: I18nManager?.getConstants().isRTL
                                ? "0 0 0 15px"
                                : "0 15px 0 0",
                            }}
                          />
                        </span>
                      ) : (
                        <span>
                          <Uncheck
                            style={{
                              width: "18px",
                              height: "18px",
                              margin: I18nManager?.getConstants().isRTL
                                ? "0 0 0 15px"
                                : "0 15px 0 0",
                            }}
                          />
                        </span>
                      )}
                    </span>
                  )}
                  {props.filters == true && (
                    <span className="filters">
                      <Uncheck
                        style={{
                          width: "18px",
                          height: "18px",
                          margin: I18nManager?.getConstants().isRTL
                            ? "0 0 0 15px"
                            : "0 15px 0 0",
                        }}
                      />
                    </span>
                  )}
                  {option[key_title]}
                  {props.type === "variant"
                    ? props.priceType == "1" || props.priceType === "2"
                      ? " (" +
                        (props.priceType == "1" ? "+ " : "") +
                        props.settings?.currency_symbol +
                        option.price +
                        ")"
                      : ""
                    : ""}
                </label>
              </Div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default radioButton;
