import * as React from "react";
import { ImgUrl } from "../ApiService/services";
import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  Image,
  I18nManager,
} from "react-native";
import { GShimmer } from "./gShimmer";

const DEVICE_WIDTH = Dimensions.get("window").width;

const styles = StyleSheet.create({
  backgroundImage: {
    height: "100%",
    width: Dimensions.get("window").width,
  },
  circleDiv: {
    position: "absolute",
    bottom: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 10,
  },
  whiteCircle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 5,
    backgroundColor: "#fff",
  },
});

class Slider extends React.Component {
  scrollRef = React.createRef();
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedIndex: 0,
    };
    this.scrollRef = React.createRef();

    this.interval = null;
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.setState(
        (prev) => ({
          selectedIndex:
            prev.selectedIndex === this.props.images.length - 1
              ? 0
              : prev.selectedIndex + 1,
        }),
        () => {
          this.scrollRef.current.scrollTo({
            animated: true,
            x: DEVICE_WIDTH * this.state.selectedIndex,
            y: 0,
          });
        }
      );
    }, 5000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  setSelectedIndex = (event) => {
    const contentOffset = event.nativeEvent.contentOffset;
    const viewSize = event.nativeEvent.layoutMeasurement;
    // Divide the horizontal offset by the width of the view to see which page is visible
    const selectedIndex = Math.floor(contentOffset.x / viewSize.width);
    this.setState({ selectedIndex });
  };

  render() {
    const { images } = this.props;
    const { selectedIndex } = this.state;
    return (
      <div id="carousel">
        <View
          style={{
            // height:
            //   DEVICE_WIDTH > 1200
            //     ? "230px"
            //     : DEVICE_WIDTH > 991 && DEVICE_WIDTH <= 1200
            //     ? "250px"
            //     : DEVICE_WIDTH > 767 && DEVICE_WIDTH <= 991
            //     ? "200px"
            //     : "200px",
            aspectRatio:
              DEVICE_WIDTH > 991
                ? "12/3"
                : DEVICE_WIDTH > 767 && DEVICE_WIDTH <= 991
                ? "12/4"
                : DEVICE_WIDTH > 601 && DEVICE_WIDTH <= 767
                ? "6.5/2"
                : "5/2",
            height:
              DEVICE_WIDTH > 991
                ? "unset"
                : DEVICE_WIDTH > 767 && DEVICE_WIDTH <= 991
                ? "unset"
                : "unset",
          }}
        >
          <ScrollView
            horizontal
            pagingEnabled={true}
            onMomentumScrollEnd={this.setSelectedIndex}
            showsHorizontalScrollIndicator={true}
            ref={this.scrollRef}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            {images &&
              images.map((image, i) => (
                <Image
                  key={i}
                  alt={image.alt_tags || ""}
                  style={{
                    //width: images.length === 1 ? DEVICE_WIDTH : DEVICE_WIDTH - 30,
                    width: "100%",
                    aspectRatio: 7 / 4,
                    resizeMode: DEVICE_WIDTH > 991 ? "auto" : "auto",
                    backgroundColor: "white",
                    marginRight: 10,
                    height: "100%",
                    minWidth: "100%",
                  }}
                  source={{
                    uri: `${ImgUrl(this.props.imgType)}/${image.photo}`,
                  }}
                />
              ))}
          </ScrollView>
        </View>
      </div>
    );
  }
}

export { Slider };
