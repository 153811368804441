import React, { useContext } from "react";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { ReactComponent as RecommendedIcon } from "../assets/images/filterIcons/recommended.svg";
import { ReactComponent as FasterDeliveryIcon } from "../assets/images/filterIcons/faster_delivery.svg";
import { ReactComponent as LowestDeliveryIcon } from "../assets/images/filterIcons/lowest_delivery.svg";
import { ReactComponent as BestRatedIcon } from "../assets/images/filterIcons/best_rated.svg";
import { ReactComponent as ClosestIcon } from "../assets/images/filterIcons/closest.svg";
import { ReactComponent as PopularIcon } from "../assets/images/filterIcons/popular.svg";
import { ReactComponent as NewIcon } from "../assets/images/filterIcons/new.svg";
import { ReactComponent as HeartIcon } from "../assets/images/filterIcons/heart.svg";
import { ReactComponent as RecentSearchIcon } from "../assets/images/filterIcons/timer.svg";
import { ReactComponent as DiscountPerIcon } from "../assets/images/filterIcons/discount_percent.svg";
import styled from "styled-components";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";

const SortView = styled.div`
  cursor: pointer;
  display: flex;
  margin: 5px;
  padding: 5px;
  svg {
    fill: #000;
    width: 17px;
    height: 17px;
  }

  &.active {
    p {
      color: ${({ theme }) => theme.body};
    }
    svg {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

const FilterDiv = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 7px 12px;
  border: 1px solid #e2e2e2;
  gap: 7px;
  box-sizing: border-box;
  svg {
    fill: #000;
    width: 17px;
    height: 17px;
  }

  &.active {
    border: 1px solid ${({ theme }) => theme.body};
    p {
      color: ${({ theme }) => theme.body};
    }
    svg {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

const FiltersView = (props) => {
  const { filters, setFilters } = props;
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);

  const SortOption = ({ icon, title, identifier }) => {
    const selected = filters?.orderby == identifier;

    return (
      <SortView
        onClick={() =>
          setFilters({
            ...filters,
            orderby: filters?.orderby == identifier ? "" : identifier,
          })
        }
        className={selected ? "active" : ""}
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {identifier === "recommended" ? (
          <RecommendedIcon />
        ) : identifier === "faster_delivery" ? (
          <FasterDeliveryIcon />
        ) : identifier === "lowest_delivery" ? (
          <LowestDeliveryIcon />
        ) : identifier === "best_rated" ? (
          <BestRatedIcon />
        ) : identifier === "closest" ? (
          <ClosestIcon />
        ) : (
          ""
        )}
        <GText text={title} semi={selected} />
      </SortView>
    );
  };

  const FilterOption = ({ identifier, title, action, selected }) => {
    return (
      <FilterDiv
        onClick={action}
        className={selected ? "active" : ""}
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 5,
          alignSelf: "flex-start",
          minWidth: 100,
        }}
      >
        {identifier === "popular" ? (
          <PopularIcon />
        ) : identifier === "new" ? (
          <NewIcon />
        ) : identifier === "favourite" ? (
          <HeartIcon />
        ) : identifier === "under_30" ? (
          <RecentSearchIcon />
        ) : identifier === "min_rating" ? (
          <RecommendedIcon />
        ) : identifier === "discounts" ? (
          <DiscountPerIcon />
        ) : (
          ""
        )}
        <GText text={title} semi={selected} />
      </FilterDiv>
    );
  };

  return (
    <div>
      <GText text={strings.w_sort_by} semi g4 style={{ marginBottom: 15 }} />
      <SortOption title={strings.w_recommended} identifier="recommended" />
      <SortOption
        title={strings.w_faster_delivery}
        identifier="faster_delivery"
      />
      <SortOption
        title={strings.w_lowest_delivery}
        identifier="lowest_delivery"
      />
      <SortOption title={strings.w_best_rated} identifier="best_rated" />
      <SortOption title={strings.w_closest} identifier="closest" />

      <hr
        style={{
          margin: "15px 0",
          border: 0,
          borderBottom: "1px solid #e2e2e2",
        }}
      />

      <GText
        text={strings.w_shops_restaurants}
        semi
        g4
        style={{ marginBottom: 15 }}
      />

      <GView
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          display: "flex",
          gap: 10,
        }}
      >
        <FilterOption
          title={strings.w_popular}
          identifier={"popular"}
          selected={filters?.popular == 1}
          action={() =>
            setFilters({ ...filters, popular: filters?.popular == 1 ? 0 : 1 })
          }
        />

        <FilterOption
          title={strings.w_new}
          identifier={"new"}
          selected={filters?.new == 1}
          action={() =>
            setFilters({ ...filters, new: filters?.new == 1 ? 0 : 1 })
          }
        />
        {settings.user?.id && (
          <FilterOption
            title={strings.w_favourites}
            identifier={"favourite"}
            selected={filters?.favourite == 1}
            action={() =>
              setFilters({
                ...filters,
                favourite: filters?.favourite == 1 ? 0 : 1,
              })
            }
          />
        )}
        <FilterOption
          title={strings.w_under_30_mins}
          identifier={"under_30"}
          selected={filters?.under_30 == 1}
          action={() =>
            setFilters({
              ...filters,
              under_30: filters?.under_30 == 1 ? 0 : 1,
            })
          }
        />
        <FilterOption
          title={strings.w_more_than_4_5}
          identifier={"min_rating"}
          selected={filters?.min_rating == 4.5}
          action={() =>
            setFilters({
              ...filters,
              min_rating: filters?.min_rating == 4.5 ? "" : 4.5,
            })
          }
        />
        <FilterOption
          title={strings.w_discounts}
          identifier={"discounts"}
          selected={filters?.discounts == 1}
          action={() =>
            setFilters({
              ...filters,
              discounts: filters?.discounts == 1 ? 0 : 1,
            })
          }
        />
      </GView>

      <hr
        style={{
          margin: "20px 0 15px",
          border: 0,
          borderBottom: "1px solid #e2e2e2",
        }}
      />

      <GText
        text={strings.w_price_range}
        semi
        g4
        style={{ marginBottom: 15 }}
      />

      <GView
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          display: "flex",
          gap: 10,
        }}
      >
        <FilterOption
          title="$"
          selected={filters?.price_range == 1}
          action={() =>
            setFilters({
              ...filters,
              price_range: filters?.price_range == 1 ? 0 : 1,
            })
          }
        />
        <FilterOption
          title="$$"
          selected={filters?.price_range == 2}
          action={() =>
            setFilters({
              ...filters,
              price_range: filters?.price_range == 2 ? 0 : 2,
            })
          }
        />
        <FilterOption
          title="$$$"
          selected={filters?.price_range == 3}
          action={() =>
            setFilters({
              ...filters,
              price_range: filters?.price_range == 3 ? 0 : 3,
            })
          }
        />
      </GView>
    </div>
  );
};
export default FiltersView;
