import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import GText from "../Elements/GText";
import GEmpty from "../Elements/gEmpty";
import useWindowSize from "../DataFactory/useWindowSize";
import GTableShimmer from "../Elements/gTableShimmer";
import { StringsContext } from "../DataFactory/useStrings";

const CatDiv = styled.div`
  width: 25%;
  display: inline-block;
  padding: 25px 0;
  box-sizing: border-box;
  &.popup {
    width: 100%;
    display: block;
    padding: 10px 20px;
    max-height: 53vh;
    overflow: overlay;
  }
  .sticky {
    position: fixed;
    top: 100px;
    width: 20%;
  }
`;

export default function CategoriesView(props) {
  const [category, setCategory] = useState(props.data || []);
  const { width, height } = useWindowSize();
  const [strings] = useContext(StringsContext);

  useEffect(() => {
    // console.log(props);
    if (props.data.length > 0) {
      setCategory(props.data || []);
    }
  }, [props]);

  function myFunction() {
    var productSection = document.getElementById("productView");
    var sidebar = document.getElementById("catSidebar");
    var stickyBottom = productSection?.clientHeight;
    var stickyTop = productSection?.offsetTop - 100;
    var bottom = height;
    bottom = stickyBottom - bottom + 300;

    if (sidebar && (stickyTop || stickyBottom)) {
      if (stickyTop < window.pageYOffset && bottom > window.pageYOffset) {
        sidebar.classList.add("sticky");
      } else {
        sidebar.classList.remove("sticky");
      }
    }
  }
  useEffect(() => {
    if (width > 959) {
      window.addEventListener("scroll", myFunction);
    }
  }, [width]);

  return (
    <CatDiv className={props.isModal === true ? "popup" : ""}>
      <div
        id="catSidebar"
        style={{ padding: props.isModal === true ? "0" : "0 5px" }}
      >
        {props.isModal === true ? (
          ""
        ) : (
          <GText
            g3
            semi
            text={strings?.w_categories}
            style={{ marginBottom: "20px" }}
          />
        )}

        {props.isLoading === true ? (
          <GTableShimmer />
        ) : category.length === 0 ? (
          <GEmpty type="category" />
        ) : (
          category
            .filter((s) => s.products.length > 0)
            .map((cat, i) => {
              return (
                <div
                  onClick={() => props.onSubCatSelect(cat.id)}
                  style={{
                    margin: "10px 3px",
                    display: "flex",
                    cursor: "pointer",
                  }}
                  key={i}
                >
                  <GText g4 text={cat.title} style={{ fontSize: "15.5px" }} />
                  <GText
                    g4
                    text={cat.products.length}
                    style={{
                      width: "40px",
                      textAlign: "center",
                      fontSize: "15.5px",
                    }}
                  />
                </div>
              );
            })
        )}
      </div>
    </CatDiv>
  );
}
