import React, { Component, useRef, useEffect, useState } from "react";
import LocationIcon from "../assets/images/map-marker.svg";
import locationMarker from "../assets/images/map-marker.svg";
import MapStyles from "../jsonData/mapStyles.json";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  getCity,
  getArea,
  getState,
  getCountry,
  getZipcode,
} from "./gMapGeoLocationAdresss";

class LocationSearchInput extends React.Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = { address: props.value || "" };
    this.placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    this.setState({ address });

    const request = {
      placeId: placeId,
      fields: ["name", "geometry"],
    };
    this.placesService.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        this.props.onPlaceChanged(place);
      }
    });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            className="autocomplete-container"
            style={{ position: "relative" }}
          >
            <input
              {...getInputProps({
                placeholder: this.props.placeholder || "Search Places ...",
                className: "location-search-input",
              })}
              style={{
                width: "100%",
                border: "1px solid #ddd",
                height: "36px",
                outline: "0",
                padding: "8px 10px",
                boxSizing: "border-box",
              }}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                border: suggestions.length > 0 ? "1px solid #e9e9e9" : "0",
                position: "absolute",
                zIndex: "99999999",
                width: "100%",
                borderTop: "0px",
                boxSizing: "border-box",
              }}
            >
              {loading && <div></div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      borderBottom: "1px solid #e2e2e2",
                      padding: "5px",
                      fontSize: "13px",
                      display: "flex",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      borderBottom: "1px solid #e2e2e2",
                      padding: "5px",
                      fontSize: "13px",
                      display: "flex",
                    };
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <img
                      src={locationMarker}
                      alt=""
                      style={{
                        width: "20px",
                        opacity: "0.6",
                        paddingRight: "5px",
                        verticalAlign: "text-top",
                      }}
                    />
                    <span
                      style={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

const GMap = (props) => {
  const googleMapRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [googleMap, setGoogleMap] = useState(null);
  const [mapMarker, setMapMarker] = useState(null);
  const [address, setAddress] = useState(props.address || "");
  const [marker, setMarker] = useState({
    lat: parseFloat(props.lat),
    lng: parseFloat(props.lng),
    icon: LocationIcon,
    title: props.address,
    type: "address",
  });
  // let googleMap = null;
  let geocoder = new window.google.maps.Geocoder();
  // console.log(props);
  // list of icons

  useEffect(() => {
    let gMap = initGoogleMap();
    setGoogleMap(gMap);
  }, []);

  useEffect(() => {
    if (googleMap && !mapMarker) {
      var latlng = new window.google.maps.LatLng(props.lat, props.lng);

      googleMap.setCenter(latlng);
      let marker1 = createMarker(latlng);
      marker1.addListener("dragend", handleEvent);
      googleMap.setZoom(13);

      geocodeAddress(latlng, googleMap);
      setInitialLoading(false);
      setMapMarker(marker1);
    }
  }, [googleMap]);

  function handleEvent(event) {
    if (event?.latLng) {
      setMarker({
        ...marker,
        lat: event?.latLng?.lat(),
        lng: event?.latLng?.lng(),
      });
    }
  }

  useEffect(() => {
    if (marker?.lat && googleMap && mapMarker && initialLoading == false) {
      console.log(marker);
      var latlng = new window.google.maps.LatLng(marker.lat, marker.lng);
      //console.log(latlng);
      googleMap.setCenter(latlng);
      mapMarker.setPosition(latlng);
      mapMarker.addListener("dragend", handleEvent);
      googleMap.setZoom(13);

      geocodeAddress(latlng, googleMap);
    }
  }, [marker, googleMap, mapMarker]);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      //   zoom: 25,
      center: {
        lat: parseFloat(props.lat),
        lng: parseFloat(props.lng),
      },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: MapStyles,
    });
  };

  // create marker on google map
  const createMarker = (markerObj) => {
    return new window.google.maps.Marker({
      position: markerObj,
      map: googleMap,
      icon: LocationIcon,
      draggable: true,
    });
  };

  function showPlace(place) {
    //console.log(place?.geometry?.location);
    if (props.map === true) {
      setMarker({
        ...marker,
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });
    }
  }

  function geocodeAddress(latlng, map) {
    var latlngNew = {
      lat: parseFloat(latlng.lat()),
      lng: parseFloat(latlng.lng()),
    };
    // console.log(latlngNew);
    geocoder.geocode(
      { location: latlngNew },
      function handleResults(results, status) {
        console.log(results[0]);
        //console.log(results[0].address_components);
        var addressArray = results[0].address_components;
        var city = getCity(addressArray);
        var area = getArea(addressArray);
        var state = getState(addressArray);
        var country = getCountry(addressArray);
        var zipcode = getZipcode(addressArray);

        var adreessComponents = {
          address: address[0]?.name || results[0].formatted_address,
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          country: country ? country : "",
          zipcode: zipcode ? zipcode : "",
        };
        setAddress(address[0]?.name || results[0].formatted_address);
        // this.setState({address : adreessComponents.address})
        props.onChange(adreessComponents, latlng, results[0]);

        if (status === window.google.maps.GeocoderStatus.OK) {
          console.log(status);
          if (props.map === true) {
            map.setCenter(results[0].geometry.location);
          }
          return;
        }
      }.bind(this)
    );
  }

  return (
    <div
      className="map-container"
      style={{
        padding: props.padding === true ? "10px 15px 0" : "",
      }}
    >
      {props.label ? <label>{props.label}</label> : ""}
      {props.search === true ? (
        <LocationSearchInput
          onPlaceChanged={showPlace}
          value={address}
          placeholder={props.placeholder}
        />
      ) : (
        ""
      )}
      <div
        ref={googleMapRef}
        style={{
          maxWidth: "100%",
          height: props?.height || "350px",
        }}
      />
    </div>
  );
};

export default GMap;
