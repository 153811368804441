import Slider from "react-slick";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { addDefaultSrc, ImgUrl } from "../ApiService/services";
import { StringsContext } from "../DataFactory/useStrings";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "./GText";

const SDiv = styled.div`
  margin: 0 10px 0 0;
  position: relative;
  text-align: center;
  padding: 8px;

  .imgDiv {
    border: 1px solid #e2e2e2;
    background-color: transprent;
    border-radius: 20px;
    cursor: pointer;
    padding: 20px;
    margin-bottom: 10px;
  }

  .active {
    .imgDiv {
      border: 1px solid ${({ theme }) => theme.body};
      background-color: ${({ theme }) => theme.opacityColor};
    }
  }
`;

export default function GStoreSubClasses(props) {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const [selectedSubClass, setSelectedSubClass] = useState(
    props.selectedSubClass || ""
  );
  const [subClasses, setSubClasses] = useState(props.data);

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      width >= 1800
        ? 10
        : width > 1500 && width <= 1799
        ? 8
        : width > 1279 && width <= 1500
        ? 7
        : width > 991 && width <= 1279
        ? 6
        : width > 767 && width <= 991
        ? 5
        : width > 500 && width <= 767
        ? 4
        : 3,
    slidesToScroll: 1,
  };
  useEffect(() => {
    setSubClasses(props.data || []);
  }, [props]);

  useEffect(() => {
    props.setSelectedSubClass(selectedSubClass);
  }, [selectedSubClass]);

  return (
    <>
      <div style={{ padding: "20px 0 " }}>
        <Slider {...SliderSettings}>
          {subClasses.map((s, index) => {
            return (
              <div key={index}>
                <SDiv>
                  <div
                    style={{ width: "inherit", borderRadius: "20px" }}
                    onClick={() => {
                      if (selectedSubClass == s.id) {
                        setSelectedSubClass("");
                      } else {
                        setSelectedSubClass(s.id);
                      }
                    }}
                    className={selectedSubClass === s.id ? "active" : ""}
                  >
                    <div
                      className="imgDiv"
                      style={{
                        aspectRatio: "4/4",
                        position: "relative",
                      }}
                    >
                      <img
                        src={s.icon ? ImgUrl("store-class") + "/" + s.icon : ""}
                        onError={(e) => addDefaultSrc(e, "store-class")}
                        alt={s.photo}
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>

                    <GText text={s.title} />
                  </div>
                </SDiv>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
}
