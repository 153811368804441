import React, { useState, useEffect, useContext } from "react";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import VehicleSection from "../Components/courierOrderVehicles";
import OrderStops from "../Components/courierOrderStops";
import GTaxiMap from "../Components/courierOrderStopsMap";
import FormSection from "../Components/courierOrderForm";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GButton from "../Elements/GButton";
import CourierOrderCheckout from "./courierOrderCheckout";
import Modal from "react-modal";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../Elements/gShimmer";

const CourierOrderCreate = () => {
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const { addresses, fields, vehicle_types } = formData;
  const enqueueSnackbar = useEnqueueSnackbar();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [places, setPlaces] = useState([]);
  const [orderData, setOrderData] = useState({
    stops: [],
    fields: [],
    points: false,
    tip: 0,
    vehicle_type_id: selectedVehicleType,
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  useEffect(() => {
    ApiService({ method: "GET", route: "c/courier/order-options" })
      .then((response) => {
        console.log(response);
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setFormData(response.data.data);
            orderData.fields = response.data?.data?.fields || [];
            setOrderData({ ...orderData });
          } else {
            enqueueSnackbar(response.data?.message || "");
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log(selectedVehicleType);
    orderData.vehicle_type_id = selectedVehicleType;
    orderData.fields = formFields.length > 0 ? formFields : fields;
    setOrderData({ ...orderData });
  }, [selectedVehicleType, formFields]);

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  function showValidations() {
    if (orderData.stops.length == 0) {
      enqueueSnackbar("Choose Pickup address");
      return false;
    }
    if (orderData.stops.length > 0 && orderData.stops.length < 2) {
      enqueueSnackbar("Choose Delivery address");
      return false;
    }
    if (orderData.fields.length >= 0) {
      let validate = true;
      let m = "";
      orderData.fields.map((f) => {
        console.log(f);
        if (f.required === 1) {
          if (!f.value) {
            validate = false;
            m = f;
          }
        }
      });
      if (validate == false) {
        enqueueSnackbar(m.title + " is required");
        return false;
      }
    }
    return true;
  }

  return (
    <GView
      style={{
        display: "flex",
        flexDirection: width <= 767 ? "column" : "row",
      }}
    >
      {width <= 767 && (
        <GView
          style={{
            width: "100%",
          }}
        >
          <GTaxiMap
            center={{ lat: 30.70586, lng: 76.708282 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            style={{ position: "relative" }}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </GView>
      )}
      <GView
        style={{
          width: width <= 767 ? "100%" : "30%",
          minWidth: "250px",
          background: "#f5f6f9",
        }}
      >
        <GView
          style={{
            height: width > 767 ? "calc(100vh - 175px)" : "auto",
            overflow: "overlay",
            padding: "10px 20px 10px",
          }}
        >
          {isLoading == true ? (
            <>
              <GLineShimmer />
              <GRoundShimmer></GRoundShimmer>
              <GLineShimmer />
              <GCardShimmer type={"single"} color={"#DBDCDD"}></GCardShimmer>
            </>
          ) : (
            <>
              {vehicle_types && vehicle_types.length > 0 && (
                <VehicleSection
                  data={vehicle_types}
                  onSelect={(id) => setSelectedVehicleType(id)}
                  strings={strings}
                />
              )}

              <GView style={{ margin: "20px 0" }}>
                <GText
                  g4
                  semi
                  text={strings.w_select_stops}
                  style={{ margin: "10px 0" }}
                />
                {addresses && (
                  <OrderStops setPlaces={setPlaces} address={addresses} />
                )}
              </GView>

              {fields && fields.length > 0 && (
                <FormSection
                  strings={strings}
                  data={fields}
                  settings={settings}
                  onChange={(fields) => {
                    setFormFields(fields);
                  }}
                />
              )}
            </>
          )}
        </GView>
        {isLoading != true && (
          <GButton
            variant="condensed"
            children={strings?.w_proceed_to_payment}
            style={{
              width: "calc(100% - 50px)",
              margin: "20px 25px",
              textTransfoem: "uppercase",
              fontSize: "14px",
              padding: "0.8em",
              fontWeight: "550",
              borderRadius: "2px",
            }}
            onClick={() => {
              let v = showValidations();
              if (v == true) {
                setCheckoutVisible(true);
              }
            }}
          />
        )}
      </GView>

      {width > 767 && (
        <GView
          style={{
            width: "70%",
            position: "relative",
          }}
        >
          <GTaxiMap
            center={{ lat: 30.70586, lng: 76.708282 }}
            zoom={15}
            places={places}
            isAutoComplete={false}
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?key=" +
              process.env.REACT_APP_GOOGLE_KEY +
              "&libraries=geometry,drawing,places"
            }
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: "calc(100vh - 80px)",
                }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
            style={{ height: "100%" }}
          />
        </GView>
      )}

      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CourierOrderCheckout
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{ ...orderData }}
        />
      </Modal>
    </GView>
  );
};

export default CourierOrderCreate;
