import axios from "axios";
import moment from "moment-timezone";
import Axios from "./Axios";
import AppLogo from "../assets/images/logo.png";
import placeholder from "../assets/images/placeholders/list-placeholder.png";
import categoryPlaceholder from "../assets/images/placeholders/p-category.png";
import itemPlaceholder from "../assets/images/placeholders/p-item.png";
import storePlaceholder from "../assets/images/placeholders/p-store.png";
import userPlaceholder from "../assets/images/placeholders/f-placeholder.png";
import offerPlaceholder from "../assets/images/placeholders/offerBanner.png";

// require("dotenv").config();

export function getStoreType() {
  let s = localStorage.getItem("settings");
  s = s ? JSON.parse(s) : s;
  return s.multi_store;
}

export function getProductType() {
  let s = localStorage.getItem("settings");
  s = s ? JSON.parse(s) : s;
  if (s?.product) {
    return s.product;
  }
}

export function getAmount(amount) {
  let s = localStorage.getItem("settings");
  s = s ? JSON.parse(s) : s;
  var amountVal = amount;

  if (s.currency_direction == 1) {
    amountVal = amountVal + s.currency_symbol;
  } else {
    amountVal = s.currency_symbol + amountVal;
  }
  return amountVal;
}

export function addDefaultLogo(e) {
  e.target.src = AppLogo;
}

export function addDefaultSrc(e, type) {
  e.target.src = type
    ? type === "item" || type === "pricematch"
      ? itemPlaceholder
      : type === "store"
      ? storePlaceholder
      : type === "offer"
      ? offerPlaceholder
      : type === "category"
      ? categoryPlaceholder
      : type === "user"
      ? userPlaceholder
      : placeholder
    : "";
}

export function ImgUrl(imgType) {
  var ImageUrl = process.env.REACT_APP_IMAGE_URL;

  if (imgType) {
    ImageUrl = ImageUrl + "/" + imgType;
  }
  return ImageUrl;
}

export async function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  // console.log(match);
  return match ? match[1] : undefined;
}

export async function ApiService(service) {
  const getParams = () => {
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return "?" + formBody;
  };

  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;

  try {
    let response = await axios({
      method: service.method,
      url: process.env.REACT_APP_API_URL + "/" + service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
        timezone: moment.tz.guess(),
        locale: localStorage.getItem("language") || "en",
      },
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error.response);
    if (!error.response) {
      if (error?.request) {
        return {
          status: 26,
          message: "NETWORK FAILED. Please check your internet connection",
        };
      } else {
        return error.response;
      }
    } else {
      return error.response;
    }
  }
}

export async function PaymentApiService(service) {
  // console.log(moment.tz.guess());
  const getParams = () => {
    var formBody = [];
    for (var property in service.body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(service.body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return "?" + formBody;
  };
  let urlParam =
    service.method === "GET"
      ? service.body && Object.keys(service.body).length > 0
        ? getParams()
        : ""
      : "";
  let postData = service.method === "GET" ? "" : service.body;

  try {
    let response1 = await axios({
      method: service.method,
      url: service.route + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
        timezone: moment.tz.guess(),
      },
    });
    console.log(response1);
    // if (response1.data.status_code === 417) {
    //   localStorage.clear();
    //   window.location = "/";
    // }

    return response1;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function FileApiService(method, hUrl, param) {
  try {
    // console.log(param);
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/c/upload-image?type=" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function FormFileApiService(method, hUrl, param) {
  try {
    // console.log(param);
    var fd = new FormData();
    fd.append("image", param);

    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: fd,
      headers: {
        ContentType: undefined,
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}

export async function ApiLoginService(method, hUrl, param) {
  const getParams = () => {
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    return "?" + formBody;
  };

  let urlParam =
    method === "GET"
      ? param && Object.keys(param).length > 0
        ? getParams()
        : ""
      : "";
  let postData = method === "GET" ? "" : param;

  try {
    let response1 = await axios({
      method: method,
      url: process.env.REACT_APP_API_URL + "/" + hUrl + "" + urlParam,
      data: postData,
      headers: {
        Accept: "application/json",
        Authorization: localStorage.getItem("aAccessToken")
          ? "Bearer " + localStorage.getItem("aAccessToken")
          : "",
        locale: localStorage.getItem("language") || "en",
      },
    });
    console.log(response1);
    return response1.data;
  } catch (error) {
    console.log(error);
    if (!error.response) {
      if (error?.request) {
        return {
          status: 26,
          message: "NETWORK FAILED. Please check your internet connection",
        };
      } else {
        return error;
      }
    } else {
      return error;
    }
  }
}

export async function LogoutService(hUrl) {
  try {
    let response1 = await axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL + "/" + hUrl,
      data: "",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("aAccessToken"),
      },
    });
    return response1.data;
  } catch (error) {
    return error;
  }
}
