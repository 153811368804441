export class Item {
  constructor(obj) {
    this.id = obj.id;
    this.title = obj.title;
    this.unit = obj.unit;
    this.price = parseFloat(obj.price).toFixed(2);
    this.price_old = parseFloat(obj.price_old).toFixed(2);
    this.price_discounted = parseFloat(obj.price_discounted).toFixed(2);
    this.icon = obj.thumb_photo ? `${obj.thumb_photo}` : "";
    this.photo = obj.photo ? `${obj.photo}` : "";
    this.thumb_photo = obj.thumb_photo ? `${obj.thumb_photo}` : "";
    this.variants_available = obj.variants_available;

    this.discount = obj.discount;
    this.store_id = obj.store_id;
    this.next_available_display = obj.next_available_display;
    this.unlimited = obj.unlimited;
    this.stock = obj.stock;
    this.description = obj.description;
    this.status = obj.status;
  }
}
