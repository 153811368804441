import React, { useState, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import {
  ApiLoginService,
  ImgUrl,
  addDefaultLogo,
} from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import VerifyOtpForm from "./verifyOtp";
import NewPassForm from "./resetPassword";
import GText from "../Elements/GText";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";

export default function ForgotPassword(props) {
  const [strings] = useContext(StringsContext);
  const [verifyOtpvisible, setVerifyOtpvisible] = useState(false);
  const [npvisible, setNpvisible] = useState(false);
  const [forgotData, setForgotData] = useState({});
  const [isBtnLoading, setBtnLoading] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  const initialValues = {
    username: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (props.data) {
      delete values.password;
    }
    console.log(values);

    ApiLoginService("POST", "c/forgot-otp", values).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        setForgotData(values);
        setVerifyOtpvisible(true);
      }
      enqueueSnackbar(response.message);
      setBtnLoading(false);
      return;
    });
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(strings?.w_required),
  });

  const onHandleVerify = (otp) => {
    setForgotData({ ...forgotData, otp: otp });
    console.log(forgotData);
    setNpvisible(true);
  };
  const onHandleChange = () => {
    props.setFpvisible(false);
    props.setSigninvisible(true);
  };

  if (props.fpvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;
        <GBackIcon onClick={() => props.setFpvisible(false)} />
        {strings?.w_forgot_password}
      </div>
      {props.settings?.web?.web_logo ? (
        <img
          src={ImgUrl("web") + "/" + props.settings?.web?.web_logo}
          className="header-logo"
          alt={props.settings?.project_name}
          onError={addDefaultLogo}
          width="auto"
          height="auto"
        />
      ) : (
        <GText
          g2="true"
          bold
          theme
          text={"LOGO"}
          style={{ margin: "15px 30px 10px", width: "auto" }}
        />
      )}

      <div className="modal-body">
        <GText
          semi
          g2
          text={strings?.w_forgot_password}
          style={{
            margin: "10px 15px 10px",
          }}
        />
        <GText
          text={strings?.s_enter_email_or_phone}
          style={{
            margin: "0px 15px 20px",
          }}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings?.w_email_or_phone}
                name="username"
              />

              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children={strings?.w_reset_password}
                type="submit"
                loading={isBtnLoading}
              />
              <br />
            </Form>
          )}
        </Formik>
      </div>

      <Modal
        isOpen={verifyOtpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVerifyOtpvisible(false)}
        style={{
          content: {
            width: "350px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            height: "270px",
          },
        }}
        contentLabel="Verify OTP Modal"
      >
        <VerifyOtpForm
          verifyOtpvisible={verifyOtpvisible}
          setVerifyOtpvisible={setVerifyOtpvisible}
          data={forgotData}
          onVerify={(otp) => onHandleVerify(otp)}
        />
      </Modal>

      <Modal
        isOpen={npvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setNpvisible(false)}
        style={{ content: { width: "400px" } }}
        contentLabel="Forgot Password Form"
      >
        <NewPassForm
          npvisible={npvisible}
          setNpvisible={setNpvisible}
          data={forgotData}
          onChange={() => onHandleChange()}
        />
      </Modal>
    </React.Fragment>
  );
}
