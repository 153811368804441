import React, { useContext, useState, useEffect } from "react";
import { LogoutService, ImgUrl } from "../ApiService/services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import CartModal from "../Components/cartModal";
import FavouriteProducts from "../Components/favouriteList";
import SearchProducts from "../productSearchComponents/productSearch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GAvtar from "../Elements/GAvtar";
import Modal from "react-modal";
import { ReactComponent as OfferIcon } from "../assets/images/offers.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import GConfirmDialog from "../Elements/gConfirmDialog";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GText from "../Elements/GText";
import ChangeLanguage from "../Localisation/ChangeLanguage";
import useWindowSize from "../DataFactory/useWindowSize";
import { I18nManager } from "react-native";
import { CartContext } from "../DataFactory/useCart";
import { Popper, Grow, ClickAwayListener } from "@mui/material";
import NavLocation from "../Components/navLocation";

const Header = styled.header`
  background: #fff;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  box-sizing: border-box;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: block;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const LinkDropdown = styled.div`
  position: absolute;
  background: #fff;
  right: -50px;
  top: 20px;
  min-width: 200px;
  box-shadow: 0 0 13px #c1bebe8f;
  border-top: 3px solid ${({ theme }) => theme.body};
  padding: 10px 0;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    border-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.body};
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-width: 3px;
    padding: 7px;
    margin-left: -10px;
    transform: rotate(45deg);
    top: -10px;
    right: 30px;
    z-index: 1;
  }
  &.rtl::before {
    right: auto;
    left: 30px;
  }
  ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    li {
      a {
        padding: 10px 20px;
        text-decoration: none;
        color: #222;
        cursor: pointer;
        display: block;
        text-align: left;

        &:hover {
          background: #f4f5f9;
          color: #000;
        }
      }
    }
  }
`;

const Div = styled.div`
  &.cart {
    position: relative;
    span {
      background: red;
      color: #fff;
      width: 22px;
      height: 18px;
      font-size: 13px;
      font-weight: 600;
      padding-top: 3px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      // border-radius: 20px;
      text-align: center;
      display: inline-block;

      &.bounce {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: bounce;
        animation-name: bounce;
      }
    }
  }
`;

const HeaderView = (props) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { itemCart } = useContext(CartContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const [cartvisible, setCartvisible] = useState(false);
  const [wishlistVisible, setWishlistVisible] = useState(false);
  const [productSearchVisible, setProductSearchVisible] = useState(false);
  const [showCartBtn, setShowCartBtn] = useState(props.showCart);
  const [isItemsInCart, setIsItemsInCart] = useState(false);
  const [localisation, setLocalisation] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = (event) => {
    setOpen(false);
    setPlacement();
  };
  // function handleListKeyDown(event) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }
  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const customStyles1 = {
    content: {
      width: "450px",
    },
  };

  useEffect(() => {
    // console.log(props)
    setShowCartBtn(props.showCart);
  }, [props.showCart]);

  useEffect(() => {
    setTimeout(function () {
      setInitialLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (itemCart && itemCart.length > 0) {
      setIsItemsInCart(true);
    }
    if (initialLoading === false) {
      let cart_store = localStorage.getItem("cartStore");
      let bounceElement = document.getElementById("cartVal");
      if (cart_store) {
        // console.log(cart);
        // console.log(itemCart);
        let item_count = 0;

        if (itemCart.length > 0) {
          itemCart.map((i) => {
            item_count = item_count + i.quantity;
          });
          setIsItemsInCart(true);
        } else {
          setIsItemsInCart(false);
        }
        if (bounceElement) {
          bounceElement.classList.add("bounce");
          setTimeout(() => {
            bounceElement.classList.remove("bounce");
          }, 1000);
        }
      }
    }
  }, [itemCart]);

  useEffect(() => {
    if (settings) {
      let l = settings?.localisation
        ? JSON.parse(settings?.localisation)
        : settings?.localisation;
      setLocalisation(l);
    }
  }, [settings]);

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("aAccessToken", "");
          localStorage.setItem("aRefreshToken", "");
          localStorage.setItem("aExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header
      style={{
        minHeight: "40px",
        height: "80px",
      }}
    >
      <div
        className="main-content"
        style={{ marginTop: "0", marginBottom: "0" }}
      >
        <GView
          style={{
            display: "flex",
            padding: width <= 991 ? "14px 0" : "14px 0 14px",
            alignItems: "center",
          }}
        >
          <Link
            to="/"
            style={{
              display: "inline-flex",
              height: "100%",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {settings?.web?.web_logo ? (
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{
                  maxWidth: "180px",
                  maxHeight: "65px",
                  width: "80%",
                  minWidth: width <= 767 ? "90px" : "auto",
                }}
                width="auto"
                height="auto"
              />
            ) : (
              <GText g2="true" bold="true" theme text={strings?.w_home} />
            )}
          </Link>

          {width > 767 &&
          (location.pathname == "/" || location.pathname == "/offers") &&
          settings?.currency_symbol ? (
            <NavLocation />
          ) : (
            ""
          )}
          <GView
            style={{
              marginLeft: I18nManager.getConstants().isRTL ? 0 : "auto",
              marginRight: I18nManager.getConstants().isRTL ? "auto" : 0,
            }}
          >
            <GView
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                onClick={() => setProductSearchVisible(true)}
                style={{ cursor: "pointer" }}
                role="button"
              >
                <SearchIcon
                  style={{
                    marginLeft: I18nManager.getConstants().isRTL ? "7px" : 0,
                    marginRight: I18nManager.getConstants().isRTL ? "0" : "7px",
                  }}
                />
                {width > 767 ? strings?.w_search : ""}
              </a>

              {width > 1200 && (
                <Link
                  to={{
                    pathname: "/offers",
                  }}
                  state={{ title: strings?.w_offers }}
                >
                  <OfferIcon
                    style={{
                      marginLeft: I18nManager.getConstants().isRTL ? "7px" : 0,
                      marginRight: I18nManager.getConstants().isRTL
                        ? "0"
                        : "7px",
                    }}
                  />
                  {strings?.w_offers}
                </Link>
              )}
              {settings && (
                <>
                  {localisation?.enabled == true &&
                  localisation?.options.length > 1 ? (
                    <GView
                      style={{
                        position: "relative",
                        padding: width <= 767 ? "0 6px" : "0 12px",
                      }}
                    >
                      <ChangeLanguage />
                    </GView>
                  ) : (
                    ""
                  )}
                </>
              )}

              {settings ? (
                !settings?.user?.id ? (
                  <a
                    onClick={() => setSigninvisible(true)}
                    style={{ cursor: "pointer" }}
                    role="button"
                  >
                    <UserIcon
                      style={{
                        marginLeft: I18nManager.getConstants().isRTL
                          ? "7px"
                          : 0,
                        marginRight: I18nManager.getConstants().isRTL
                          ? "0"
                          : "7px",
                      }}
                    />
                    {strings?.w_login}
                  </a>
                ) : (
                  <a
                    aria-controls={open ? "menu-list-grow" : undefined}
                    onClick={handleClick("bottom")}
                    aria-describedby={"id"}
                    aria-haspopup="true"
                    // onClick={handleToggle}
                    className="dropdownMenu"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: width <= 767 ? "0 6px" : "0 10px",
                    }}
                  >
                    <GAvtar
                      title={settings?.user?.title}
                      width={"40px"}
                      height={"40px"}
                      src={settings?.user?.photo || ""}
                      imgType="user"
                      alt={settings?.user?.title}
                      imgStyle={{ objectFit: "cover" }}
                    />
                    &nbsp;{width > 767 && <>&nbsp;</>}
                    <GText
                      text={settings?.user?.title}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: width <= 767 ? "70px" : "100px",
                        textAlign: "left",
                      }}
                    />
                  </a>
                )
              ) : (
                ""
              )}

              <Popper
                id={"id"}
                style={{ zIndex: "99", top: 20 }}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                role={undefined}
                transition
                //    disablePortal
              >
                {({ TransitionProps }) => {
                  //const  settings  = useSettings();
                  return (
                    <Grow
                      {...TransitionProps}
                      // style={{ transformOrigin:placement==='bottom'? 'bottom right':  'top right' , anchorOrigin : 'bottom right'}}
                    >
                      {/* <Paper> */}
                      <LinkDropdown
                        className={
                          I18nManager.getConstants().isRTL ? "rtl" : ""
                        }
                        // style={{
                        //   right: I18nManager.getConstants().isRTL
                        //     ? "auto"
                        //     : "15px",
                        //   left: I18nManager.getConstants().isRTL
                        //     ? "15px"
                        //     : "auto",
                        // }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <ul>
                            <li>
                              <Link
                                to={{
                                  pathname: "/profile/address",
                                }}
                                state={{ title: strings?.w_address }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  setOpen(false);
                                }}
                              >
                                {strings?.w_manage_profile}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: "/orders",
                                }}
                                state={{ title: strings?.w_orders }}
                                onClick={() => setOpen(false)}
                              >
                                {strings?.w_orders}
                              </Link>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setOpen(false);
                                  setAnchorEl(null);
                                  setWishlistVisible(true);
                                }}
                              >
                                {strings?.w_favourite_products}
                              </a>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setCpvisible(true);
                                  setAnchorEl(null);
                                  setOpen(false);
                                }}
                              >
                                {strings?.w_change_password}
                              </a>
                            </li>
                            <li>
                              <a
                                role="button"
                                onClick={() => {
                                  setOpen(false);
                                  setAnchorEl(null);
                                  setDialogOpen(true);
                                  // handleLogout();
                                }}
                              >
                                {strings?.w_signout}
                              </a>
                            </li>
                          </ul>
                        </ClickAwayListener>
                      </LinkDropdown>
                      {/* </Paper> */}
                    </Grow>
                  );
                }}
              </Popper>

              {showCartBtn === true && (
                <>
                  {itemCart && itemCart.length > 0 ? (
                    <Div
                      className="cart"
                      onClick={() => setCartvisible(true)}
                      style={{
                        padding: width <= 767 ? "6px" : "8px 12px",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        height: "fit-content",
                      }}
                    >
                      <span id="cartVal">{itemCart.length}</span>
                      &nbsp;
                      {width > 767 && <>&nbsp;</>}
                      {strings?.w_cart}
                    </Div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </GView>
          </GView>
        </GView>
      </div>

      {dialogOpen === true && (
        <GConfirmDialog
          open={dialogOpen}
          title={strings?.w_signout}
          text={strings?.s_logout_confirmation_message}
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleLogout();
          }}
        ></GConfirmDialog>
      )}

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Signin Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Passwpord Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "450px",
            // top: "50%",
            // left: "50%",
            // right: "auto",
            // bottom: "auto",
            // marginRight: "-50%",
            // transform: "translate(-50%, -50%)",
            // borderRadius: "5px!important",
          },
        }}
        contentLabel="Edit Profile Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "385px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Change Password Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={cartvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCartvisible(false)}
        style={customStyles1}
        contentLabel="Cart Modal"
      >
        <CartModal
          cartvisible={cartvisible}
          setCartvisible={setCartvisible}
          onProceed={() => {
            navigate(
              {
                pathname: "/checkout",
              },
              {
                state: { title: "Checkout" },
              }
            );
          }}
        />
      </Modal>

      <Modal
        isOpen={wishlistVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWishlistVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Favorite Products Modal"
      >
        <FavouriteProducts
          wishlistVisible={wishlistVisible}
          setWishlistVisible={setWishlistVisible}
        />
      </Modal>

      <Modal
        isOpen={productSearchVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setProductSearchVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
            overflow: "hidden",
          },
        }}
        contentLabel="Search Products Modal"
      >
        <SearchProducts
          productSearchVisible={productSearchVisible}
          setProductSearchVisible={setProductSearchVisible}
        />
      </Modal>
    </Header>
  );
};

export default HeaderView;
