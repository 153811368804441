import React, { useEffect, useState, useContext } from "react";
import { ApiService, getAmount } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import useCart from "../useCart";
import Modal from "react-modal";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import ForgotPassword from "../forms/forgotPassword";
import { SettingsContext } from "../DataFactory/useSettings";
import { ReactComponent as FavoriteBorder } from "../assets/images/heart-empty.svg";
import { ReactComponent as Favorite } from "../assets/images/heart.svg";
import { BackgroundCarousel } from "../Elements/gSlider";
import GBackIcon from "../Elements/gBackIcon";
import GShimmer from "../Elements/gTableShimmer";
import ProductVariants from "./productVariantsForm";

export default function ProductInfo(props) {
  const [strings] = useContext(StringsContext);
  const { addProduct } = useCart();
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    images: [],
    variants: [],
  });
  const [favourite, setFavourite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { settings } = useContext(SettingsContext);
  const enqueueSnackbar = useEnqueueSnackbar();

  const customStyles = {
    content: {
      width: "400px",
      // transform: 'translate(0%, -50%)'
    },
  };

  useEffect(() => {
    if (props.item?.photo) {
      setSelectedProduct({
        ...selectedProduct,
        images: [
          {
            photo: props.item?.photo,
            thumb_photo: props.item?.thumb_photo,
          },
        ],
      });
    }

    ApiService({ method: "GET", route: "c/products/" + props.item.id }).then(
      (response) => {
        console.log(response);
        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response.data.status_code === 1) {
            setSelectedProduct(response.data.data);
            setFavourite(response.data.data.favourite);
            setTimeout(function () {
              setIsLoading(false);
            }, 300);
          }
        }
      }
    );
  }, []);

  const addToFavourite = () => {
    if (!settings?.user?.id) {
      setSigninvisible(true);
      return;
    }
    ApiService({
      method: "POST",
      route: "c/product-wishlist",
      body: { product_id: selectedProduct.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/product-wishlist",
      body: { product_id: selectedProduct.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
        // setSelectedProduct(response.data);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };

  if (props.itemInfoVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setItemInfoVisible(false)} />
        {strings?.w_product_info}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "15px 20px 0",
          boxSizing: "border-box",
          height:
            props.position === "right"
              ? "calc(100% - 50px)"
              : "calc(100% - 90px)",
        }}
      >
        <div id="itenInfo">
          <>
            <BackgroundCarousel
              imgType="item"
              images={selectedProduct?.images}
            />
          </>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "30px",
            }}
          >
            <div style={{ flex: "1" }}>
              <GText
                g4="true"
                semi="true"
                text={props.item?.title || selectedProduct?.title}
                style={{ letterSpacing: "0.3px", margin: "0" }}
              />

              {props.item?.unit || selectedProduct?.unit ? (
                <GText
                  semi="true"
                  med="true"
                  text={props.item?.unit || selectedProduct?.unit}
                  style={{
                    display: "inline-block",
                    width: "auto",
                    padding: "5px 0 ",
                  }}
                />
              ) : (
                ""
              )}

              <>
                {props.item?.price != props.item?.price_old ||
                selectedProduct?.price != selectedProduct?.price_old ? (
                  <GView>
                    <GText
                      theme="true"
                      semi="true"
                      text={getAmount(
                        props.item?.price_discounted ||
                          selectedProduct?.price_discounted
                      )}
                      style={{
                        padding: "0",
                        display: "inline-block",
                        width: "auto",
                      }}
                    />{" "}
                    <GText
                      light="true"
                      text={getAmount(
                        props.item?.price || selectedProduct?.price
                      )}
                      style={{
                        padding: "0",
                        display: "inline-block",
                        width: "auto",
                        textDecoration: "line-through",
                      }}
                    />
                  </GView>
                ) : (
                  <GText
                    theme
                    semi
                    text={getAmount(
                      props.item?.price || selectedProduct?.price
                    )}
                    style={{ padding: "0" }}
                  />
                )}
              </>
            </div>

            <span style={{ margin: "0 5px " }}>
              {favourite === true ? (
                <Favorite
                  style={{ cursor: "pointer", fill: "red" }}
                  onClick={() => {
                    removeFavourite();
                  }}
                />
              ) : (
                <FavoriteBorder
                  style={{ cursor: "pointer", fill: "#aaa" }}
                  onClick={() => {
                    addToFavourite();
                  }}
                />
              )}
            </span>
          </div>
          {props.item?.description || selectedProduct?.description ? (
            <GText
              med
              text={props.item?.description || selectedProduct?.description}
              style={{
                letterSpacing: "0.2px",
                margin: "5px 0",
                maxHeight: "250px",
                overflow: "overlay",
                overflow: "auto",
              }}
            />
          ) : (
            ""
          )}
        </div>

        {props.item.status == "0" ? (
          <GButton
            variant="outlined"
            children={strings?.w_not_available}
            disabled
            style={{
              borderRadius: "0",
              color: "#a2a2a2",
              border: "1px solid #d2d2d2",
              fontSize: "11px",
              padding: "0.6rem 0.4rem",
              fontWeight: "400",
              width: "100%",
              margin: "20px 0",
            }}
          />
        ) : (
          <>
            {props.item?.variants_available === true ? (
              <React.Fragment>
                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px dashed #e2e2e2",
                    marginTop: "15px",
                  }}
                />
                {isLoading === true ? (
                  <GShimmer />
                ) : (
                  <div
                    style={{
                      maxHeight:
                        props.position === "right"
                          ? "initial"
                          : "calc(80vh - 135px)",
                      overflow: "overlay",
                    }}
                  >
                    <ProductVariants
                      item={selectedProduct}
                      data={selectedProduct?.variants || []}
                      settings={settings}
                      onSubmitVariants={(variants) => {
                        let newItem = { ...props.item };
                        newItem.variants = variants;
                        addProduct(newItem, variants);
                        props.setItemInfoVisible(false);
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <GButton
                variant="condensed"
                children={
                  props.item?.unlimited == 0 && props.item?.stock == 0
                    ? strings?.w_out_of_stock
                    : props.item?.next_available_display
                    ? props.item?.next_available_display
                    : props.item?.status == 0
                    ? strings?.w_not_available
                    : strings?.w_add_to_cart
                }
                disabled={
                  (props.item?.unlimited == 0 && props.item?.stock == 0) ||
                  props.item?.next_available_display ||
                  props.item.status == 0
                    ? true
                    : false
                }
                onClick={() => {
                  if (props.item?.unlimited == 0 && props.item?.stock == 0) {
                    return false;
                  }
                  if (
                    props.item?.next_available_display ||
                    props.item.status == 0
                  ) {
                    return false;
                  }
                  props.item.variants = [];
                  addProduct(props.item);
                  props.setItemInfoVisible(false);
                }}
                style={{
                  borderRadius: "4px",
                  width: "100%",
                  margin: "20px 0",
                  cursor:
                    (props.item?.unlimited == 0 && props.item?.stock == 0) ||
                    props.item?.next_available_display
                      ? "auto"
                      : "pointer",
                }}
              />
            )}
          </>
        )}

        <Modal
          isOpen={signinvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setSigninvisible(false)}
          style={customStyles}
          contentLabel="Login Modal"
        >
          <SignIn
            signinvisible={signinvisible}
            setSigninvisible={setSigninvisible}
            setSignupvisible={setSignupvisible}
            setFpvisible={setFpvisible}
            settings={props.settings}
          />
        </Modal>

        <Modal
          isOpen={signupvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setSignupvisible(false)}
          style={customStyles}
          contentLabel="Signup Modal"
        >
          <SignUp
            signupvisible={signupvisible}
            setSignupvisible={setSignupvisible}
            setSigninvisible={setSigninvisible}
            settings={props.settings}
          />
        </Modal>
        <Modal
          isOpen={fpvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setFpvisible(false)}
          style={customStyles}
          contentLabel="Forgot Password Modal"
        >
          <ForgotPassword
            fpvisible={fpvisible}
            setFpvisible={setFpvisible}
            setSigninvisible={setSigninvisible}
            settings={props.settings}
          />
        </Modal>
      </div>
    </React.Fragment>
  );
}
