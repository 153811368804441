import React, { useEffect, useState, useContext } from "react";
import { getAmount, ImgUrl, addDefaultSrc } from "../ApiService/services";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import GButton from "../Elements/GButton";
import useCart from "../useCart";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Remove } from "../assets/images/icons/minus.svg";
import OrderProductVariants from "../Components/orderProductVariants";
import ProductInfo from "../Components/productInfo";
import Modal from "react-modal";
import { CartContext } from "../DataFactory/useCart";
import { StringsContext } from "../DataFactory/useStrings";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GView from "./GView";

const ProductDiv = styled.div`
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f4f5f9;
  p {
    font-size: 14px;
    line-height: 20px;
    margin: 2px 0;
    .price {
      color: ${({ theme }) => theme.body};
    }
  }
`;

const QuantityDiv = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  padding: 6px 10px;
  border: 1px solid ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.body};
  svg {
    fill: ${({ theme }) => theme.body};
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`;
const AddDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
    width: 15px;
    height: 15px;
    margin: 0 -15px;
    position: absolute;
  }
`;

export default function GProductView(props) {
  const { width } = useWindowSize();
  const [productData, setProductData] = useState({});
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { itemCart } = useContext(CartContext);
  const { cart, addProduct, decreaseQuantity } = useCart();
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    setProductData(props.data);
  }, []);

  return (
    <React.Fragment>
      <ProductDiv style={{ flex: 1, display: "flex" }}>
        <img
          src={ImgUrl("item") + "/" + productData?.icon}
          alt={productData?.title}
          onError={(e) => addDefaultSrc(e, "item")}
          style={{
            width: "70px",
            height: "70px",
            marginRight: "20px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedProduct(productData);
            setItemInfoVisible(true);
          }}
          width="70px"
          height="70px"
        />
        <div style={{ padding: "0 10px", flex: "1" }}>
          <GText
            g4
            semi
            text={productData?.title}
            style={{ fontSize: "16px" }}
          />
          <p> {productData?.unit || ""}</p>

          <>
            {productData.price != productData.price_old ? (
              <GView>
                <GText
                  theme
                  semi
                  text={
                    settings?.currency_symbol + productData.price_discounted
                  }
                  style={{
                    padding: "0",
                    display: "inline-block",
                    width: "auto",
                  }}
                />{" "}
                <GText
                  light
                  text={settings?.currency_symbol + productData.price}
                  style={{
                    padding: "0",
                    display: "inline-block",
                    width: "auto",
                    textDecoration: "line-through",
                  }}
                />
              </GView>
            ) : (
              <GText
                theme
                semi
                text={settings?.currency_symbol + productData.price}
                style={{ padding: "0" }}
              />
            )}
          </>
        </div>
        {productData.unlimited == 0 && productData?.stock == 0 ? (
          <AddDiv>
            <GButton
              variant="outlined"
              children={strings?.w_out_of_stock}
              disabled
              style={{
                borderRadius: "0",
                color: "#fff",
                border: "1px solid #d2d2d2",
                fontSize: "11.5px",
                padding: "0.6rem o.4rem",
                fontWeight: "400",
              }}
            />
          </AddDiv>
        ) : productData?.next_available_display ? (
          <AddDiv>
            <GButton
              variant="outlined"
              children={productData.next_available_display}
              disabled
              style={{
                borderRadius: "0",
                background: "#fff",
                color: "#ccc",
                border: "1px solid #d2d2d2",
                fontSize: "11.5px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0.3px",
                padding: "0.3rem 0.4rem",
                maxWidth: "110px",
              }}
            />
          </AddDiv>
        ) : itemCart && itemCart.some((c) => c.id === productData?.id) ? (
          itemCart
            .filter((c) => c.id === productData?.id)
            .slice(0, 1)
            .map((c, i) => {
              return (
                <QuantityDiv key={i}>
                  <Remove onClick={() => decreaseQuantity(c)} />
                  <span style={{ padding: "0 8px", fontSize: "14px" }}>
                    {itemCart
                      .filter((c) => c.id === productData.id)
                      .map((o) => o.quantity)
                      .reduce((a, c) => a + c, 0)}
                  </span>
                  <Add
                    onClick={() => {
                      if (productData.variants_available === false) {
                        //productData.variants = [];
                        addProduct(c, c.variants);
                      } else {
                        setSelectedProduct(c);
                        setOrderVarVisible(true);
                      }
                    }}
                  />
                </QuantityDiv>
              );
            })
        ) : (
          <AddDiv style={{ position: "relative" }}>
            {productData.status == "0" ? (
              <GButton
                variant="outlined"
                children={strings?.w_not_available}
                disabled
                style={{
                  borderRadius: "0",
                  color: "#a2a2a2",
                  border: "1px solid #d2d2d2",
                  fontSize: "11px",
                  padding: "0.6rem 0.4rem",
                  fontWeight: "400",
                }}
              />
            ) : (
              <React.Fragment>
                <GButton
                  variant="outlined"
                  children={strings?.w_add}
                  onClick={() => {
                    if (
                      productData?.unlimited == 0 &&
                      productData?.stock == 0
                    ) {
                      return false;
                    }
                    if (productData?.variants_available === false) {
                      productData.variants = [];
                      addProduct(productData);
                    } else {
                      setSelectedProduct(productData);
                      setOrderVarVisible(true);
                    }
                  }}
                  style={{
                    padding: "0.45rem 2rem",
                    borderRadius: "0",
                    cursor:
                      (productData?.unlimited == 0) & (productData?.stock == 0)
                        ? "auto"
                        : "pointer",
                  }}
                />
                {productData?.variants_available === true ? (
                  <Add
                    style={{
                      cursor: "pointer",
                      margin: "2px",
                      right: 0,
                      width: "11px",
                      height: "11px",
                    }}
                    onClick={() => {
                      if (
                        productData?.unlimited == 0 &&
                        productData?.stock == 0
                      ) {
                        return false;
                      }
                      setSelectedProduct(productData);
                      setOrderVarVisible(true);
                    }}
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </AddDiv>
        )}
      </ProductDiv>

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          settings={settings}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(productData, itemVariants) => {
            console.log(itemVariants);
            addProduct(productData, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>

      <Modal
        isOpen={itemInfoVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
            // transform: 'translate(0%, -50%)'
          },
        }}
        contentLabel="Item info Modal"
      >
        <ProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
        />
      </Modal>
    </React.Fragment>
  );
}
