import React, { useState, useContext } from "react";
import useLocalStorage from "./DataFactory/useLocalStorage";
import { CartContext } from "./DataFactory/useCart";
import { StringsContext } from "./DataFactory/useStrings";

const UseCart = (refresh = false) => {
  const [strings] = useContext(StringsContext);
  const { itemCart, updateCart, storeCart, setStoreCart } =
    useContext(CartContext);
  const [cartStore, setCartStore] = useLocalStorage("cartStore", "");
  const [cart, setCart] = useState(itemCart || []);

  const updateStorage = (c) => {
    localStorage.setItem("cart", JSON.stringify(c));
  };

  const addProduct = (product, variants = []) => {
    //console.log(cart);
    // console.log(itemCart);
    if (!itemCart || itemCart.length === 0) {
      product.quantity = 1;
      product.variants = variants;
      setCartStore(product.store_id);
      setCart([product]);
      updateCart([product]);
      setStoreCart(product.store_id);
      updateStorage([product]);
      return;
    }
    // CHECK FOR ANY ITEM FROM DIFFERENT STORE
    if (storeCart && product.store_id != storeCart) {
      if (window.confirm(strings?.s_clear_cart_message)) {
        product.quantity = 1;
        product.variants = variants;
        setCart([product]);
        setCartStore(product.store_id);
        updateCart([product]);
        setStoreCart(product.store_id);
        updateStorage([product]);
        return;
      } else {
        return;
      }
    }

    // VALIDATE VARIANTS
    if (variants.length > 0) {
      //return;
    }

    // CHECK FOR SIMILAR PRODUCT -> INCREASE QUANTITY

    let existing = itemCart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, variants)
    );
    if (existing >= 0) {
      let tempArray = [...itemCart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity + 1;
      tempArray[existing] = temp;
      setCart(tempArray);
      updateCart(tempArray);
      updateStorage(tempArray);
    } else {
      // ADD THE PRODUCT
      product.quantity = 1;
      product.variants = variants;
      setCart([...itemCart, product]);
      updateCart([...itemCart, product]);
      updateStorage([...itemCart, product]);
    }
    setCartStore(product.store_id);
  };

  const decreaseQuantity = (product) => {
    //
    let existing = itemCart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, product.variants)
    );
    console.log(existing);
    if (existing >= 0) {
      let tempArray = [...itemCart];
      let temp = tempArray[existing];
      temp.quantity = temp.quantity - 1;
      tempArray[existing] = temp;
      // console.log(tempArray);
      tempArray = tempArray.filter((t) => t.quantity > 0);
      setCart(tempArray);
      updateCart(tempArray);
      updateStorage(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length === 0) {
        setCartStore(null);
      }
    } else {
      let tempArrayNew = [...itemCart];
      tempArrayNew = tempArrayNew.filter((t) => t.quantity > 0);
      setCart(tempArrayNew);
      updateCart(tempArrayNew);
      updateStorage(tempArrayNew);
    }
    setCartStore(product.store_id);
    //return;
  };
  const removeProduct = (product) => {
    //
    let existing = itemCart.findIndex(
      (c) => c.id == product.id && isSameArray(c.variants, product.variants)
    );
    if (existing >= 0) {
      let tempArray = [...itemCart];
      let temp = tempArray[existing];
      temp.quantity = 0;
      tempArray[existing] = temp;
      tempArray = tempArray.filter((t) => t.quantity > 0);
      // console.log(tempArray);
      setCart(tempArray);
      updateCart(tempArray);
      updateStorage(tempArray);
      //   writeItemToStorage(tempArray);

      if (tempArray.length === 0) {
        setCartStore(null);
      }
      setCartStore(product.store_id);
      return;
    }
  };

  return {
    cart: itemCart,
    addProduct: addProduct,
    removeProduct: removeProduct,
    cartStore: cartStore,
    setCartStore: setCartStore,
    setCart: setCart,
    decreaseQuantity: decreaseQuantity,
  };
};

export default UseCart;

const isSameArray = (first, second) => {
  if (first.length === second.length) {
    let firstIds = first.map((f) => f.id);
    let secondIds = second.map((s) => s.id);
    if (firstIds.every((f) => secondIds.includes(f))) {
      return true;
    }
  }
  return false;
};
