import React, { useEffect, useState, useContext } from "react";
import { FlatList, View } from "react-native";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import GProductView from "../Elements/gProductView";

export default function GProductStyles(props) {
  const [strings] = useContext(StringsContext);
  const [items, setItems] = useState(props.data || []);
  useEffect(() => {
    console.log(props.data);
    setItems(props.data);
  }, [props]);

  return (
    <React.Fragment>
      {props.data.length > 0 ? (
        <h5 style={{ margin: "15px", color: "#777", fontStyle: "italic" }}>
          {props.data.length}{" "}
          {props.data.length === 1
            ? " " + strings?.w_item
            : " " + strings?.w_items}
        </h5>
      ) : (
        ""
      )}
      <View style={{ flex: 1, flexDirection: "row" }}>
        <FlatList
          // style={{ flex: 0 }}
          initialNumToRender={items.length}
          contentContainerStyle={{ flex: 1 }}
          keyExtractor={(items) => items.id}
          data={props.data}
          numColumns={1}
          renderItem={({ item, ind }) => (
            <>
              <GProductView data={item} key={ind} />
            </>
          )}
          ListEmptyComponent={props.Placeholder}
          // onEndReachedThreshold={0.4}
          // onEndReached={loadMore}
        />
      </View>

      {props.pagination.next_page_url ? (
        <GButton
          variant="condensed"
          children={strings?.w_load_more}
          type="button"
          onClick={() => props.onLoadMore()}
          style={{ display: "flex", margin: "10px auto" }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
