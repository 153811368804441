import React, { useContext } from "react";
import GView from "../Elements/GView";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";

export default function PrivacyPolicy() {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <h1>{strings?.w_privacy_policy}</h1>
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: settings?.privacy_policy }}
        ></div>
      </GView>
      <br />
    </div>
  );
}
