import React, { useContext, useState, useEffect } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import BannerImg from "../assets/images/driver-signup-banner.png";
import { ReactComponent as BgImg } from "../assets/images/driver-signup-bg.svg";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import useDataFctory from "../ApiService/useDataFactory";
import GInput from "../Elements/gInput";
import GImageEditor from "../Elements/gFormImageEditor";
import { ImgUrl, ApiLoginService } from "../ApiService/services";
import { Link } from "react-router-dom";

const Div = styled.div`
  flex: 1;
  background: #fff;
  display: inline-flex;
  .imgView {
    margin-top: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    text-align: center;

    svg {
      fill: ${({ theme }) => theme.body};
      max-width: 100%;
      height: auto;
      path {
        fill: ${({ theme }) => theme.opacityColor};
      }
    }

    img {
      position: absolute;
      left: 10%;
      bottom: 0;
    }
  }
`;
const FormDiv = styled.div`
  flex: 1;
  background: #f4f4f4;
  padding: 20px 40px;
  align-selft: center;
  box-sizing: border-box;
  overflow: overlay;

  form {
    .form-control {
      padding: 2px 12px;
      margin-bottom: 2px;

      input {
        padding: 0.5em;
        height: 38px;
      }
    }
  }
`;

const P = styled.p`
  padding: 30px 15px 15px;
  font-size: 14px;
  a {
    color: ${({ theme }) => theme.body};
  }
`;

export default function DriverSignup() {
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    terms: "",
  };
  const enqueueSnackbar = useEnqueueSnackbar();
  const { loading, Shimmer, data, refreshData } = useDataFctory(
    "d_document_types",
    false,
    { user_type: 3 }
  );
  const [docsData, setDocsData] = useState([]);

  useEffect(() => {
    if (data && data.length > 0 && loading == false) {
      data.map((d) => {
        let obj = {};
        obj.title = d.title;
        obj.document_type_id = d.id;
        obj.id = d.id;
        obj.type = d.type;
        if (d.type === "image") {
          obj.photo = "";
          obj.thumb_photo = "";
        } else {
          obj.text = "";
        }

        docsData.push(obj);
      });

      setDocsData([...docsData]);
    }
  }, [data, loading]);

  const onSubmit = (values) => {
    if (docsData.length > 0) {
      let validate = true;
      let m = "";
      docsData.map((f) => {
        console.log(f);
        if (f.type === "text") {
          if (!f.text) {
            validate = false;
            m = f;
          }
        }
        if (f.type === "image") {
          if (!f.photo) {
            validate = false;
            m = f;
          }
        }
      });
      if (validate == false) {
        enqueueSnackbar(m.title + " is required");
        return false;
      }
    }
    setBtnLoading(true);
    let sendData = {
      driver: values,
      documents: [...docsData],
    };
    console.log(sendData);
    // return;
    ApiLoginService("POST", "d/signup", sendData).then((response) => {
      console.log("r" + response.status_code);
      if (response.status_code === 1) {
        document.getElementById("resetButton").click();
        setDocsData([]);
        setChecked(false);
        refreshData({ user_type: "3" });
        window.scrollTo(0, 0);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(strings?.w_required),
    email: Yup.string().required(strings?.w_required),
    phone: Yup.string().required(strings?.w_required),
    password: Yup.string().required(strings?.w_required),
    terms: Yup.string().required(strings?.w_required),
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: width <= 767 ? "column" : "row",
          background: "#f4f5f9",
        }}
      >
        <Div style={{ padding: width <= 767 ? "30px 15px 0" : "40px 30px 0" }}>
          <div
            style={{
              width: width <= 767 ? "90%" : "75%",
              margin: "10px auto 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GText
              g1
              bold
              text={strings.w_become_an_agent}
              style={{ fontSize: "27px", fontWeight: "700" }}
            />
            <GText
              text={strings.s_join_our_team_of_delivery_agents}
              style={{ margin: "15px 0" }}
            />
            <GText
              text={strings.s_signup_to_be_a_delivery_agent}
              style={{ marginBottom: width <= 767 ? 0 : "40px" }}
            />

            <div
              className="imgView"
              style={{
                marginTop: width <= 767 ? "50px" : "auto",
                position: "relative",
              }}
            >
              <BgImg />
              <img src={BannerImg} style={{ maxWidth: "80%" }} />
            </div>
          </div>
        </Div>

        <FormDiv
          style={{
            padding: width <= 767 ? "30px 15px" : "20px 40px",
            maxHeight: width > 767 ? "calc(100vh - 80px)" : "auto",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ values, setFieldValue, resetForm, isValid }) => (
              <Form
                style={{
                  width: width <= 767 ? "100%" : "75%",
                }}
              >
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_first_name}
                  name="first_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_last_name}
                  name="last_name"
                  divstyle={{
                    display: "inline-block",
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                />
                <FormikControl
                  control="input"
                  type="email"
                  label={strings?.w_email_id}
                  name="email"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings?.w_phone}
                  name="phone"
                  onChange={(event) => {
                    const re = /^[0-9 +\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("phone", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label={strings.w_password}
                  name="password"
                />

                {docsData && docsData.length > 0 ? (
                  <>
                    <GText
                      g4
                      semi
                      text={strings.w_other_details}
                      style={{ padding: "20px 15px 5px" }}
                    />
                    {docsData.map((d, ind) => {
                      return (
                        <div
                          key={ind}
                          style={{
                            padding: "2px 10px",
                          }}
                        >
                          {d.type === "text" && (
                            <>
                              <GText
                                text={d.title}
                                med
                                style={{
                                  margin: "3px 8px 0",
                                  fontSize: "14px",
                                }}
                              />
                              <GInput
                                type="text"
                                name={d.id}
                                required={true}
                                id={d.id + "_" + ind}
                                value={d.text}
                                display="block"
                                onChange={(e) => {
                                  d.text = e.target.value;
                                  docsData[ind].text = e.target.value;
                                }}
                                style={{
                                  background: "#fff",
                                  color: "#333",
                                }}
                                divstyle={{ padding: "2px 5px" }}
                              />
                            </>
                          )}

                          {d.type === "image" && (
                            <GImageEditor
                              title={d.title}
                              onSubmit={(newValue, id, thumb_val) => {
                                d.photo = newValue;
                                d.thumb_photo = thumb_val;
                                docsData[ind].photo = newValue;
                                docsData[ind].thumb_photo = thumb_val;
                              }}
                              id={`thumb_photo_${d.id}`}
                              image={
                                d.photo
                                  ? ImgUrl("document") + "/" + d.photo
                                  : ""
                              }
                              type="document"
                              apiType="driver"
                              style={{ height: "155px", paddingTop: "60px" }}
                              divstyle={{ margin: "0", display: "block" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}

                <P>
                  <span
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="Terms"
                      name="terms"
                      value={checked}
                      checked={checked}
                      onChange={() => {
                        console.log(!checked == true ? "agree" : "");
                        setChecked(!checked);
                        setFieldValue("terms", !checked == true ? "agree" : "");
                      }}
                      style={{ marginRight: "8px", amrginTop: "2px" }}
                    ></input>
                    <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                      {strings.s_by_proceeding_i_acknowledge}{" "}
                      <Link
                        to={{
                          pathname: "/terms",
                          state: { title: "Terms & Conditions" },
                        }}
                        target="_blank"
                      >
                        {strings?.w_read}
                      </Link>
                    </span>
                  </span>
                </P>
                <GButton
                  variant="condensed"
                  disabled={!isValid}
                  children={strings?.w_sign_up}
                  style={{ margin: "8px 15px", width: "200px" }}
                  type="submit"
                  loading={isBtnLoading}
                />
                <button
                  type="button"
                  id="resetButton"
                  style={{ display: "none" }}
                  onClick={() => resetForm()}
                ></button>
              </Form>
            )}
          </Formik>
        </FormDiv>
      </div>
    </div>
  );
}
