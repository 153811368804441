import React, { useState, useContext } from "react";
import { ApiService } from "../ApiService/services";
import AddressView from "../Elements/gAddressView";
import AddressForm from "../forms/addressForm";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import useDataFctory from "../ApiService/useDataFactory";
import Modal from "react-modal";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

export default function Addresses() {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const [advisible, setAdvisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const enqueueSnackbar = useEnqueueSnackbar();
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("address", true);

  const handleDelete = (addressId) => {
    ApiService({
      method: "DELETE",
      route: "c/addresses/" + addressId,
      body: "",
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDefaultAddress = (newVal) => {
    ApiService({
      method: "PUT",
      route: "c/addresses/" + newVal,
      body: { default: 1 },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <GView
        style={{ display: "flex", margin: "10px 5px", alignItems: "center" }}
      >
        <GText g2="true" theme bold text={strings?.w_delivery_address} />
        {pagination?.total > 0 && (
          <>
            &nbsp;
            <GText
              g5
              med
              semi
              text={` (${pagination.total} ${strings.w_address})`}
              style={{ fontStyle: "italic" }}
            />
          </>
        )}
        <GButton
          variant="condensed"
          children={strings?.w_add}
          onClick={() => {
            setSelectedAddress({});
            setIsEmpty(true);
            setAdvisible(true);
          }}
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? 0 : "auto",
            marginRight: I18nManager.getConstants().isRTL ? "auto" : 0,
          }}
        />
      </GView>

      {loading === true ? (
        <Shimmer></Shimmer>
      ) : data && data.length > 0 ? (
        <React.Fragment>
          <GView
            style={{
              display: "grid",
              gridTemplateColumns:
                width <= 350
                  ? "repeat(auto-fit, minmax(350px, 1fr))"
                  : width > 1200 && width <= 1600
                  ? data.length > 2
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr 1fr"
                  : width > 1600
                  ? data.length > 1
                    ? "repeat(auto-fit, minmax(350px, 1fr))"
                    : "1fr 1fr 1fr 1fr"
                  : "repeat(auto-fit, minmax(350px, 1fr))",
              gridGap: "10px",
            }}
          >
            {data.map((v, i) => {
              return (
                <GView key={i}>
                  <AddressView
                    dropdownActions={true}
                    onEdit={(sAddress) => {
                      setSelectedAddress(sAddress);
                      setIsEmpty(false);
                      setAdvisible(true);
                    }}
                    onDelete={handleDelete}
                    addressData={v}
                    onSubmit={refreshData}
                    default={true}
                    onSelectDefault={handleDefaultAddress}
                  />
                </GView>
              );
            })}
          </GView>
          {pagination.next_page_url && (
            <GButton
              variant="condensed"
              children={strings?.w_load_more}
              type="button"
              onClick={() => loadMore()}
              style={{
                display: "flex",
                margin: "10px auto",
                justifyContent: "center",
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <Placeholder />
      )}

      <br />

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={{
          content: {
            width: "500px",
            paddingBottom: "0",
          },
        }}
        contentLabel="Address Modal"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={selectedAddress}
          onSubmit={refreshData}
          isEmpty={isEmpty}
        />
      </Modal>
    </React.Fragment>
  );
}
