import React, { useEffect, useState, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import styled from "styled-components";
import GText from "../Elements/GText";
import useWindowSize from "../DataFactory/useWindowSize";
import { SettingsContext } from "../DataFactory/useSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Css/slickCarousel.css";
import { I18nManager } from "react-native";
import { useNavigate } from "react-router-dom";
import GView from "../Elements/GView";
import Modal from "react-modal";
import SignIn from "../forms/login";
import SignUp from "../forms/signup";
import ForgotPassword from "../forms/forgotPassword";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { StringsContext } from "../DataFactory/useStrings";

const customStyles = {
  content: {
    width: "400px",
  },
};

const ClassDiv = styled.div`
  background: #fff;
  margin: 0 10px 0 0;
  position: relative;
`;

const HomeClasses = (props) => {
  let navigate = useNavigate();
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [classesData, setClassesData] = useState(props.data || []);
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [signinvisible, setSigninvisible] = useState(false);
  const [fpvisible, setFpvisible] = useState(false);
  const [signupvisible, setSignupvisible] = useState(false);

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      width >= 1800
        ? 10
        : width > 1500 && width <= 1799
        ? 8
        : width > 1279 && width <= 1500
        ? 7
        : width > 991 && width <= 1279
        ? 6
        : width > 767 && width <= 991
        ? 5
        : width > 500 && width <= 767
        ? 4
        : 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (props.isAtmAvailable === true) {
      setClassesData(
        [
          ...props.data,
          {
            title: "Cajero",
            icon: "cash.png",
            type: "atm",
            status: 1,
          },
          {
            title: "Envios",
            icon: "courier.png",
            type: "courier",
            status: 1,
          },
        ] || []
      );
    } else {
      setClassesData(
        [
          ...props.data,
          {
            title: "Envios",
            icon: "courier.png",
            type: "courier",
            status: 1,
          },
        ] || []
      );
    }
  }, [props]);

  const goToCreateOrder = (type) => {
    if (settings.user?.id) {
      navigate(
        {
          pathname:
            type === "courier"
              ? "courier-order-create"
              : type === "atm"
              ? "atm-order-create"
              : "",
        },
        {
          state: {
            title:
              type === "courier"
                ? "Courier Order Create"
                : type === "atm"
                ? "Atm Order Create"
                : "",
          },
        }
      );
    } else {
      setSigninvisible(true);
    }
  };

  return (
    <GView style={{ padding: "45px 0 30px" }}>
      <Slider {...SliderSettings}>
        {/* <GView style={{ display: "flex", flexWrap: "wrap" }}> */}
        {classesData.map((c, i) => {
          return (
            <div
              key={i}
              style={{
                width:
                  width >= 1800
                    ? "10%"
                    : width > 1500 && width <= 1799
                    ? "15%"
                    : width > 1279 && width <= 1500
                    ? "12.5%"
                    : width > 991 && width <= 1279
                    ? "16.66%"
                    : width > 767 && width <= 991
                    ? "20%"
                    : width > 500 && width <= 767
                    ? "25%"
                    : "33.3%",
              }}
            >
              <ClassDiv
                style={{
                  padding: I18nManager.getConstants().isRTL
                    ? width <= 767
                      ? "15px 0 15px 8px"
                      : "15px 5px 15px 10px"
                    : width <= 767
                    ? "15px 8px 15px 0"
                    : "15px 10px 15px 5px",
                  margin: "0px 7px",
                }}
              >
                <div
                  style={{
                    aspectRatio: "4/4",
                    position: "relative",
                    cursor: c.status === 1 ? "pointer" : "auto",
                  }}
                  onClick={() => {
                    if (c.type === "courier" || c.type === "atm") {
                      goToCreateOrder(c.type);
                    } else {
                      if (c.status === 1) {
                        if (c.skip_listing === 1 && c.nearest_store?.id) {
                          navigate(
                            {
                              pathname: `/store/${c.nearest_store?.id}`,
                            },
                            {
                              state: {
                                store: c.nearest_store?.id,
                                title: "Products",
                              },
                            }
                          );
                        } else {
                          navigate(
                            {
                              pathname: `${c.id}/stores`,
                            },
                            {
                              state: {
                                title: "Stores",
                              },
                            }
                          );
                        }
                      } else {
                        enqueueSnackbar(strings.w_not_available);
                      }
                    }
                  }}
                >
                  <img
                    src={ImgUrl("store-class") + "/" + c.icon}
                    alt={c.title}
                    onError={(e) => addDefaultSrc(e, "store-class")}
                    style={Object.assign(
                      {
                        maxWidth: "100%",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      },
                      props.imgStyle
                    )}
                  />
                  {c.status !== 1 && (
                    <div
                      style={{
                        position: "absolute",
                        borderRadius: "60px",
                        width: "100%",
                        top: 0,
                        height: "calc(100% - 30px)",
                        background: "rgba(255, 255, 255, 0.4)",
                      }}
                    ></div>
                  )}
                  <GText
                    semi
                    text={c.title}
                    style={{
                      margin: "10px 0",
                      textAlign: "center",
                      color: c.status === 1 ? "#000" : "#ccc",
                    }}
                  />
                </div>
              </ClassDiv>
            </div>
          );
        })}
        {/* </GView> */}
      </Slider>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Signin Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </GView>
  );
};

export default HomeClasses;
