import axios from "axios";
// require("dotenv").config();

const path = process.env.REACT_APP_API_URL;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// ADD A REQUEST INTERCEPTOR
axios.interceptors.request.use(
  (config) => {
    // console.log(config);
    config.baseURL = path;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//ADD A RESPONSE INTERCEPTOR
axios.interceptors.response.use(
  (response) => {
    // console.log("failed 13");
    return response;
  },
  async (error) => {
    console.log(error.request);
    const originalRequest = error.config;
    if (!error?.response) {
      if (error?.request) {
        return Promise.reject(error);
      }
    } else {
      if (
        error.response.status === 401 &&
        originalRequest.url === "oauth/token"
      ) {
        // console.log("failed 11");
        localStorage.clear();
        window.location = "/";
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        let refreshToken = localStorage.getItem("aRefreshToken");
        console.log(refreshToken);

        return new Promise(function (resolve, reject) {
          axios
            .post("oauth/token", {
              client_id: "2",
              client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
              grant_type: "refresh_token",
              refresh_token: refreshToken,
              provider: "customers",
            })
            .then(async ({ data }) => {
              console.log(data);
              localStorage.setItem("aAccessToken", data.access_token);
              localStorage.setItem("aRefreshToken", data?.refresh_token);
              let now = (Date.now() / 1000) | 0;
              localStorage.setItem("aExpiresIn", now + data.expires_in);

              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data?.access_token;
              originalRequest.headers["Authorization"] =
                "Bearer " + data?.access_token;
              processQueue(null, data?.access_token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
    }
    return Promise.reject(error);
  }
);
