import React, { useState, useContext, useEffect } from "react";
import GView from "../Elements/GView";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ProfileSidebar from "./profileRoutes";
import Orders from "./orders";
import Addresses from "./addresses";
import Wallet from "./wallet";
import Invite from "./invite";
import GInfo from "../Elements/gInfo";
import Modal from "react-modal";
import EditProfile from "../forms/editProfile";
import GButton from "../Elements/GButton";
import { SettingsContext } from "../DataFactory/useSettings";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";

export default function Profile({ match }) {
  let location = useLocation();
  const [epvisible, setEpvisible] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      if (!settings?.user?.id) {
        window.location.href = "/";
      }
    }
  }, [settings]);

  return (
    <div className="main-content">
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: width > 767 ? "row" : "column",
          }}
        >
          <GInfo
            title={settings?.user?.title}
            key={settings?.user?.id}
            subtitle={settings?.user?.email}
            subtitle2={settings?.user?.phone}
            photo={settings?.user?.photo}
            imgType="user"
            height="60px"
            width="60px"
          />
          <GButton
            variant="linkable"
            children={strings?.w_edit_profile}
            onClick={() => setEpvisible(true)}
            style={{
              marginLeft: "auto",
              textDecoration: "underline",
              fontWeight: "600",
              minWidth: "120px",
            }}
          />
        </GView>
      </GView>
      <br />

      <div
        style={{
          display: width <= 767 ? "block" : "inline-flex",
          width: "100%",
        }}
      >
        <ProfileSidebar url={location?.pathname} />
        <div
          style={{
            verticalAlign: "top",
            width: width <= 767 ? "100%" : "calc(100% - 200px)",
            padding: width <= 767 ? "0" : "0 0 0 20px",
          }}
        >
          <Routes>
            <Route path="orders" element={<Orders />}></Route>
            <Route path="/" element={<Navigate replace to="orders" />} />
            <Route path="address" element={<Addresses />} />
            <Route exact path="wallet" element={<Wallet />} />
            <Route exact path="invite" element={<Invite />} />
          </Routes>
        </div>
      </div>

      <Modal
        isOpen={epvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Edit Profile Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>
    </div>
  );
}
