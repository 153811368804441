import React, { useState, useContext, useEffect } from "react";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GBackIcon from "../Elements/gBackIcon";
import PaymentMethod from "../paymentComponents/checkoutPaymentMethods";
import styled from "styled-components";

const Div = styled.div`
  padding: 0 15px 20px;

  ul {
    padding: 0;
  }
`;

export default function AddFundForm(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isShowGateways, setIsShowGateways] = useState(false);
  const [cPaymentMethod, setCPaymentMethod] = useState({});
  const [isGatewaysLoading, setIsGatewaysLoading] = useState(true);
  const [amount, setAmount] = useState("");
  const [gateways, setGateways] = useState({
    card: {},
    gateways: [],
  });

  function getGateways() {
    console.log(props);
    ApiService({
      method: "GET",
      route: `c/payment-gateways`,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setGateways(response.data.data);
        setIsGatewaysLoading(false);
      }
    });
  }
  const initialValues = {
    amount: "",
  };

  const onSubmit = (values) => {
    setAmount(values.amount);
    getGateways();
    setIsShowGateways(true);
  };

  useEffect(() => {
    console.log(cPaymentMethod);

    if (isShowGateways == true) {
      if (cPaymentMethod?.identifier) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [cPaymentMethod]);

  function placeOrder() {
    console.log(cPaymentMethod);
    setBtnLoading(true);
    let sendData = {
      gateway: cPaymentMethod,
      amount: amount,
    };
    ApiService({
      method: "POST",
      route: "c/wallet",
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setAdFundVisible(false);
        props.onSubmit();
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  }

  const validationSchema = Yup.object({
    amount: Yup.string().required(strings?.w_required),
  });

  if (props.adFundVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setAdFundVisible(false)} />
        {strings?.w_add_funds}
      </div>
      <div
        className="modal-body"
        style={{
          height: "calc(100vh - 150px)",
          padding: 0,
          margin: "15px 20px",
        }}
      >
        <h4 style={{ margin: "5px 10px" }}>{strings?.w_add_funds}</h4>
        <p
          style={{
            fontSize: "14px",
            margin: "10px",
            lineHeight: "20px",
            color: "#333",
          }}
        >
          {strings?.w_enter_amount}
        </p>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                placeholder={"0.00"}
                name="amount"
                style={{ height: "45px" }}
                readOnly={isShowGateways}
              />
              <br />

              {!isShowGateways && (
                <GButton
                  variant="condensed"
                  style={{
                    margin: "0 15px",
                    width: "92%",
                  }}
                  children={strings?.w_add}
                  type="submit"
                  loading={isBtnLoading}
                />
              )}
            </Form>
          )}
        </Formik>

        {isShowGateways && (
          <Div>
            {isGatewaysLoading == false && (
              <PaymentMethod
                paymentGateways={gateways}
                cPaymentMethod={cPaymentMethod}
                onSelecteGateway={(gateway) => {
                  console.log(gateway);
                  setCPaymentMethod(gateway);
                }}
                setCPaymentMethod={setCPaymentMethod}
                orderTotal={settings.currency_symbol + "" + amount}
              />
            )}

            <GButton
              variant="condensed"
              disabled={isDisabled}
              loading={isBtnLoading}
              style={{
                width: "calc(100% - 70px)",
                margin: "20px 0",
                textAlign: "left",
                position: "fixed",
                bottom: "0",
                textTransform: "uppercase",
                fontWeight: "550",
                fontSize: "14px",
                padding: "0.9em 1.6em",
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => placeOrder()}
            >
              {strings.w_add_funds}
              <span
                style={{
                  marginLeft: "auto",
                  fontWeight: "600",
                  float: "right",
                }}
              >
                {settings.currency_symbol + amount}
              </span>
            </GButton>
          </Div>
        )}
      </div>
    </React.Fragment>
  );
}
