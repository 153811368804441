import React from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";

const Div = styled.div`
  &.inline {
    display: inline-block;
  }
`;
const InputLabel = styled.label`
  font-size: 0.9rem;
  padding: 6px 0;
  display: block;
  color: #616161;
  margin-top: 5px;
`;
const TextField = styled.input`
  padding: 0.65em;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0px;
  /* width: fit-content; */
  width: -webkit-fill-available;
  box-shadow: none;
  outline: 0;
  border: 0px solid #ccc;
  color: #555;
  font-size: 0.9rem;
  background: #f5f5f5;
  &::placeholder {
    color: #b2b2b2;
  }

  &.block {
    display: block;
  }

  &.inline {
    display: inline-block;
  }
  &:disabled {
    background: #f5f5f5;
  }

  &::placeholder {
    font-style: italic;
  }
`;

const inputDiv = {
  padding: "5px",
};

function GInput(props) {
  const onBlurHandler = (e) => {
    // if (!e.target.value) {
    //   return;
    // }
    if (props.onBlur) {
      props.onBlur(e.target.value, props.name);
    }
  };

  return (
    <Div
      className={props.mainClass}
      style={Object.assign({}, inputDiv, props.divStyle)}
    >
      {props.label ? <InputLabel>{props.label}</InputLabel> : ""}
      <TextField
        type={props.type}
        value={props.value}
        className={props.display}
        name={props.name}
        style={props.style}
        id={props.id}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        onBlur={onBlurHandler}
        disabled={props.disabled}
        min={props.min}
        max={props.max ? props.max : ""}
        maxLength={props.maxLength}
        autoComplete={props.autoComplete || ""}
        // {...props}
      ></TextField>
    </Div>
  );
}

export default GInput;
