import React, { useState, useEffect } from "react";
import { ApiLoginService } from "../ApiService/services";
import GButton from "../Elements/GButton";
import { ReactComponent as GoogleIcon } from "../assets/images/social/google.svg";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { useGoogleLogin } from "@react-oauth/google";
import SignUp from "../forms/signup";
import Modal from "react-modal";

const GoogleSocialLogin = (props) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [signupvisible, setSignupvisible] = useState(false);
  const [googleResponseData, setGoogleResponseData] = useState();

  function responseGoogle(response) {
    // The ID token you need to pass to your backend:

    var id_token = response?.access_token;
    console.log("ID Token: " + id_token);
    // return;
    if (id_token) {
      var google_login_oauth = {
        username: "",
        grant_type: "google",
        token: id_token,
        client_id: "2",
        client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
        provider: "customers",
      };

      g_login_social(google_login_oauth, id_token);
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      responseGoogle(tokenResponse);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    if (googleResponseData?.username) {
      setSignupvisible(true);
    }
  }, [googleResponseData]);

  const g_login_social = (outhData, GToken) => {
    ApiLoginService("POST", "c/social-login", {
      method: "google",
      token: GToken,
    })
      .then(function (response) {
        console.log(response);

        let google_login_oauth = {
          ...outhData,
          username:
            (response?.data?.last_name
              ? response?.data?.first_name + " " + response?.data?.last_name
              : response?.data?.first_name) || "",
        };
        if (response.status_code === 1) {
          if (props.phoneRequired === true) {
            setGoogleResponseData({
              ...google_login_oauth,
              email: response?.data?.email,
            });
          } else {
            getGOauthToken(google_login_oauth, GToken);
          }
        } else {
          if (props.phoneRequired === true && response.data?.email) {
            setGoogleResponseData({
              ...google_login_oauth,
              email: response?.data?.email,
            });
          } else {
            enqueueSnackbar(response.message);
          }
          return;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getGOauthToken = (oauthData) => {
    ApiLoginService("POST", "oauth/token", oauthData)
      .then(function (response) {
        console.log(response);
        if (response) {
          localStorage.setItem("aAccessToken", response.access_token);
          localStorage.setItem("aRefreshToken", response?.refresh_token);
          let now = (Date.now() / 1000) | 0;
          localStorage.setItem("aExpiresIn", now + response.expires_in);

          window.location.reload(false);
        }
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <GButton variant="linkable" onClick={() => googleLogin()}>
          <GoogleIcon />
        </GButton>
      </div>
      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={props.setSigninvisible}
          data={googleResponseData}
        />
      </Modal>
    </>
  );
};

export default GoogleSocialLogin;
