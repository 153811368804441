import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import { ReactComponent as Uncheck } from "../assets/images/icons/untick.svg";
import GButton from "../Elements/GButton";
import CardPayment from "./cardPayments";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";

const PaymentDiv = styled.div`
  display: flex;
  margin: 20px 0;
  label {
    display: block;
    margin: 10px 0 15px;
    text-transform: uppercase;
    font-size: 14px;
    color: #555;
  }
  .inputDiv {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    padding: 10px 10px;
    position: relative;
    box-sizing: border-box;

    &.active {
      border: 1px solid ${({ theme }) => theme.body};
    }
    label {
      font-weight: 500;
      cursor: pointer;
      text-transform: capitalize;
      color: #333;
      margin: 0;
      flex: 1;
      font-size: 15px;
      vertical-align: top;
      p {
        color: #555;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        padding-top: 3px;
        margin: 0;
      }
      span {
        font-weight: 400;
        padding: 5px 0;
        position: relative;
      }
    }
    svg {
      font-size: 0.8rem;
      width: 17px;
      height: 17px;
    }

    img {
      width: 22px;
    }

    span {
      display: inline-flex;
      margin-left: auto;
      &.active svg {
        path {
          fill: ${({ theme }) => theme.body};
        }
      }
    }
  }
`;

export default function PaymentMethod(props) {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const [selectedPaymentType, setSelectedPaymentType] = useState("web");
  const [selectedGateway, setSelectedGateway] = useState(
    props.setCPaymentMethod || {}
  );
  const [cardPayment, setCardPayment] = useState(
    props.paymentGateways?.card || {}
  );
  const [paymentGateways, setPaymentGateways] = useState(
    props.paymentGateways?.gateways || []
  );

  useEffect(() => {
    console.log(paymentGateways);
    if (!props.paymentGateways?.card?.identifier) {
      setSelectedGateway(props.paymentGateways?.gateways[0]);
      props.onSelecteGateway(props.paymentGateways?.gateways[0]);
      setSelectedPaymentType("web");
    }
  }, []);

  return (
    <>
      {" "}
      <PaymentDiv>
        <div
          style={{
            width: "100%",
            display: "inline-block",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(260px, 2fr))",
              gridGap: "20px",
              width: "100%",
            }}
          >
            {paymentGateways.map((gateway, i) => {
              return (
                <React.Fragment key={i}>
                  <div
                    className={
                      selectedGateway?.identifier === gateway.identifier
                        ? "inputDiv active"
                        : "inputDiv"
                    }
                    onClick={() => {
                      setSelectedGateway(gateway);
                      if (props.paymentGateways?.card) {
                        cardPayment.card_id = "";
                        setCardPayment({ ...cardPayment });
                      }
                      props.onSelecteGateway(gateway);
                    }}
                  >
                    <input
                      name="option_payment_method"
                      type="radio"
                      id={"v_option_" + gateway.identifier}
                      value={gateway.identifier}
                      style={{ display: "none" }}
                      defaultChecked={
                        selectedGateway?.identifier === gateway.identifier
                      }
                    />
                    <img
                      src={
                        process.env.REACT_APP_API_IMAGE_URL +
                        "/assets/" +
                        gateway.identifier +
                        ".png"
                      }
                      alt={gateway.identifier}
                      width="22px"
                      height="auto"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor={"v_option_" + gateway.identifier}>
                      {gateway.title}
                      {gateway.identifier === "cash" ? (
                        <p>{strings?.s_payment_cash}</p>
                      ) : (
                        ""
                      )}
                    </label>
                    {selectedGateway?.identifier === gateway.identifier ? (
                      <span className="active">
                        <Check style={{ fontSize: "1rem" }} />
                      </span>
                    ) : (
                      <span>
                        <Uncheck style={{ fontSize: "1rem" }} />
                      </span>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {props.paymentGateways?.card?.identifier ? (
            <>
              {paymentGateways.length > 0 && (
                <hr
                  style={{
                    margin: "20px 0",
                    border: "0",
                    borderBottom: "1px solid #e2e2e2",
                  }}
                />
              )}

              <CardPayment
                cardPayment={cardPayment}
                paymentGateways={props.paymentGateways}
                onSelecteCard={(card) => {
                  setSelectedGateway({});
                  cardPayment.card_id = card.id;
                  props.onSelecteGateway(cardPayment);
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </PaymentDiv>
      {width > 959 && props.handleOrderPlaced ? (
        <GButton
          variant="condensed"
          style={{
            borderRadius: "0",
            fontSize: "13px",
            padding: "12px 20px",
            textTransform: "uppercase",
            minWidth: "240px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            props.handleOrderPlaced();
          }}
          loading={props.isBtnLoading}
          disabled={props.isDisabled}
        >
          <span style={{ flex: "1", fontWeight: "600", textAlign: "initial" }}>
            {strings?.w_place_order}
          </span>
          <span
            style={{
              fontWeight: "600",
            }}
          >
            {props.orderTotal}
          </span>
        </GButton>
      ) : (
        ""
      )}
    </>
  );
}
