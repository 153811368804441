import React, { useState, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import { ApiLoginService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function NewPassword(props) {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [strings] = useContext(StringsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    username: props.data.username,
    otp: props.data.otp,
    password: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiLoginService("POST", "c/reset-password", values).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        props.onChange();
        props.setNpvisible(false);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(strings?.w_required),
  });

  if (props.npvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setNpvisible(false)} />
        {strings?.w_reset_password}
      </div>
      <div className="modal-body">
        <h4 style={{ margin: "5px 15px 10px" }}>
          {" "}
          {strings?.w_enter_new_password}
        </h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                placeholder={strings?.w_new_password}
                name="password"
                style={{ height: "45px" }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "92%",
                }}
                children={strings?.w_reset_password}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
