import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ApiLoginService, ApiService, ImgUrl } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { GItemInfoShimmer } from "../Elements/gShimmer";
import StoreInfo from "./storeInfoView";
import styled from "styled-components";
import { ReactComponent as FavoriteBorder } from "../assets/images/heart-empty.svg";
import { ReactComponent as Favorite } from "../assets/images/heart.svg";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import { Grid } from "@mui/material";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Remove } from "../assets/images/icons/minus.svg";
import useCart from "../useCart";
import Modal from "react-modal";
import OrderProductVariants from "../Components/orderProductVariants";
import { BackgroundCarousel } from "../Elements/gSlider";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import SignIn from "../forms/login";
import SignUp from "../forms/signup";
import ForgotPassword from "../forms/forgotPassword";

const QuantityDiv = styled.div`
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 0.45rem 0.6rem;
  width: 72px;
  border: 1px solid ${({ theme }) => theme.body};
  border-radius: 10px;
  color: ${({ theme }) => theme.body};
  svg {
    height: 12px;
    width: 12px;
    fill: ${({ theme }) => theme.body};
  }
`;

const FavDiv = styled.div`
  width: 10%;
  margin-right: -5%;
  svg.MuiSvgIcon-root {
    color: #fa6808;
    font-size: 1.5rem;
    position: absolute;
  }
`;
const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "450px",
    padding: "20px 0",
    // transform: 'translate(0%, -50%)'
  },
};

const AddDiv = styled.div`
  margin-left: auto;
  svg {
    fill: ${({ theme }) => theme.body};
    font-size: 0.9rem;
    margin-left: -15px;
    position: absolute;
  }
`;
const ProductViewDiv = styled.div`
  padding: 0 15px;
  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.body};
  }
  .slick-arrow .slick-prev {
    left: -20px;
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -15px;
  }
  /* .slick-track {
  margin-left: 0;
} */
  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.body};
  }
  .imgCont {
    display: flex;
    align-items: center;
    padding: 5px 0;

    .selectImg {
      border: 2.5px solid ${({ theme }) => theme.body};
    }
  }
  hr {
    margin: 20px 0;
    border-top: 1px solid #e2e2e2;
  }
  p {
    margin: 5px 0;
    color: #777;
    .price {
      color: ${({ theme }) => theme.body};
    }
  }
`;

export default function ProductDetail() {
  let { productId } = useParams();
  const { cart, addProduct, decreaseQuantity } = useCart();
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { settings } = useContext(SettingsContext);
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [data, setData] = useState([]);
  const [addMultiple, setAddMultiple] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedImg, setSelectedImg] = useState("");
  const [favourite, setFavourite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [signinvisible, setSigninvisible] = useState(false);
  const [fpvisible, setFpvisible] = useState(false);
  const [signupvisible, setSignupvisible] = useState(false);

  const [showQuantityDiv, setShowQuantityDiv] = useState({});
  const [storeData, setStoreData] = useState();
  const toggleHide = (index) => {
    setShowQuantityDiv({
      [index]: !showQuantityDiv[index],
    });
  };
  useEffect(() => {
    const getStoreInfo = () => {
      ApiLoginService("GET", "c/stores/" + data.store_id)
        .then((response) => {
          console.log(response.data);
          if (response.status_code === 1) {
            setStoreData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getStoreInfo();
  }, [data.store_id]);

  const getProductDetails = () => {
    ApiService({ method: "GET", route: "c/products/" + productId })
      .then((response) => {
        console.log(response?.data?.data);
        if (response.data.status_code === 1) {
          console.log(response?.data?.data?.images[0]);
          // console.log(response?.data?.data?.images);
          setData(response?.data?.data);
          setImagesData(response?.data?.data?.images || []);
          setSelectedImg(response?.data?.data?.images[0]);
          setFavourite(response?.data?.data?.favourite);
          setIsLoading(false);
        } else {
          enqueueSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addToFavourite = () => {
    if (!settings?.user?.id) {
      setSigninvisible(true);
      return;
    }
    ApiService({
      method: "POST",
      route: "c/product-wishlist",
      body: { product_id: data.id },
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/product-wishlist",
      body: { product_id: data.id },
    }).then((response) => {
      console.log(JSON.stringify(response));
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  useEffect(() => {
    getProductDetails();
  }, [productId]);
  return (
    <>
      <div
        className="main-content"
        style={{
          // marginTop: "10px",
          // marginBottom: "20px",
          // backgroundColor: "white",
          padding: "15px 20px",
        }}
      >
        {storeData?.id && (
          <StoreInfo
            data={storeData}
            settings={settings}
            onAddToFavourite={() => setSigninvisible(true)}
          />
        )}
        <GText
          bold
          g2
          text={data.categories_title}
          style={{ fontSize: "22px", padding: "0 15px", marginTop: "20px" }}
        />
      </div>

      <div
        className="main-content"
        style={{
          marginTop: "10px",
          marginBottom: "20px",
          backgroundColor: "white",
        }}
      >
        {isLoading === true ? (
          <GItemInfoShimmer />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid
                item
                xl={7}
                lg={7}
                md={6}
                sm={12}
                xs={12}
                style={{
                  borderRight:
                    window.innerWidth > 767 ? "1px solid #e2e2e2 " : "0",
                }}
              >
                <ProductViewDiv>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "90%",
                      }}
                    >
                      <BackgroundCarousel imgType="item" images={imagesData} />
                    </div>
                    <div style={{ width: "10%" }}>
                      <FavDiv style={{ padding: "0 15px", marginTop: "15px" }}>
                        {favourite === true ? (
                          <Favorite
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => {
                              removeFavourite(data?.id);
                            }}
                          />
                        ) : (
                          <FavoriteBorder
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              addToFavourite(data?.id);
                            }}
                          />
                        )}
                      </FavDiv>
                    </div>
                  </div>
                </ProductViewDiv>
              </Grid>
              <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                <div
                  style={{
                    padding: "0 10px",
                    display: "inline-flex",
                    justifyContent: " space-between",
                    width: "-webkit-fill-available",
                  }}
                >
                  <div style={{ width: "88%" }}>
                    <GText
                      g2
                      bold
                      text={data.title}
                      style={{ margin: "10px 0", color: "rgb(0 0 0 / 70%)" }}
                    />
                    {/* <GText
											g4
											light
											text={"Sold by " + data.store_title}
											style={{ margin: "10px 0" }}
										/> */}
                    <GText
                      g3
                      semi
                      text={
                        data.price + settings.currency_symbol + "/" + data.unit
                      }
                      style={{
                        margin: "10px 0",
                        fontSize: "15px",
                        color: "#b3b3b3",
                      }}
                    />
                    {cart && cart.some((c) => c.id === data.id) ? (
                      cart
                        .filter((c) => c.id === data.id)
                        .slice(0, 1)
                        .map((c, i) => {
                          return (
                            <QuantityDiv key={i}>
                              <Remove onClick={() => decreaseQuantity(c)} />
                              <span
                                style={{
                                  padding: "1px 10px 0",
                                  fontSize: "14px",
                                }}
                              >
                                {cart
                                  .filter((c) => c.id === data.id)
                                  .map((o) => o.quantity)
                                  .reduce((a, c) => a + c, 0)}
                              </span>
                              <Add
                                onClick={() => {
                                  // toggleHide(c.id);

                                  if (data.variants.length <= 0) {
                                    //data.variants = [];
                                    addProduct(c, c.variants);
                                  } else {
                                    console.log(c);
                                    setSelectedProduct(c);
                                    setOrderVarVisible(true);
                                  }
                                }}
                              />
                            </QuantityDiv>
                          );
                        })
                    ) : (
                      <AddDiv>
                        {data.next_available_display ? (
                          <GButton
                            variant="outlined"
                            children={data?.next_available_display}
                            disabled
                            style={{
                              borderRadius: "10px",
                              color: "#a2a2a2",
                              border: "1px solid #d2d2d2",
                              fontSize: "13px",
                              padding: "0.6rem 0.4rem",
                              width: "145px",
                              marginLeft: "-17px",
                              background: "#fff",
                            }}
                          />
                        ) : data.status === "0" ? (
                          <GButton
                            variant="outlined"
                            children={strings?.w_not_available}
                            disabled
                            style={{
                              borderRadius: "10px",
                              color: "#a2a2a2",
                              border: "1px solid #d2d2d2",
                              fontSize: "14px",
                            }}
                          />
                        ) : (data.unlimited === 0) & (data.stock === 0) ? (
                          <GButton
                            variant="outlined"
                            children={strings?.w_out_of_stock}
                            disabled
                            style={{
                              borderRadius: "10px",
                              color: "#a2a2a2",
                              border: "1px solid #d2d2d2",
                              fontSize: "14px",
                              padding: "0.6rem 2rem",
                            }}
                          />
                        ) : (
                          <React.Fragment>
                            <GButton
                              variant="outlined"
                              children={strings?.w_add}
                              onClick={() => {
                                if (data.unlimited === 0 && data.stock === 0) {
                                  return false;
                                }
                                if (data?.variants.length <= 0) {
                                  data.variants = [];
                                  addProduct(data);
                                } else {
                                  console.log(data);
                                  setSelectedProduct(data);
                                  setOrderVarVisible(true);
                                }
                              }}
                              style={{
                                padding: "0.45rem 2rem",
                                borderRadius: "10px",
                                cursor:
                                  (data.unlimited === 0) & (data.stock === 0)
                                    ? "auto"
                                    : "pointer",
                              }}
                            />
                            {data?.variants.length > 0 ? (
                              <Add
                                style={{
                                  cursor: "pointer",
                                  width: 10,
                                  height: 10,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    settings?.product_stock == "1" &&
                                    (data.unlimited == 0) & (data.stock == 0)
                                  ) {
                                    return false;
                                  } else {
                                    console.log(data);
                                    setSelectedProduct(data);
                                    setOrderVarVisible(true);
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        )}
                      </AddDiv>
                    )}
                  </div>
                </div>

                {data?.description && (
                  <div style={{ padding: "0 10px" }}>
                    <hr
                      style={{
                        margin: "20px 0",
                        border: "0",
                        borderTop: "1px solid #e2e2e2",
                      }}
                    />
                    <GText
                      g3
                      semi
                      text={strings?.w_description}
                      style={{ margin: "10px 0" }}
                    />
                    <p
                      dangerouslySetInnerHTML={{ __html: data.description }}
                      style={{
                        margin: "10px 0",
                        fontSize: "15px",
                        color: "#777",
                      }}
                    ></p>
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <Modal
          isOpen={orderVarVisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setOrderVarVisible(false)}
          style={customStyles}
          contentLabel="Variant Modal"
        >
          <OrderProductVariants
            item={selectedProduct}
            position="right"
            settings={settings}
            orderVarVisible={orderVarVisible}
            setOrderVarVisible={setOrderVarVisible}
            onSubmitVariants={(item, itemVariants) => {
              // console.log(selectedProduct);
              if (addMultiple == true) {
                addProduct(item, itemVariants, true);
                setAddMultiple(false);
              } else {
                addProduct(item, itemVariants);
              }

              // setCart([...cart, item]);
            }}
          />
        </Modal>

        <Modal
          isOpen={signinvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setSigninvisible(false)}
          style={customStyles}
          contentLabel="Signin Form Modal"
        >
          <SignIn
            signinvisible={signinvisible}
            setSigninvisible={setSigninvisible}
            setSignupvisible={setSignupvisible}
            setFpvisible={setFpvisible}
            settings={settings}
          />
        </Modal>

        <Modal
          isOpen={signupvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setSignupvisible(false)}
          style={customStyles}
          contentLabel="Signup Form Modal"
        >
          <SignUp
            signupvisible={signupvisible}
            setSignupvisible={setSignupvisible}
            setSigninvisible={setSigninvisible}
            settings={settings}
          />
        </Modal>

        <Modal
          isOpen={fpvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setFpvisible(false)}
          style={customStyles}
          contentLabel="Forgot Password Modal"
        >
          <ForgotPassword
            fpvisible={fpvisible}
            setFpvisible={setFpvisible}
            setSigninvisible={setSigninvisible}
            settings={settings}
          />
        </Modal>
      </div>
    </>
  );
}
