import React, { useContext } from "react";
import { UserLocContext } from "../contexts/userLocation";
import { AreaSearch } from "./areaSearch";
import { LocationSearch } from "./locationSearch";

export default function NavLocation() {
  const { updateLocation, userArea, userLocation } = useContext(UserLocContext);

  return (
    <React.Fragment>
      <div
        style={{
          paddingTop: window.innerWidth > 767 ? "0" : "2px",
          width: window.innerWidth > 767 ? "auto" : "100%",
          flex: "1",
          margin: window.innerWidth > 767 ? "0 5% 0 25px" : "0",
          // position: "relative",
        }}
      >
        {/* <AreaSearch
          value={userArea || userLocation || ""}
          handleAreaChange={(area) => {
            console.log(area);
            localStorage.setItem("area", area || "");
            updateLocation(area || "", "");
          }}
          handleLocationChange={(loc) => {
            console.log(loc);
            localStorage.setItem("location", JSON.stringify(loc) || "");
            updateLocation("", loc || "");
          }}
        /> */}
        <LocationSearch
          value={userLocation?.address || ""}
          valueData={userLocation || ""}
          handleLocationChange={(loc) => {
            console.log(loc);
            localStorage.setItem("location", JSON.stringify(loc) || "");
            updateLocation("", loc || "");
          }}
        />
      </div>
    </React.Fragment>
  );
}
