import React from "react";

import { useSnackbar as useDefaultSnackbar } from "notistack";
import { Snackbar } from "../Elements/GSnackbar";

export const useEnqueueSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (
    message,
    options = {
      variant: "default" || "success" || "error" || "warning",
    }
  ) => {
    enqueueSnackbar(message, {
      variant: "error",
      ...options,
      content: (key) => {
        // destructure the options we need from the extended options
        // object, and provide a default case if we didn't provide any
        const { variant } = options || { variant: "default" };
        return (
          <Snackbar
            id={key}
            message={message}
            variant={variant || "default"}
            options={options}
          />
        );
      },
    });
  };

  return pushSnackbar;
};
