import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/icons/tick-sq-empty1.svg";
import { ReactComponent as Uncheck } from "../assets/images/icons/tick-sq-empty.svg";

const CheckBoxDiv = styled.div`
  padding: 8px 0;

  & input[type="checkbox"] {
    display: none;

    &:checked + label span {
      svg {
        fill: ${({ theme }) => theme.body};
      }
    }
  }

  & label {
    display: flex;
    align-items: center;
    font-size: 15.5px;
    color: #555;
    margin: 0;

    span {
      height: 16px;
      svg {
        width: 18px;
        height: 18px;
        margin: 0px 15px 0px 0px;
      }
    }
  }
`;

function checkBoxes(props) {
  const { label, name, key_title, key_value, options, checkHandler, ...rest } =
    props;
  return (
    <div
      className="form-control"
      style={Object.assign({}, { padding: "10px 0" }, props?.maindivstyle)}
    >
      <label style={Object.assign({}, props.mainLabelStyle)}>
        {label}
        {props.type === "variant" && props.isRequired === true ? "*" : ""}
      </label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option, index) => {
            return (
              <CheckBoxDiv key={index} style={props.divstyle}>
                <input
                  type="checkbox"
                  id={label + "_" + option[key_title]}
                  {...field}
                  value={JSON.stringify(option[key_value])}
                  checked={field.value.includes(
                    JSON.stringify(option[key_value])
                  )}
                  onChange={checkHandler}
                />

                <label
                  className="radioLabelStyle"
                  htmlFor={label + "_" + option[key_title]}
                  style={Object.assign({}, props.labelStyle)}
                >
                  {field.value.includes(JSON.stringify(option[key_value])) ? (
                    <span>
                      <Check htmlFor={label + "_" + option[key_title]} />
                    </span>
                  ) : (
                    <span>
                      <Uncheck style={{ fill: "#bbb" }} />
                    </span>
                  )}
                  {option[key_title]}

                  {props.type === "variant"
                    ? props.priceType == "1" || props.priceType === "2"
                      ? " (" +
                        (props.priceType == "1" ? "+ " : "") +
                        props.settings?.currency_symbol +
                        option.price +
                        ")"
                      : ""
                    : ""}
                </label>
              </CheckBoxDiv>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default checkBoxes;
