import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc, getAmount } from "../ApiService/services";
import { Grid } from "@mui/material";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Remove } from "../assets/images/icons/minus.svg";
import { CartContext } from "../DataFactory/useCart";
import GButton from "./GButton";
import GText from "./GText";
import useWindowSize from "../DataFactory/useWindowSize";

const QuantityDiv = styled.div`
  display: inline-flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.3rem;
  width: 68px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.body};
  svg {
    height: 12px;
    width: 12px;
    fill: ${({ theme }) => theme.body};
  }
`;

const AddDiv = styled.div`
  svg {
    color: #fff;
    margin: 0 -11px;
    position: absolute;
    margin-top: 2px;
    fill: ${({ theme }) => theme.body};
  }
`;

export default function GProductThemeCard(props) {
  const { width } = useWindowSize();
  const { item, ind, settings, strings } = props;
  const { itemCart } = useContext(CartContext);

  useEffect(() => {
    // console.log(itemCart);
  }, [itemCart]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "rgb(204 204 204 / 52%) 0px 0px 10px",
        minHeight: width < 500 ? "auto" : width > 1200 ? "380px" : "350px",
        position: "relative",
      }}
      key={ind}
    >
      <img
        src={ImgUrl("item") + "/" + item.photo}
        alt={item.title}
        onError={(e) => addDefaultSrc(e, "item")}
        style={{
          maxWidth: "100%",
          height:
            width <= 767
              ? "auto"
              : width > 1200 && width <= 1500
              ? "200px"
              : width > 1500 && width <= 1600
              ? "230px"
              : width > 1600 && width <= 1800
              ? "200px"
              : width > 1800
              ? "230px"
              : "190px",
          objectFit: "cover",
          borderRadius: "8px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          width: "100%",
          aspectRatio: "2/2",
        }}
        onClick={() => {
          props.setSelectedProduct(item);
          props.setItemInfoVisible(true);
        }}
        width="auto"
        height="120px"
      />

      <div
        style={{
          padding: "15px",
        }}
      >
        <GText
          g4
          semi
          text={item.title}
          style={{
            letterSpacing: "0",
            fontSize: "15px",
          }}
        />
        {item.description && (
          <GText
            g5
            text={item.description}
            style={{
              padding: "2px 0",
              fontSize: "14px",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          />
        )}
        {item.unit && (
          <GText
            med
            g5
            text={item.unit}
            style={{ padding: "2px 0 0", fontSize: "14px" }}
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: "45px",
            bottom: "20px",
            position: width <= 767 ? "inherit" : "absolute",
            width: width <= 767 ? "100%" : "calc(100% - 30px)",
          }}
        >
          {item.price !== item.price_old ? (
            <div>
              <GText
                theme
                semi
                text={getAmount(item.price_discounted)}
                style={{
                  padding: "5px 5px 0",
                  display: "inline-block",
                  width: "auto",
                }}
              />
              <GText
                light
                text={getAmount(item.price)}
                style={{
                  display: "inline-block",
                  width: "auto",
                  textDecoration: "line-through",
                }}
              />
            </div>
          ) : (
            <GText
              theme
              semi
              text={getAmount(item.price)}
              style={{ padding: "5px 0", fontWeight: 700 }}
            />
          )}
          <div style={{ marginLeft: "auto" }}>
            {itemCart && itemCart.some((c) => c.id === item.id) ? (
              itemCart
                .filter((c) => c.id === item.id)
                .slice(0, 1)
                .map((c, i) => {
                  return (
                    <QuantityDiv key={i}>
                      <Remove onClick={() => props.decreaseQuantity(c)} />
                      <span
                        style={{
                          padding: "1px 10px 0",
                          fontSize: "14px",
                        }}
                      >
                        {itemCart
                          .filter((c) => c.id === item.id)
                          .map((o) => o.quantity)
                          .reduce((a, c) => a + c, 0)}
                      </span>
                      <Add
                        onClick={() => {
                          if (item.variants_available === false) {
                            //item.variants = [];
                            props.addProduct(c, c.variants);
                          } else {
                            props.setSelectedProduct(c);
                            props.setOrderVarVisible(true);
                          }
                        }}
                      />
                    </QuantityDiv>
                  );
                })
            ) : (
              <AddDiv>
                {item.status == "0" ? (
                  <GButton
                    variant="outlined"
                    children={strings?.w_not_available}
                    disabled
                    style={{
                      borderRadius: "4px",
                      color: "#a2a2a2",
                      border: "1px solid #d2d2d2",
                      fontSize: "12px",
                      background: "#fff",
                      padding: "0.4rem 0.8rem",
                    }}
                  />
                ) : settings?.product_stock == "1" &&
                  (item.unlimited == 0) & (item.stock == 0) ? (
                  <GButton
                    variant="outlined"
                    children={strings?.w_out_of_stock}
                    disabled
                    style={{
                      borderRadius: "4px",
                      color: "#a2a2a2",
                      border: "1px solid #d2d2d2",
                      fontSize: "13.5px",
                      padding: "0.5rem 0.6rem",
                      background: "#fff",
                      minWidth: "100px",
                    }}
                  />
                ) : (
                  <React.Fragment>
                    <GButton
                      variant="condensed"
                      children={strings?.w_add}
                      onClick={() => {
                        if (
                          settings?.product_stock == "1" &&
                          item.unlimited == 0 &&
                          item.stock == 0
                        ) {
                          return false;
                        }
                        if (item.variants_available === false) {
                          item.variants = [];
                          props.addProduct(item);
                        } else {
                          props.setSelectedProduct(item);
                          props.setOrderVarVisible(true);
                        }
                      }}
                      style={{
                        padding: "0.45rem 1.5rem",
                        borderRadius: "4px",
                        cursor:
                          settings?.product_stock == "1" &&
                          (item.unlimited == 0) & (item.stock == 0)
                            ? "auto"
                            : "pointer",
                      }}
                    />
                    {item.variants_available === true ? (
                      <Add
                        style={{ cursor: "pointer", width: 10, height: 10 }}
                        onClick={() => {
                          if (
                            settings?.product_stock == "1" &&
                            (item.unlimited == 0) & (item.stock == 0)
                          ) {
                            return false;
                          }
                          props.setSelectedProduct(item);
                          props.setOrderVarVisible(true);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
              </AddDiv>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
