import React, { useState, useContext, useEffect } from "react";
import GBackIcon from "../Elements/gBackIcon";
import GMap from "../Elements/gMaps";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import styled from "styled-components";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

const Div = styled.div`
  label {
    text-transform: uppercase;
  }
`;
function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry,places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

export default function ModifyOrderAddress(props) {
  console.log(props);
  const { defaultAddress } = useContext(UserAddressContext);
  const [loadMap, setLoadMap] = useState(false);
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [addressLocation] = useState({
    lat: parseFloat(props.data?.delivery_lat || defaultAddress?.lat),
    lng: parseFloat(props.data?.delivery_lng || defaultAddress?.lng),
  });

  const addressTitle = [
    {
      key: strings.w_house,
      value: "house",
    },
    {
      key: strings.w_apartment,
      value: "apartment",
    },
    {
      key: strings.w_office,
      value: "office",
    },
    {
      key: strings.w_hotel,
      value: "hotel",
    },
    {
      key: strings.w_other,
      value: "other",
    },
  ];

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const initialValues = {
    title: !props.isEmpty
      ? props.data?.delivery_payload?.title
        ? addressTitle.filter(
            (at) => at.value == props.data?.delivery_payload?.title
          ).length > 0
          ? props.data?.delivery_payload?.title
          : "other"
        : "house"
      : "house",
    other_title: !props.isEmpty
      ? props.data?.delivery_payload?.title
        ? addressTitle.filter(
            (at) => at.value == props.data?.delivery_payload?.title
          ).length > 0
          ? ""
          : props.data?.delivery_payload?.title
        : props.data?.delivery_payload?.title
      : "",
    line1: props.data?.delivery_payload?.line1 || "",
    line2: "",
    latitude: parseFloat(props.data?.delivery_lat) || addressLocation?.lat,
    longitude: parseFloat(props.data?.delivery_lng) || addressLocation?.lng,
    apartment: props.data?.delivery_payload?.apartment || "",
    building_name: props.data?.delivery_payload?.building_name || "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    let sendValues = { ...values };
    sendValues.title =
      sendValues.title === "other" ? sendValues.other_title : sendValues.title;

    console.log(sendValues);
    ApiService({
      method: "PUT",
      route: `c/order/address/${props.orderId}`,
      body: sendValues,
    }).then((response) => {
      console.log(response.data);

      if (response.status === 26) {
        enqueueSnackbar(
          strings?.s_placeholder_internet_title +
            ". " +
            strings?.s_placeholder_internet_subtitle
        );
        return;
      } else {
        if (response.data.status_code === 1) {
          props.onSubmit(response.data.data);
          props.setOAdVisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.data.message);
        return;
      }
    });
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(strings?.w_required),
    line1: Yup.string().required(strings?.w_required),
    apartment: Yup.string().required(strings.w_required),
  });

  if (props.oAdVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOAdVisible(false)} />
        {strings?.w_update_address}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <GMap
                  lat={values.latitude}
                  lng={values.longitude}
                  search={true}
                  map={true}
                  placeholder={strings?.w_search + ".."}
                  centerLocation={addressLocation}
                  zoom={16}
                  height="30vh"
                  type="createAddress"
                  onChange={(address, loc) => {
                    values.latitude = loc.lat();
                    values.longitude = loc.lng();
                    values.line1 = address.address;
                    setFieldValue("line1", address.address);
                  }}
                  padding={true}
                  onLoad={!props.isEmpty ? false : true}
                />
              )}
              <FormikControl
                control="input"
                type="text"
                placeholder={strings?.s_address_line1}
                name="line1"
                readOnly={true}
              />
              <Div>
                <FormikControl
                  control="select"
                  as="select"
                  name="title"
                  label={strings.w_building_type}
                  options={addressTitle}
                  key_title="key"
                  key_value="value"
                />
                {values.title === "other" && (
                  <FormikControl
                    control="input"
                    type="text"
                    label={strings?.s_address_line_title}
                    name="other_title"
                  />
                )}
              </Div>
              <FormikControl
                control="input"
                type="text"
                label={
                  strings.w_address_apartment + " (" + strings.w_required + ")"
                }
                name="apartment"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings.w_building_name}
                name="building_name"
              />
              <FormikControl
                control="input"
                type="text"
                label={strings?.s_address_line2}
                name="line2"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={strings?.s_save_and_proceed}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px 15px" }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
