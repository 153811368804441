import React, { useContext } from "react";
import GOffers from "../Elements/gOffers";
import GView from "../Elements/GView";
import useDataFctory from "../ApiService/useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";

export default function Coupons() {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const { loading, Shimmer, Placeholder, data } = useDataFctory(
    "coupon",
    false
  );

  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <h1>{strings?.w_coupons} </h1>
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0 40px",
        }}
      >
        {loading === true ? (
          <Shimmer />
        ) : data.data.length === 0 ? (
          <Placeholder />
        ) : (
          <GView
            style={{
              display: "grid",
              gridTemplateColumns:
                width <= 350
                  ? "repeat(auto-fit, minmax(300px, 1fr))"
                  : width > 350 && width <= 767
                  ? "1fr"
                  : width > 767 && width <= 991
                  ? "1fr 1fr"
                  : width > 991 && width <= 1200
                  ? "1fr 1fr"
                  : width > 1200 && width <= 1500
                  ? "1fr 1fr 1fr"
                  : width > 1500 && width <= 1800
                  ? "1fr 1fr 1fr 1fr"
                  : width > 1800
                  ? "1fr 1fr 1fr 1fr 1fr"
                  : "repeat(auto-fit, minmax(250px, 1fr))",
              gridGap: "20px",
            }}
          >
            {data.data.map((c, i) => {
              return <GOffers data={c} key={i} copy={true} />;
            })}
          </GView>
        )}
      </GView>
    </div>
  );
}
