import React, { useState, useEffect, useContext } from "react";
import GView from "../Elements/GView";
import RecentAddress from "../assets/images/recent.png";
import GBackIcon from "../Elements/gBackIcon";
import GText from "../Elements/GText";
import GTaxiMap from "../Components/courierOrderStopsMap";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

const OrderStopsLocations = (props) => {
  const { defaultAddress } = useContext(UserAddressContext);
  // console.log(props);
  const [addressLocation] = useState({
    lat: defaultAddress?.lat,
    lng: defaultAddress?.lng,
  });

  return (
    <>
      <div className="modal-header">
        <GBackIcon onClick={() => props.seIsLocAddressVisible(false)} />{" "}
        {props.strings.w_choose_location}
      </div>

      <div
        className="modal-body"
        // style={{ padding: "5px 0" }}
      >
        <GTaxiMap
          center={{ lat: 30.70586, lng: 76.708282 }}
          zoom={15}
          places={[]}
          isAutoComplete={true}
          showMap={false}
          style={{ height: "0px", position: "relative" }}
          onChange={(address, loc) => {
            let newAddress = {
              lat: loc.lat(),
              lng: loc.lng(),
              line1: address.address,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
            };
            props.onSelectAddress(newAddress);
          }}
        />

        <GView>
          {props.addressData.map((address, i) => {
            return (
              <GView
                key={i}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                  borderTop: i > 0 ? "1px solid #f2f2f2" : "",
                  cursor: "pointer",

                  // "&:hover": {
                  //   background: "#f5f5f5",
                  // },
                }}
                onClick={() => {
                  props.onSelectAddress(address);
                }}
              >
                <img
                  src={RecentAddress}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                <GView>
                  <GText
                    semi
                    text={address.title}
                    style={{
                      textTransform: "uppercase",
                      paddingBottom: "3px",
                      color: "#333",
                      fontSize: "15.5px",
                      fontWeight: "550",
                    }}
                  />
                  <GText
                    dark
                    text={address.line1}
                    style={{ fontSize: "14px" }}
                  />
                </GView>
              </GView>
            );
          })}
        </GView>
      </div>
    </>
  );
};

export default OrderStopsLocations;
