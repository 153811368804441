import React, { useContext, useState } from "react";
import GButton from "../Elements/GButton";
import { ApiService } from "../ApiService/services";
import GStarRating from "../Elements/gStarRating";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GText from "../Elements/GText";
import { StringsContext } from "../DataFactory/useStrings";
import useDataFctory from "../ApiService/useDataFactory";
import { SettingsContext } from "../DataFactory/useSettings";
import GBackIcon from "../Elements/gBackIcon";
import GView from "../Elements/GView";
import Modal from "react-modal";
import OrderFeedbackTipPayment from "./orderFeedbackTipPayment";
import CheckoutOrderTip from "./checkoutOrderTip";

const FeedbackDiv = styled.div`
  text-align: center;
  p.theme {
    color: ${({ theme }) => theme.body};
  }

  .star-rating {
    margin: 0 15px 10px;
    .star {
      width: 25px;
      height: 25px;
    }
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  li {
    border: 1px solid #e2e2e2;
    padding: 2px 8px 4px;
    border-radius: 4px;
    cursor: pointer;

    &.active {
      background: ${({ theme }) => theme.body};
      color: #fff;
      border: 1px solid #fff;
    }
  }
`;

export default function OrderFeedback(props) {
  const { settings } = useContext(SettingsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [strings] = useContext(StringsContext);
  const [ofTipPayVisible, setOfTipPayVisible] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [selectedTip, setSelectedTip] = useState();
  const { loading, Shimmer, Placeholder, data } = useDataFctory(
    "feedback_templates",
    false
  );
  const initialValues = {
    rating: "",
    review: "",
    order_id: props.orderId,
    other_review: "",
  };
  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    let sendData = { ...values };
    console.log(values);
    if (sendData.review !== "other") {
      delete sendData.other_review;
    } else {
      sendData.review = sendData.other_review;
    }
    console.log(sendData);

    var url =
      props.reviewType === "delivery"
        ? "c/delivery-reviews"
        : "c/order/feedback";

    ApiService({
      method: "POST",
      route: url,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        if (props.reviewType == "delivery" && selectedTip) {
          setOfTipPayVisible(true);
        } else {
          props.onSubmit();
          props.setOfvisible(false);
        }
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
    });
  };

  if (props.ofvisible === false) {
    return null;
  }

  const validationSchema = Yup.object({
    rating: Yup.string().required(strings?.w_required),
  });

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOfvisible(false)} />{" "}
        {props.reviewType === "delivery"
          ? strings?.w_agent_feedback
          : strings?.w_order_feedback}
      </div>
      <div
        className="modal-body"
        style={{
          height: "calc(100vh - 150px)",
          padding: 0,
          margin: "15px 20px",
        }}
      >
        <FeedbackDiv>
          {props.reviewType !== "delivery" && (
            <GText
              g5
              semi
              className="theme"
              text={strings.s_how_was_your_order}
            />
          )}
          <GText text={strings?.s_rating_note} style={{ margin: "10px 0" }} />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <GStarRating
                  totalStars={5}
                  selectedStars={0}
                  selected={true}
                  onSelect={(val) => {
                    values.rating = val;
                    setFieldValue("rating", val);
                    setFieldValue("review", "other");
                    setFieldValue("other_review", "");
                  }}
                />

                {values.rating && (
                  <GView style={{ padding: "10px 0" }}>
                    <GText
                      g5
                      semi
                      text={strings.s_what_would_you_highlight}
                      style={{ margin: "10px 0" }}
                    />

                    <Ul>
                      {data
                        .filter(
                          (t) =>
                            t.type ==
                            (props.reviewType === "delivery"
                              ? "driver"
                              : "store")
                        )
                        .filter((d) => d.star == values.rating)
                        .map((temp) => {
                          return (
                            <li
                              className={
                                values.review == temp.title ? "active" : ""
                              }
                              onClick={() => {
                                setFieldValue("review", temp.title);
                                setFieldValue("other_review", "");
                              }}
                            >
                              {temp.title}
                            </li>
                          );
                        })}
                      <li
                        className={values.review == "other" ? "active" : ""}
                        onClick={() => {
                          setFieldValue("review", "other");
                        }}
                      >
                        {strings.w_other}
                      </li>
                    </Ul>
                    {values.review == "other" && (
                      <FormikControl
                        control="input"
                        as="textarea"
                        placeholder={strings?.s_enter_feedback}
                        name="other_review"
                      />
                    )}
                  </GView>
                )}

                {props.reviewType === "delivery" &&
                props.orderData?.delivery_tip_allowed == true ? (
                  <CheckoutOrderTip
                    selectedTip={selectedTip}
                    setSelectedTip={(tip) => setSelectedTip(tip)}
                    currencySymbol={settings.currency_symbol}
                    showImg={false}
                  />
                ) : (
                  ""
                )}

                <GButton
                  variant="condensed"
                  disabled={(!values.rating ? true : false) || isBtnDisabled}
                  children={strings?.w_submit}
                  type="submit"
                  style={{
                    width: "calc(100% - 60px)",
                    margin: "20px 30px",
                    // position: "fixed",
                    // bottom: "5px",
                    // left: 0,
                    textTransform: "uppercase",
                    fontWeight: "550",
                    fontSize: "14px",
                    padding: "0.9em 1.6em",
                  }}
                />
              </Form>
            )}
          </Formik>
        </FeedbackDiv>
      </div>

      <Modal
        isOpen={ofTipPayVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfTipPayVisible(false)}
        style={{
          content: {
            width: "375px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order Feedback Tip Payment Modal"
      >
        <OrderFeedbackTipPayment
          ofTipPayVisible={ofTipPayVisible}
          setOfTipPayVisible={setOfTipPayVisible}
          tipAmount={selectedTip}
          orderId={props.orderId}
          onSubmit={() => {
            props.onSubmit();
            props.setOfvisible(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
