import React, { useContext, useState } from "react";
import GButton from "../Elements/GButton";
import GBackIcon from "../Elements/gBackIcon";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native-web";
import FiltersView from "./storeFiltersView";

const Filters = (props) => {
  const [filters, setFilters] = useState(props?.filters);
  const [strings] = useContext(StringsContext);

  const handleClear = () => {
    setFilters({
      orderby: "",
      popular: "",
      new: "",
      favourite: "",
      under_30: "",
      min_rating: "",
      discounts: "",
      price_range: "",
    });
  };

  const handleApply = () => {
    // refRBSheet?.current?.close();
    props?.applyFilters(filters);
  };

  if (props.sFilterVisible === false) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setSFilterVisible(false)} />
        {strings?.w_filters}

        <GButton
          variant="condensed"
          children={strings?.w_apply}
          type="button"
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? "0" : "auto",
            marginRight: I18nManager.getConstants().isRTL ? "10px" : "0",
            borderRadius: 4,
          }}
          onClick={() => handleApply()}
        />
        <GButton
          variant="outlined"
          children={strings?.w_reset}
          type="button"
          style={{
            marginLeft: I18nManager.getConstants().isRTL ? "0" : "10px",
            marginRight: I18nManager.getConstants().isRTL ? "auto" : "0",
            color: "#777",
            border: "1px solid #e2e2e2",
            fontWeight: 500,
            padding: "8px 20px",
            borderRadius: 4,
          }}
          onClick={() => handleClear()}
        />
      </div>
      <div className="modal-body">
        <FiltersView filters={filters} setFilters={setFilters} />
      </div>
    </>
  );
};

export default Filters;
