import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import "../Css/switch.css";

function formikToggle(props) {
  const { label, name, isToggled, onToggle, ...rest } = props;
  return (
    <div
      className="form-control"
      style={Object.assign({}, props.style, { display: "inline-block" })}
    >
      {label ? (
        <label htmlFor={name} style={props.labelStyle}>
          {label}
        </label>
      ) : (
        ""
      )}
      <label className="toggle-switch" htmlFor={name}>
        <Field
          type="checkbox"
          id={name}
          name={name}
          checked={isToggled}
          onChange={onToggle}
          {...rest}
        />
        <span className="switch" />
      </label>

      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default formikToggle;
