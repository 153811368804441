import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "./GButton";
import Loader from "../assets/images/loader.svg";
import {
  FormFileApiService,
  ImgUrl,
  addDefaultSrc,
} from "../ApiService/services";
import GText from "./GText";
import { StringsContext } from "../DataFactory/useStrings";

const Img = styled.img`
  width: 80px;
`;
const Div = styled.div`
  & svg.MuiSvgIcon-root {
    bottom: 0;
    right: -5px;
  }
`;

const IconStyle = {
  position: "absolute",
  background: "#fff",
  padding: "5px",
  width: "18px",
  height: "18px",
  border: "3px solid #f4f5f9",
  bottom: "0",
  borderRadius: "20px",
  marginLeft: "-15px",
  cursor: "pointer",
};

function GImageEditor(props) {
  const [strings] = useContext(StringsContext);
  const [imageType] = useState(props.type);
  const fileInput = useRef(null);
  const [editableId] = useState(props.id);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [imageValue, setImageValue] = useState(props.image);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setImageValue(props.image);
  }, [props]);

  const changeFileHandler = (e) => {
    if (!fileInput.current?.files[0]) {
      return;
    }
    setUploading(true);
    var files = fileInput.current.files[0];
    let url = "c/upload-image";
    if (props.apiType === "driver") {
      url = "d/upload-image";
    }
    if (props.apiType === "vendor") {
      url = "m/upload-image";
    }

    FormFileApiService("POST", url + "?type=" + imageType, files)
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          if (props.edit !== false) {
            setImageValue(ImgUrl(imageType) + "/" + response.data.thumb_image);
          }
          props.onSubmit(
            response.data.image,
            props.id,
            response.data.thumb_image
          );
          fileInput.current.value = "";
        } else {
          enqueueSnackbar(response.message);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <input
        id={props.id + "_imgInput"}
        type="file"
        ref={fileInput}
        onChange={changeFileHandler}
        style={{ display: "none" }}
        accept="image/x-png,image/gif,image/jpeg"
      />

      <Div
        style={Object.assign(
          {},
          {
            padding: "5px",
            position: "relative",
            boxSizing: "border-box",
            verticalAlign: "top",
            minWidth: "225px",
          },
          props.divstyle
        )}
      >
        {uploading === true ? (
          <>
            <GText
              text={props.title}
              med
              style={{
                margin: "3px 0 10px",
                fontSize: "14px",
              }}
            />
            <GButton
              variant="condensed"
              disabled={true}
              style={{
                borderRadius: "4px",
                padding: "0.5em 1.5em",
                fontWeight: "600",
              }}
            >
              {strings.w_uploading} &nbsp;
              <img
                src={Loader}
                alt="loading"
                style={{ verticalAlign: "middle", float: "right" }}
              />
            </GButton>
          </>
        ) : imageValue ? (
          <div style={{ display: "flex" }}>
            <div>
              <GText
                text={props.title}
                med
                style={{
                  margin: "3px 0 10px",
                  fontSize: "14px",
                }}
              />
              <GButton
                variant="linkable"
                type="button"
                onClick={() => {
                  setImageValue("");
                  props.onSubmit();
                }}
                style={{ textDecoration: "underline", marginTop: "5px" }}
              >
                {strings.w_remove}
              </GButton>
            </div>

            <Img
              alt="Add Image"
              src={imageValue}
              style={{
                width: "50%",
                maxWidth: "200px",
                maxHeight: "150px",
                minHeight: "120px",
                display: "flex",
                margin: "0 0 0 auto",
                padding: "5px",
                border: "1px solid #e9e9e9",
                background: "#fff",
                objectFit: "cover",
              }}
            />
          </div>
        ) : (
          <>
            <GText
              text={props.title}
              med
              style={{
                margin: "3px 0 10px",
                fontSize: "14px",
              }}
            />
            <GButton
              variant="outlined"
              type="button"
              onClick={() => {
                document.getElementById(props.id + "_imgInput").click();
              }}
              style={{
                borderRadius: "4px",
                padding: "0.5em 1.5em",
                fontWeight: "600",
              }}
            >
              {strings.w_choose}
            </GButton>
          </>
        )}
      </Div>
    </React.Fragment>
  );
}

export default GImageEditor;
