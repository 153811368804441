import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import RedeemCode from "../forms/redeemPoints";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

const InviteDiv = styled.div`
  margin: 10px;
  padding: 0 10px;
  @media (max-width: 599px) {
    margin: 0;
    padding: 10px 0;
  }
  hr {
    border: 0;
    border-bottom: 1px solid #f2f2f2;
    margin: 15px 0;
  }
  h3 {
    border: 1px dashed #a2a2a2;
    margin: 5px 0;
    padding: 8px 25px 7px;
    color: #333;
    font-size: 17px;
    line-height: 28px;
    display: inline-block;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
  }
`;

export default function Invite() {
  const { settings, refreshSettings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [redeemCodeVisible, setRedeemCodeVisible] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { width } = useWindowSize();

  return (
    <div>
      {settings?.redeemed === false && (
        <GButton
          variant="outlined"
          children={strings?.w_redeem}
          onClick={() => {
            setRedeemCodeVisible(true);
          }}
          style={{
            float: I18nManager.getConstants().isRTL ? "left" : "right",
            padding: "0.5rem 1.5rem",
          }}
        />
      )}
      <GText g2="true" theme bold text={strings?.w_invite_earn} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px 0 ",
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              padding: "10px 25px 20px",
              minWidth: width <= 991 ? "150px" : "200px",
            }}
          >
            <GText
              h5
              med
              text={strings?.w_available_wallet_points}
              style={{ margin: "10px 0", letterSpacing: "0" }}
            />

            {settings?.user?.wallet ? (
              <GText g3 semi text={settings?.user?.wallet} />
            ) : (
              <GText text={"You have 0 wallet points"} />
            )}
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              borderLeft: width <= 767 ? "0" : "1px solid #f2f2f2",
              borderTop: width <= 767 ? "1px solid #f2f2f2" : "0",
            }}
          >
            <InviteDiv>
              <GText
                g4
                semi
                text={strings?.s_earn_wallet_points}
                style={{ textTransform: "uppercase", paddingTop: "10px" }}
              />
              <GText
                dark
                g5
                text={strings?.s_invite_and_earn_message}
                style={{ letterSpacing: "0.2px", margin: "7px 0" }}
              />
              <hr />

              <GText
                text={strings?.s_share_referral_code}
                style={{ letterSpacing: "0", margin: "7px 0" }}
              />
              <h3
                onClick={(e) => {
                  if (navigator.clipboard != undefined) {
                    //Chrome
                    navigator.clipboard
                      .writeText(settings?.user.referral_code)
                      .then(
                        function () {
                          console.log(
                            "Async: Copying to clipboard was successful!"
                          );
                        },
                        function (err) {
                          console.error("Async: Could not copy text: ", err);
                        }
                      );
                  } else if (window.clipboardData) {
                    // Internet Explorer
                    window.clipboardData.setData(
                      "Text",
                      settings?.user.referral_code
                    );
                  } else {
                    var TempText = document.createElement("input");
                    TempText.value = settings?.user.referral_code;
                    document.body.appendChild(TempText);
                    TempText.select();

                    document.execCommand("copy");
                    document.body.removeChild(TempText);
                    e.target.focus();
                  }

                  enqueueSnackbar(`Copied : ${settings?.user.referral_code}`);
                }}
              >
                {settings?.user?.referral_code}
              </h3>
            </InviteDiv>
          </GView>
        </GView>
      </GView>

      <Modal
        isOpen={redeemCodeVisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setRedeemCodeVisible(false)}
        style={{
          content: {
            width: "350px",
            top: "0%",
            left: "0%",
            right: "0",
            bottom: "0",
            margin: "auto",
            height: "270px",
          },
        }}
        contentLabel="Redeem code Modal"
      >
        <RedeemCode
          redeemCodeVisible={redeemCodeVisible}
          setRedeemCodeVisible={setRedeemCodeVisible}
          onRedeem={() => {
            refreshSettings();
          }}
        />
      </Modal>
    </div>
  );
}
