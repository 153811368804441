import React from 'react';
import styled from 'styled-components';

const View = styled.div((props) => props.style);

const GView = (props) => {
  return <View {...props} />;
};

export default GView;
