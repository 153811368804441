import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import GView from "./GView";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import { ReactComponent as ArrowDown } from "../assets/images/icons/arrowDown.svg";

const LI = styled.li`
  padding: 5px 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  line-height: 26px;
  &:hover {
    background: #f4f5f9;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

function GSelectDropdown(props) {
  const wrapperSelectRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(props.defaultvalue);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSelectRef,
    false
  );

  useEffect(() => {
    setSelectedValue(props.defaultvalue);
  }, [props]);

  var optionsList = props.data.map((option, i) => {
    return (
      <option key={i} value={option[props.option_value]}>
        {option[props.option_key]}
      </option>
    );
  });

  return (
    <div
      ref={wrapperSelectRef}
      style={Object.assign(
        {},
        { display: "inline-block", position: "relative" },
        props.style
      )}
    >
      <GView
        style={{
          color: selectedValue ? "#fff" : "#555",
          boxSizing: "border-box",
          fontWeight: "500",
          cursor: "pointer",
          background: "transparent",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
        }}
        className={selectedValue ? "active" : ""}
      >
        <p
          style={{
            margin: "0",
            fontWeight: "550",
            textTransform: "capitalize",
          }}
        >
          {selectedValue
            ? props.data
                .filter((d) => d[props.option_value] == selectedValue)
                .map((s) => {
                  return s[props.option_key];
                })
            : props.placeholder}
        </p>

        <ArrowDown
          style={{
            width: 10,
            height: 10,
            fill: selectedValue ? "#fff" : "#777",
          }}
        />
      </GView>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "150px",
            padding: "5px",
            borderRadius: "4px",
            border: "1px solid #e2e2e2",
            marginTop: "6px",
            left: "-35px",
            right: "auto",
            // top: "-145px",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "1px",
              margin: "0",
              maxHeight: "150px",
              overflow: "auto",
            }}
          >
            {props.data.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedValue(c[props.option_value]);
                    props.onChange(c[props.option_value]);
                  }}
                >
                  {c[props.option_key]}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default GSelectDropdown;
