import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import { ReactComponent as Remove } from "../assets/images/icons/minus.svg";
import { ReactComponent as Delete } from "../assets/images/icons/delete.svg";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import { I18nManager } from "react-native";
import { Table, TableBody, TableRowData } from "../styledComponents/tableStyle";
import GButton from "./GButton";
import { StringsContext } from "../DataFactory/useStrings";

const Small = styled.small`
  font-size: 0.85rem;
  font-weight: 400;
  display: inline-block;
  padding-right: 5px;
  /* display: block; */
`;

const Tr = styled.tr`
  td {
    border-bottom: 1px solid #f2f2f2;
    vertical-align: top;
    padding: 14px 10px;

    &:first-child {
      padding: 14px 10px 14px 0;
    }

    div {
      border: 1px solid ${({ theme }) => theme.body};
      display: inline-flex;
      align-items: center;
      color: ${({ theme }) => theme.body};
      padding: 5px;
    }

    svg {
      fill: ${({ theme }) => theme.body};
      cursor: pointer;
      width: 18px;
      height: 18px;
    }

    &.quantityRow {
      svg {
        fill: ${({ theme }) => theme.body};
        cursor: pointer;
      }
      span {
        display: inline-table;
      }
    }

    .free {
      color: ${({ theme }) => theme.body};
      // color: #fff;
      // padding: 2px 7px;
      // border-radius: 2px;
      font-size: 13px;
      font-weight: 500;
    }
  }

  &:last-child {
    td {
      border-bottom: 0px;
    }
  }

  p.quantity {
    color: ${({ theme }) => theme.body};
  }
`;

export default function GItemTable(props) {
  const [strings] = useContext(StringsContext);
  const [items, setItems] = useState(props.items || []);
  const [freeItems, setFreeItems] = useState(props.freeItems || []);

  useEffect(() => {
    console.log(props);
    setItems(props.items || []);
    setFreeItems(props.freeItems || []);
  }, [props]);

  return (
    <>
      <Table className="table">
        <TableBody>
          {items.map((i, ind) => {
            return (
              <Tr key={i.id + "_" + ind}>
                {props.showIng === true && (
                  <td
                    style={Object.assign({ width: "60px" }, props.imgTdStyle)}
                  >
                    <img
                      alt={i.title}
                      src={ImgUrl("item") + "/" + i.thumb_photo}
                      onError={(e) => addDefaultSrc(e, "item")}
                      type="item"
                      className="thumbnail"
                      style={{
                        maxWidth: "45px",
                        maxHeight: "45px",
                        objectFit: "cover",
                      }}
                      width="auto"
                      height="auto"
                    />
                  </td>
                )}
                {props.editable === true ? null : (
                  <td style={{ width: "27px" }}>
                    <span>{i.quantity} &nbsp;x</span>
                  </td>
                )}
                <TableRowData
                  style={{
                    textAlign: "initial",
                  }}
                >
                  <span
                    style={{
                      fontWeight: props.editable === true ? "500" : "400",
                    }}
                  >
                    {i.title}
                  </span>

                  {i.variants.length > 0 && (
                    <>
                      <br />
                      {i.variants.map((v, $index) => {
                        return (
                          <Small key={v.id}>
                            <span style={{ color: "#333" }}>{v.title}</span>
                            {v.value ? (
                              <span style={{ color: "#777" }}>
                                {" "}
                                : {v.value}
                              </span>
                            ) : (
                              ""
                            )}
                            {i.variants.length > $index + 1 ? (
                              <span>, </span>
                            ) : (
                              ""
                            )}
                          </Small>
                        );
                      })}
                    </>
                  )}
                </TableRowData>
                <TableRowData
                  style={{
                    textAlign:
                      props.editable === true
                        ? "center"
                        : I18nManager.getConstants().isRTL
                        ? ""
                        : "right",
                    fontWeight: "500",
                  }}
                >
                  <>
                    {props.editable === true ? (
                      <>
                        <span style={{ display: "block" }}>
                          {i.price_display} {i.unit ? "/ " + i.unit : ""}
                        </span>
                        {props.currencySymbol}
                        {i.price
                          ? (parseFloat(i.price) * i.quantity).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                              }
                            )
                          : "0.00"}
                      </>
                    ) : (
                      <>
                        {i.price == 0 ? (
                          <span className="free">{strings.w_free}</span>
                        ) : (
                          <>
                            {props.currencySymbol}
                            {(parseFloat(i.price) * i.quantity).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </TableRowData>
                {props.editable === true && (
                  <td
                    className="quantityRow"
                    style={{
                      textAlign: props.removeItem
                        ? "center"
                        : I18nManager.getConstants().isRTL
                        ? ""
                        : "right",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Remove
                        onClick={() => props.onDecQuantity(i)}
                        style={{ width: 12, height: 12 }}
                      />
                      <span
                        style={{
                          padding: "0 8px",
                          fontSize: "14px",
                          width: 15,
                          height: 15,
                        }}
                      >
                        {i.quantity}
                      </span>
                      <Add
                        onClick={() => props.onIncQuantity(i)}
                        style={{ width: "12px", height: 12 }}
                      />
                    </div>
                  </td>
                )}

                {props.removeItem === true && (
                  <td
                    style={{
                      width: "20px",
                      textAlign: I18nManager.getConstants().isRTL
                        ? ""
                        : "right",
                    }}
                  >
                    <GButton variant="linkable">
                      <Delete onClick={() => props.onRemove(i)} />
                    </GButton>
                  </td>
                )}
              </Tr>
            );
          })}

          {freeItems && freeItems.length > 0
            ? freeItems.map((f_item, index) => {
                return (
                  <Tr key={f_item.id + "_" + index}>
                    {props.showIng === true && (
                      <td
                        style={Object.assign(
                          { width: "60px" },
                          props.imgTdStyle
                        )}
                      >
                        <img
                          alt={f_item?.title}
                          src={ImgUrl("item") + "/" + f_item?.thumb_photo}
                          onError={(e) => addDefaultSrc(e, "item")}
                          type="item"
                          className="thumbnail"
                          style={{
                            maxWidth: "45px",
                            maxHeight: "45px",
                            objectFit: "cover",
                          }}
                          width="auto"
                          height="auto"
                        />
                      </td>
                    )}
                    {props.editable === true ? null : (
                      <td style={{ width: "27px" }}>
                        <span>{f_item.quantity} &nbsp;x</span>
                      </td>
                    )}
                    <TableRowData
                      style={{
                        textAlign: "initial",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: props.editable === true ? "500" : "400",
                        }}
                      >
                        {f_item?.title}
                      </span>

                      {f_item?.variants?.length > 0 && (
                        <>
                          <br />
                          {f_item.variants.map((v, $index) => {
                            return (
                              <Small key={v.id}>
                                <span style={{ color: "#333" }}>
                                  {v?.title}
                                </span>
                                {v.value ? (
                                  <span style={{ color: "#777" }}>
                                    {" "}
                                    : {v.value}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {f_item.variants.length > $index + 1 ? (
                                  <span>, </span>
                                ) : (
                                  ""
                                )}
                              </Small>
                            );
                          })}
                        </>
                      )}
                    </TableRowData>
                    <TableRowData
                      style={{
                        textAlign:
                          props.editable === true
                            ? "center"
                            : I18nManager.getConstants().isRTL
                            ? ""
                            : "right",
                        fontWeight: "500",
                      }}
                    >
                      <>
                        {props.editable === true ? (
                          <>
                            <span style={{ display: "block" }}>
                              {f_item.unit || ""}
                            </span>
                            <span className="free">{strings.w_free}</span>
                          </>
                        ) : (
                          <>
                            <span className="free">{strings.w_free}</span>
                          </>
                        )}
                      </>
                    </TableRowData>
                    {props.editable === true && (
                      <td
                        className="quantityRow"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <span>x&nbsp;{f_item.quantity} </span>
                      </td>
                    )}
                  </Tr>
                );
              })
            : ""}
        </TableBody>
      </Table>
    </>
  );
}
