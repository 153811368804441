import React, { createContext, useEffect, useState, useContext } from "react";
import { ApiService, ApiLoginService } from "../ApiService/services";
import useLocalStorage from "./useLocalStorage";

const SettingsContext = createContext(null);

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useLocalStorage("settings");

  useEffect(() => {
    if (Object.keys(settings).length > 0) {
      return;
    }
    refreshSettings();
  }, []);

  const refreshSettings = () => {
    var token = localStorage.getItem("aAccessToken");
    var rtoken = localStorage.getItem("aRefreshToken");
    var expires = localStorage.getItem("aExpiresIn");
    if (token && rtoken && expires && expires > 0) {
      ApiService({ method: "GET", route: "c/me" }).then((response) => {
        if (response.data) {
          setSettings(response.data.data);
          console.log(response.data.data);
        }
      });
    } else {
      ApiLoginService("GET", "c/settings").then((response) => {
        setSettings(response.data || {});
        return response;
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        refreshSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };

export default () => useContext(SettingsContext);
