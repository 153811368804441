import React, { useState, useEffect, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import { ApiService } from "../ApiService/services";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import PaymentMethod from "../paymentComponents/checkoutPaymentMethods";
import WebPaymentCheckout from "../paymentComponents/webPaymentCheckout";
import OrderPlaced from "../Components/orderPlaced";
import { GTableContent } from "../Elements/gContent";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import Modal from "react-modal";
import styled from "styled-components";

const BlockDiv = styled.div`
  p.headTitle {
    padding: 15px 25px 7px;
    background: #f4f5f9;
    texttransform: uppercase;
  }
`;
const BlockInnerView = styled.div`
  padding: 5px 20px 20px;
`;

const CourierOrderCheckout = (props) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [initialLoading, setInitialLoading] = useState(true);
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [COrderData, setCOrderData] = useState(props.data || {});
  const [checkoutData, setCheckoutData] = useState({
    summary: {
      data: [],
    },
    wallet: {},
  });
  const [cPaymentMethod, setCPaymentMethod] = useState({});
  const [isGatewaysLoading, setIsGatewaysLoading] = useState(true);
  const [gateways, setGateways] = useState(
    props.checkoutData?.gateways || {
      card: {},
      gateways: [],
    }
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [placedOrderId, setOrderId] = useState("");
  const [webPaymentVisible, setWebPaymentVisible] = useState(false);
  const [webPaymentUrl, setWebPaymentUrl] = useState({});

  useEffect(() => {
    console.log(props);
    setCOrderData(props.data || {});
    setCheckoutData(props.checkoutData);
    setGateways(props.checkoutData?.gateways || {});
    setIsGatewaysLoading(false);
    setInitialLoading(false);
  }, [props]);

  useEffect(() => {
    console.log(cPaymentMethod);

    if (initialLoading == false && gateways) {
      if (cPaymentMethod?.identifier) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [cPaymentMethod]);

  const placeOrder = () => {
    // if (!cPaymentMethod?.gateway_type) {
    //   enqueueSnackbar(strings.s_payment_toast);
    //   return;
    // }
    console.log(cPaymentMethod);
    if (cPaymentMethod.gateway_type === "web") {
      getWebToken();
    } else {
      finalOrderPlaced();
    }
  };

  const getWebToken = () => {
    ApiService({
      method: "POST",
      route: `c/webpayments/token`,
      body: {
        amount: checkoutData.summary.total,
        gateway: cPaymentMethod.identifier,
        callback_url: process.env.REACT_APP_BASE_URL + "/add-order",
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setWebPaymentUrl(response.data.data);
        setCPaymentMethod({
          ...cPaymentMethod,
          token: response.data.data.token,
        });
        setWebPaymentVisible(true);
      } else {
        enqueueSnackbar(response.data.message);
        return;
      }
    });
  };

  function finalOrderPlaced() {
    setBtnLoading(true);
    const placedData = { ...COrderData };
    placedData.gateway = cPaymentMethod || {};
    console.log(placedData);
    //return;
    ApiService({
      method: "POST",
      route: "c/atm/orders",
      body: placedData,
    }).then((response) => {
      console.log("r" + response.status_code);
      if (response.data.status_code === 1) {
        if (settings.theme == "3") {
          props.onSuccess();
        } else {
          setOrderId(response.data.data.id);
          setShowOrderSuccess(true);
        }
      } else {
        enqueueSnackbar(response.data.message);
        setBtnLoading(false);
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setCheckoutVisible(false)} />
        {strings.w_payment}
      </div>
      <div
        className="modal-body"
        style={{ padding: "0", height: "calc(100vh - 130px)" }}
      >
        <BlockDiv>
          <GText
            g6
            dark
            text={strings.w_payment_method}
            className="headTitle"
          />
          <BlockInnerView>
            {isGatewaysLoading == false && (
              <PaymentMethod
                paymentGateways={gateways}
                cPaymentMethod={cPaymentMethod}
                onSelecteGateway={(gateway) => {
                  console.log(gateway);
                  setCPaymentMethod(gateway);
                }}
                setCPaymentMethod={setCPaymentMethod}
                orderTotal={
                  settings.currency_symbol +
                  "" +
                  checkoutData?.summary?.total_display
                }
              />
            )}
          </BlockInnerView>
        </BlockDiv>

        {settings.theme !== "3" && (
          <BlockDiv>
            {checkoutData ? (
              <React.Fragment>
                <GText
                  g6
                  dark
                  text={strings.w_payment_summary}
                  className="headTitle"
                />

                <BlockInnerView style={{ padding: "10px 20px 0" }}>
                  {checkoutData?.summary?.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={settings.currency_symbol + "" + p.value}
                        align="right"
                        lAlign="right"
                        color="#333"
                        padding="2px 5px"
                      />
                    );
                  })}
                </BlockInnerView>
              </React.Fragment>
            ) : (
              ""
            )}

            <BlockInnerView>
              <>
                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #e2e2e2",
                    margin: "5px 0",
                  }}
                />
                <GTableContent
                  title={strings.w_grand_total}
                  value={
                    settings.currency_symbol +
                    checkoutData?.summary?.total_display
                  }
                  align="right"
                  lAlign="right"
                  bold={true}
                />{" "}
              </>
            </BlockInnerView>
          </BlockDiv>
        )}
      </div>
      <div className="modal-footer">
        <GButton
          variant="condensed"
          disabled={isDisabled}
          loading={isBtnLoading}
          style={{
            width: "calc(100% - 50px)",
            margin: "20px 25px",
            textAlign: "left",
            position: "fixed",
            bottom: "0",
            textTransform: "uppercase",
            fontWeight: "550",
            fontSize: "14px",
            padding: "0.9em 1.6em",
            borderRadius: "2px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => placeOrder()}
        >
          {strings.w_place_order}
          <span
            style={{
              marginLeft: "auto",
              fontWeight: "600",
              float: "right",
            }}
          >
            {settings.currency_symbol + checkoutData?.summary?.total_display}
          </span>
        </GButton>
      </div>

      <Modal
        isOpen={showOrderSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderPlaced
          orderId={placedOrderId}
          showOrderSuccess={showOrderSuccess}
        />
      </Modal>

      {webPaymentVisible == true && (
        <WebPaymentCheckout
          selectedGateway={cPaymentMethod}
          setWebPaymentVisible={setWebPaymentVisible}
          tokenData={webPaymentUrl}
          orderId={placedOrderId}
          amount={checkoutData?.summary?.total}
          onSuccessPayment={() => {
            setWebPaymentVisible(false);
            finalOrderPlaced();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CourierOrderCheckout;
