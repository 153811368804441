import React, { useState, useContext } from "react";
import { ApiLoginService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function VerifySignupOtp(props) {
  const [strings, isRTL, availableLaunguages, language] =
    useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    otp: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    if (props.type === "signup") {
      values.first_name = props.data?.first_name || "";
      values.last_name = props.data?.last_name || "";
      values.email = props.data.email;
      values.phone = props.data.phone;
      values.password = props.data.password;
      values.country_code = props.data.country_code;
      values.locale = language;
      values.newsletter_subscribed = props.data.newsletter_subscribed;

      console.log(values);
      // return;
      ApiLoginService("POST", "c/signup", values).then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          props.onVerify();
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    } else {
      values.username = props.data.username;

      ApiLoginService("POST", "c/verify-otp", values).then((response) => {
        console.log("r" + response.status_code);
        if (response.status_code === 1) {
          props.onVerify(values.otp);
          props.setVerifyOtpvisible(false);
        }
        setBtnLoading(false);
        enqueueSnackbar(response.message);
        return;
      });
    }
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required(strings?.w_required),
  });

  if (props.verifyOtpvisible === false) {
    return null;
  }
  return (
    <div className="modal-body">
      <h4 style={{ margin: "5px 10px" }}>{strings?.w_enter_otp}</h4>
      <p
        style={{
          fontSize: "14px",
          margin: "10px",
          lineHeight: "20px",
          color: "#333",
        }}
      >
        {strings?.s_enter_otp_sent}
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder={strings?.w_type_otp}
              name="otp"
              style={{ height: "45px" }}
            />
            <br />
            <GButton
              variant="condensed"
              disabled={!isValid}
              style={{
                margin: "0 15px",
                width: "92%",
              }}
              children={strings?.w_verify}
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
