import React, { useState, useContext } from "react";
import { ReactComponent as Add } from "../assets/images/icons/add.svg";
import AddressView from "../Elements/gAddressView";
import AddressForm from "../forms/addressForm";
import GButton from "../Elements/GButton";
import useDataFctory from "../ApiService/useDataFactory";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";
import BackIcon from "../Elements/gBackIcon";
import { I18nManager } from "react-native";

export default function OrderCustomerAddress(props) {
  const [strings] = useContext(StringsContext);
  const [advisible, setAdvisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);
  const { loading, Shimmer, Placeholder, data, refreshData } = useDataFctory(
    "address",
    true
  );
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleDelete = (addressId) => {
    ApiService({
      method: "DELETE",
      route: "c/addresses/" + addressId,
      body: "",
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          refreshData();
        }
        enqueueSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (props.ocAddvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <BackIcon onClick={() => props.setOcAddVisible(false)} />
        {strings?.w_delivery_address}
        <GButton
          variant="linkable"
          children={strings?.w_add}
          onClick={() => {
            setSelectedAddress({});
            setIsEmpty(true);
            setAdvisible(true);
          }}
          style={{
            margin: I18nManager.getConstants().isRTL
              ? "0 auto 0 0 "
              : "0 0 0 auto",
            padding: "0 1rem",
            height: "21px",
          }}
        >
          <Add style={{ width: 16, height: 16 }} />
        </GButton>
      </div>
      <div className="modal-body" style={{ padding: "10px" }}>
        {loading === true ? (
          <Shimmer></Shimmer>
        ) : data && data.length > 0 ? (
          <React.Fragment>
            <h5
              style={{
                margin: "10px 20px",
                color: "#777",
                fontStyle: "italic",
              }}
            >
              {data.length} {strings?.w_address}
            </h5>
            {data.map((v, i) => {
              return (
                <AddressView
                  key={i}
                  dropdownActions={true}
                  onSelectOrderAddress={(address) => {
                    props.onSelectAddress(address);
                    props.setOcAddVisible(false);
                  }}
                  // onDelete={handleDelete}
                  addressData={v}
                  onSubmit={refreshData}
                  default={false}
                  orderAdress={true}
                  defaultAddress={props.defaultAddress}
                  onEdit={(sAddress) => {
                    console.log(sAddress);
                    setSelectedAddress(sAddress);
                    setIsEmpty(false);
                    setAdvisible(true);
                  }}
                  onDelete={handleDelete}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <Placeholder />
        )}
      </div>

      <Modal
        isOpen={advisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAdvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Checkout Customer Address Form"
      >
        <AddressForm
          advisible={advisible}
          setAdvisible={setAdvisible}
          data={selectedAddress}
          onSubmit={refreshData}
          isEmpty={isEmpty}
        />
      </Modal>
    </React.Fragment>
  );
}
