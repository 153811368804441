import React, { useState, useContext } from "react";
import GView from "../Elements/GView";
import styled from "styled-components";
import Modal from "react-modal";
import { StringsContext } from "../DataFactory/useStrings";
import { ReactComponent as FilterIcon } from "../assets/images/filterIcons/filter.svg";
import { ReactComponent as OfferIcon } from "../assets/images/filterIcons/discount.svg";
import { ReactComponent as DeliveryIcon } from "../assets/images/filterIcons/delivery.svg";
import { ReactComponent as RatingIcon } from "../assets/images/filterIcons/star.svg";
import StoreFiltersPopup from "./StoreFiltersPopup";
import GSelectDropdown from "../Elements/gSelectDropdown";

const FilterDiv = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 6px 20px;
  border-radius: 20px;
  background: #f4f5f9;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  color: #333;
  gap: 7px;
  font-weight: 500;
  font-size: 15px;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.body};
  }

  &.active {
    background: #222;

    border: 1px solid ${({ theme }) => theme.body};
    color: #fff;
  }
`;

export default function StoreFiltersMobileView(props) {
  const [strings] = useContext(StringsContext);
  const [filters, setFilters] = useState(props.filters);
  const [sFilterVisible, setSFilterVisible] = useState(false);

  return (
    <>
      <GView style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <FilterDiv onClick={() => setSFilterVisible(true)}>
          <FilterIcon />
        </FilterDiv>

        <FilterDiv
          className={filters.discounts == 1 ? "active" : ""}
          onClick={() => {
            setFilters({
              ...filters,
              discounts: filters?.discounts == 1 ? 0 : 1,
            });
            props.onApply({
              ...filters,
              discounts: filters?.discounts == 1 ? 0 : 1,
            });
          }}
        >
          <OfferIcon />
          {strings.w_offers}
        </FilterDiv>

        <FilterDiv className={filters.order_type ? "active" : ""}>
          <DeliveryIcon style={{ width: "18px", height: "18px" }} />

          <GSelectDropdown
            defaultvalue={filters.order_type}
            placeholder={strings?.w_all}
            data={[
              { title: `${strings.w_all}`, value: "" },
              { title: `${strings.w_home_delivery}`, value: "home_delivery" },
              { title: `${strings.w_store_pickup}`, value: "store_pickup" },
            ]}
            option_key="title"
            option_value="value"
            onChange={(newVal) => {
              setFilters({
                ...filters,
                order_type: newVal || "",
              });
              props.onApply({
                ...filters,
                order_type: newVal || "",
              });
            }}
          />
        </FilterDiv>

        <FilterDiv className={filters.min_rating ? "active" : ""}>
          <RatingIcon />
          <GSelectDropdown
            placeholder={strings.w_ratings}
            defaultvalue={filters.min_rating}
            data={[
              { title: `${strings.w_all}`, value: "" },
              { title: `${strings.w_more_than_3_5}`, value: 3.5 },
              { title: `${strings.w_more_than_4_5}`, value: 4.5 },
            ]}
            option_key="title"
            option_value="value"
            onChange={(newVal) => {
              setFilters({
                ...filters,
                min_rating: newVal || "",
              });
              props.onApply({
                ...filters,
                min_rating: newVal || "",
              });
            }}
          />
        </FilterDiv>

        <FilterDiv
          className={filters.under_30 == 1 ? "active" : ""}
          onClick={() => {
            setFilters({
              ...filters,
              under_30: filters?.under_30 == 1 ? 0 : 1,
            });
            props.onApply({
              ...filters,
              under_30: filters?.under_30 == 1 ? 0 : 1,
            });
          }}
        >
          {strings.w_under_30}
        </FilterDiv>
      </GView>
      <Modal
        isOpen={sFilterVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSFilterVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Filters Modal"
      >
        <StoreFiltersPopup
          sFilterVisible={sFilterVisible}
          setSFilterVisible={setSFilterVisible}
          filters={filters}
          applyFilters={(filter) => {
            console.log(filter);
            setFilters({ ...filter });
            props.onApply({ ...filter });
            setSFilterVisible(false);
          }}
        />
      </Modal>
    </>
  );
}
