import React from "react";
import GText from "./GText";
import GView from "./GView";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import userPlaceholder from "../assets/images/placeholders/f-placeholder.png";
import atmPlaceholder from "../assets/images/placeholders/p-atm.png";
import agentPlaceholder from "../assets/images/placeholders/p-agent.png";

const GAvtar = (props) => {
  let width = props.width || "65px";
  let height = props.height || "65px";
  return (
    <GView
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        width: width,
        height: height,
        borderRadius: "50%",
      }}
    >
      {props.src ? (
        <img
          src={`${ImgUrl(props.imgType)}/${props.src}`}
          onError={(e) => addDefaultSrc(e, props.imgType)}
          style={Object.assign(
            {
              width: props.width || "100%",
              height: props.height || "auto",
              borderRadius: "50%",
            },
            props.imgStyle
          )}
          alt={props.alt || ""}
        />
      ) : props.imgType === "user" ? (
        <img
          src={
            props.imgOrderType === "atm"
              ? atmPlaceholder
              : props.imgOrderType === "courier"
              ? agentPlaceholder
              : userPlaceholder
          }
          style={Object.assign(
            {
              width: props.width || "100%",
              height: props.height || "auto",
              borderRadius: "50%",
            },
            props.imgStyle
          )}
          alt={props.alt || ""}
        />
      ) : (
        <GText
          text={props?.title?.charAt(0)}
          style={{ textTransform: "uppercase" }}
        />
      )}
    </GView>
  );
};

export default GAvtar;
