import React, { useContext, useEffect, useState } from "react";
import useDataFactory from "../ApiService/useDataFactory";
import GView from "../Elements/GView";
import GInfo from "../Elements/gInfo";
import { StringsContext } from "../DataFactory/useStrings";
import { UserLocContext } from "../contexts/userLocation";
import GBackIcon from "../Elements/gBackIcon";
import GText from "../Elements/GText";
import { Filter } from "./selectListFilter";
import GProductView from "../Elements/gProductView";
import { Link } from "react-router-dom";
import { Item } from "../Models/Item";
import useLocalStorage from "../DataFactory/useLocalStorage";
import { ReactComponent as TimerIcon } from "../assets/images/icons/timer.svg";
import { ReactComponent as RemoveIcon } from "../assets/images/icons/cancel.svg";
import { I18nManager } from "react-native-web";

function SearchProducts(props) {
  const [strings] = useContext(StringsContext);
  const { userLocation } = useContext(UserLocContext);
  const [initialLoading, setInitialLoading] = useState(false);
  const [word, setWord] = useState("");

  const [recent, setRecent] = useLocalStorage("searchByStore", []);
  const { loading, Shimmer, Placeholder, data, fullData, refreshData } =
    useDataFactory("product_by_store", false, {
      geofence: "",
      location: userLocation?.lat
        ? userLocation.lat + "," + userLocation.lng
        : "",
      keyword: word,
    });

  useEffect(() => {
    if (loading === false)
      setTimeout(function () {
        setInitialLoading(false);
      }, 2000);
  }, [loading]);

  useEffect(() => {
    if (initialLoading === false && loading === false) {
      if (word) {
        refreshData({
          geofence: "",
          location: userLocation?.lat
            ? userLocation.lat + "," + userLocation.lng
            : "",
          keyword: word,
        });
      }
    }
  }, [word]);

  useEffect(() => {
    let recentData = localStorage.getItem("searchByStore");
    if (recentData) {
      let r = JSON.parse(recentData);
      r = [...new Set(r)];
      setRecent(r);
    }
  }, []);

  useEffect(() => {
    if (data?.length > 0 && word?.length > 2) {
      let temp = [word.trim(), ...recent];
      temp = [...new Set(temp)]; // Remove Duplicates
      temp = temp.slice(0, 10); // Only 10 recent entries
      setRecent(temp);
    }
  }, [data]);

  if (props.productSearchVisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setProductSearchVisible(false)} />
        <Filter
          value={word}
          handleChange={(e) => setWord(e || "")}
          placeholder={strings?.w_search_products}
          initialLoading={initialLoading}
        />
      </div>
      <div
        className="modal-body"
        style={{
          padding: "15px 25px",
          overflow: "overlay",
        }}
      >
        {word ? (
          <>
            {loading == true ? (
              <Shimmer />
            ) : fullData.stores && fullData.stores.length > 0 ? (
              <GView>
                {fullData.stores.map((s, ind) => {
                  return (
                    <GView key={ind}>
                      <Link
                        to={{ pathname: `stores/${s.id}` }}
                        state="Product"
                        style={{ cursor: "pointer" }}
                      >
                        <GInfo
                          title={`${s?.title}`}
                          subtitle={
                            s.promotion_display
                              ? `${s.promotion_display}`
                              : `Delivery Cost : ${s.delivery_fee_display} • ${s.delivery_time_display}`
                          }
                          subtitle2={
                            s.promotion_display
                              ? `Delivery Cost : ${s.delivery_fee_display} • ${s.delivery_time_display}`
                              : ""
                          }
                          photo={s?.thumb_photo}
                          imgType={"store"}
                          imgStyle={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                          }}
                          style={{ padding: "10px 0 5px" }}
                          subtitleClass={s.max_discount ? "tagSubtitle" : ""}
                          subtitleStyle={{ fontSize: "14px" }}
                          subtitleStyle2={{ fontSize: "14px", color: "#555" }}
                        />
                      </Link>

                      {data &&
                        data.length > 0 &&
                        data.filter((d) => d.store_id == s.id).length > 0 && (
                          <GView>
                            {data
                              .filter((d) => d.store_id == s.id)
                              .map((p, i) => {
                                return (
                                  <GProductView data={new Item(p)} key={i} />
                                );
                              })}
                          </GView>
                        )}
                    </GView>
                  );
                })}
              </GView>
            ) : (
              <Placeholder />
            )}
          </>
        ) : (
          <>
            <GText semi text={strings?.w_recent_searches} />
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                margin: "10px 0",
              }}
            >
              {recent.map((r, i) => {
                return (
                  <li
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => setWord(r)}
                  >
                    <TimerIcon
                      style={{
                        width: 15,
                        height: 15,
                        fill: "#aaa",
                        margin: I18nManager.getConstants().isRTL
                          ? "0 0 0 8px"
                          : "0 8px 0 0",
                      }}
                    />
                    <GText med text={r} />
                    <RemoveIcon
                      style={{
                        width: 15,
                        height: 15,
                        fill: "#ccc",
                        marginLeft: I18nManager.getConstants().isRTL
                          ? "0"
                          : "auto",
                        marginRight: I18nManager.getConstants().isRTL
                          ? "auto"
                          : "0",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        recent.splice(i, 1);
                        setRecent([...recent]);
                      }}
                    />
                  </li>
                );
              })}{" "}
            </ul>
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default SearchProducts;
