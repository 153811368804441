import React, { useState, useEffect, useContext } from "react";
import { GContent, GTableContent } from "../Elements/gContent";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import { StringsContext } from "../DataFactory/useStrings";

export default function AtmOrderDetail(props) {
  const [strings] = useContext(StringsContext);
  const [orderData, setOrderData] = useState(props.orderData || {});
  const { status, delivery, payment_summary, cancelled_log } = orderData;

  useEffect(() => {
    setOrderData(props.orderData || {});
  }, [props.orderData]);

  return (
    <React.Fragment>
      <GView
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px 0 15px",
          alignItems: "center",
        }}
      >
        <GView style={{ flex: "1" }}>
          <GText g5 semi text={"Created"} style={{ margin: "0px 0" }} />
          <GText
            g5
            med
            text={orderData.created}
            style={{ margin: "0px 0", fontSize: "13.5px" }}
          />
        </GView>
        <span
          className="orderStatus"
          style={{
            background: `#${status?.color1}`,
            color: "#fff",
            marginLeft: "15px",
            height: "fit-content",
            borderRadius: "20px",
            padding: "3px 12px",
            fontSize: "14px",
          }}
        >
          {status?.title}
        </span>{" "}
      </GView>

      {orderData?.order_status === "cancelled" && (
        <div
          style={{
            padding: "10px",
            border: "1px solid #F96D77",
            borderRadius: "5px",
            boxSizing: "border-box",
            background: "rgba(249, 109, 119, 0.2)",
          }}
        >
          <GText
            style={{
              color: "#F96D77",
              fontWeight: "450",
              letterSpacing: "0.2px",
              fontSize: "13.5px",
            }}
            text={cancelled_log.reason}
          />
        </div>
      )}
      <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

      {delivery?.delivery_address || orderData.delivery_address ? (
        <div
          style={{
            paddingBottom: "10px",
            borderTop: "3px solid #f4f5f9",
          }}
        >
          <GText
            g6
            med
            text={strings?.w_delivery_address}
            style={{
              textTransform: "uppercase",
              fontStyle: "italic",
              margin: "15px 0 10px",
            }}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <GContent
                title={delivery.delivery_payload?.title_display || ""}
                value={delivery?.delivery_address || orderData.delivery_address}
                style={{ flex: 1 }}
              />
            </div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                delivery?.delivery_address
                  ? "https://www.google.com/maps?q=" +
                    delivery?.delivery_lat +
                    "," +
                    delivery?.delivery_lng
                  : orderData.delivery_address
                  ? "https://www.google.com/maps?q=" +
                    orderData?.delivery_lat +
                    "," +
                    orderData?.delivery_lng
                  : ""
              }
            >
              <NavigateIcon
                style={{
                  marginLeft: "10px",
                  width: "28px",
                  height: "28px",
                  cursor: "pointer",
                }}
              />
            </a>
          </div>
        </div>
      ) : (
        ""
      )}

      <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
      <div>
        <GText
          g6
          med
          text={strings.w_payment_summary}
          style={{
            textTransform: "uppercase",
            fontStyle: "italic",
            margin: "15px 0 10px",
          }}
        />
        {payment_summary?.data.map((p) => {
          return (
            <GTableContent
              key={p.title}
              title={p.title}
              value={props.settings.currency_symbol + p.value}
              align="right"
              lAlign="right"
              padding="2px 5px"
            />
          );
        })}
        <hr style={{ border: "0", borderTop: "1px solid #e2e2e2" }} />

        <GTableContent
          title={strings?.w_grand_total}
          value={
            props.settings.currency_symbol + payment_summary?.total_display
          }
          align="right"
          lAlign="right"
          fontSize="15px"
          color="#000"
          bold={true}
        />
      </div>
    </React.Fragment>
  );
}
