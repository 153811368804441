import React, { useState, useContext, useEffect } from "react";
import GBackIcon from "../Elements/gBackIcon";
import GMap from "../Elements/gMaps";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry,places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

export default function SignupAddress(props) {
  const [loadMap, setLoadMap] = useState(false);
  const [strings] = useContext(StringsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const { defaultAddress } = useContext(UserAddressContext);
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.data?.latitude),
          lng: parseFloat(props.data?.longitude),
        }
      : {
          lat: defaultAddress?.lat,
          lng: defaultAddress?.lng,
        }
  );

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  const initialValues = {
    address: !props.isEmpty ? props.data.address : "",
    latitude: !props.isEmpty ? props.data.latitude : addressLocation?.lat,
    longitude: !props.isEmpty ? props.data.longitude : addressLocation?.lng,
  };

  const onSubmit = (values) => {
    setBtnLoading(true);

    let newValues = { ...values };
    console.log(newValues);
    props.onSubmit(newValues);
    props.setSignupAddFVisible(false);
  };

  const validationSchema = Yup.object({
    address: Yup.string().required(strings?.w_required),
  });

  if (props.signupAddFVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setAdvisible(false)} />
        {props.isEmpty === true
          ? strings?.w_add_address
          : strings?.w_edit_address}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "10px 20px 0",
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {!loadMap ? (
                <div>Loading...</div>
              ) : (
                <GMap
                  centerLocation={addressLocation}
                  lat={values.latitude}
                  lng={values.longitude}
                  search={true}
                  map={true}
                  placeholder={strings?.w_search + ".."}
                  zoom={16}
                  height="40vh"
                  type="createAddress"
                  onChange={(address, loc) => {
                    values.latitude = loc.lat();
                    values.longitude = loc.lng();
                    values.address = address.address;
                    setFieldValue("address", address.address);
                  }}
                  padding={true}
                />
              )}
              <FormikControl
                control="input"
                type="text"
                label={strings?.w_address}
                name="address"
                // className="noBorder"
              />
              <br />
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={strings?.s_save_and_proceed}
                type="submit"
                style={{ width: "94%", margin: "0 15px 15px" }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
