import React, { useEffect, useState, useContext } from "react";
import { ApiLoginService, getAmount } from "../ApiService/services";
import { ReactComponent as Close } from "../assets/images/icons/close.svg";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import GShimmer from "../Elements/gTableShimmer";
import ProductVariants from "./productVariantsForm";

export default function OrderProductVariants(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({
    variants: [],
  });

  useEffect(() => {
    console.log(props);
    ApiLoginService("GET", "c/products/" + props.item.id).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        setSelectedProduct(response.data);
        setIsLoading(false);
      } else {
        //openSnackbar(response.message);
      }
    });
  }, []);

  if (props.orderVarVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
        }}
      >
        <div style={{ display: "flex" }}>
          <GText g4 semi text={props.item?.title} style={{ flex: "1" }} />

          <Close
            className="closeIcon"
            onClick={() => props.setOrderVarVisible(false)}
            style={{
              fill: "#ccc",
              cursor: "pointer",
              width: "15px",
              height: "15px",
            }}
          />
        </div>
        <GText g5 light text={props.item?.unit} style={{ paddingTop: "5px" }} />
        <>
          {props.item?.price != props.item?.price_old ||
          selectedProduct?.price != selectedProduct?.price_old ? (
            <GView>
              <GText
                theme="true"
                semi="true"
                text={getAmount(
                  props.item?.price_discounted ||
                    selectedProduct?.price_discounted
                )}
                style={{
                  padding: "0",
                  display: "inline-block",
                  width: "auto",
                }}
              />{" "}
              <GText
                light="true"
                text={getAmount(props.item?.price || selectedProduct?.price)}
                style={{
                  padding: "0",
                  display: "inline-block",
                  width: "auto",
                  textDecoration: "line-through",
                }}
              />
            </GView>
          ) : (
            <GText
              theme
              semi
              text={getAmount(props.item?.price || selectedProduct?.price)}
              style={{ padding: "0" }}
            />
          )}
        </>
        <hr
          style={{
            border: "0",
            borderBottom: "1px dashed #e2e2e2",
            marginTop: "15px",
          }}
        />
        {isLoading === true ? (
          <GShimmer />
        ) : (
          <React.Fragment>
            <div
              style={{
                maxHeight:
                  props.position === "right" ? "initial" : "calc(80vh - 135px)",
                overflow: "overlay",
              }}
            >
              <ProductVariants
                item={props.item}
                data={selectedProduct.variants || []}
                settings={props.settings}
                onSubmitVariants={(variants) => {
                  let newItem = { ...props.item };
                  newItem.variants = variants;
                  props.onSubmitVariants(newItem, variants);
                  props.setOrderVarVisible(false);
                }}
              />
            </div>{" "}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
