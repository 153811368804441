import React, { useState, useEffect, createContext, useContext } from "react";
import es from "../Localisation/es.json";
import en from "../Localisation/en.json";
import ar from "../Localisation/ar.json";
import fr from "../Localisation/fr.json";
import { I18nManager } from "react-native";
import { SettingsContext } from "./useSettings";

const StringsContext = createContext(null);

const StringsProvider = ({ children }) => {
  const { settings } = useContext(SettingsContext);
  const localisation = JSON.parse(settings?.localisation || "{}");
  const firstLanguage = localisation?.options?.[0];

  let availableLaunguages = [
    { languageCode: "en", title: "English" },
    { languageCode: "es", title: "español" },
    { languageCode: "ar", title: "Arabic" },
    { languageCode: "fr", title: "French" },
  ].filter((t) => localisation?.options?.includes(t.languageCode));

  let defaultCode = settings?.user?.locale || "en";

  if (!localisation?.enabled) {
    //
  } else if (strings?.[firstLanguage] && localisation?.options?.length === 1) {
    defaultCode = firstLanguage;
  }

  const [data, setData] = useState(strings[defaultCode]);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState(settings?.user?.locale || "");

  useEffect(() => {
    setLanguage(
      settings?.user?.locale || localStorage.getItem("language") || ""
    );
  }, [settings]);

  useEffect(() => {
    let l = language || defaultCode;

    console.log(l);

    if (["ar"].includes(l) && !I18nManager.getConstants().isRTL) {
      I18nManager.allowRTL(true);
      I18nManager.forceRTL(true);
      document.documentElement.setAttribute("lang", language);
    }
    if (["en", "es", "fr"].includes(l) && I18nManager.getConstants().isRTL) {
      I18nManager.allowRTL(false);
      I18nManager.forceRTL(false);
    }

    setData(strings[l]);
    setLoading(false);
  }, [language]);

  return (
    <StringsContext.Provider
      value={[
        data,
        I18nManager.getConstants().isRTL,
        availableLaunguages,
        language,
        setLanguage,
        loading,
      ]}
    >
      {children}
    </StringsContext.Provider>
  );
};

export { StringsProvider, StringsContext };

const strings = { en, es, ar, fr };
