import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Css/slickCarousel.css";
import GView from "../Elements/GView";
import GText from "../Elements/GText";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";

const IconDiv = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    right: 10px;
    border: 2px solid #fff;
    border-radius: 20px;
    path {
      fill: ${({ theme }) => theme.body};
    }
  }
`;

const OrderVehicles = (props) => {
  const { width } = useWindowSize();
  const [vehicleTypes, setVehicleTypes] = useState(props.data);
  const [selectedVehicle, setSelectedVehicle] = useState("");

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: true,
    speed: 500,
    slidesToShow:
      width > 1500
        ? 5
        : width > 1200 && width <= 1500
        ? 4
        : width > 767 && width <= 960
        ? 2
        : width > 630 && width <= 767
        ? 6
        : width > 530 && width <= 767
        ? 5
        : width > 430 && width <= 530
        ? 4
        : 3,
    slidesToScroll: 3,
  };

  return (
    <GView style={{ padding: "20px 0" }}>
      <GText
        g4
        semi
        text={props.strings.w_choose_vehicle}
        style={{ marginBottom: "20px" }}
      />
      <Slider {...SliderSettings}>
        {vehicleTypes.map((v, i) => {
          return (
            <GView
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedVehicle(v.id);
                if (props.onSelect) {
                  props.onSelect(v.id);
                }
              }}
            >
              {v.id == selectedVehicle && (
                <IconDiv>
                  <Check />
                </IconDiv>
              )}
              <img
                src={ImgUrl("vehicle_type") + "/" + v.thumb_photo}
                alt={v.title}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  margin: "0 auto",
                  cursor: "pointer",
                }}
                onError={(e) => addDefaultSrc(e, "vehicle")}
              />
              <GText
                text={v.title}
                style={{
                  textAlign: "center",
                  padding: "5px 0",
                  cursor: "pointer",
                }}
              ></GText>
            </GView>
          );
        })}
      </Slider>
    </GView>
  );
};

export default OrderVehicles;
