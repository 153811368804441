import React, { useState, useEffect, useContext } from "react";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import styled from "styled-components";
import Modal from "react-modal";
import LocationAddress from "./courierOrderStopsLocations";
import ConfirmLocationAddress from "./courierOrderLocationForm";
import GButton from "../Elements/GButton";
// import Cross from "../assets/images/cross.png";
import GActions from "../Elements/gActionsStyle";
import { StringsContext } from "../DataFactory/useStrings";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import SwitchIcon from "../assets/images/icons/switch.png";

const StopsDiv = styled.div`
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 12px;
  margin-bottom: 12px;
  // cursor: pointer;

  .type {
    border: 1px solid #777;
    border-radius: 20px;
    text-align: center;
    width: 21px;
    height: 21x;

    &.exist {
      background: ${({ theme }) => theme.body};
      border: 1px solid ${({ theme }) => theme.body};
      color: #fff;
    }
  }
`;

const OrderStops = (props) => {
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isLocAddressVisible, seIsLocAddressVisible] = useState(false);
  const [orderLocVisible, setOrderLocVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedLocationIndex, setSelectedLocationIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [stops, setStops] = useState([
    {
      type: "pickup",
      complete_after: "",
      complete_before: "",
    },
    {
      type: "delivery",
      complete_after: "",
      complete_before: "",
    },
  ]);

  useEffect(() => {
    console.log(stops);
    let stopdata = stops
      .filter((f) => f.lat)
      .map((s) => {
        return s;
      });

    console.log(stopdata);
    if (stopdata.length > 0) {
      console.log([...stopdata]);
      props.setPlaces([...stopdata]);
      //props.onChange(stopdata);
    }
  }, [stops]);
  return (
    <>
      <GView style={{ display: "flex", alignItems: "center" }}>
        <GView style={{ flex: 1 }}>
          {stops.map((s, i) => {
            return (
              <StopsDiv key={i}>
                {s.type === "pickup" ? (
                  <GView
                    style={{
                      display: "flex",
                      alignItems: "start",
                      cursor: !s.lat ? "pointer" : "",
                    }}
                  >
                    <GText
                      text={"A"}
                      className={s.lat ? "type exist" : "type"}
                    />
                    &nbsp;&nbsp;
                    {s.lat ? (
                      <GView
                        // onClick={() => {
                        //   setIsEdit(true);
                        //   setSelectedLocationIndex(i);
                        //   seIsLocAddressVisible(true);
                        // }}
                        style={{ width: "calc(100% - 50px)" }}
                      >
                        <GText
                          semi
                          text={s.title}
                          style={{
                            textTransform: "uppercase",
                            paddingBottom: "3px",
                          }}
                        />
                        <GText text={s.line1} style={{ color: "#333" }} />
                        <hr
                          style={{
                            borderTop: "0",
                            margin: "10px 0",
                            borderBottom: "1px solid #e2e2e2",
                          }}
                        />
                        {s.rec_name || s.rec_phone ? (
                          <GText text={s.rec_name + " . " + s.rec_phone} />
                        ) : (
                          <GText text={strings.w_add_contact_details} />
                        )}
                      </GView>
                    ) : (
                      <GText
                        text={strings.w_choose_pickup_address}
                        onClick={() => {
                          setIsEdit(false);
                          setSelectedLocationIndex(i);
                          seIsLocAddressVisible(true);
                        }}
                      />
                    )}
                    {s.lat && (
                      <GActions
                        onEdit={() => {
                          setSelectedLocation(s);
                          setOrderLocVisible(true);
                          setSelectedLocationIndex(i);
                          //props.onEdit(addressData)
                        }}
                        onSelect={() => {
                          setIsEdit(true);
                          setSelectedLocationIndex(i);
                          seIsLocAddressVisible(true);
                        }}
                        menuEditTitle={strings.w_update_details}
                        menuTitle={strings.w_change_location}
                        mode={"dark"}
                        style={{ position: "relative", marginLeft: "auto" }}
                        menuStyle={{
                          fontSize: "13.5px",
                          padding: "4px 15px",
                        }}
                        paperStyle={{
                          minWidth: "140px",
                          background: "#3a3a3a",
                          color: "#f5f5f5",
                        }}
                      />
                    )}
                  </GView>
                ) : (
                  <GView
                    style={{
                      display: "flex",
                      alignItems: "start",
                      cursor: !s.lat ? "pointer" : "",
                    }}
                  >
                    <GText
                      text={"B"}
                      className={s.lat ? "type exist" : "type"}
                    />
                    &nbsp;&nbsp;
                    {s.lat ? (
                      <GView style={{ width: "calc(100% - 50px)" }}>
                        <GText
                          semi
                          text={s.title}
                          style={{
                            textTransform: "uppercase",
                            paddingBottom: "3px",
                          }}
                        />
                        <GText text={s.line1} style={{ color: "#333" }} />
                        <hr
                          style={{
                            margin: "10px 0",
                            borderTop: "0",
                            borderBottom: "1px solid #e2e2e2",
                          }}
                        />
                        {s.rec_name || s.rec_phone ? (
                          <GText text={s.rec_name + " . " + s.rec_phone} />
                        ) : (
                          <GText text={strings.w_add_contact_details} />
                        )}
                      </GView>
                    ) : (
                      <GText
                        text={strings.w_choose_delivery_address}
                        onClick={() => {
                          setIsEdit(false);
                          setSelectedLocationIndex(i);
                          seIsLocAddressVisible(true);
                        }}
                      />
                    )}
                    {s.lat || i > 1 ? (
                      <>
                        <GActions
                          onEdit={() => {
                            setSelectedLocation(s);
                            setOrderLocVisible(true);
                            setSelectedLocationIndex(i);
                            //props.onEdit(addressData)
                          }}
                          onRemove={(e) => {
                            e.stopPropagation();
                            stops.splice(i, 1);
                            setStops([...stops]);
                          }}
                          onSelect={() => {
                            setIsEdit(true);
                            setSelectedLocationIndex(i);
                            seIsLocAddressVisible(true);
                          }}
                          menuEditTitle={strings.w_update_details}
                          menuTitle={strings.w_change_location}
                          mode={"dark"}
                          style={{ position: "relative", marginLeft: "auto" }}
                          menuStyle={{
                            fontSize: "13.5px",
                            padding: "4px 15px",
                          }}
                          paperStyle={{
                            minWidth: "140px",
                            background: "#3a3a3a",
                            color: "#f5f5f5",
                          }}
                        />
                        {/* <GButton
                    variant="linkable"
                    onClick={(e) => {
                      e.stopPropagation();
                      stops.splice(i, 1);
                      setStops([...stops]);
                    }}
                    style={{ marginLeft: "auto", padding: "3px 5px" }}
                  >
                    <img src={Cross} style={{ width: "13px" }} />
                  </GButton> */}
                      </>
                    ) : (
                      ""
                    )}
                  </GView>
                )}
              </StopsDiv>
            );
          })}
        </GView>

        <img
          src={SwitchIcon}
          style={{
            height: "max-content",
            width: "25px",
            marginLeft: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            //  console.log(stops);
            let newStops = [];
            newStops.push(stops[1]);
            newStops.push(stops[0]);
            newStops[0].type = "pickup";
            newStops[1].type = "delivery";
            // console.log(newStops);
            setStops(newStops);
          }}
        />
      </GView>
      <Modal
        isOpen={isLocAddressVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          seIsLocAddressVisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Locations Modal"
      >
        <LocationAddress
          isEdit={isEdit}
          strings={strings}
          addressData={props.address}
          isLocAddressVisible={isLocAddressVisible}
          seIsLocAddressVisible={seIsLocAddressVisible}
          onSelectAddress={(data) => {
            console.log(data);
            setSelectedLocation({
              ...data,
              lat: data.latitude,
              lng: data.longitude,
            });
            setOrderLocVisible(true);
            seIsLocAddressVisible(false);

            // console.log(selectedLocationIndex);
            // console.log(stops[selectedLocationIndex]);
            // data.type = stops[selectedLocationIndex].type;
            // console.log(data);
            // stops[selectedLocationIndex] = {
            //   ...stops[selectedLocationIndex],
            //   ...data,
            // };
            // console.log(stops);
            // setStops([...stops]);
          }}
        />
      </Modal>
      <Modal
        isOpen={orderLocVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderLocVisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Location confirm Modal"
      >
        <ConfirmLocationAddress
          addressData={props.address}
          selectedLocation={selectedLocation}
          orderLocVisible={orderLocVisible}
          setOrderLocVisible={setOrderLocVisible}
          onSubmit={(data) => {
            console.log(stops);
            console.log(selectedLocationIndex);
            console.log(stops[selectedLocationIndex]);
            data.type = stops[selectedLocationIndex].type;
            console.log(data);
            stops[selectedLocationIndex] = {
              ...stops[selectedLocationIndex],
              ...data,
            };
            console.log(stops);
            setStops([...stops]);
            setOrderLocVisible(false);
            seIsLocAddressVisible(false);
          }}
        />
      </Modal>
    </>
  );
};

export default OrderStops;
