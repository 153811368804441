import React, { useState, useContext } from "react";
import GView from "../Elements/GView";
import GBackIcon from "../Elements/gBackIcon";
import GButton from "../Elements/GButton";
import GOffers from "../Elements/gOffers";
import useDataFctory from "../ApiService/useDataFactory";
import { StringsContext } from "../DataFactory/useStrings";

export default function OrderCouponPopup(props) {
  const [strings] = useContext(StringsContext);
  const { loading, Shimmer, Placeholder, data } = useDataFctory(
    "coupon",
    false,
    { for_store: props.storeId }
  );
  const [couponCode, setCouponCode] = useState("");

  if (props.couponVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setCouponVisible(false)} />
        {strings?.w_apply_coupon}
      </div>
      <div className="modal-body">
        {loading === true ? (
          <Shimmer />
        ) : data && data.data.length > 0 ? (
          <React.Fragment>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder={strings?.s_coupon_code}
                value={couponCode || ""}
                style={{
                  border: "0",
                  outline: "0",
                  background: "#f4f5f9",
                  width: "63%",
                  borderRadius: "4px",
                  padding: "12px",
                }}
                onBlur={(e) => setCouponCode(e.target.value)}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <GButton
                variant="condensed"
                children={strings?.w_apply}
                style={{ marginLeft: "10px" }}
                disabled={couponCode ? false : true}
                onClick={() => {
                  console.log(couponCode);
                  props.onApplyCoupon(couponCode);
                }}
              />
            </div>
            <br />
            <GView
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
                gridGap: "20px",
              }}
            >
              {data.data.map((c, i) => {
                return (
                  <GOffers
                    key={i}
                    data={c}
                    onCopy={(coupon) => {
                      setCouponCode(coupon.code);
                    }}
                  />
                );
              })}
            </GView>
          </React.Fragment>
        ) : (
          <Placeholder />
        )}
      </div>
    </React.Fragment>
  );
}
