import React, { useState, useContext } from "react";
import { ApiService } from "../ApiService/services";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";
import countriesList from "../jsonData/cardcountries.json";
import statesList from "../jsonData/states.json";

const detectCardType = (number) => {
  var re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
};

export default function PaymentForm(props) {
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    cvc: "",
    exp_month: "",
    exp_year: "",
    card_number: "",
    identifier: props.cardType,
    brand: "",
    country: "",
    state: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    let sendData = { ...values };
    sendData.brand = detectCardType(sendData.card_number);
    console.log(sendData);
    ApiService({ method: "POST", route: "c/cards", body: sendData })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          props.onCardAdd(response.data.data);
        }
        enqueueSnackbar(response.data.message);
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    card_number: Yup.string().required(strings?.w_required),
    exp_month: Yup.string().required(strings?.w_required),
    exp_year: Yup.string().required(strings?.w_required),
    cvc: Yup.string().required(strings?.w_required),
    country: Yup.string().required(strings?.w_required),
    state: Yup.string().required(strings?.w_required),
  });

  return (
    <div id="PaymentForm">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder={"1234 5678 1234 5678"}
              name="card_number"
              maxLength={16}
              divstyle={{ padding: "4px 5px" }}
              style={{ fontSize: "13px", height: "35px" }}
              onChange={(event) => {
                const re = /^[0-9\b]+$/;
                if (event.target.value && !re.test(event.target.value)) {
                  return;
                } else {
                  setFieldValue("card_number", event.target.value);
                }
              }}
            />
            <div style={{ display: "inline-flex", width: "100%" }}>
              <FormikControl
                control="input"
                type="text"
                placeholder="MM"
                name="exp_month"
                maxLength={2}
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("exp_month", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="YYYY"
                name="exp_year"
                maxLength={4}
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("exp_year", event.target.value);
                  }
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="CVC"
                name="cvc"
                maxLength={4}
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px" }}
                onChange={(event) => {
                  const re = /^[0-9\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("cvc", event.target.value);
                  }
                }}
              />
            </div>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <FormikControl
                control="select"
                as="select"
                placeholder={strings.w_country}
                name="country"
                options={countriesList}
                key_title="name"
                key_value="iso2"
                onChange={(event) => {
                  setFieldValue("country", event.target.value || "");
                  setFieldValue("state", "");
                }}
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px", margin: "2px 0" }}
              />

              <FormikControl
                control="select"
                as="select"
                placeholder={strings.w_state}
                name="state"
                options={statesList.filter(
                  (s) => s.country_code == values.country
                )}
                key_title="name"
                key_value="state_code"
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px", margin: "2px 0" }}
              />

              <FormikControl
                control="input"
                type="text"
                placeholder={strings.w_city}
                name="city"
                divstyle={{ padding: "4px 5px", flex: "1" }}
                style={{ fontSize: "13px", height: "35px" }}
              />
            </div>
            <FormikControl
              control="input"
              type="text"
              placeholder={strings.w_address}
              name="address"
              divstyle={{ padding: "4px 5px", flex: "1" }}
              style={{ fontSize: "13px", height: "35px" }}
            />
            <FormikControl
              control="input"
              type="text"
              placeholder={strings.w_zipcode}
              name="zipcode"
              divstyle={{ padding: "4px 5px", flex: "1" }}
              style={{ fontSize: "13px", height: "35px" }}
            />

            <div style={{ textAlign: "right" }}>
              <GButton
                variant="condensed"
                loading={isBtnLoading}
                children={strings?.w_add_card}
                type="submit"
                style={{
                  margin: "10px 5px",
                  padding: "8px 25px",
                  fontSize: "13px",
                }}
              />
              <GButton
                variant="outlined"
                children={strings?.w_cancel}
                type="button"
                style={{
                  margin: "10px 5px",
                  padding: "7px 25px",
                  fontSize: "13px",
                }}
                onClick={() => props.setAddCard(false)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
