import React, { useState, useContext } from "react";
import GBackIcon from "../Elements/gBackIcon";
import GTaxiMap from "../Components/courierOrderStopsMap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import { StringsContext } from "../DataFactory/useStrings";
import { UserAddressContext } from "../DataFactory/useDefaultAddress";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function CreateAddress(props) {
  const { defaultAddress } = useContext(UserAddressContext);
  const [strings] = useContext(StringsContext);
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.selectedLocation.lat),
          lng: parseFloat(props.selectedLocation.lng),
        }
      : {
          lat: defaultAddress?.lat,
          lng: defaultAddress?.lng,
        }
  );

  const addressTitle = [
    {
      key: strings?.w_home,
      value: "home",
    },
    {
      key: strings?.w_office,
      value: "work",
    },
    {
      key: strings?.w_other,
      value: "other",
    },
  ];

  const initialValues = {
    title: props.selectedLocation.title || "home",
    line1: props.selectedLocation.line1 || "",
    line2: props.selectedLocation.line2 || "",
    rec_name: props.selectedLocation.rec_name || "",
    rec_phone: props.selectedLocation.rec_phone || "",
    lat: props.selectedLocation.lat || "",
    lng: props.selectedLocation.lng || "",
    notes: props.selectedLocation.notes || "",
    city: props.selectedLocation.city || "",
    state: props.selectedLocation.state || "",
    country: props.selectedLocation.country || "",
    zipcode: props.selectedLocation.zipcode || "",
  };

  const onSubmit = (values) => {
    values.title = values.title == "other" ? values.other_title : values.title;
    console.log(values);
    props.onSubmit(values);
    props.setOrderLocVisible(false);
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required(strings?.w_required),
    // rec_name: Yup.string().required(strings?.w_required),
    // rec_phone: Yup.string()
    //   .min("10", "Phone should be atleast 10 didgits")
    //   .required(strings?.w_required),
  });

  if (props.orderLocVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOrderLocVisible(false)} />
        {strings.w_confirm_location}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 70px)",
          height: "calc(100vh - 70px)",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <br />
              <GView style={{ padding: "0 15px" }}>
                <GTaxiMap
                  center={addressLocation}
                  zoom={16}
                  places={[]}
                  isAutoComplete={true}
                  style={{ height: "300px", position: "relative" }}
                  onChange={(address, loc) => {
                    values.lat = loc.lat();
                    values.lng = loc.lng();
                    values.line1 = address.address;
                    values.city = address.city;
                    values.state = address.state;
                    values.country = address.country;
                    values.zipcode = address.zipcode;
                    setFieldValue("line1", address.address);
                  }}
                />
              </GView>
              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "33%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />
              {values.title === "other" && (
                <FormikControl
                  control="input"
                  type="text"
                  placeholder={strings?.s_address_line_title}
                  name="other_title"
                  className="noBorder"
                />
              )}
              <FormikControl
                control="input"
                type="text"
                placeholder={strings?.w_address_line1}
                name="line1"
                className="noBorder"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder={strings?.w_address_line2}
                name="line2"
                className="noBorder"
              />
              <GText
                g6
                med
                semi
                text={strings.w_other_details}
                style={{
                  padding: "15px 15px 10px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder={strings.w_contact_name}
                name="rec_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_name", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder={strings.w_contact_phone}
                name="rec_phone"
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_phone", event.target.value);
                  }
                }}
                divstyle={{
                  width: "50%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                as="textarea"
                placeholder={strings.w_address_notes}
                name="notes"
              />
              <br />
              <GButton
                variant="condensed"
                children={strings.w_confirm_location}
                type="submit"
                style={{
                  width: "calc(100% - 30px)",
                  margin: "0 15px 15px",
                  borderRadius: "2px",
                  fontWeight: "550",
                }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
