import React, { useContext } from "react";
import ProductList from "./favouriteProducts";
import useDataFactory from "../ApiService/useDataFactory";
import { SettingsContext } from "../DataFactory/useSettings";
import { Item } from "../Models/Item";
import { StringsContext } from "../DataFactory/useStrings";
import GBackIcon from "../Elements/gBackIcon";
import GText from "../Elements/GText";

function FavouriteProducts(props) {
  const [strings] = useContext(StringsContext);
  const {
    loading,
    Shimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFactory("wishlist", true);
  const { settings } = useContext(SettingsContext);

  if (props.wishlistVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setWishlistVisible(false)} />
        {strings?.w_favourite_products}
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 25px",
          overflow: "overlay",
        }}
      >
        {loading === true ? (
          <Shimmer></Shimmer>
        ) : (
          <ProductList
            data={data.map((o) => new Item(o))}
            Placeholder={Placeholder}
            settings={settings}
            pagination={pagination}
            onLoadMore={() => loadMore()}
            type="favourite"
            onFavouriteRemove={() => {
              refreshData();
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default FavouriteProducts;
