import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as KeyboardArrowDown } from "../assets/images/icons/arrowDown.svg";
import { ReactComponent as Language } from "../assets/images/language.svg";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import styled from "styled-components";
import CountriesJson from "../jsonData/countries.json";
import { I18nManager } from "react-native";

const CodeSelect = styled.div`
  display: inline-block;
`;
const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    background: #f4f5f9;
  }

  img {
    width: 25px;
    margin-right: 8px;
  }
  span {
    color: #777;
  }
`;

export const GMobileCountryCode = ({ type, value, onSelect, style }) => {
  const [selectedCode, setSelectedCode] = useState(value || {});
  const wrapperCountryRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useDetectOutsideClick(
    wrapperCountryRef,
    false
  );
  return (
    <div ref={wrapperCountryRef} style={style}>
      <CodeSelect>
        <div
          style={{
            height: "34px",
            fontSize: "15px",
            color: "#555",
            boxSizing: "border-box",
            padding: "4px 3px",
            display: "flex",
            alignItems: "center",
            cursor: "default",
          }}
          onClick={() => {
            setShowSuggestions(true);
          }}
        >
          {selectedCode?.code ? (
            <span
              style={{
                direction: "ltr",
                textAlign: I18nManager.getConstants().isRTL ? "right" : "left",
              }}
            >
              {type === "language" ? selectedCode.title : selectedCode.code}
            </span>
          ) : (
            <span
              style={{
                color: "#777",
                display: "inline-flex",
                alignItems: "center",
                cursor: "default",
              }}
            >
              <Language style={{ fontSize: "1.2rem", width: 15, height: 15 }} />{" "}
              Select
            </span>
          )}
          <KeyboardArrowDown
            style={{
              float: "right",
              fontSize: "18px",
              color: "#a2a2a2",
              marginLeft: I18nManager.getConstants().isRTL ? 0 : "5px",
              marginRight: I18nManager.getConstants().isRTL ? "5px" : 0,
              paddingRight: I18nManager.getConstants().isRTL ? 0 : "5px",
              paddingLeft: I18nManager.getConstants().isRTL ? "5px" : 0,
              width: 11,
              height: 11,
            }}
          />
        </div>

        {showSuggestions === true ? (
          <div
            style={{
              background: "#fff",
              position: "absolute",
              zIndex: "9",
              boxSizing: "border-box",
              // width: "100%",
              top: "41px",
              left: "0",
              padding: "0 5px 5px",
              border: "1px solid rgb(226, 226, 226)",
            }}
          >
            <ul
              style={{
                listStyleType: "none",
                padding: "5px 0",
                margin: "0",
                // borderTop: "1px solid #e2e2e2",
                maxHeight: "220px",
                overflow: "auto",
              }}
            >
              {CountriesJson &&
                CountriesJson.map((c, i) => {
                  return (
                    <LI
                      key={i}
                      onClick={() => {
                        console.log(c.code);
                        setSelectedCode(c);
                        setShowSuggestions(false);
                        if (onSelect) {
                          onSelect(c);
                        }
                      }}
                    >
                      {/* <img src={c.id == 1 ? EstoniaFlag : IndiaFlag} /> */}
                      {c.flag}
                      <span
                        style={{
                          display: "inline-block",
                          color: "#000",
                          paddingLeft: I18nManager.getConstants().isRTL
                            ? 0
                            : "10px",
                          paddingRight: I18nManager.getConstants().isRTL
                            ? "10px"
                            : 0,
                        }}
                      >
                        {c.title}
                      </span>
                      <span
                        style={{
                          paddingLeft: I18nManager.getConstants().isRTL
                            ? 0
                            : "10px",
                          paddingRight: I18nManager.getConstants().isRTL
                            ? "10px"
                            : 0,
                          direction: "ltr",
                          textAlign: I18nManager.getConstants().isRTL
                            ? "right"
                            : "left",
                        }}
                      >
                        {" "}
                        {c.code}
                      </span>
                    </LI>
                  );
                })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </CodeSelect>{" "}
    </div>
  );
};
