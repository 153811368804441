import React, { useState, useEffect, useContext } from "react";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import "../Css/slickCarousel.css";
import StoreView from "../Elements/gStoreView";
import styled from "styled-components";
import GText from "../Elements/GText";
import { ReactComponent as StarRate } from "../assets/images/star.svg";
import { I18nManager } from "react-native";
import useWindowSize from "../DataFactory/useWindowSize";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import StoreReviews from "../Components/storeReviews";
import GView from "./GView";

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  padding: 10px;
  .discount {
    background: ${({ theme }) => theme.body};
    color: #fff;
  }
  img {
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
  .rating {
    margin: 8px 5px;
    display: flex;
    align-items: baseline;
    svg {
      width: 13px;
      fill: ${({ theme }) => theme.body};
    }
  }

  .featured {
    background: #333;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    margin-left: -5px;
    font-weight: 500;
    top: 11px;
    position: absolute;
  }

  .closed {
    background: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 75px;
    margin: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    height: calc(170px - 75px);
    font-weight: 600;
  }
`;
export default function GStoresSlider(props) {
  const [stores, setStores] = useState(props.data || []);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [display, setDisplay] = useState(true);
  const { width } = useWindowSize();
  const [selectedProduct, setSelectedProduct] = useState({});

  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: false,
    speed: 500,
    slidesToShow:
      props.slidesShow ||
      (width >= 1800
        ? 4.5
        : width > 1500 && width <= 1799
        ? 3.5
        : width > 1279 && width <= 1500
        ? 3.2
        : width > 991 && width <= 1279
        ? 2.3
        : width > 767 && width <= 991
        ? 2.5
        : 1.3),
    slidesToScroll: 1,
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: display ? "block" : "none",
        }}
      >
        <Slider {...SliderSettings}>
          {stores.map((store, index) => {
            return (
              <div key={index}>
                <StoreView
                  data={store}
                  theme={"column"}
                  mainDivStyle={{
                    margin: "5px 7px",
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
