import React, { useContext, useEffect, useState } from "react";
import GButton from "../Elements/GButton";
import { ApiService } from "../ApiService/services";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { StringsContext } from "../DataFactory/useStrings";
import GBackIcon from "../Elements/gBackIcon";
import { SettingsContext } from "../DataFactory/useSettings";
import useDataFctory from "../ApiService/useDataFactory";
import CardPayment from "../paymentComponents/cardPayments";

export default function OrderFeedbackTipPayment(props) {
  const { settings } = useContext(SettingsContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { loading, data, TableShimmer } = useDataFctory("gateways", false);
  const [cardPayment, setCardPayment] = useState({});

  useEffect(() => {
    if (loading == false) {
      setCardPayment({ identifier: data?.card?.identifier || {} });
    }
  }, [data]);

  const onSubmit = () => {
    setIsBtnDisabled(true);
    let sendData = {
      gateway: cardPayment,
      type: "tip",
      amount: props.tipAmount,
    };

    console.log(sendData);
    var url = "c/order-payment/" + props.orderId;

    ApiService({
      method: "POST",
      route: url,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.onSubmit();
        props.setOfTipPayVisible(false);
      }
      setIsBtnDisabled(false);
      enqueueSnackbar(response.data.message);
    });
  };

  if (props.ofTipPayVisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOfTipPayVisible(false)} />{" "}
        {strings.w_payment}
      </div>
      <div
        className="modal-body"
        style={{
          height: "calc(100vh - 150px)",
          padding: 0,
          margin: "15px 20px",
        }}
      >
        {loading == true ? (
          <TableShimmer />
        ) : (
          <CardPayment
            cardPayment={cardPayment}
            paymentGateways={data}
            onSelecteCard={(card) => {
              setCardPayment({
                ...cardPayment,
                card_id: card?.id || "",
                token: card?.token || "",
              });
            }}
          />
        )}

        <GButton
          variant="condensed"
          disabled={isBtnDisabled}
          style={{
            borderRadius: "4px",
            fontSize: "13px",
            padding: "12px 20px",
            minWidth: "240px",
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 40px)",
            position: "fixed",
            bottom: "15px",
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          <span style={{ flex: "1", fontWeight: "600", textAlign: "initial" }}>
            {strings?.w_add_tip}
          </span>
          <span
            style={{
              fontWeight: "600",
            }}
          >
            {settings.currency_symbol}
            {props.tipAmount}
          </span>
        </GButton>
      </div>
    </React.Fragment>
  );
}
