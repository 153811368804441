import React, { useEffect, useState, useContext } from "react";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { StringsContext } from "../DataFactory/useStrings";
import { Formik, Form } from "formik";
import FormikControl from "../formikComponents/formikControl";
import GText from "../Elements/GText";
import styled from "styled-components";

const Span = styled.span`
  background: ${({ theme }) => theme.body};
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
`;

export default function ProductVariants(props) {
  const [strings] = useContext(StringsContext);
  const [itemVariants, setItemVariants] = useState(props.data || []);
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    console.log(props);
    setItemVariants(props.data);
  }, []);

  let obj = {};
  itemVariants.map((d) => {
    obj[d.title] = d[d.title] || "";
  });

  const initialValues = { ...obj };
  //console.log(props.data + "--");

  const onSubmit = (values) => {
    //  console.log(values);
    let validate = validateData(values);
    if (validate === true) {
      let newArray = [];
      itemVariants.map((element) => {
        if (element.selection_type !== "single") {
          element.options.map((e) => {
            var isExist = values[element.title].includes(e.id.toString());
            if (isExist == true) {
              newArray.push(e);
            }
          });
          console.log(newArray);
        } else {
          let v = element.options.filter((e) => e.id == values[element.title]);
          if (v && v.length > 0) {
            newArray = newArray.concat(v);
            console.log(newArray);
          }
        }
      });
      props.onSubmitVariants(newArray);
    }
  };

  const validateData = (values) => {
    //console.log(values);
    let validated = true;
    itemVariants.every((element) => {
      if (element.options.length > 0) {
        let count = 0;
        if (element.selection_type !== "single") {
          element.options.map((e) => {
            var isExist = values[element.title].includes(e.id.toString());
            //console.log(isExist);
            if (isExist == true) {
              count = count + 1;
              // console.log(count);
            }
          });
          //  console.log(count);
        } else {
          count = element.options.filter(
            (e) => e.id == values[element.title]
          ).length;
        }

        if (element.maximum < count) {
          enqueueSnackbar(
            `${element.title} - ${strings?.s_maximum_selection_is} ${element.maximum}`
          );
          validated = false;
          return false;
        }
        if (element.minimum > count) {
          enqueueSnackbar(
            `${element.title} - ${strings?.s_minimum_selection_is} ${element.minimum}`
          );
          validated = false;
          return false;
        }
        return true;
      } else {
        return true;
      }
    });
    return validated;
  };

  const validateFIeldData = (values, title) => {
    //console.log(values);
    let validated = true;
    itemVariants.every((element) => {
      if (element.options.length > 0 && element.title === title) {
        let count = 0;
        if (element.selection_type === "multiple") {
          element.options.map((e) => {
            var isExist = values.includes(e.id.toString());
            // console.log(isExist);
            if (isExist == true) {
              count = count + 1;
              // console.log(count);
            }
          });
          //  console.log(count);
        }

        if (element.maximum < count) {
          enqueueSnackbar(
            `${element.title} - ${strings?.s_maximum_selection_is} ${element.maximum}`
          );
          validated = false;
          return false;
        }
        return true;
      } else {
        return true;
      }
    });
    return validated;
  };

  return (
    <React.Fragment>
      <div
        style={{
          maxHeight:
            props.position === "right" ? "initial" : "calc(80vh - 135px)",
          overflow: "overlay",
        }}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, resetForm, setFieldValue }) => {
            if (values && props.submitBtn === false) {
              props.onSubmit(values);
            }

            return (
              <Form style={{ padding: "0 10px" }}>
                {itemVariants.map((d, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <div
                        style={{
                          float: "right",
                          marginTop: "14px",
                          fontSize: "13px",
                        }}
                      >
                        {d.minimum >= 1 && (
                          <>
                            {values[d.title] && values[d.title]?.length > 0 ? (
                              <>
                                {values[d.title]?.length >= d.minimum ? (
                                  <Span>{strings.w_completed}</Span>
                                ) : (
                                  <Span>
                                    {strings?.s_minimum_selection_is}{" "}
                                    {d.minimum}
                                  </Span>
                                )}
                              </>
                            ) : (
                              <GText
                                text={strings.w_required}
                                style={{ fontSize: "14px" }}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <FormikControl
                        control={
                          d.selection_type === "single" ? "radio" : "checkbox"
                        }
                        name={d.title}
                        label={d.title}
                        priceType={d.price_type}
                        settings={props.settings}
                        isRequired={d.minimum >= 1 ? true : false}
                        type="variant"
                        options={d.options}
                        key_title="title"
                        key_value="id"
                        onClick={(e) => console.log(e)}
                        mainStyle={{
                          padding: "10px 0",
                        }}
                        mainLabelStyle={{
                          fontSize: "17px",
                          margin: "0",
                          color: "#000",
                          fontWeight: "500",
                        }}
                        labelStyle={{
                          fontSize: "0.95em",
                          color: "#555",
                          margin: "0",
                          padding: "2px 0 ",
                        }}
                        divstyle={{ padding: "5px 0", display: "block" }}
                        checkHandler={(e) => {
                          //console.log(e.target.value);
                          if (d.selection_type === "multiple") {
                            let newVal = [];
                            if (values[d.title] && values[d.title].length > 0) {
                              newVal = [...values[d.title]];
                              if (newVal.includes(e.target.value)) {
                                const findInd = newVal.indexOf(e.target.value);
                                //console.log(findInd);
                                newVal.splice(findInd, 1);
                              } else {
                                newVal.push(e.target.value);
                              }
                            } else {
                              newVal = [];
                              newVal.push(e.target.value);
                            }
                            let validateF = validateFIeldData(newVal, d.title);
                            // console.log(validateF);
                            if (validateF == true) {
                              //console.log(newVal);
                              setFieldValue(d.title, newVal);
                            }
                          }
                        }}
                      />
                    </React.Fragment>
                  );
                })}

                <GButton
                  variant="condensed"
                  children={
                    props.item?.unlimited == 0 && props.item?.stock == 0
                      ? strings?.w_out_of_stock
                      : props.item?.next_available_display
                      ? props.item?.next_available_display
                      : props.item?.status == 0
                      ? strings?.w_not_available
                      : strings?.w_add
                  }
                  type={
                    (props.item?.unlimited == 0 && props.item?.stock == 0) ||
                    props.item?.next_available_display ||
                    props.item.status == 0
                      ? "button"
                      : "submit"
                  }
                  disabled={
                    (props.item?.unlimited == 0 && props.item?.stock == 0) ||
                    props.item?.next_available_display ||
                    props.item.status == 0
                      ? true
                      : false
                  }
                />

                <button
                  id="resetButton"
                  style={{ display: "none" }}
                  onClick={() => resetForm()}
                ></button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </React.Fragment>
  );
}
