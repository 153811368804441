import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Home } from "../assets/images/address-home.svg";
import GButton from "../Elements/GButton";
// import Lottie from "react-lottie";
import animationData from "../jsonData/success.json";
import { StringsContext } from "../DataFactory/useStrings";

const OrderPlacedDiv = styled.div`
  text-align: center;
  padding: 0 0 7vh 0;

  h4 {
    color: ${({ theme }) => theme.body};
    margin: 5px 0;
  }
  p {
    font-size: 15px;
    margin: 5px 0;
    color: #555;
  }
  hr {
    margin: 12px 0;
    border: 0;
    border-bottom: 1px solid #e2e2e2;
  }
`;
const defaultOptions = {
  loop: false,
  autoplay: true,
  colorFilters: [
    {
      keypath: "button",
      color: "green",
    },
    {
      keypath: "Sending Loader",
      color: "red",
    },
    {
      keypath: "tick Outlines",
      color: "#FFFFFF",
    },
    {
      keypath: "Shape Layer 1",
      color: "blue",
    },
  ],
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default function OrderPlaced(props) {
  let navigate = useNavigate();
  const [strings] = useContext(StringsContext);

  if (props.showOrderSuccess === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ padding: "10px 20px", textAlign: "center" }}>
        <h3>
          {strings?.w_order_placed}
          <Link to={"/"} style={{ float: "right", marginTop: "-5px" }}>
            <Home style={{ width: "22px", height: "22px" }} />
          </Link>
        </h3>

        {/* <Lottie options={defaultOptions} height={200} width={290} /> */}

        <OrderPlacedDiv>
          <h4>{strings?.s_order_placed}</h4>
          <p>{strings?.s_thanks_for_order}</p>
          <hr />
          <p>{strings?.s_your_order_no_is}</p>
          <h3>{props.orderId}</h3>
        </OrderPlacedDiv>

        <GButton
          variant="condensed"
          children={strings?.s_view_order_details}
          onClick={() =>
            navigate("/orders/" + props.orderId, {
              state: { title: "Orders" },
            })
          }
        />
      </div>
    </React.Fragment>
  );
}
