import React, { useContext } from "react";
import styled from "styled-components";
import AddressIcon from "../assets/images/address.png";
import WalletIcon from "../assets/images/wallet.png";
import InviteIcon from "../assets/images/invite.png";
import { Link } from "react-router-dom";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";

export const Plink = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 200px;
  margin-righloadt: 20px;
  vertical-align: top;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    overflow: overlay;
    flex-direction: row;
    margin-right: 0;
  }

  li {
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 10px;
      color: #555;
      font-size: 15px;
      font-weight: 500;

      img {
        opacity: 0.4;
      }

      &.active {
        color: ${({ theme }) => theme.text};
        img {
          opacity: 1;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        font-size: 13px;
      }
    }
    @media (max-width: 767px) {
      width: 25%;
      min-width: 75px;
    }
  }
`;

export default function ProfileLinks(props) {
  const [strings] = useContext(StringsContext);
  const { width } = useWindowSize();

  const ProfileRoutes = [
    {
      title: strings?.w_address,
      icon: AddressIcon,
      link: "address",
      match_url: "/profile/address",
    },
    {
      title: strings?.w_wallet,
      icon: WalletIcon,
      link: "wallet",
      match_url: "/profile/wallet",
    },
    {
      title: strings?.w_invite_earn,
      icon: InviteIcon,
      link: "invite",
      match_url: "/profile/invite",
    },
  ];

  return (
    <Plink style={{ flexDirection: width <= 767 ? "row" : "column" }}>
      {ProfileRoutes.map((route, i) => {
        return (
          <li key={i}>
            <Link
              to={{ pathname: route.link }}
              state={{ title: route.title }}
              className={props.url === route.match_url ? "active" : ""}
              style={{ flexDirection: width <= 767 ? "column" : "row" }}
            >
              <img
                src={route.icon}
                alt="orders"
                style={{
                  width: "19px",
                  marginRight:
                    width <= 767
                      ? "0"
                      : I18nManager.getConstants().isRTL
                      ? "0"
                      : "10px",
                  marginLeft:
                    width <= 767
                      ? "0"
                      : I18nManager.getConstants().isRTL
                      ? "10px"
                      : "0",
                  marginBottom: width <= 767 ? "10px" : "0",
                }}
              />
              {route.title}
            </Link>
          </li>
        );
      })}
    </Plink>
  );
}
