import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../ApiService/services";
import styled, { ThemeConsumer } from "styled-components";
import moment from "moment-timezone";
import { StringsContext } from "../DataFactory/useStrings";
import BackIcon from "../Elements/gBackIcon";
import GText from "../Elements/GText";
import { I18nManager } from "react-native";

const TimeslotDayDiv = styled.div`
  margin: 20px 0;
  h4 {
    border: 1px solid #e2e2e2;
    border-radius: 20px;
    color: #777;
    text-align: center;
    padding: 7px 12px 5px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
  }

  &.active h4 {
    background: ${({ theme }) => theme.body};
    color: #fff;
    border: 1px solid ${({ theme }) => theme.body};
  }
`;

const TimeslotDiv = styled.div`
  p {
    background: #f4f5f9;
    color: #555;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    margin: 10px 5px;
    box-sizing: border-box;
    text-align: center !important;
  }
`;

export default function OrderTimeslots(props) {
  const [strings] = useContext(StringsContext);
  const [timeslotsList, setTimeslots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate || "");

  useEffect(() => {
    ApiService({
      method: "GET",
      route: "c/timeslots?from=" + props.fromDate,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setTimeslots(response.data.data);
          setSelectedDate(
            props.selectedDate
              ? props.selectedDate
              : response.data.data[0].display
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (props.otslotsvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <BackIcon onClick={() => props.setOTslotsVisible(false)} />
        <GText
          g4
          semi
          text={strings?.w_choose_timeslot}
          style={{ fontSize: "17px" }}
        />
      </div>
      <div className="modal-body">
        <div
          style={{
            width: "140px",
            display: "inline-block",
            padding: I18nManager.getConstants().isRTL
              ? "5px 4px 5px 12px"
              : "5px 12px 5px 4px",
            borderRight: I18nManager.getConstants().isRTL
              ? "0"
              : "1px solid #e2e2e2",
            borderLeft: I18nManager.getConstants().isRTL
              ? "1px solid #e2e2e2"
              : "0",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
        >
          {timeslotsList.map((slots, i) => {
            return (
              <TimeslotDayDiv
                key={i}
                onClick={() => setSelectedDate(slots.display)}
                className={slots.display === selectedDate ? "active" : ""}
              >
                <h4>{slots.display}</h4>
              </TimeslotDayDiv>
            );
          })}
        </div>

        <div
          style={{
            width: "calc(100% - 140px)",
            display: "inline-block",
            boxSizing: "border-box",
            padding: "5px",
            verticalAlign: "top",
          }}
        >
          {timeslotsList.map((slots, i) => {
            return (
              <React.Fragment key={i}>
                {slots.display === selectedDate && (
                  <TimeslotDiv>
                    {slots.slots.map((t, i) => {
                      let from = moment(t.from_time, "HH:mm:ss").format("LT");
                      let to = moment(t.to_time, "HH:mm:ss").format("LT");
                      let formatted = `${from} - ${to}`;
                      return (
                        <React.Fragment key={i}>
                          <GText
                            text={formatted}
                            med
                            onClick={() => {
                              props.onChooseTimeslot(slots, t);
                              props.setOTslotsVisible(false);
                            }}
                            style={{ width: "calc(50% - 10px)" }}
                          />
                          {/* <p
                            key={i}
                            onClick={() => {
                              props.onChooseTimeslot(slots, t);
                              props.setOTslotsVisible(false);
                            }}
                          >
                            {formatted}
                          </p> */}
                        </React.Fragment>
                      );
                    })}
                  </TimeslotDiv>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
