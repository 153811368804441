import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Css/slickCarousel.css";
import GView from "../Elements/GView";
import { ImgUrl, addDefaultSrc } from "../ApiService/services";
import { ReactComponent as Check } from "../assets/images/icons/tick.svg";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";

const IconDiv = styled.div`
  position: relative;
  padding: 10px;
  boxsizing: border-box;
  svg {
    width: 20px;
    height: 20px;
    background: #fff;
    position: absolute;
    right: 5px;
    top: 0;
    border: 2px solid #fff;
    border-radius: 20px;
    path {
      fill: ${({ theme }) => theme.body};
    }
  }
  img {
    border: 1px solid #f4f5f9;
    opacity: 0.8;
    &.active {
      border: 1px solid ${({ theme }) => theme.body};
      opacity: 1;
    }
  }
`;

const OrderAtmOptions = (props) => {
  const { width } = useWindowSize();
  const [atmOptions, setOptions] = useState(props.data);
  const [selectedOption, setSelectedOption] = useState(
    props.defaultValue || ""
  );

  useEffect(() => {
    setSelectedOption(props.defaultValue || "");
  }, [props]);
  var SliderSettings = {
    dots: false,
    infinite: false,
    swipe: true,
    speed: 500,
    slidesToShow:
      width > 1500
        ? 5
        : width > 1200 && width <= 1500
        ? 4
        : width > 600 && width <= 1200
        ? 3
        : width > 430 && width <= 600
        ? 2
        : 2,
    slidesToScroll: 3,
  };

  return (
    <GView style={{ padding: "20px 0" }}>
      <Slider {...SliderSettings}>
        {atmOptions.map((v, i) => {
          return (
            <GView
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOption(v.amount);
                if (props.onSelect) {
                  props.onSelect(v.amount);
                }
              }}
            >
              <IconDiv>
                {v.amount == selectedOption && <Check />}
                <img
                  className={v.amount == selectedOption ? "active" : ""}
                  src={ImgUrl("atm") + "/" + v.photo}
                  alt={v.title}
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onError={(e) => addDefaultSrc(e, "atm")}
                />
              </IconDiv>
            </GView>
          );
        })}
      </Slider>
    </GView>
  );
};

export default OrderAtmOptions;
