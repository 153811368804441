import React, { useState, useContext } from "react";
import { ApiService } from "../ApiService/services";
import GBackIcon from "../Elements/gBackIcon";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { StringsContext } from "../DataFactory/useStrings";

export default function ChangePassword(props) {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [strings] = useContext(StringsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    old: "",
    new: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);

    ApiService({
      method: "POST",
      route: "c/change-password",
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setCpvisible(false);
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    old: Yup.string().required(strings?.w_required),
    new: Yup.string().required(strings?.w_required),
  });

  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setCpvisible(false)} />
        {strings?.w_change_password}
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings?.s_current_password}
                name="old"
              />

              <FormikControl
                control="input"
                type="text"
                label={strings?.w_enter_new_password}
                name="new"
              />
              <br />
              <GButton
                variant="condensed"
                children={strings?.w_update}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
                type="submit"
                loading={isBtnLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
