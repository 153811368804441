import React, { Component } from "react";
import styled from "styled-components";

const Input = styled.input`
  width: calc(100% - 60px);
  padding: 10px;
  margin: 0 20px;
  border: 1px solid #ddd;
  outline: 0;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi } = this.props) {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput,
      options
    );
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    this.autoComplete.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  getZipcode = (addressArray) => {
    let zipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        zipcode = addressArray[i].long_name;
        return zipcode;
      }
    }
  };

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    console.log(place);
    this.searchInput.blur();

    var addressArray = place.address_components;
    var city = this.getCity(addressArray);
    var area = this.getArea(addressArray);
    var state = this.getState(addressArray);
    var country = this.getCountry(addressArray);
    var zipcode = this.getZipcode(addressArray);

    var adreessComponents = {
      address: place.formatted_address,
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      country: country ? country : "",
      zipcode: zipcode ? zipcode : "",
    };

    console.log(adreessComponents);

    this.props.onPlacesChanged(
      adreessComponents,
      place.geometry.location,
      place
    );
  };

  clearSearchBox() {
    this.searchInput.value = "";
  }

  render() {
    return (
      <Input
        ref={(ref) => {
          this.searchInput = ref;
        }}
        type="text"
        onFocus={this.clearSearchBox}
        placeholder={"Search by Postcode, Area, Street or Landmark.."}
      />
    );
  }
}

export default AutoComplete;
