import React, { useState, useEffect, useContext } from "react";
import { ApiService, ApiLoginService } from "../ApiService/services";
import GBackIcon from "../Elements/gBackIcon";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import { GContent, GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderFeedback from "../Components/orderFeedback";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import OrderMessages from "../Components/orderMesaages";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import GInfo from "../Elements/gInfo";
import { StringsContext } from "../DataFactory/useStrings";
import { I18nManager } from "react-native";
import { RepeatOrder } from "../Models/RepeatOrder";
import { CartContext } from "../DataFactory/useCart";
import useCart from "../useCart";
import ModifyOrderAddress from "../forms/modifyOrderAddressForm";
import CartModal from "../Components/cartModal";
import OrderTrack from "../Components/orderGoogleMap";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../DataFactory/useLocalStorage";
import GItemTable from "../Elements/gCartItems";
import AtmOrderDetail from "./orderDetailAtm";
import CourierOrderDetail from "./orderDetailCourier";
import RegularOrderDetail from "./orderDetailRegular";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function OrderDetail(props) {
  const navigate = useNavigate();
  const [strings] = useContext(StringsContext);
  const { updateCart, setStoreCart } = useContext(CartContext);
  const { setCart } = useCart();
  const [cartStore, setCartStore] = useLocalStorage("cartStore", "");
  const [orderData, setOrderData] = useState({});
  const [cartvisible, setCartvisible] = useState(false);
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [omvisible, setOmvisible] = useState(false);
  const [oAdVisible, setOAdVisible] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [orderMessages, setOrderMessages] = useState([]);
  const {
    status,
    store,
    delivery,
    order_products,
    payment_summary,
    order_review,
    order_delivery_review,
    cancelled_log,
  } = orderData;
  const enqueueSnackbar = useEnqueueSnackbar();
  const [routeJson, setRouteJson] = useState({});

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log(response);
        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setOrderData(response.data.data);
            setOrderMessages(response.data.data?.order_messages || []);
            if (response?.data?.data?.class === "regular") {
              getRouteJson(response?.data?.data);
            }
            if (response.data?.data?.class === "courier") {
              response.data.data.order_fields.map((o) => {
                if (o.type === "document") {
                  o.value = o.value ? JSON.parse(o.value) : o.value;
                }
              });
            }
          } else {
            enqueueSnackbar(response?.data?.message);
            setOrderData(response?.data);
          }

          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  const handleUpdateStatus = (status) => {
    ApiService({
      method: "POST",
      route: "c/order/" + status + "/" + props.linkedId,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            strings?.s_placeholder_internet_title +
              ". " +
              strings?.s_placeholder_internet_subtitle
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            getOrderDetail();
            props.onStatusUpdate();
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getRouteJson(o) {
    ApiLoginService(
      "GET",
      "c/order/directions/" +
        o?.delivery?.id +
        "?key=" +
        process.env.REACT_APP_GOOGLE_DIRECTION_KEY
    ).then((response) => {
      console.log(response);
      if (response?.routes) {
        setRouteJson(response || {});
      }
    });
  }

  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setOrderInfovisible(false)} />{" "}
        {strings?.w_order_details}
        {orderData?.order_status === "pending" && (
          <GButton
            variant="linkable"
            children={strings?.w_problem}
            type="button"
            style={{
              marginLeft: I18nManager.getConstants().isRTL ? "0" : "auto",
              marginRight: I18nManager.getConstants().isRTL ? "auto" : "0",
            }}
            onClick={() => setOcrVisible(true)}
          />
        )}
        {orderData?.class === "courier" ? (
          status?.title === "Completed" && !order_review ? (
            <GButton
              variant="linkable"
              children={strings?.w_feedback}
              type="button"
              style={{ marginLeft: "auto" }}
              onClick={() => setOfvisible(true)}
            />
          ) : (
            ""
          )
        ) : (
          <>
            {orderData?.order_status === "completed" &&
            (!order_review || !order_delivery_review)
              ? orderData?.order_type != "store_pickup" && (
                  <>
                    {!order_delivery_review && order_review ? (
                      <GButton
                        variant="linkable"
                        children={strings?.w_agent_feedback}
                        type="button"
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          setOfvisible(true);
                          setReviewType("delivery");
                        }}
                      />
                    ) : (
                      <>
                        {!order_review && (
                          <GButton
                            variant="linkable"
                            children={strings?.w_feedback}
                            style={{ marginLeft: "auto" }}
                            type="button"
                            onClick={() => {
                              setOfvisible(true);
                              setReviewType("order");
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              : ""}
          </>
        )}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : orderData?.id ? (
          orderData.class === "atm" ? (
            <AtmOrderDetail orderData={orderData} settings={props.settings} />
          ) : orderData.class === "courier" ? (
            <CourierOrderDetail
              orderData={orderData}
              settings={props.settings}
              orderMessagesData={orderMessages}
            />
          ) : (
            <RegularOrderDetail
              orderData={orderData}
              settings={props.settings}
              orderMessagesData={orderMessages}
              routesData={routeJson}
            />
          )
        ) : (
          <div style={{ padding: "30px 0", textAlign: "center" }}>
            <GText g4 semi light text={orderData?.message} />
          </div>
        )}

        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
          },
        }}
        contentLabel="Order Feedback Modal"
      >
        <OrderFeedback
          reviewType={reviewType}
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail}
          orderData={orderData}
        />
      </Modal>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>

      <Modal
        isOpen={cartvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCartvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Cart Modal"
      >
        <CartModal
          cartvisible={cartvisible}
          setCartvisible={setCartvisible}
          onProceed={() => {
            navigate(
              {
                pathname: "/checkout",
              },
              {
                state: { title: "Checkout" },
              }
            );
          }}
        />
      </Modal>

      <Modal
        isOpen={oAdVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOAdVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Modify Order address Modal"
      >
        <ModifyOrderAddress
          oAdVisible={oAdVisible}
          setOAdVisible={setOAdVisible}
          orderId={orderData.id}
          data={delivery}
          onSubmit={(data) => {
            console.log(data);
            setOrderData({ ...data });
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
