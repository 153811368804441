import React, { useState, useEffect, useContext } from "react";
import { ApiLoginService } from "../ApiService/services";
import useCart from "../useCart";
import { GTableContent } from "../Elements/gContent";
import GItemTable from "../Elements/gCartItems";
import { CartContext } from "../DataFactory/useCart";
import SignIn from "../forms/login";
import SignUp from "../forms/signup";
import ForgotPassword from "../forms/forgotPassword";
import GEmpty from "../Elements/gEmpty";
import GShimmer from "../Elements/gTableShimmer";
import GButton from "../Elements/GButton";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import styled from "styled-components";
import Modal from "react-modal";
import GText from "../Elements/GText";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import GBackIcon from "../Elements/gBackIcon";
import { I18nManager } from "react-native";
import GView from "../Elements/GView";
import GProductsSlider from "../Elements/gProductsSliderView";

const StockDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.body};
  font-size: 15px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;

  p {
    text-align: left;
  }
`;

export default function CartModal(props) {
  const [strings] = useContext(StringsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisableCheckout, setIsDisableCheckout] = useState(true);
  const [signinvisible, setSigninvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const { itemCart, updateCart, storeCart } = useContext(CartContext);
  const [storeInfo, setStoreInfo] = useState({});
  const enqueueSnackbar = useEnqueueSnackbar();
  const { addProduct, removeProduct, decreaseQuantity } = useCart();
  const { settings } = useContext(SettingsContext);
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    free_products: [],
    summary: {
      data: [],
    },
  });

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  useEffect(() => {
    ApiLoginService("GET", `c/stores/${itemCart[0]?.store_id}`).then(
      (response) => {
        console.log(response);
        setStoreInfo(response.data);
      }
    );
  }, []);

  useEffect(() => {
    console.log(itemCart);

    if (itemCart && itemCart.length === 0) {
      setNewOrderData({
        products: [],
        summary: {
          data: [],
        },
      });
      return;
    }

    ApiLoginService("POST", "c/order/cart", {
      store_id: itemCart[0]?.store_id,
      products: itemCart,
    })
      .then((response) => {
        console.log(response);
        setNewOrderData(response.data);
        setIsLoading(false);
        setIsDisableCheckout(false);
        if (response.status_code === 1) {
        } else {
          setIsDisableCheckout(true);
          enqueueSnackbar(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [itemCart, storeCart]);

  const goToCheckout = () => {
    if (settings?.user?.id) {
      props.onProceed();
      props.setCartvisible(false);
    } else {
      setSigninvisible(true);
    }
    // } else {
    //   enqueueSnackbar(
    //     "Please choose area and location to proceed for checkout"
    //   );
    // }
  };

  if (props.cartvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <GBackIcon onClick={() => props.setCartvisible(false)} />
        {strings?.w_cart}
      </div>
      <div className="modal-body">
        {isLoading === true ? (
          <GShimmer />
        ) : (
          <div style={{ padding: "5px" }}>
            {itemCart &&
            newOrderData &&
            newOrderData.products.filter((p) => !p.free_with).length >= 0 &&
            newOrderData?.summary?.total_display ? (
              <React.Fragment>
                {newOrderData.products.filter((p) => !p.free_with).length !==
                newOrderData.original_count ? (
                  <StockDiv>
                    <p>
                      {newOrderData.original_count -
                        newOrderData.products.filter((p) => !p.free_with)
                          .length}{" "}
                      / {newOrderData.original_count}
                      {" " + strings?.w_products_unavilable_text}
                    </p>
                    <GButton
                      variant="linkable"
                      children={strings?.w_remove}
                      type="button"
                      onClick={() => {
                        updateCart([...newOrderData.products]);
                        localStorage.setItem(
                          "cart",
                          JSON.stringify([...newOrderData.products])
                        );
                        if (newOrderData.products.length === 0) {
                          props.setCartvisible(false);
                        }
                      }}
                    />
                  </StockDiv>
                ) : (
                  ""
                )}

                <GText
                  med
                  dir={I18nManager.getConstants().isRTL ? "rtl" : "ltr"}
                  text={
                    newOrderData.products.length +
                    " " +
                    (newOrderData.products.length === 1
                      ? strings?.w_item + " ("
                      : strings?.w_items + "  (") +
                    storeInfo?.title +
                    ")"
                  }
                  style={{ fontStyle: "italic" }}
                />
                <br />
                <GItemTable
                  items={newOrderData.products}
                  freeItems={newOrderData?.free_products || []}
                  currencySymbol={settings.currency_symbol}
                  editable={true}
                  removeItem={true}
                  onRemove={(item) => removeProduct(item)}
                  onIncQuantity={(item) => addProduct(item, item.variants)}
                  onDecQuantity={(item) => decreaseQuantity(item)}
                />

                <React.Fragment>
                  <hr
                    style={{ border: "1px solid #f2f2f2", margin: "15px 0" }}
                  />
                  {newOrderData?.other_products &&
                    newOrderData?.other_products?.length > 0 && (
                      <GView style={{ marginBottom: "10px" }}>
                        <GText
                          g5
                          text={strings.w_want_anything_else}
                          style={{
                            margin: "15px 0",
                          }}
                        />
                        <GProductsSlider
                          data={newOrderData?.other_products || []}
                          slidesShow={3}
                        />
                      </GView>
                    )}

                  <GText
                    semi
                    g4
                    text={strings?.w_bill_details}
                    style={{
                      padding: "15px 0",
                    }}
                  />
                  {newOrderData?.summary?.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={settings.currency_symbol + "" + p.value}
                        align="right"
                        color="#555"
                        padding="3px 5px"
                      />
                    );
                  })}
                </React.Fragment>

                <hr
                  style={{
                    border: "0",
                    borderBottom: "1px solid #e2e2e2",
                    margin: "5px 0",
                  }}
                />

                <GTableContent
                  title={strings?.w_to_pay}
                  value={
                    settings.currency_symbol +
                    "" +
                    newOrderData?.summary?.total_display
                  }
                  align="right"
                  bold={true}
                />

                <GButton
                  style={{
                    width: "100%",
                    margin: "15px 0",
                    fontWeight: "550",
                    letterSpacing: "-0.2px",
                  }}
                  type="button"
                  variant="condensed"
                  disabled={
                    newOrderData.products.filter((p) => !p.free_with).length !==
                    newOrderData.original_count
                      ? true
                      : isDisableCheckout
                  }
                  children={strings?.w_proceed_to_checkout}
                  onClick={() => goToCheckout()}
                />
              </React.Fragment>
            ) : (
              <GEmpty
                type="cart"
                title={strings?.s_placeholder_cart_title}
                subtitle={strings?.s_placeholder_cart_subtitle}
              />
            )}
          </div>
        )}
      </div>
      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Signin Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </React.Fragment>
  );
}
