import React from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../assets/images/icons/close.svg";
import GButton from "./GButton";
import { I18nManager } from "react-native-web";

const StyledSnackbarContent = styled(SnackbarContent)`
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(50, 50, 50);
  color: rgb(255, 255, 255);
  -webkit-box-align: center;
  align-items: center;
  flex-wrap: nowrap !important;
  padding: 6px 16px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;

  &.success {
    background: #43a047;
  }
  &.error {
    background-color: rgb(211, 47, 47);
  }
  &.warning {
    background: #ff9800;
  }
  &.info {
    background: #2196f3;
  }
`;

export const Snackbar = React.forwardRef((props, ref) => {
  const { id, message, variant, ...options } = props;
  const { closeSnackbar } = useSnackbar();
  const handleCloseSnackbar = () => {
    closeSnackbar(id);
  };

  return (
    <StyledSnackbarContent ref={ref} className={variant} {...options}>
      {/* {variant && variant === "success" ? <CheckIcon /> : <TimesIcon />} */}
      <div id="notistack-snackbar" className="SnackbarItem-message">
        {message}
      </div>
      <div
        className="SnackbarItem-action"
        style={{
          margin: I18nManager.getConstants().isRTL
            ? "0 auto 0 0"
            : "0 0 0 auto",
        }}
      >
        <GButton
          variant="linkable"
          onClick={handleCloseSnackbar}
          style={{
            margin: I18nManager.getConstants().isRTL
              ? "0 10px 0 0"
              : "0 0 0 10px",
          }}
        >
          {/* <ScreenReaderOnlyText>Close snackbar<ScreenReaderOnlyText> */}
          <CloseIcon
            aria-hidden
            style={{
              width: "11px",
              height: "11px",
              fill: "#fff",
              paddingTop: 3,
            }}
          />
        </GButton>
      </div>
    </StyledSnackbarContent>
  );
});
