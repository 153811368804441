import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

function formikInput(props) {
  const { label, name, ...rest } = props;
  return (
    <div className="form-control" style={props.divstyle}>
      {label ? (
        <label htmlFor={name} style={props.labelStyle}>
          {label}
        </label>
      ) : (
        ""
      )}

      <Field id={name} name={name} {...rest} />

      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default formikInput;
