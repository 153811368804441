import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { ApiService } from "../ApiService/services";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import useWindowSize from "../DataFactory/useWindowSize";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { UserLocContext } from "../contexts/userLocation";
import { CartContext } from "../DataFactory/useCart";
import useCart from "../useCart";
import { useNavigate } from "react-router-dom";
import GTableShimmer from "../Elements/gTableShimmer";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import GButton from "../Elements/GButton";
import GInfo from "../Elements/gInfo";
import GItemTable from "../Elements/gCartItems";
import { GTableContent } from "../Elements/gContent";
import { CheckoutForm } from "../Components/checkoutForm";
import AddTip from "../Components/checkoutOrderTip";
import OrderWallet from "../Components/checkoutWallet";
import OrderCoupons from "../Components/checkoutOrderCoupon";
import PaymentMethod from "../paymentComponents/checkoutPaymentMethods";
import OrderPlaced from "../Components/orderPlaced";
import { I18nManager } from "react-native";
import Modal from "react-modal";
import styled from "styled-components";

const StickyDiv = styled.div`
#bottomPlaceOrder {
  border-radius: 0;
  margin-bottom: 20px;
  &.sticky {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
`;

export default function Checkout() {
  let navigate = useNavigate();
  const { width } = useWindowSize();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { userLocation } = useContext(UserLocContext);
  const { itemCart, storeCart, updateCart } = useContext(CartContext);
  const { cart, addProduct, removeProduct, decreaseQuantity } = useCart();
  const [placeOrderBtn, stPlaceOrderBtn] = useState(false);
  const [CheckoutData, setCheckoutData] = useState({});
  const [newOrderData, setNewOrderData] = useState({
    products: [],
    summary: {
      data: [],
    },
    wallet: {},
    free_products: [],
  });
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [useWallet, setUseWallet] = useState(false);
  const [selectedTip, setSelectedTip] = useState("");
  const [couponVisible, setCouponVisible] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [cPaymentMethod, setCPaymentMethod] = useState({});
  const [isProceedBtnDisabled, setIsProceedbtnDisabled] = useState(true);
  const [showOrderSuccess, setShowOrderSuccess] = useState(false);
  const [placedOrderId, setOrderId] = useState("");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [gateways, setGateways] = useState({
    card: {},
    gateways: [],
  });
  const [initialLoading, setInitialLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(true);

  useEffect(() => {
    if (newOrderData?.summary?.total) {
      ApiService({
        method: "GET",
        route: `c/gateways`,
        body: { amount: newOrderData?.summary?.total },
      }).then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setGateways(response.data.data);
        }
      });
    }
  }, [newOrderData]);

  useEffect(() => {
    if (showOrderSuccess !== true) {
      if (
        !(localStorage.getItem("cart") && localStorage.getItem("cartStore"))
      ) {
        navigate({
          pathname: "/",
          state: {},
        });
      }
    }

    if (!storeCart) {
      return;
    }
    ApiService({
      method: "GET",
      route: `c/stores/${storeCart}`,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        setCheckoutData({
          store: response.data.data,
          order_type: response.data.data.order_type || "home_delivery",
          order_type_visible: response.data.data.order_type === "",
          delivery_type:
            response.data.data.delivery_type !== "scheduled" ? "asap" : "",
          asap_visible: response.data.data.delivery_type !== "scheduled",
          delivery_type_visible: response.data.data.delivery_type !== "asap",
          delivery_address:
            response.data.data.order_type !== "store_pickup"
              ? settings?.default_address || ""
              : "",
          delivery_from: "",
          delivery_to: "",
          instructions: "",
        });
      }
      setIsFormLoading(false);
    });
  }, [storeCart]);

  const OrderCheckout = () => {
    if (!storeCart) {
      return;
    }
    if (!CheckoutData?.order_type) {
      return;
    }
    let newCheckoutData = { ...CheckoutData };

    if (newCheckoutData.order_type === "home_delivery") {
      if (!newCheckoutData.delivery_address?.id) {
        stPlaceOrderBtn(false);
      }
    }
    if (newCheckoutData.delivery_type === "scheduled") {
      if (!newCheckoutData.delivery_from) {
        stPlaceOrderBtn(false);
      }
    }

    if (newCheckoutData?.order_type === "store_pickup") {
      setSelectedTip("");
    }

    console.log(newCheckoutData);
    ApiService({
      method: "POST",
      route: "c/order/checkout",
      body: {
        store_id: storeCart,
        customer_id: settings?.user?.id,
        products: itemCart,
        order_type: newCheckoutData?.order_type,
        delivery_address: newCheckoutData?.delivery_address,
        delivery_type: newCheckoutData?.delivery_type,
        delivery_from: newCheckoutData?.delivery_from,
        delivery_to: newCheckoutData?.delivery_to,
        instructions: newCheckoutData?.instructions,
        coupon_code: selectedCoupon || "",
        points: useWallet,
        tip: selectedTip,
        gateway: "cash",
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setNewOrderData(response.data.data);
          setCouponVisible(false);
          setIsProceedbtnDisabled(false);
        } else {
          setIsProceedbtnDisabled(true);
          setNewOrderData(response.data.data);
          if (couponVisible === true) {
            setSelectedCoupon("");
            setIsCouponValid(false);
          }
          if (initialLoading === true) {
            if (
              newCheckoutData.order_type &&
              newCheckoutData.delivery_type_visible &&
              newCheckoutData.delivery_type
            ) {
              enqueueSnackbar(response.data.message);
            }
            setInitialLoading(false);
            return;
          } else if (
            newCheckoutData.delivery_type_visible &&
            !newCheckoutData.delivery_type
          ) {
            enqueueSnackbar(response.data.message);
          } else if (
            newCheckoutData.order_type == "home_delivery" &&
            !newCheckoutData.delivery_address
          ) {
            enqueueSnackbar(response.data.message);
          } else if (!initialLoading) {
            enqueueSnackbar(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (showOrderSuccess !== true) {
      console.log(userLocation);
      if (!userLocation) {
        navigate(
          {
            pathname: "/",
          },
          {
            state: {},
          }
        );
      } else if (
        !itemCart ||
        (itemCart && itemCart.length <= 0 && localStorage.getItem("cartStore"))
      ) {
        navigate({
          pathname: "/",

          // state: { geofence: localStorage.getItem("area") },
        });
      } else {
      }

      OrderCheckout();
    }
  }, [itemCart, CheckoutData, selectedCoupon, useWallet, selectedTip]);

  const orderPlaced = () => {
    if (newOrderData?.summary?.total == 0) {
      finalOrderPlaced();
    } else {
      if (!cPaymentMethod?.gateway_type) {
        enqueueSnackbar(strings?.s_payment_toast);
        return;
      }
      if (cPaymentMethod.gateway_type === "web") {
        getWebToken();
      } else {
        finalOrderPlaced();
      }
    }
  };

  const getWebToken = () => {};

  const finalOrderPlaced = () => {
    setBtnLoading(true);

    const placedData = {
      store_id: storeCart,
      customer_id: settings?.user?.id,
      products: newOrderData.products,
      order_type: CheckoutData?.order_type,
      delivery_address: CheckoutData?.delivery_address,
      delivery_type: CheckoutData?.delivery_type,
      delivery_from: CheckoutData?.delivery_from,
      delivery_to: CheckoutData?.delivery_to,
      instructions: CheckoutData?.instructions,
      coupon_code: selectedCoupon || "",
      points: useWallet,
      tip: selectedTip,
      gateway:
        newOrderData?.summary?.total == 0
          ? { ...cPaymentMethod, identifier: "cash" }
          : cPaymentMethod,
    };
    console.log(placedData);
    // return;
    ApiService({ method: "POST", route: "c/orders", body: placedData }).then(
      (response) => {
        console.log(response.data);

        if (response.data.status_code === 1) {
          // setBtnLoading(false);
          setOrderId(response.data.data.id);
          setShowOrderSuccess(true);
          localStorage.setItem("cart", []);
          localStorage.setItem("cartStore", "");
          updateCart([]);
        } else {
          enqueueSnackbar(response.data.message);
          setBtnLoading(false);
          return;
        }
      }
    );
  };

  function myFunction() {
    if (itemCart && newOrderData && itemCart.length > 0) {
      var footer = document.getElementById("stickyPlaceOrderBtn");
      var footerBtn = document.getElementById("bottomPlaceOrder");
      var sticky = footer?.offsetTop - 600;
      if (sticky) {
        if (window.pageYOffset < sticky) {
          footerBtn.classList.add("sticky");
        } else {
          footerBtn.classList.remove("sticky");
        }
      }
    }
  }
  useEffect(() => {
    if (placeOrderBtn === true && width <= 959) {
      window.addEventListener("scroll", myFunction);
      myFunction();
    }
  }, [placeOrderBtn]);

  return (
    <div
      className="main-content"
      style={{ minHeight: "500px", paddingTop: "20px", paddingBottom: "20px" }}
    >
      {isFormLoading === true ? (
        <GTableShimmer />
      ) : (
        <Grid container spacing={2}>
          <Grid item xl={9} lg={8} md={7} sm={12} xs={12}>
            <div
              style={{
                maxHeight: width > 991 ? "100vh" : "auto",
                overflow: "overlay",
              }}
            >
              <GView
                style={{
                  padding: width > 767 ? "25px 20px" : "0",
                  border: width > 767 ? "1px solid #f2f2f2" : 0,
                  boxShadow: width > 767 ? "0 0 10px #cccccc85" : "none",
                  boxSizing: "border-box",
                }}
              >
                <CheckoutForm
                  CheckoutData={CheckoutData}
                  newOrderData={newOrderData}
                  onChange={(data) => {
                    setCheckoutData({ ...data });
                  }}
                  setCheckoutData={setCheckoutData}
                  stPlaceOrderBtn={stPlaceOrderBtn}
                />

                {isProceedBtnDisabled === false && (
                  <>
                    <hr
                      style={{
                        border: "0",
                        borderBottom: "1px solid #f2f2f2",
                        margin: "15px 0",
                      }}
                    />
                    <div>
                      <OrderCoupons
                        selectedCoupon={selectedCoupon}
                        setSelectedCoupon={setSelectedCoupon}
                        setIsCouponValid={setIsCouponValid}
                        isCouponValid={isCouponValid}
                        storeId={storeCart}
                        couponVisible={couponVisible}
                        setCouponVisible={setCouponVisible}
                      />
                    </div>

                    {settings?.delivery_tip === "1" &&
                    width <= 767 &&
                    CheckoutData.order_type === "home_delivery" ? (
                      <React.Fragment>
                        <hr
                          style={{
                            border: "0",
                            borderBottom: "1px solid #f2f2f2",
                            margin: "17px 0",
                          }}
                        />
                        <div>
                          <AddTip
                            selectedTip={selectedTip}
                            setSelectedTip={(tip) => setSelectedTip(tip)}
                            currencySymbol={settings.currency_symbol}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </GView>

              {placeOrderBtn === true ? (
                <GView
                  style={{
                    padding: width > 767 ? "25px 20px" : "0",
                    border: width > 767 ? "1px solid #f2f2f2" : 0,
                    boxShadow: width > 767 ? "0 0 10px #cccccc85" : "none",
                    boxSizing: "border-box",
                    marginTop: "15px",
                  }}
                >
                  {width <= 767 && (
                    <hr
                      style={{
                        border: "0",
                        borderBottom: "1px solid #f2f2f2",
                        margin: "15px 0",
                      }}
                    />
                  )}

                  <GText g4 semi text={strings?.w_payment_method} />
                  <GText
                    med
                    style={{ margin: "2px 0" }}
                    text={strings?.s_payment_toast}
                  />

                  <PaymentMethod
                    paymentGateways={gateways}
                    cPaymentMethod={cPaymentMethod}
                    onSelecteGateway={(gateway) => {
                      setCPaymentMethod(gateway);
                    }}
                    setCPaymentMethod={setCPaymentMethod}
                    isBtnLoading={isBtnLoading}
                    handleOrderPlaced={() => orderPlaced()}
                    orderTotal={
                      settings.currency_symbol +
                      newOrderData?.summary?.total_display
                    }
                    isDisabled={
                      gateways && gateways.gateways.length > 0 ? false : true
                    }
                  />

                  {width <= 767 && (
                    <hr
                      style={{
                        border: "0",
                        borderBottom: "1px solid #f2f2f2",
                        margin: "15px 0",
                      }}
                    />
                  )}
                </GView>
              ) : (
                <GButton
                  variant="condensed"
                  disabled={isProceedBtnDisabled}
                  children={strings?.w_proceed_to_payment}
                  style={{ margin: "20px 0" }}
                  onClick={() => {
                    //   setCheckoutFormData({...CheckoutData});
                    stPlaceOrderBtn(true);
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
            <GView
              style={{
                padding: width > 767 ? "25px 20px" : "0",
                border: width > 767 ? "1px solid #f2f2f2" : 0,
                boxShadow: width > 767 ? "0 0 10px #cccccc85" : "none",
                boxSizing: "border-box",
                maxHeight: width > 991 ? "100vh" : "auto",
                overflow: "overlay",
              }}
            >
              <GInfo
                title={CheckoutData?.store?.title}
                subtitle={CheckoutData?.store?.address}
                imgType="store"
                photo={CheckoutData?.store?.thumb_photo}
                subtitleStyle={{
                  color: "#777",
                  fontSize: "14px",
                  lineHeight: "19px",
                }}
              />
              {itemCart && newOrderData?.products.length > 0 ? (
                <div style={{ marginTop: "20px" }}>
                  <GText
                    g4
                    semi
                    dir={I18nManager.getConstants().isRTL ? "rtl" : "ltr"}
                    text={
                      newOrderData?.products.length +
                      (newOrderData?.products.length == 1
                        ? " " + strings?.w_item
                        : " " + strings?.w_items)
                    }
                  />
                  <GItemTable
                    items={newOrderData?.products}
                    freeItems={newOrderData?.free_products || []}
                    currencySymbol={settings.currency_symbol}
                    editable={true}
                    onRemove={(item) => removeProduct(item)}
                    onIncQuantity={(item) => addProduct(item, item.variants)}
                    onDecQuantity={(item) => decreaseQuantity(item)}
                  />
                  <hr
                    style={{
                      border: "0",
                      borderBottom: "1px solid #f2f2f2",
                      margin: "10px 0 15px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              {settings?.delivery_tip === "1" &&
              width > 767 &&
              CheckoutData.order_type === "home_delivery" ? (
                <React.Fragment>
                  <div>
                    <AddTip
                      selectedTip={selectedTip}
                      setSelectedTip={(tip) => setSelectedTip(tip)}
                      currencySymbol={settings.currency_symbol}
                    />
                  </div>
                  <hr
                    style={{
                      border: "0",
                      borderBottom: "1px solid #f2f2f2",
                      margin: "15px 0",
                    }}
                  />
                </React.Fragment>
              ) : (
                ""
              )}

              {newOrderData?.summary && (
                <>
                  <GText
                    g4
                    semi
                    text={strings?.w_bill_details}
                    style={{ padding: "5px 8px 8px", width: "auto" }}
                  />
                  {newOrderData?.summary?.data.map((p) => {
                    return (
                      <GTableContent
                        key={p.title}
                        title={p.title}
                        value={settings.currency_symbol + "" + p.value}
                        align="right"
                        color="#555"
                        padding="2px 5px"
                      />
                    );
                  })}
                  <hr
                    style={{
                      border: "0",
                      borderBottom: "1px solid #e2e2e2",
                      margin: "5px 0",
                    }}
                  />

                  <GTableContent
                    title={strings?.w_to_pay}
                    value={
                      settings.currency_symbol +
                      newOrderData?.summary?.total_display
                    }
                    align="right"
                    bold={true}
                  />
                </>
              )}
            </GView>

            {placeOrderBtn === true && width <= 959 ? (
              <StickyDiv id="stickyPlaceOrderBtn">
                <GButton
                  id="bottomPlaceOrder"
                  variant="condensed"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => orderPlaced()}
                  loading={isBtnLoading}
                  disabled={
                    gateways && gateways.gateways.length > 0 ? false : true
                  }
                >
                  <span style={{ flex: "1", textAlign: "initial" }}>
                    {strings?.w_place_order}
                  </span>
                  <span
                    style={{
                      marginLeft: "auto",
                      fontWeight: "500",
                    }}
                  >
                    {settings.currency_symbol +
                      newOrderData?.summary?.total_display}
                  </span>
                </GButton>
              </StickyDiv>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      )}

      <Modal
        isOpen={showOrderSuccess}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setShowOrderSuccess(true)}
        style={{
          content: {
            width: "350px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderPlaced
          orderId={placedOrderId}
          showOrderSuccess={showOrderSuccess}
        />
      </Modal>
    </div>
  );
}
