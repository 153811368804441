import React, { useState, useContext } from "react";
import {
  ApiLoginService,
  ApiService,
  ImgUrl,
  addDefaultLogo,
} from "../ApiService/services";
import GText from "../Elements/GText";
import GButton from "../Elements/GButton";
import GBackIcon from "../Elements/gBackIcon";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useEnqueueSnackbar } from "../DataFactory/useCustomSanckbar";
import { SettingsContext } from "../DataFactory/useSettings";
import { StringsContext } from "../DataFactory/useStrings";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GLogin from "../socialLogin/googleLogin";
// import FBLogin from "../socialLogin/facebookLogin";

export default function Signin(props) {
  const { settings, setSettings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  const initialValues = {
    username: "",
    password: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    if (props.data) {
      delete values.password;
    }
    console.log(values);

    ApiLoginService("POST", "c/login", values).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        getOauthToken(values);
      } else {
        setBtnLoading(false);
      }
      enqueueSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(strings?.w_required),
    password: Yup.string().required(strings?.w_required),
  });

  const getOauthToken = (signinData) => {
    var refresh_token_data = {
      username: signinData.username,
      password: signinData.password,
      client_id: 2,
      client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
      grant_type: "password",
      provider: "customers",
    };
    console.log(refresh_token_data);

    ApiLoginService("POST", "oauth/token", refresh_token_data)
      .then(function (response) {
        console.log(response);
        if (response) {
          if (response?.access_token) {
            localStorage.setItem("aAccessToken", response.access_token);
            localStorage.setItem("aRefreshToken", response?.refresh_token);
            let now = (Date.now() / 1000) | 0;
            localStorage.setItem("aExpiresIn", now + response.expires_in);
            props.setSigninvisible(false);
            getUserData();
          }
        }
        setBtnLoading(false);
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function getUserData() {
    ApiService({
      method: "GET",
      route: "c/me",
    }).then((response) => {
      console.log(response);
      if (response.data) {
        setBtnLoading(false);
        setSettings(response.data.data || {});
        props.setSigninvisible(false);
        // window.location.reload(false);
      }
    });
  }

  if (props.signinvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        &nbsp;&nbsp;{" "}
        <GBackIcon
          className="backIcon"
          onClick={() => props.setSigninvisible(false)}
        />
        {strings?.w_sign_in}
      </div>

      {settings?.web?.web_logo ? (
        <img
          src={ImgUrl("web") + "/" + settings?.web?.web_logo}
          className="header-logo"
          alt={settings?.project_name}
          onError={addDefaultLogo}
          width="auto"
          height="auto"
        />
      ) : (
        <GText
          g2="true"
          bold
          theme
          text={"LOGO"}
          style={{ margin: "15px 30px 10px", width: "auto" }}
        />
      )}

      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ isValid }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={strings?.w_email_or_phone}
                name="username"
              />

              <FormikControl
                control="input"
                type="password"
                label={strings?.w_password}
                name="password"
              />

              <span
                style={{
                  margin: "0 15px 25px",
                  float: "right",
                  color: ({ theme }) => theme.body,
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setFpvisible(true);
                  props.setSigninvisible(false);
                }}
              >
                {strings?.w_forgot_password_ques}
              </span>
              <br />
              <GButton
                variant="condensed"
                disabled={!isValid}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children={strings?.w_sign_in}
                type="submit"
                loading={isBtnLoading}
              />
              <br />
            </Form>
          )}
        </Formik>

        <GButton
          variant="linkable"
          style={{
            margin: "15px",
            width: "95%",
          }}
          children={strings?.w_create_an_account}
          onClick={() => {
            props.setSignupvisible(true);
            props.setSigninvisible(false);
          }}
          type="button"
        />

        <div
          style={{
            margin: "0 15px",
            textAlign: "center",
            position: "relative",
          }}
        >
          <hr
            style={{
              position: "absolute",
              width: "100%",
              marginTop: "10px",
              zIndex: "-1",
              border: "0",
              borderTop: "1px solid #ddd",
            }}
          />
          <p
            style={{
              fontSize: "14px",
              color: "#777",
              display: "inline-block",
              padding: "0 10px",
              background: "#fff",
              margin: 0,
            }}
          >
            {strings?.s_social_login}
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              margin: "15px 0",
              gap: "10px",
            }}
          >
            {/* <FBLogin /> */}
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GLogin
                phoneRequired={settings?.signup_phone == 1 ? true : false}
              />
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
