import React, { useEffect, useState, useContext, createRef } from "react";
import GText from "../Elements/GText";
import { SettingsContext } from "../DataFactory/useSettings";
import { Item } from "../Models/Item";
import { Grid } from "@mui/material";
import useCart from "../useCart";
import Modal from "react-modal";
import OrderProductVariants from "../Components/orderProductVariants";
import OrderProductInfo from "../Components/productInfo";
import GTableShimmer from "../Elements/gTableShimmer";
import GEmpty from "../Elements/gEmpty";
import useWindowSize from "../DataFactory/useWindowSize";
import { StringsContext } from "../DataFactory/useStrings";
import GProductThemeView from "../Elements/gProductThemeView";

export default function ProductsView(props) {
  const { width } = useWindowSize();
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const { addProduct, decreaseQuantity } = useCart();
  const [category, setCategory] = useState(props.data || []);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [orderVarVisible, setOrderVarVisible] = useState(false);
  const [itemInfoVisible, setItemInfoVisible] = useState(false);
  const [elRefs, setElRefs] = useState([]);

  useEffect(() => {
    if (props.data.length > 0) {
      setCategory(props.data || []);
      setElRefs((elRefs) => props.data.map((_, i) => elRefs[i] || createRef()));
    }
  }, [props]);

  useEffect(() => {
    // setTimeout(function () {
    if (props.selectedCatId) {
      if (category && category.length > 0) {
        // console.log(props.selectedCatId);
        var subCat = document.getElementById("catInd_" + props.selectedCatId);
        var subCatVal = subCat.offsetTop;
        // console.log(subCatVal);
        // console.log(elRefs[props.selectedCatId]);
        if (subCatVal) {
          window.scrollTo({
            behavior: "smooth",
            top: subCatVal + 400,
          });
        }
      }
    }
    // }, 1000);
  }, [props.selectedCatId]);

  return (
    <div
      style={{
        padding:
          width > 959
            ? settings?.product_theme === "row1"
              ? "20px 30px 20px 0"
              : "20px 30px"
            : "10px",
        borderLeft: width > 959 ? "1px solid #f2f2f2" : "0",
        margin: width > 959 ? "0 15px" : "0",
      }}
    >
      {props.isLoading === true ? (
        <GTableShimmer />
      ) : category.filter((s) => s.products.length > 0) ? (
        category
          .filter((s) => s.products.length > 0)
          .map((cat, index) => {
            return (
              <div
                style={{
                  borderBottom:
                    index + 1 < category.length
                      ? category.filter((s) => s.products.length > 0).length > 0
                        ? "2px solid #555"
                        : "0"
                      : "0",
                  marginBottom: "15px",
                }}
                key={index}
              >
                <div
                  id={"catInd_" + cat.id}
                  ref={elRefs[index]}
                  style={{
                    borderBottom:
                      index + 1 < cat.length ? "4px solid #f4f5f9" : "0",
                    padding: "10px 0",
                  }}
                >
                  <GText
                    g4
                    semi
                    text={cat.title + " (" + cat?.products.length + ")"}
                    style={{
                      margin:
                        settings?.product_theme === "row1"
                          ? width > 767
                            ? "5px 15px 10px"
                            : "5px 0 10px"
                          : "5px 0 0",
                    }}
                  />
                  <Grid
                    container
                    spacing={
                      settings?.product_theme === "row2" ||
                      settings?.product_theme === "row1"
                        ? 0
                        : 2
                    }
                    style={{
                      borderLeft:
                        settings?.product_theme === "row1" && width <= 767
                          ? "1px solid #e2e2e2"
                          : "0",
                    }}
                  >
                    {cat.products
                      .map((o) => new Item(o))
                      .map((item, ind) => {
                        return (
                          <>
                            <GProductThemeView
                              item={item}
                              ind={ind}
                              sub={cat}
                              setSelectedProduct={setSelectedProduct}
                              setItemInfoVisible={setItemInfoVisible}
                              settings={settings}
                              addProduct={addProduct}
                              decreaseQuantity={decreaseQuantity}
                              setOrderVarVisible={setOrderVarVisible}
                              strings={strings}
                            />
                          </>
                        );
                      })}
                  </Grid>
                </div>
              </div>
            );
          })
      ) : (
        <GEmpty />
      )}

      <Modal
        isOpen={orderVarVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOrderVarVisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Variant Modal"
      >
        <OrderProductVariants
          settings={settings}
          item={selectedProduct}
          orderVarVisible={orderVarVisible}
          setOrderVarVisible={setOrderVarVisible}
          onSubmitVariants={(item, itemVariants) => {
            console.log(itemVariants);
            addProduct(item, itemVariants);
            // setCart([...cart, item]);
          }}
        />
      </Modal>

      <Modal
        isOpen={itemInfoVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemInfoVisible(false)}
        style={{
          content: {
            width: "450px",
            padding: "20px 0",
          },
        }}
        contentLabel="Item Info Modal"
      >
        <OrderProductInfo
          item={selectedProduct}
          itemInfoVisible={itemInfoVisible}
          setItemInfoVisible={setItemInfoVisible}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
